"use client";

import { XMarkIcon } from "@heroicons/react/24/solid";
import FacebookIcon from "@components/svgs/facebook";
import LinkedIn from "@components/svgs/linkedin";
import Instagram from "@components/svgs/instagram";
import EditIcon from "@components/svgs/edit";
import Button from "@components/ButtonV2/ButtonV2";
import Avartar from "@assets/candidate.jpg";
import Accordion from "@components/accordion/accordion";
import { TopLoader } from "@components/loaders/toploader";
import { Suspense, lazy, useState } from "react";
import PoolPopup from "@pages/dashboard/hr/employees/viewemployee/PoolPopup";
import { Pool } from "../../../../../../types/pools";
import FilePickerImage from "@components/filepickerImage";

const ProfileUpdate = lazy(() => import("@components/accordion/add/profile"));

function CandidateProfile() {
  const [show, setShow] = useState(false);

  const [selectedPools, setSelectedPools] = useState([
    { id: 1, name: "Talent pool", color: "bg-custom_deep_purple" },
  ]);

  const addToPool = (selected: Pool) => {
    setSelectedPools([...selectedPools, selected]);
  };

  const handleRemovePool = (id: number) => {
    const newPools = selectedPools.filter((pool) => pool.id !== id);
    setSelectedPools(newPools);
  };

  return (
    <>
      <div className="gap-y-4 flex flex-col  ">
        <div className="py-8 bg-custom_faded_blue flex flex-col lg:flex-row px-4">
          <div className="flex flex-col items-center justify-center">
            <img
              src={Avartar}
              className=" h-auto w-80 rounded-full"
              alt="Picture of the Candidate"
            />

            <div className="flex gap-x-3 items-center mt-2">
              <LinkedIn />
              <Instagram />
              <FacebookIcon />
            </div>
          </div>
          <div className="lg:ms-12 mt-8 lg:mt-0">
            <div className="flex justify-between">
              <div className="flex">
                <div>
                  <h2 className="text-xl">Immaculate Akuvi</h2>
                  <p className="font-light">UI/UX Design</p>
                </div>
                <div className="ms-6">
                  {/* badge */}
                  <div className="bg-custom_faded_purple text-custom_deep_purple text-xs rounded-full px-3 py-1">
                    <p>Talent pool</p>
                  </div>
                </div>
              </div>
              <button
                onClick={() => setShow(true)}
                className="bg-transparent focus:outline-none"
              >
                <EditIcon />
              </button>
            </div>
            <div className="mt-3">
              <p className="text-gray-400 font-outfit">
                Highly skilled software quality assurance engineer with over 4
                years experience in testing and ensuring the quality of software
                applications. consistently met project deadlines and exceeded
                client expectations by implementing effective testing
                strategies. seeking a challenging role where i can leverage my
                expertise to contribute to the success of a dynamic
                organization.
              </p>
            </div>
          </div>
        </div>

        <div className="gap-5 flex lg:flex-row flex-col">
          <div className="lg:w-1/4 w-full py-4 px-3 rounded-lg flex flex-col gap-6 bg-custom_faded_blue">
            <div className="">
              <div className="flex mb-3 justify-between items-center">
                <label className="text-xs">Talent pool:</label>
                <PoolPopup handleCLick={addToPool} />
              </div>
              <div className="border rounded-xl gap-2 py-3 px-2 flex items-center flex-wrap ">
                {selectedPools.map((pool) => (
                  <div className="flex rounded-md border bg-white py-2 px-3 items-center gap-x-2">
                    <div className={`h-4 w-4 rounded-full ${pool?.color}`} />{" "}
                    <span className="text-black text-xs">{pool?.name}</span>
                    <XMarkIcon
                      onClick={() => handleRemovePool(pool.id)}
                      className="h-3 w-3 cursor-pointer"
                    />
                  </div>
                ))}
              </div>
            </div>

            {/* <div className="">
              <label className="text-xs">Candidates pool:</label>
              <Dropdown
                list={list}
                selected={selected}
                setSelected={setSelected}
              />
            </div> */}

            <div className="">
              <button className="inline-flex h-12 justify-center py-3 w-full rounded-lg bg-transparent border border-[#27CE88] px-4 text-xs  focus:outline-none  focus-visible:ring-white/75 items-center">
                Portfolio
              </button>
            </div>
            <Button text="CV" />
            <Button text="Remotelli CV" />

            <div className="">
              <FilePickerImage />
            </div>

            <div>
              <Button variant="tertiary" text="Update" onClick={() => null} />
            </div>
          </div>

          <div className="lg:w-3/4 w-full  rounded-lg flex flex-col gap-6 ">
            <div className="bg-custom_faded_blue py-4 px-3">
              <Accordion />
            </div>
          </div>
        </div>
      </div>
      <Suspense fallback={<TopLoader />}>
        <ProfileUpdate open={show} setOpen={setShow} />
      </Suspense>
    </>
  );
}

export default CandidateProfile;
