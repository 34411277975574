interface TimelineItem {
  title: string;
  description: string;
  timestamp: string;
  author?: string;
}

interface AcitiviesProps {
  items: TimelineItem[];
}

const ActivitiesList = ({ items }: AcitiviesProps) => {
  return (
    <div className="relative p-6 rounded-lg">
      <div className="absolute left-[35px] top-0 h-full border-l-[1px] border-[#94A4C4]"></div>
      <div className="space-y-10">
        {items.map((activity, index) => (
          <div key={index} className="relative flex items-start">
            <div className="relative z-10 flex-shrink-0 w-6 h-6 bg-white border-[1px] border-[#94A4C4] rounded-full"></div>

            <div className="ml-8 flex justify-between items-start w-full bg-custom_faded_blue border p-4 rounded-lg">
              <div>
                <h3 className="text-sm font-semibold text-gray-800">
                  {activity.title}
                </h3>
                <p className="text-sm text-[#7C8DB5]">{activity.description}</p>
              </div>
              <div className="text-sm text-[#7C8DB5]">{activity.timestamp}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ActivitiesList;
