 
import {SVGProps} from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={23}
    fill="none"
    {...props}
  >
    <path
      fill="#6C63FF"
      d="M10.04 3 7.53 5.5h5L10.04 3ZM2 7.5v13h16v-13H2Zm8-7 5 5h3a2 2 0 0 1 2 2v13a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-13a2 2 0 0 1 2-2h3l5-5Zm-5 18v-4h5v4H5Zm7-1v-7h4v7h-4Zm-8-5v-3h5v3H4Z"
    />
  </svg>
);
export default SvgComponent;
