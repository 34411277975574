import ButtonV2 from "@components/ButtonV2/ButtonV2";
import CustomDatePicker from "@components/datepicker";
import StatusDropdown from "@components/dropdowns/status";
import UserDropdown from "@components/dropdowns/userdropdown";
import Editor from "@components/editor";
import Input from "@components/input";
import {useState} from "react";

function UpdateTimeReport() {
  const [name, setName] = useState("Sena Khan");
  const [task, setTask] = useState(
    "Are you a communication virtuoso with exceptional problem-solving skills and a passion for customer satisfaction? Do you speak fluent Arabic? Remoteli, a leading on-demand staffing solution, is seeking talented Customer Service Assistants to join our team. This role is available in both our Accra, Ghana office and as a remote position in Kigali, Rwanda."
  );
  const [company, setCompany] = useState("Remoteli");
  const [user, setUser] = useState({
    first_name: "Sena",
    last_name: "Khan",
    id: 123,
    image: "",
  });
  const [startDate, setStartDate] = useState("02/02/2024");
  const [endDate, setEndDate] = useState("02/02/2024");
  const [days, setDays] = useState("4");
  const [hours, setHours] = useState("4");
  const [minutes, setMinutes] = useState("25");
  const [status, setStatus] = useState("In Progress");

  return (
    <div>
      <div className="gap-y-4 flex flex-col pb-10 overflow-auto">
        <div className="w-full flex-1 flex flex-col py-4 px-6 rounded-lg">
          <div className=" my-6 grid gap-x-4 gap-y-3 grid-cols-1">
            <div>
              <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                Employee name:{" "}
              </label>
              <Input
                placeholder={"eg. Sena Khan"}
                className={"mt-1 h-10"}
                value={name}
                onChange={(e: any) => setName(e.target.value)}
              />
            </div>

            <div>
              <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                Task{" "}
              </label>
              <Editor value={task} setValue={setTask} placeholder="Tasks" />

              <div className="flex mt-3 items-center gap-x-2">
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.66667 9.66667H7V7H6.33333M7 4.33333H7.00667M13 7C13 7.78793 12.8448 8.56815 12.5433 9.2961C12.2417 10.0241 11.7998 10.6855 11.2426 11.2426C10.6855 11.7998 10.0241 12.2417 9.2961 12.5433C8.56815 12.8448 7.78793 13 7 13C6.21207 13 5.43185 12.8448 4.7039 12.5433C3.97595 12.2417 3.31451 11.7998 2.75736 11.2426C2.20021 10.6855 1.75825 10.0241 1.45672 9.2961C1.15519 8.56815 1 7.78793 1 7C1 5.4087 1.63214 3.88258 2.75736 2.75736C3.88258 1.63214 5.4087 1 7 1C8.5913 1 10.1174 1.63214 11.2426 2.75736C12.3679 3.88258 13 5.4087 13 7Z"
                    stroke="#6F727A"
                    strokeWidth="1.4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span className="text-xs text-[#6F727A]">
                  Be clear and concise when writing the content. Be as inclusive
                  as possible!
                </span>
              </div>
            </div>

            <div>
              <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                Company:{" "}
              </label>
              <Input
                placeholder={"eg. Remoteli"}
                className={"mt-1 h-10"}
                value={company}
                onChange={(e: any) => setCompany(e.target.value)}
              />
            </div>

            <div>
              <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                User:{" "}
              </label>
              <UserDropdown selecteduser={user} setSelectedUser={setUser} />
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                  Start date:{" "}
                </label>
                <CustomDatePicker
                  selectedDate={startDate}
                  setSelectedDate={setStartDate}
                  type="date"
                />
              </div>
              <div>
                <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                  End date:{" "}
                </label>
                <CustomDatePicker
                  selectedDate={endDate}
                  setSelectedDate={setEndDate}
                  type="date"
                />
              </div>
              <div>
                <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                  Days:
                </label>
                <Input
                  placeholder={"eg. 2"}
                  className={"mt-1 h-10"}
                  value={days}
                  onChange={(e: any) => setDays(e.target.value)}
                />
              </div>

              <div>
                <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                  Hours:
                </label>
                <Input
                  placeholder={"eg. 3"}
                  className={"mt-1 h-10"}
                  value={hours}
                  onChange={(e: any) => setHours(e.target.value)}
                />
              </div>

              <div>
                <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                  Minutes:
                </label>
                <Input
                  placeholder={"eg. 3"}
                  className={"mt-1 h-10"}
                  value={minutes}
                  onChange={(e: any) => setMinutes(e.target.value)}
                />
              </div>

              <div>
                <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                  Status:
                </label>
                <StatusDropdown
                  selectedStatus={status}
                  setSelectedStatus={setStatus}
                />
              </div>
            </div>
          </div>
          <div className="w-full pb-8 mt-4 col-span-10 flex justify-end items-end">
            <ButtonV2 text="Update time report" onClick={() => {}} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateTimeReport;
