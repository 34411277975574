import TimeReportsTable from "./reports/timereports";
import TotalTimesTables from "./times/totaltime";

function Page() {
  return (
    <div className="pb-10 rounded-es-xl rounded-ee-xl bg-custom_faded_blue px-3">
      <TimeReportsTable />
      <TotalTimesTables />
    </div>
  );
}

export default Page;
