function Totalholidays(props: any) {
  return (
    <div>
      <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
      >
        <path
          d="M5.5 8.00001L2.5 8.50001C3.315 4.74301 5.682 3.50351 8 3.50001C9.4985 3.49751 10.976 4.01301 12 4.50001C13.024 4.01301 14.5 3.50001 16 3.50001C18.3055 3.50001 20.685 4.74301 21.5 8.50001L18.5 8.00001C18.7795 9.05001 19.394 10.896 18.5 12.5C17.01 11.1635 13.565 9.14551 12 8.00001C10.435 9.14551 6.99 11.1635 5.5 12.5C4.606 10.8965 5.2205 9.05001 5.5 8.00001Z"
          stroke="#7B61FF"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12 8C11.6225 9.9445 11.0945 14.7665 12 18.5"
          stroke="#7B61FF"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6 21.5H18C15.6065 19.2075 14.5 18.5025 12 18.5C9.5 18.4975 6.946 20.191 6 21.5Z"
          stroke="#7B61FF"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
}

export default Totalholidays;
