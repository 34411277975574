import ButtonV2 from "@components/ButtonV2/ButtonV2";
import Input from "@components/input";
import {useState} from "react";

function UpdateContact() {
  const [firstName, setFirstName] = useState("");

  return (
    <div>
      <>
        <div className="gap-y-4 flex flex-col pb-10 overflow-auto">
          <div className="w-full flex-1 flex flex-col py-4 px-6 rounded-lg">
            <div className=" my-6 grid gap-x-4 gap-y-5 grid-cols-1">
              <div>
                <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                  Address:{" "}
                </label>
                <Input
                  placeholder={"eg. 123 Main Street"}
                  className={"mt-1 h-10"}
                  value={firstName}
                  onChange={(e: any) => setFirstName(e.target.value)}
                />
              </div>
              <div>
                <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                  Post Code:{" "}
                </label>
                <Input
                  placeholder={"eg.  12345"}
                  className={"mt-1 h-10"}
                  value={firstName}
                  onChange={(e: any) => setFirstName(e.target.value)}
                />
              </div>
              <div>
                <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                  Email:{" "}
                </label>
                <Input
                  placeholder={"eg. name@provider.com"}
                  className={"mt-1 h-10"}
                  value={firstName}
                  onChange={(e: any) => setFirstName(e.target.value)}
                />
              </div>
              <div>
                <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                  Phone Number:{" "}
                </label>
                <Input
                  placeholder={"eg. 123-456-7890"}
                  className={"mt-1 h-10"}
                  value={firstName}
                  onChange={(e: any) => setFirstName(e.target.value)}
                />
              </div>
            </div>
            <div className="w-full pb-8 mt-4 col-span-10 flex justify-end items-end">
              <ButtonV2 text="Update Contact" onClick={() => {}} />
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default UpdateContact;
