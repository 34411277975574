import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

type Props = {
  value: string;
  label?: string;
  country?: string;
  onChange: (value: string) => void;
};

const PhoneInputComponent = ({
  value,
  country = "us",
  onChange,
  label,
}: Props) => {
  return (
    <div>
      {label && (
        <span className="block text-[#969EAE] text-xs mb-1">{label}</span>
      )}
      <PhoneInput
        onChange={onChange}
        country={country}
        value={value}
        buttonStyle={{
          height: "45px",
          backgroundColor: "#fff",
          borderTopLeftRadius: "8px",
          borderBottomLeftRadius: "8px",
          paddingRight: "6px",
        }}
        inputStyle={{
          width: "100%",
          height: "45px",
          borderRadius: "8px",
        }}
      />
    </div>
  );
};

export default PhoneInputComponent;
