import {PhotoIcon} from "@heroicons/react/24/outline";
import {useState} from "react";

interface Props {
  selectedImage: any;
  setSelectedImage: any;
  label?: string;
  defaultURL?: string;
}

function ImagePicker({
  selectedImage,
  setSelectedImage,
  label,
  defaultURL,
}: Props) {
  const [tempUrl, setTempUrl] = useState("");

  const handleOnChange = (e: any) => {
    e.preventDefault();
    const file = e.target.files[0];
    setSelectedImage(file);
    setTempUrl(URL.createObjectURL(file));
  };

  return (
    <>
      <label className="text-xs placeholder:text-custom_gray text-custom_black">
        {label}
      </label>
      {selectedImage ? (
        <div className="flex mt-3">
          <img
            src={tempUrl || defaultURL}
            alt="profile"
            className="h-24 w-24 rounded-full object-cover"
          />
          <div className="ml-4 w-80 flex items-center gapx-4">
            <button className="ml-4 text-xs font-semibold bg-custom_deep_blue text-white px-4 py-3 rounded-lg">
              <label>
                <input
                  onChange={handleOnChange}
                  type="file"
                  className="sr-only"
                />
                Upload new image
              </label>
            </button>
            <button
              onClick={() => setSelectedImage("")}
              className="ml-4 text-xs font-light border-custom_deep_blue border text-black px-8 py-3 rounded-lg"
            >
              Delete
            </button>
          </div>
        </div>
      ) : (
        <div className="flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-8">
          <div className="text-center">
            <PhotoIcon
              className="mx-auto h-12 w-12 text-gray-300"
              aria-hidden="true"
            />
            <div className="mt-4 flex text-sm leading-6 text-gray-600">
              <label
                htmlFor="file-upload"
                className="relative cursor-pointer rounded-md bg-white font-semibold text-custom_blue focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
              >
                <span>Upload a file</span>
                <input
                  onChange={handleOnChange}
                  id="file-upload"
                  name="file-upload"
                  value={selectedImage}
                  type="file"
                  className="sr-only"
                />
              </label>
              <p className="pl-1">or drag and drop</p>
            </div>
            <p className="text-xs leading-5 text-gray-600">
              PNG, JPG, GIF up to 10MB
            </p>
          </div>
        </div>
      )}
    </>
  );
}

export default ImagePicker;
