import Button from "@components/button";
import Drawer from "@components/drawer/Drawer";
import DeleteIcon from "@components/svgs/delete";
import PencilIcon from "@components/svgs/pencil";
import AddAdditionalInformation from "@features/employees/AddAdditionalInformation";
import UpdateAdditionalInformation from "@features/employees/UpdateAdditionalInformation";
import {Disclosure, Transition} from "@headlessui/react";
import {ChevronUpIcon} from "@heroicons/react/16/solid";
import Delete from "@pages/dashboard/cms/blogs/delete";
import {useState} from "react";

function AdditionalInformation() {
  const [edit, setEdit] = useState(false);
  const [add, setAdd] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const toggleEdit = (e: any) => {
    e?.stopPropagation();
    setEdit(!edit);
  };

  const toggleAdd = (e: any) => {
    e?.stopPropagation();
    setAdd(!add);
  };

  const toggleDelete = (e: any) => {
    e?.stopPropagation();
    setOpenDelete(!openDelete);
  };

  return (
    <Disclosure>
      {({open}) => (
        <>
          <Disclosure.Button className="flex w-full justify-between rounded-lg bg-white px-4 py-6 text-left text-sm font-medium text-black  focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75">
            <span>Additional Information</span>
            <div className="flex items-center gap-3">
              <div
                className={` ${open ? "flex" : "hidden"} items-center gap-2`}
              >
                <button onClick={toggleEdit}>
                  <PencilIcon />
                </button>
                <button onClick={toggleDelete}>
                  <DeleteIcon />
                </button>
              </div>

              <ChevronUpIcon
                className={`${
                  open ? "rotate-180 transform" : ""
                } h-6 w-6 text-black-500`}
              />
            </div>
          </Disclosure.Button>
          <Transition
            enter="transition ease-out duration-300 transform"
            enterFrom="opacity-0 translate-y-[-10%]"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-300 transform"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-[-10%]"
          >
            <Disclosure.Panel className="px-4 pb-4 bg-white pt-4 rounded-es-lg rounded-ee-lg  text-sm text-gray-500">
              <div className="flex mt-5 items-center gap-5">
                <div className="flex flex-col gap-y-8">
                  <label className="text-sm text-custom_gray">Country: </label>

                  <label className="text-sm text-custom_gray">
                    Line Manager:{" "}
                  </label>
                  <label className="text-sm text-custom_gray">
                    Department:{" "}
                  </label>
                  <label className="text-sm text-custom_gray">Division: </label>
                  <label className="text-sm text-custom_gray">Salary: </label>
                </div>

                <div className="flex flex-col gap-y-8">
                  <span className="text-sm text-black">Liam Summers</span>

                  <span className="text-sm text-black">
                    Software and Graphic Design
                  </span>
                  <span className="text-sm text-black">
                    Software and Graphic Design
                  </span>
                  <span className="text-sm text-black">Africa</span>
                  <span className="text-sm text-black"> &#8373; 2000</span>
                </div>
              </div>
              <div className="flex items-end justify-end mt-8">
                <div>
                  <Button
                    title="Add a new detail"
                    className="w-1/6 px-8"
                    onClick={toggleAdd}
                  />
                </div>
              </div>
            </Disclosure.Panel>
          </Transition>
          <Drawer
            isOpen={edit}
            onClose={toggleEdit}
            drawerHeader="Update Additional Information"
          >
            <UpdateAdditionalInformation />
          </Drawer>
          <Drawer
            isOpen={add}
            onClose={toggleAdd}
            drawerHeader="Additional Information"
          >
            <AddAdditionalInformation />
          </Drawer>

          <Delete
            open={openDelete}
            setOpen={setOpenDelete}
            selected={{
              title: "",
              class: "addtional information",
            }}
            buttonTitle="Delete"
          />
        </>
      )}
    </Disclosure>
  );
}

export default AdditionalInformation;
