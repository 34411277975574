import ContactsStats from "@components/stats/contactsStats";
import ContacTable from "../companies/view/[id]/components/contacts/contactstable";
import Drawer from "@components/drawer/Drawer";
import CopyIcon from "@components/svgs/copy";
import ShareIcon from "@components/svgs/share";
import PrintIcon from "@components/svgs/print";
import SearchComponent from "@components/baseSearch";
import { useState } from "react";
import AddNewContacts from "./components/addNewContacts";

const ContactsPage = () => {
  const [showAddContact, setShowAddContact] = useState(false);
  const toggleAddContact = () => {
    setShowAddContact(!showAddContact);
  };

  return (
    <>
      <div className="bg-[#DDEFFF33] mx-4 rounded-lg font-outfit">
        <div className="flex justify-between items-center">
          <div className="w-1/3">
            <ContactsStats />
          </div>
        </div>
      </div>
      <div className={`mt-8 bg-[#DDEFFF33] p-5`}>
        <div className="bg-white rounded-lg">
          <div className="px-5 py-4 flex justify-between items-center">
            <h1 className="text-2xl font-outfit font-semibold">Contacts</h1>
            <div className="flex gap-x-3 items-center">
              <SearchComponent
                placeholder="Find contacts.."
                value=""
                onChange={() => {}}
              />
              <CopyIcon />
              <ShareIcon />
              <PrintIcon />
              <button
                onClick={toggleAddContact}
                className="bg-custom_deep_blue text-sm text-white px-4 py-2 rounded-lg flex items-center gap-3"
              >
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.5 8.5H8.5V14.5H6.5V8.5H0.5V6.5H6.5V0.5H8.5V6.5H14.5V8.5Z"
                    fill="white"
                  />
                </svg>
                <span>Add contact</span>
              </button>
            </div>
          </div>
          <div className="bg-white mx-4 rounded-lg">
            <ContacTable handleClick={() => {}} />
            <Drawer
              drawerHeader="Add a new contact"
              isOpen={showAddContact}
              onClose={toggleAddContact}
            >
              <AddNewContacts />
            </Drawer>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactsPage;
