import React from "react";
import tasks from "./data.json";
import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/16/solid";
import Delete from "@pages/dashboard/cms/blogs/delete";
import Table from "@components/DataTable";
import data from "./data.json";
import {TableColumn} from "@components/DataTable/types";
import LinkButton from "@components/ButtonV2/LinkButton";
import PencilIcon from "@components/svgs/pencil";
import DeleteIcon from "@components/svgs/delete";

interface IEmployeeListprops {
  handleEdit: (e: any) => void;
  handleView: (e: any) => void;
}

function EmployeeList({handleEdit, handleView}: IEmployeeListprops) {
  const [start, setStart] = React.useState(0);
  const [end, setEnd] = React.useState(10);
  const [selected, setSelected] = React.useState<any>({});
  const [open, setOpen] = React.useState(false);

  const handleNext = () => {
    setStart(start + 10);
    setEnd(end + 10);
  };

  const handlePrev = () => {
    setStart(start - 10);
    setEnd(end - 10);
  };

  const handleDelete = (e: any, employee: any) => {
    e?.stopPropagation();
    setSelected(employee);
    setOpen(true);
  };

  const columns: TableColumn<any>[] = [
    {key: "id", label: "ID", sortable: true},
    {
      key: "name",
      label: "Name",
      sortable: true,
      nowrap: true,
      render: (name) => <LinkButton label={name} onClick={handleView} />,
    },
    {key: "email", label: "Email", sortable: false},
    {key: "skills", label: "Skills", sortable: true},

    {key: "status", label: "Job title", sortable: true},
    {
      key: "dateAdded",
      label: "Date added",
      sortable: true,
      nowrap: true,
      render: () => new Date().toDateString(),
    },

    {
      key: "action",
      label: "Action",
      sortable: false,
      render: () => (
        <span className="flex align-middle gap-x-2">
          <button onClick={handleEdit}>
            <PencilIcon />
          </button>
          <button onClick={(e) => handleDelete(e, data[0])}>
            <DeleteIcon />
          </button>
        </span>
      ),
    },
  ];

  return (
    <div className="px-5 py-3">
      <div className="-mx-4 mt-5 sm:-mx-0">
        <Table<any> data={data} columns={columns} onRowClick={handleView} />
      </div>

      <div className="hidden sm:flex sm:flex-1 sm:items-center mt-4 sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{start + 1}</span> to{" "}
            <span className="font-medium">{end}</span> of{" "}
            <span className="font-medium">{tasks?.length}</span> results
          </p>
        </div>
        <div>
          <nav
            className="isolate inline-flex gap-x-3 -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            <button
              onClick={handlePrev}
              className="relative inline-flex items-center rounded-full px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            {/* Current: "z-10 bg-indigo-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600", Default: "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0" */}

            <a
              href="#"
              className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-[#94A4C4] ring-1 rounded-full ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              1
            </a>

            <button
              onClick={handleNext}
              className="relative inline-flex items-center rounded-full px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
      <Delete
        open={open}
        setOpen={setOpen}
        selected={{
          title: selected?.name,
          class: "employee",
        }}
        buttonTitle="Delete"
      />
    </div>
  );
}

export default EmployeeList;
