import {Popover, PopoverButton, PopoverPanel} from "@headlessui/react";
import {ReactNode} from "react";

export default function PopUp({
  label,
  children,
}: {
  label: string | ReactNode;
  children: ReactNode;
}) {
  return (
    <div className="">
      <div className="">
        <Popover>
          <PopoverButton className="">{label}</PopoverButton>
          <PopoverPanel
            transition
            anchor="bottom"
            className="shadow rounded-xl mt-1 bg-white text-sm/6 transition duration-200 ease-in-out [--anchor-gap:var(--spacing-5)] data-[closed]:-translate-y-1 data-[closed]:opacity-0"
          >
            {children}
          </PopoverPanel>
        </Popover>
      </div>
    </div>
  );
}
