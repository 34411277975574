import SearchComponent from "@components/baseSearch";
import EmailCard from "./emailCards";
import SmallAvatar from "@assets/avatar6.png";

const emails = [
  {
    title: "Project Proposal",
    sender: "Gift Avi",
    email: "giftavi@gmail.com",
    timestamp: "2 hours ago",
    avatar: SmallAvatar,
    message:
      "Dear Paula Agyepong, I hope this email finds you well. Attached is a proposal for our suggested approach to enhance your...",
  },
  {
    title: "Budget Review",
    sender: "Paula Agyepong",
    email: "paula@gmail.com",
    timestamp: "2 hours ago",
    avatar: SmallAvatar,
    message:
      "Dear John Doe, I hope this email finds you well. Attached is a proposal for our suggested approach to enhance your company's...",
  },
  {
    title: "Budget Review",
    sender: "Paula Agyepong",
    email: "paula@gmail.com",
    timestamp: "2 hours ago",
    avatar: SmallAvatar,
    message:
      "Dear John Doe, I hope this email finds you well. Attached is a proposal for our suggested approach to enhance your company's...",
  },
  {
    title: "Budget Review",
    sender: "Paula Agyepong",
    email: "paula@gmail.com",
    timestamp: "2 hours ago",
    avatar: SmallAvatar,
    message:
      "Dear John Doe, I hope this email finds you well. Attached is a proposal for our suggested approach to enhance your company's...",
  },
];

const Email = () => {
  return (
    <div className="py-2 px-4 bg-custom_faded_blue rounded-xl">
      <div className=" py-2 px-4 bg-white rounded-xl">
        <div className="px-5 py-4 flex justify-between items-center">
          <div className="w-1/3">
            <SearchComponent
              placeholder="Find email"
              value=""
              onChange={() => {}}
            />
          </div>
          <div className="flex gap-x-3 items-center">
            <button
              onClick={() => {}}
              className="bg-custom_deep_blue text-white font-light text-sm px-10 py-3 rounded-lg flex items-center gap-3"
            >
              <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.5 8.5H8.5V14.5H6.5V8.5H0.5V6.5H6.5V0.5H8.5V6.5H14.5V8.5Z"
                  fill="white"
                />
              </svg>
              <span>Create a new Email</span>
            </button>
          </div>
        </div>
        <div className="container mx-auto my-8">
          {emails.map((email, index) => (
            <EmailCard
              key={index}
              title={email.title}
              sender={email.sender}
              email={email.email}
              timestamp={email.timestamp}
              avartar={email.avatar}
              message={email.message}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Email;
