import React from "react";
import tasks from "./data.json";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/16/solid";
import Badge from "@components/badges/Badge";
import { TableColumn } from "@components/DataTable/types";
import Table from "@components/DataTable";
import LinkButton from "@components/ButtonV2/LinkButton";

interface EmployeeLeave {
  employee: string;
  typeOfLeave: "Holiday" | "Doctors" | "Education" | "Funeral";
  extraSickLeave: boolean;
  numberOfDaysExtraTaken: number;
}

const sampleData: EmployeeLeave[] = [
  {
    employee: "Obed Frimpong",
    typeOfLeave: "Holiday",
    extraSickLeave: true,
    numberOfDaysExtraTaken: 4,
  },
  {
    employee: "Obed Frimpong",
    typeOfLeave: "Doctors",
    extraSickLeave: true,
    numberOfDaysExtraTaken: 4,
  },
  {
    employee: "Obed Frimpong",
    typeOfLeave: "Education",
    extraSickLeave: false,
    numberOfDaysExtraTaken: 4,
  },
  {
    employee: "Obed Frimpong",
    typeOfLeave: "Education",
    extraSickLeave: false,
    numberOfDaysExtraTaken: 4,
  },
  {
    employee: "Obed Frimpong",
    typeOfLeave: "Funeral",
    extraSickLeave: false,
    numberOfDaysExtraTaken: 4,
  },
  {
    employee: "Obed Frimpong",
    typeOfLeave: "Doctors",
    extraSickLeave: true,
    numberOfDaysExtraTaken: 10,
  },
];

const badgeColors: any = {
  Holiday: "leaveHoliday",
  Doctors: "leaveDoctor",
  Education: "leaveEducation",
  Funeral: "leaveFuneral",
  true: "success",
  false: "warning",
};

const badgeText: any = {
  true: "Yes",
  false: "No",
};

const LeaveSummaryListColumns: TableColumn<EmployeeLeave>[] = [
  {
    key: "employee",
    label: "Employee",
    sortable: true,
    render: (value) => <LinkButton label={value} />,
  },
  {
    key: "typeOfLeave",
    label: "Type of leave",
    render: (value) => <Badge type={badgeColors[value]} label={value} />,
  },
  {
    key: "extraSickLeave",
    label: "Extra sick leave",
    render: (value) => (
      <Badge type={badgeColors[value]} label={badgeText[value]} />
    ),
  },
  {
    key: "numberOfDaysExtraTaken",
    label: "Number of days extra taken",
  },
];

function EmployeeLeaveList() {
  const [start, setStart] = React.useState(0);
  const [end, setEnd] = React.useState(6);

  const handleNext = () => {
    setStart(start + 6);
    setEnd(end + 6);
  };

  const handlePrev = () => {
    setStart(start - 6);
    setEnd(end - 6);
  };

  return (
    <div className="px-5 py-3">
      <div className="-mx-4 sm:-mx-0">
        <Table<EmployeeLeave>
          data={sampleData}
          columns={LeaveSummaryListColumns}
        />
      </div>

      <div className="hidden sm:flex sm:flex-1 sm:items-center mt-4 sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{start + 1}</span> to{" "}
            <span className="font-medium">{end}</span> of{" "}
            <span className="font-medium">{tasks?.length}</span> results
          </p>
        </div>
        <div>
          <nav
            className="isolate inline-flex gap-x-3 -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            <button
              onClick={handlePrev}
              className="relative inline-flex items-center rounded-full px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            {/* Current: "z-10 bg-indigo-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600", Default: "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0" */}

            <a
              href="#"
              className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-[#94A4C4] ring-1 rounded-full ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              1
            </a>

            <button
              onClick={handleNext}
              className="relative inline-flex items-center rounded-full px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default EmployeeLeaveList;
