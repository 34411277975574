import PhoneInputComponent from "@components/phoneInput";
import TextInput from "@components/textInput";
import { useState, ChangeEvent } from "react";

interface LeadForm {
  name: string;
  email: string;
  phone: string;
  postcode: string;
  address: string;
  country: string;
  addressLine1: string;
  addressLine2: string;
  instagram: string;
  facebook: string;
  linkedin: string;
}

function AddLead() {
  const [form, setForm] = useState<LeadForm>({
    name: "",
    email: "",
    phone: "",
    postcode: "",
    address: "",
    country: "",
    addressLine1: "",
    addressLine2: "",
    instagram: "",
    facebook: "",
    linkedin: "",
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const handlePhoneChange = (value: string) => {
    setForm((prevForm) => ({ ...prevForm, phone: value }));
  };

  return (
    <div className="gap-y-4 flex flex-col pb-12">
      <div className="w-full flex-1 flex flex-col py-4 px-6 rounded-lg">
        <div className="my-6 grid gap-x-4 gap-y-5 grid-cols-1 lg:px-2 xl:px-0">
          {Object.keys(form).map((key) =>
            key !== "phone" ? (
              <TextInput
                key={key}
                name={key}
                value={form[key as keyof LeadForm]}
                label={`${key.charAt(0).toUpperCase() + key.slice(1)}:`}
                onChange={handleChange}
              />
            ) : (
              <PhoneInputComponent
                value={form.phone}
                onChange={handlePhoneChange}
                label="Phone:"
              />
            )
          )}
        </div>
        <div className="lg:col-span-4 mt-4 col-span-10 flex justify-end items-end">
          <button className="bg-[#1F3C49] rounded-lg font-light py-3 px-4 flex">
            <span className="text-white">Add a new lead</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default AddLead;
