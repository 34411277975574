import LMSPerWeek from "./lmsperweek";
import LMSANALYTICS from "./lmsanalytics";
function LMSGraph() {
  return (
    <div className="mt-4">
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 lg:grid-cols-10 lg:px-2 xl:px-0">
        <LMSPerWeek />
        <LMSANALYTICS />
      </div>
    </div>
  );
}

export default LMSGraph;
