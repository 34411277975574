import Dropdown from "@components/dropdowns/dropdown";
import {Months} from "@utils/constants";
import {useState} from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
} from "recharts";

function LMSANALYTICS() {
  const data = [
    {day: "Mon", value: 4},
    {day: "Tue", value: 9, label: 245},
    {day: "Wed", value: 6},
    {day: "Thu", value: 10},
    {day: "Fri", value: 8},
    {day: "Sat", value: 15, label: 357},
    {day: "Sun", value: 20},
  ];

  const CustomTooltip = ({active, payload}: any) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-white border border-gray-300 shadow-md p-2 rounded text-center">
          <p className="text-sm font-semibold">{payload[0].value}m</p>
        </div>
      );
    }
    return null;
  };
  const renderLabels = (dataPoint: any, index: number) => {
    if (dataPoint.label) {
      return (
        <text
          key={`label-${index}`}
          x={index * 100 + 30} // Adjust positioning
          y={220 - dataPoint.value * 10} // Adjust height
          dy={-10}
          textAnchor="middle"
          fill="#FF4C29"
          fontSize={12}
          fontWeight="bold"
        >
          {dataPoint.label}
        </text>
      );
    }
    return null;
  };
  const [selected, setSelected] = useState("January");

  return (
    <div className="col-span-5 flex flex-col bg-white rounded-xl px-3 pt-3">
      <div className="flex justify-between items-start gap-y-2">
        <h4 className="font-outfit font-semibold text-black">
          Engagement analytics
        </h4>
        <Dropdown selected={selected} setSelected={setSelected} list={Months} />
      </div>

      <div className="w-full mt-6">
        <ResponsiveContainer width="100%" height={250}>
          <LineChart
            data={data}
            margin={{top: 20, right: 20, bottom: 20, left: 0}}
          >
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis
              dataKey="day"
              tick={{
                fontSize: 12,
                fontWeight: 400,
                fill: "#6B7280",
              }}
              axisLine={false}
              tickLine={false}
            />
            <YAxis
              tick={{
                fontSize: 12,
                fontWeight: 400,
                fill: "#6B7280",
              }}
              tickFormatter={(tick) => `${tick}m`}
              axisLine={false}
              tickLine={false}
            />
            <Tooltip
              content={<CustomTooltip />}
              cursor={{stroke: "#ccc", strokeDasharray: "5 5"}}
            />
            <Line
              type="monotone"
              dataKey="value"
              stroke="#16DBCC"
              strokeWidth={2}
              dot={{r: 4, fill: "#00C49F"}}
              activeDot={{r: 8, stroke: "#FF4C29", strokeWidth: 2}}
            />
            {/* Render custom labels */}
            {data.map(renderLabels)}
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

export default LMSANALYTICS;
