const LinkedinAlt = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_7109_130198)">
        <path
          d="M18 3C18.7956 3 19.4374 3.43739 20 4C20.5626 4.56261 21 5.20435 21 6V18C21 18.7956 20.5626 19.4374 20 20C19.4374 20.5626 18.7956 21 18 21H6C5.20435 21 4.56261 20.5626 4 20C3.43739 19.4374 3 18.7956 3 18V6C3 5.20435 3.43739 4.56261 4 4C4.56261 3.43739 5.20435 3 6 3H18ZM18 5H6C5.73478 5 5.18754 4.81246 5 5C4.81246 5.18754 5 5.73478 5 6V18C5 18.2652 4.81246 18.8125 5 19C5.18754 19.1875 5.73478 19 6 19H18C18.2652 19 18.8125 19.1875 19 19C19.1875 18.8125 19 18.2652 19 18V6C19 5.73478 19.1875 5.18754 19 5C18.8125 4.81246 18.2652 5 18 5ZM8 10C8.24493 10 8.81697 9.83724 9 10C9.18303 10.1628 8.97134 10.7567 9 11V16C8.99972 16.2549 9.17497 16.8147 9 17C8.82503 17.1853 8.25444 16.9851 8 17C7.74556 17.0149 7.19545 17.1636 7 17C6.80455 16.8364 7.0301 16.2531 7 16V11C7 10.7348 6.81246 10.1875 7 10C7.18754 9.81246 7.73478 10 8 10ZM11 9C11.2342 8.99996 11.8202 8.84996 12 9C12.1798 9.15004 11.9581 9.76959 12 10C12.2012 9.88331 12.7863 10.0918 13 10C13.667 9.715 14.098 8.717 15 9C15.473 9.15 15.648 9.523 16 10C16.315 10.425 16.966 11.419 17 12V16C16.9997 16.2549 17.175 16.8147 17 17C16.825 17.1853 16.2544 16.9851 16 17C15.7456 17.0149 15.1955 17.1636 15 17C14.8045 16.8364 15.0301 16.2531 15 16V12C15 11.67 15.052 11.071 15 11C14.9254 10.9072 15.1155 11.0292 15 11C14.652 10.89 13.333 10.858 13 11C12.5 11.214 12.312 11.689 12 12V16C11.9997 16.2549 12.175 16.8147 12 17C11.825 17.1853 11.2544 16.9851 11 17C10.7456 17.0149 10.1955 17.1636 10 17C9.80455 16.8364 10.0301 16.2531 10 16V10C10 9.73478 9.81246 9.18754 10 9C10.1875 8.81246 10.7348 9 11 9ZM8 7C8.26522 7 8.81246 6.81246 9 7C9.18754 7.18754 9 7.73478 9 8C9 8.26522 9.18754 8.81246 9 9C8.81246 9.18754 8.26522 9 8 9C7.73478 9 7.18754 9.18754 7 9C6.81246 8.81246 7 8.26522 7 8C7 7.73478 6.81246 7.18754 7 7C7.18754 6.81246 7.73478 7 8 7Z"
          fill="#232325"
        />
      </g>
      <defs>
        <clipPath id="clip0_7109_130198">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LinkedinAlt;
