import LMSGraph from "@components/stats/graphs/lmsgraphs";
import LMSStats from "@components/stats/lmsstats";
import SearchIcon from "@components/svgs/search";
import EmptyInterface from "@components/empty";
import data from "@pages/dashboard/hr/employees/dataview/data.json";
import Input from "@components/input";
import LMSList from "./dataview";
import ToggleGrid from "@components/svgs/toggleGrid";
import ToggleTable from "@components/svgs/toggleTable";
import {useState} from "react";
import LMSGridList from "./dataview/gridlist";
import {useNavigate} from "react-router-dom";

function LMS() {
  const [structure, setStructure] = useState("table");

  const toggleStructure = (strct: "table" | "grid") => {
    setStructure(strct);
  };

  const navigate = useNavigate();

  return (
    <div className="bg-white">
      <div className="bg-[#DDEFFF33] p-1 rounded-lg font-inter">
        <div className="flex justify-between items-center">
          <div className="w-1/2">
            <LMSStats />
          </div>
        </div>
        <LMSGraph />
      </div>
      <div className="bg-[#DDEFFF33] p-1 mt-3">
        <div className={` ${data && "bg-white rounded-2xl"}`}>
          <div className="px-5 py-4 flex justify-between items-center">
            <h1 className="text-2xl font-outfit font-semibold">
              Current Courses
            </h1>
            <div className="flex gap-x-3 items-center">
              <div className="relative">
                <SearchIcon className="absolute top-3 left-3" />

                <Input
                  placeholder="Find course..."
                  className={`ps-10 text-sm`}
                />
              </div>
              <div className="border items-center gap-x-3 rounded-md px-2 py-2 h-full flex">
                <ToggleGrid
                  onClick={() => toggleStructure("grid")}
                  className={`cursor-pointer ${
                    structure === "grid"
                      ? "text-custom_deep_blue"
                      : "text-custom_light_gray"
                  } `}
                />

                <svg
                  width="2"
                  height="20"
                  viewBox="0 0 2 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M1 0V20" stroke="#7085A2" />
                </svg>

                <ToggleTable
                  onClick={() => toggleStructure("table")}
                  className={`cursor-pointer ${
                    structure === "table"
                      ? "text-custom_deep_blue"
                      : "text-custom_light_gray"
                  } `}
                />
              </div>

              <div className="flex">
                <button
                  onClick={() => navigate("/lms/addcourse")}
                  className="flex items-center gap-3 bg-custom_deep_blue rounded-xl text-sm px-4  font-outfit  py-3 text-white"
                >
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.5 8.5H8.5V14.5H6.5V8.5H0.5V6.5H6.5V0.5H8.5V6.5H14.5V8.5Z"
                      fill="white"
                    />
                  </svg>
                  <span>Add new course</span>
                </button>
              </div>
            </div>
          </div>
          <div className="">
            {data.length === 0 ? (
              <div className="mt-4 border border-custom_lighter_gray rounded-2xl">
                <EmptyInterface
                  description="No tasks assigned yet..."
                  buttonText="Add a new task"
                  buttonAction={() => {}}
                />
              </div>
            ) : (
              <div>
                <div className="bg-white">
                  {structure === "table" ? <LMSList /> : <LMSGridList />}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LMS;
