import FacebookIcon from "@components/svgs/faceBookAlt";
import LinkedIn from "@components/svgs/linkedinAlt";
import Instagram from "@components/svgs/instagramAlt";
import Twitter from "@components/svgs/twitter";
import Avartar from "@assets/candidateSummary.png";
import SmallAvatar from "@assets/avatar6.png";
import Badge from "@components/badges/Badge";
import {CalendarDaysIcon} from "@heroicons/react/24/outline";
import LinkButton from "@components/ButtonV2/LinkButton";
import {useNavigate} from "react-router-dom";
import NotesComponent from "./NotesComponent";
import ButtonV2 from "@components/ButtonV2/ButtonV2";

type CandidateSummaryModalProps = {};

interface DetailCardProps {
  label: string;
  value: string;
  valueColor?: string;
}

interface Task {
  id: string;
  title: string;
  description: string;
  status: "incomplete" | "completed";
  dueDate: string;
  assignee: {
    name: string;
    avatarSrc: string;
  };
}

const statusTypes: any = {
  completed: "success",
  incomplete: "error",
};

const tasks: Task[] = [
  {
    id: "1",
    title: "Design a Responsive Dashboard for a Talent Management Platform",
    description:
      "The platform is used by HR admins to manage and track talent pools, view candidate profiles, and assign roles based on specific categories (e.g., Software Development, Marketing). This dashboard should provide a clear overview of talent pools, allow admins to quickly access individual profiles, and offer a smooth navigation experience between categories.",
    status: "incomplete",
    dueDate: "12/01/2024",
    assignee: {
      name: "Fidelia Quaye",
      avatarSrc: SmallAvatar,
    },
  },
  {
    id: "2",
    title: "Redesign the User Onboarding Flow for a Job Application Platform",
    description:
      "The platform is used by HR admins to manage and track talent pools, view candidate profiles, and assign roles based on specific categories (e.g., Software Development, Marketing). This dashboard should provide a clear overview of talent pools, allow admins to quickly access individual profiles, and offer a smooth navigation experience between categories.",
    status: "completed",
    dueDate: "12/01/2024",
    assignee: {
      name: "Fidelia Quaye",
      avatarSrc: SmallAvatar,
    },
  },
];

const notes = [
  {
    id: "1",
    title: "Meeting with customer",
    content:
      "Reduce technical debt by refactoring legacy code and improving architecture design. Reduce technical debt by refactoring legacy code and improving architecture design. Reduce technical debt by refactoring legacy code and improving architecture design. Reduce technical debt by refactoring legacy code and improving architecture design.",
    timestamp: "12/01/2024 12:05pm",
    author: {
      name: "Fidelia Quaye",
      avatarSrc: SmallAvatar,
    },
    accentColor: "bg-indigo-500",
  },
];

const TaskCard: React.FC<{task: Task}> = ({task}) => {
  return (
    <div className="rounded-lg p-6 mb-4  border border-custom_input_border">
      <div className="flex justify-between items-start mb-4">
        <h3 className="text-sm font-semibold text-custom_black">
          {task.title}
        </h3>
        <Badge
          type={statusTypes[task.status]}
          label={task.status === "incomplete" ? "Incomplete" : "Completed"}
        />
      </div>

      <p className="text-[#82868C] text-xs font-light mb-6">
        {task.description}
      </p>

      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-3">
          <img
            src={task.assignee.avatarSrc}
            alt={`${task.assignee.name}'s avatar`}
            className="w-8 h-8 rounded-full"
          />
          <span className="text-sm text-gray-700">{task.assignee.name}</span>
          <div className="flex items-center text-gray-500">
            <CalendarDaysIcon className="w-4 h-4 mr-2" />
            <span className="text-sm">Due: {task.dueDate}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const DetailCard = ({
  label,
  value,
  valueColor = "text-custom_danger_base",
}: DetailCardProps) => {
  return (
    <div className="border border-custom_input_border rounded-md p-3 ">
      <h3 className="text-[#82868C] font-extralight text-sm mb-2">{label}</h3>
      <p className={`text-xl font-medium ${valueColor}`}>{value}</p>
    </div>
  );
};

const CandidateSummaryModal = ({}: CandidateSummaryModalProps) => {
  const navigate = useNavigate();
  return (
    <div className="overflow-auto pb-20 px-6">
      {" "}
      <div className="flex flex-col items-center justify-center border-b border-custom_input_border pb-3">
        <img
          src={Avartar}
          className="h-[168px] w-[162px] rounded-full mb-5"
          alt="Picture of the Candidate"
        />
        <div className="flex flex-col gap-y-2">
          <h2 className="text-xl text-custom_black">Immaculate Akuvi</h2>
          <div className="flex gap-2">
            <p className="font-light pr-2 border-r border-custom_input_border">
              UI/UX Design
            </p>
            <Badge type="neutral" label="Talent pool" />
          </div>
        </div>
        <div className="flex gap-x-3 items-center mt-2">
          <LinkedIn />
          <Instagram />
          <FacebookIcon />
          <Twitter />
        </div>
        <div className=" my-4">
          <ButtonV2
            onClick={() =>
              navigate("/hr/candidates/viewcandidate/1#candidate-profile")
            }
            className="bg-custom_button_dark"
            text="View candidate profile"
          />
        </div>
      </div>
      <div className="py-6 space-y-6 border-b border-custom_input_border">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <DetailCard label="Notice period" value="2022-09-30" />
          <DetailCard label="Salary expectation" value="GHS 4,000" />
        </div>
      </div>
      <div className="py-6 border-b border-custom_input_border">
        <p className="text-custom_black text-sm leading-relaxed">
          Highly skilled software quality assurance engineer with over 4 years
          experience in testing and ensuring the quality of software
          applications. consistently met project deadlines and exceeded client
          expectations by implementing effective testing strategies. seeking a
          challenging role where i can leverage my expertise to contribute to
          the success of a dynamic organization.
        </p>
      </div>
      <div className="py-6 border-b border-custom_input_border">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-lg font-light text-[#82868C]">Task:</h1>
          <LinkButton
            underlineOnHover
            label="View all Tasks"
            onClick={() => navigate("viewcandidate/${task?.id}#tasks")}
          />
        </div>

        <div className="space-y-4">
          {tasks.map((task) => (
            <TaskCard key={task.id} task={task} />
          ))}
        </div>
      </div>
      <div className="py-6">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-lg font-light text-[#82868C]">Notes:</h1>
          <LinkButton
            underlineOnHover
            label="View all notes"
            onClick={() => navigate("viewcandidate/${task?.id}#notes")}
          />
        </div>
        <NotesComponent notes={notes} />
      </div>
    </div>
  );
};

export default CandidateSummaryModal;
