import CustomDatePicker from "@components/datepicker";
import StatusDropdown from "@components/dropdowns/status";
import Input from "@components/input";
import {useState} from "react";

const AddNewJobApplication = () => {
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  return (
    <>
      <div className="gap-y-4 flex flex-col pb-28">
        <div className="w-full flex-1 flex flex-col py-4 px-6 rounded-lg">
          <div className="lg:col-span-2 col-span-10">
            <label className="text-xs">Job title</label>

            <div className="mt-1">
              <Input />
            </div>
          </div>
          <div className="lg:col-span-2 col-span-10">
            <label className="text-xs">Status:</label>
            <StatusDropdown
              selectedStatus={selectedStatus}
              setSelectedStatus={setSelectedStatus}
            />
          </div>
          <div className="grid gap-4 my-4">
            <div className="lg:col-span-2 col-span-10">
              <label className="text-xs">Date applied</label>

              <div className="mt-1">
                <CustomDatePicker
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="lg:col-span-4 mt-4 col-span-10 flex justify-end items-end">
          <button className="bg-[#1F3C49] rounded-lg font-light py-3 px-4 flex">
            <span className="text-white">Add a job application</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default AddNewJobApplication;
