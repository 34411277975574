import { SVGProps } from "react";

const TimeLine = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      {...props}
    >
      <g clip-path="url(#clip0_534_35506)">
        <path
          d="M12 2.5C17.523 2.5 22 6.977 22 12.5C22 18.023 17.523 22.5 12 22.5C6.477 22.5 2 18.023 2 12.5C2 6.977 6.477 2.5 12 2.5ZM12 4.5C9.87827 4.5 7.84344 5.34285 6.34315 6.84315C4.84285 8.34344 4 10.3783 4 12.5C4 14.6217 4.84285 16.6566 6.34315 18.1569C7.84344 19.6571 9.87827 20.5 12 20.5C14.1217 20.5 16.1566 19.6571 17.6569 18.1569C19.1571 16.6566 20 14.6217 20 12.5C20 10.3783 19.1571 8.34344 17.6569 6.84315C16.1566 5.34285 14.1217 4.5 12 4.5ZM12 6.5C12.2449 6.50003 12.4813 6.58996 12.6644 6.75272C12.8474 6.91547 12.9643 7.13975 12.993 7.383L13 7.5V12.086L15.707 14.793C15.8863 14.973 15.9905 15.2144 15.9982 15.4684C16.006 15.7223 15.9168 15.9697 15.7488 16.1603C15.5807 16.3508 15.3464 16.4703 15.0935 16.4944C14.8406 16.5185 14.588 16.4454 14.387 16.29L14.293 16.207L11.293 13.207C11.1376 13.0514 11.0378 12.849 11.009 12.631L11 12.5V7.5C11 7.23478 11.1054 6.98043 11.2929 6.79289C11.4804 6.60536 11.7348 6.5 12 6.5Z"
          fill="#A0CCFF"
        />
      </g>
      <defs>
        <clipPath id="clip0_534_35506">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TimeLine;
