 
import {SVGProps} from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={15}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillOpacity={0.7}
      d="M7.625 0A2.635 2.635 0 0 0 5 2.625 2.636 2.636 0 0 0 7.625 5.25a2.636 2.636 0 0 0 2.625-2.625A2.636 2.636 0 0 0 7.625 0Zm6.75 0a2.636 2.636 0 0 0-2.625 2.625 2.636 2.636 0 0 0 2.625 2.625A2.636 2.636 0 0 0 17 2.625 2.636 2.636 0 0 0 14.375 0Zm-6.75 1.5c.63 0 1.125.495 1.125 1.125S8.255 3.75 7.625 3.75 6.5 3.255 6.5 2.625 6.995 1.5 7.625 1.5Zm6.75 0c.63 0 1.125.495 1.125 1.125s-.495 1.125-1.125 1.125-1.125-.495-1.125-1.125.495-1.125 1.125-1.125ZM4.25 4.5c-1.65 0-3 1.35-3 3 0 .835.358 1.588.914 2.133A3.777 3.777 0 0 0 .5 12.75H2a2.24 2.24 0 0 1 2.25-2.25 2.24 2.24 0 0 1 2.25 2.25H8a3.777 3.777 0 0 0-1.664-3.117A2.985 2.985 0 0 0 7.25 7.5c0-1.65-1.35-3-3-3ZM8 12.75A3.768 3.768 0 0 0 7.25 15h1.5A2.24 2.24 0 0 1 11 12.75 2.24 2.24 0 0 1 13.25 15h1.5a3.768 3.768 0 0 0-1.664-3.117A2.985 2.985 0 0 0 14 9.75c0-1.65-1.35-3-3-3s-3 1.35-3 3c0 .835.358 1.588.914 2.133A3.78 3.78 0 0 0 8 12.75Zm6 0h1.5a2.24 2.24 0 0 1 2.25-2.25A2.24 2.24 0 0 1 20 12.75h1.5a3.778 3.778 0 0 0-1.664-3.117A2.985 2.985 0 0 0 20.75 7.5c0-1.65-1.35-3-3-3s-3 1.35-3 3c0 .835.358 1.588.914 2.133A3.777 3.777 0 0 0 14 12.75ZM4.25 6c.838 0 1.5.662 1.5 1.5S5.088 9 4.25 9s-1.5-.662-1.5-1.5S3.412 6 4.25 6Zm13.5 0c.838 0 1.5.662 1.5 1.5S18.588 9 17.75 9s-1.5-.662-1.5-1.5.662-1.5 1.5-1.5ZM11 8.25c.838 0 1.5.662 1.5 1.5s-.662 1.5-1.5 1.5-1.5-.662-1.5-1.5.662-1.5 1.5-1.5Z"
    />
  </svg>
);
export default SvgComponent;
