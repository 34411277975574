const GhanaFlag = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <g clip-path="url(#clip0_9350_150648)">
        <path
          d="M0 9.99898C0 11.2222 0.220039 12.3939 0.621992 13.4772L10 13.912L19.378 13.4773C19.78 12.3939 20 11.2222 20 9.99898C20 8.77578 19.78 7.60406 19.378 6.52074L10 6.08594L0.621992 6.5207C0.220039 7.60406 0 8.77578 0 9.99898Z"
          fill="#FFDA44"
        />
        <path
          d="M9.9991 0C5.69945 0 2.03402 2.71375 0.621094 6.52176H19.3771C17.9642 2.71375 14.2987 0 9.9991 0Z"
          fill="#D80027"
        />
        <path
          d="M19.3771 13.4766H0.621094C2.03402 17.2845 5.69945 19.9983 9.9991 19.9983C14.2987 19.9983 17.9642 17.2845 19.3771 13.4766Z"
          fill="#496E2D"
        />
        <path
          d="M9.99898 6.52344L10.8623 9.18047H13.6563L11.3961 10.8228L12.2593 13.4799L9.99898 11.8377L7.73863 13.4799L8.60207 10.8228L6.3418 9.18047H9.1357L9.99898 6.52344Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_9350_150648">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GhanaFlag;
