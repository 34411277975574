import PopUp from "@components/PopOver";
import AddIcon from "@components/svgs/AddIcon";
import {Pool} from "../../../../../types/pools";

interface PoolPopupProps {
  handleCLick: (selected: Pool) => void;
}

function PoolPopup({handleCLick}: PoolPopupProps) {
  const pools = [
    {id: 1, name: "Candidate", color: "bg-custom_deep_purple"},
    {id: 2, name: "Interviewed", color: "bg-card_green"},
    {id: 3, name: "Employee", color: "bg-custom_orange"},
    {id: 3, name: "Rejected", color: "bg-custom_red"},
  ];

  return (
    <div>
      <PopUp label={<AddIcon />}>
        <div className="bg-white shadow-2xl z-50 flex flex-col gap-y-4 py-6 px-8">
          {pools.map((pool) => (
            <div
              onClick={() => handleCLick(pool)}
              key={pool.id}
              className="flex cursor-pointer select-none gap-x-3 px-4 items-center"
            >
              <div className={`h-4 w-4 rounded-full ${pool.color}`} />
              <span>{pool.name}</span>
            </div>
          ))}
        </div>
      </PopUp>
    </div>
  );
}

export default PoolPopup;
