import Dropdown from "@components/dropdowns/dropdown";
import Input from "@components/input";
import DeleteIcon from "@components/svgs/delete";
import Star from "@components/svgs/Star";
import Timer from "@components/svgs/Timer";
import {useState} from "react";

const list = [
  {
    value: "Keep choices in current order",
    name: "Keep choices in current order",
    id: 1,
  },
  {
    value: "Randomize choices",
    name: "Randomize choices",
    id: 2,
  },
];

function TimeFields({removeQuiz}: {removeQuiz: () => void}) {
  const [randomizeOrder, setRandomizeOrder] = useState("");
  return (
    <div className="col-span-2 grid grid-cols-5 gap-4">
      <div className="col-span-5 md:col-span-1">
        <label className="text-xs placeholder:text-custom_gray text-black">
          Randomize Order:
        </label>

        <Dropdown
          list={list}
          selected={randomizeOrder}
          setSelected={setRandomizeOrder}
        />
      </div>

      <div className=" col-span-2 md:col-span-1">
        <label className="text-xs placeholder:text-custom_gray text-black">
          Estimation Time (Opt):
        </label>
        <div className="border rounded-lg flex items-center gap-2">
          <div className="w-full md:w-1/2">
            <Input
              className="border-0 w-full focus:ring-0 outline-none bg-red-500"
              placeholder="2"
            />
          </div>
          <div className="border-l w-1/2 px-3 hidden md:block border-custom_input_border my-2.5">
            <div className="flex items-center justify-between">
              <span className="text-custom_text_secondary">Min</span>
              <Timer />
            </div>
          </div>
        </div>
      </div>

      <div className="col-span-2 md:col-span-1">
        <label className="text-xs placeholder:text-custom_gray text-black">
          Mark as point:
        </label>
        <div className="border rounded-lg flex items-center gap-2">
          <div className=" w-1/2">
            <Input
              className="border-0 focus:ring-0 outline-none bg-red-500"
              placeholder="10"
            />
          </div>
          <div className="border-l w-1/2 px-3 hidden md:block border-custom_input_border my-2.5">
            <div className="flex items-center justify-between">
              <span className="text-custom_text_secondary font-normal">
                Points
              </span>
              <Star />
            </div>
          </div>
        </div>
      </div>

      <div className="md:col-span-2 justify-end flex items-center">
        <button onClick={removeQuiz}>
          <DeleteIcon className="mt-5 h-5 w-5" />
        </button>
      </div>
    </div>
  );
}

export default TimeFields;
