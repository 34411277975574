import FilePicker from "@components/filepicker";

function OpenEnded() {
  return (
    <>
      <div className="col-span-2">
        <label className="text-xs placeholder:text-custom_gray text-custom_gray">
          Attach video or image:
        </label>
        <FilePicker />
      </div>
    </>
  );
}

export default OpenEnded;
