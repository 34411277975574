import Select from "react-select";

const skillsOptions = [
  {
    value: "UI/UX Design",
    label: "UI/UX Design",
  },
  {
    value: "Software Engineering",
    label: "Software Engineering",
  },
  {
    value: "Finance",
    label: "Finance",
  },
  {
    value: "Customer Service",
    label: "Customer Service",
  },
  {
    value: "Business Development",
    label: "Business Development",
  },
];

function SkillSelector() {
  return (
    <>
      <Select isMulti options={skillsOptions} className="" />
    </>
  );
}

export default SkillSelector;
