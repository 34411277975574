import {SVGProps} from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={43}
    height={43}
    fill="none"
    {...props}
  >
    <path
      fill="#909090"
      d="m32.383 2.781 7.476 7.794v29.638H11.93v.096h28.026V10.674l-7.572-7.892Z"
    />
    <path
      fill="#fff"
      d="M32.292 2.688H11.836v37.528H39.86V10.579l-7.57-7.892Z"
    />
    <path fill="#7A7B7C" d="M11.63 4.703H3.043v9.174h27.01V4.703H11.628Z" />
    <path fill="#D00" d="M30.197 13.72H3.22V4.54h26.978v9.18Z" />
    <path
      fill="#464648"
      d="M12.162 6.09h-1.756v6.45h1.382v-2.175l.305.018c.296-.005.59-.059.87-.158.244-.084.47-.217.662-.39.195-.166.35-.375.45-.61.135-.392.183-.809.14-1.22a3.006 3.006 0 0 0-.152-.866 1.575 1.575 0 0 0-.924-.874 2.885 2.885 0 0 0-.978-.174m-.254 3.082h-.12V7.184h.26a.766.766 0 0 1 .617.244c.161.216.248.48.246.75 0 .33 0 .63-.299.84-.215.119-.46.173-.704.155Zm4.933-3.1c-.149 0-.294.01-.396.015l-.32.008h-1.048v6.45h1.233c.472.013.941-.067 1.382-.235.354-.14.668-.367.914-.66.238-.295.409-.638.5-1.006a5.01 5.01 0 0 0 .154-1.276 5.936 5.936 0 0 0-.117-1.514 2.389 2.389 0 0 0-.537-.985c-.2-.227-.445-.41-.72-.537a3.244 3.244 0 0 0-.737-.24 1.722 1.722 0 0 0-.306-.022m-.245 5.29h-.135V7.244h.018c.277-.032.557.018.806.143.182.146.331.33.436.538.112.22.177.46.19.707.012.295 0 .537 0 .737.006.231-.009.462-.044.69-.041.234-.118.46-.227.672-.123.196-.29.361-.488.483a.905.905 0 0 1-.559.143m6.826-5.261h-3.265v6.45h1.381V9.988h1.747v-1.2h-1.747V7.296h1.882V6.096"
    />
    <path
      fill="#D00"
      d="M29.267 27.215s4.284-.776 4.284.687-2.654.868-4.284-.687Zm-3.167.112c-.68.15-1.344.37-1.98.657l.538-1.21c.538-1.209 1.095-2.858 1.095-2.858a19.106 19.106 0 0 0 2.229 3.027c-.634.094-1.262.223-1.882.387v-.003Zm-1.696-8.734c0-1.276.413-1.624.734-1.624s.683.155.695 1.262a14.49 14.49 0 0 1-.695 3.27 5.947 5.947 0 0 1-.735-2.904l.001-.004Zm-6.247 14.13c-1.314-.786 2.756-3.206 3.494-3.284-.004.002-2.118 4.107-3.494 3.285Zm16.645-4.647c-.013-.135-.134-1.622-2.781-1.56a19.114 19.114 0 0 0-3.296.233 16.851 16.851 0 0 1-2.704-3.567c.465-1.345.747-2.746.837-4.166-.039-1.613-.425-2.537-1.66-2.524-1.237.014-1.417 1.095-1.255 2.705.16 1.082.46 2.138.894 3.142 0 0-.571 1.778-1.326 3.546-.755 1.768-1.271 2.696-1.271 2.696a12.928 12.928 0 0 0-3.662 1.881c-1.107 1.03-1.558 1.822-.974 2.613.502.683 2.261.838 3.833-1.222a30.288 30.288 0 0 0 2.285-3.349s2.397-.657 3.143-.837c.746-.18 1.647-.323 1.647-.323s2.19 2.203 4.3 2.125c2.111-.078 2.01-1.262 1.996-1.39"
    />
    <path fill="#909090" d="M32.188 2.79v7.89h7.569l-7.57-7.89Z" />
    <path
      fill="#fff"
      d="M32.293 2.688v7.891h7.57l-7.57-7.892ZM12.061 5.99h-1.756v6.45h1.386v-2.175l.307.017c.296-.005.59-.058.87-.157a1.92 1.92 0 0 0 .662-.39c.194-.167.347-.376.446-.61.135-.393.183-.809.14-1.221a3.006 3.006 0 0 0-.152-.865 1.576 1.576 0 0 0-.923-.874 2.889 2.889 0 0 0-.981-.176m-.254 3.083h-.12v-1.99h.26a.766.766 0 0 1 .617.244c.162.216.249.48.246.75 0 .33 0 .63-.298.841-.215.118-.46.172-.704.153m4.932-3.098c-.15 0-.295.01-.397.014l-.316.008h-1.048v6.45h1.234c.471.013.94-.067 1.381-.235.355-.14.669-.367.914-.66.238-.294.41-.638.501-1.006.106-.417.157-.845.153-1.275a5.932 5.932 0 0 0-.117-1.515 2.386 2.386 0 0 0-.537-.985 2.19 2.19 0 0 0-.719-.537 3.242 3.242 0 0 0-.738-.24 1.724 1.724 0 0 0-.306-.022m-.245 5.29h-.134V7.142h.018c.277-.032.557.018.806.144.182.145.33.329.435.537.113.22.178.46.19.707.013.296 0 .538 0 .738.006.23-.009.461-.043.69-.042.233-.118.46-.227.671-.124.197-.29.362-.488.484a.905.905 0 0 1-.56.142m6.823-5.26h-3.265v6.45h1.381V9.886h1.747V8.687h-1.747V7.193h1.881V5.994"
    />
  </svg>
);
export default SvgComponent;
