const FaceBookAlt = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 12C21 6.74286 17.244 2 12 2C6.756 2 2 6.74286 2 12C2 16.6095 5.668 20.1143 10 21V14H8V12H10V9C10 7.1619 11.1665 6 13 6H15V9H13C12.4775 9 12 9.47619 12 10V12H15V14H12V21C16.7975 20.5238 21 16.9429 21 12Z"
        fill="#232325"
      />
    </svg>
  );
};

export default FaceBookAlt;
