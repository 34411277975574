import {Link, useLocation} from "react-router-dom";
import {TabInterface} from "./types";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function VerticalTab({tabs}: TabInterface) {
  const {pathname} = useLocation();
  return (
    <div>
      <div className="block">
        <div className="border-gray-200">
          <nav className="-mb-px flex flex-col space-y-4" aria-label="Tabs">
            {tabs.map((tab) => (
              <Link
                key={tab.name}
                to={tab.href}
                className={classNames(
                  pathname === tab.href
                    ? "border-custom_deep_blue border-l-2 bg-custom_faded_blue text-black"
                    : "border-transparent text-black hover:border-gray-300 hover:text-gray-700",
                  "whitespace-nowrap  py-5 px-5 text-md font-medium"
                )}
                aria-current={tab ? "page" : undefined}
              >
                {tab.name}
              </Link>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
}
