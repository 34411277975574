import { ReactNode, useState } from "react";
import Table from "@components/DataTable";
import { TableColumn } from "@components/DataTable/types";
import Badge from "@components/badges/Badge";
import PencilIcon from "@components/svgs/pencil";
import DeleteIcon from "@components/svgs/delete";
import ConfirmModal from "@components/confirmationDialog/ConfirmationDialog";
import Drawer from "@components/drawer/Drawer";
import CandidateSummaryModal from "@features/candidates/CandidateSummaryModal";
import LinkButton from "@components/ButtonV2/LinkButton";
import { Rating } from "react-simple-star-rating";
import { Pagination } from "@components/pagination/Pagination";
import { useNavigate } from "react-router-dom";

interface Candidate {
  id: number;
  name: string;
  email: string;
  skills: string;
  rating: ReactNode;
  jobTitle: string;
  dateAdded: string;
  status: ReactNode;
  action: string;
}

function generateSampleCandidateData(numRows = 10) {
  const candidateData = [];
  const ratings = ["★☆☆☆☆", "★★☆☆☆", "★★★☆☆", "★★★★☆", "★★★★★"];
  const statuses = ["Accepted", "Pending", "Rejected"];
  const jobTitles = [
    "DevOps Engineer",
    "Marketing Professional",
    "UI/UX Designer",
    "Software Engineer",
    "Data Analyst",
  ];

  for (let i = 1; i <= numRows; i++) {
    const candidate = {
      id: i,
      name: `Candidate ${i}`,
      email: `candidate${i}@example.com`,
      skills: "JavaScript, React, Node.js",
      rating: ratings[Math.floor(Math.random() * ratings.length)],
      jobTitle: jobTitles[Math.floor(Math.random() * jobTitles.length)],
      dateAdded: "2023-04-12",
      status: statuses[Math.floor(Math.random() * statuses.length)],
      action: "Action",
    };
    candidateData.push(candidate);
  }

  return candidateData;
}

const badgeTypes: any = {
  Accepted: "success",
  Pending: "warning",
  Rejected: "error",
};

function CandidateList() {
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(10);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showPatientProfile, setShowPatientProfile] = useState(false);

  const navigate = useNavigate();

  function togglePatientProfileModal() {
    setShowPatientProfile(!showPatientProfile);
  }

  const columns: TableColumn<Candidate>[] = [
    { key: "id", label: "ID", sortable: true },
    {
      key: "name",
      label: "Name",
      sortable: true,
      render: (name) => (
        <LinkButton
          label={name}
          onClick={() =>
            navigate("viewcandidate/${task?.id}#candidate-profile")
          }
        />
      ),
    },
    { key: "email", label: "Email", sortable: false },
    { key: "skills", label: "Skills", sortable: true },
    {
      key: "rating",
      label: "Rating",
      sortable: true,
      render: () => <Rating initialValue={3} size={15} readonly />,
    },
    { key: "jobTitle", label: "Job title", sortable: true },
    { key: "dateAdded", label: "Date added", sortable: false },
    {
      key: "status",
      label: "Status",
      sortable: false,
      render: (status) => <Badge type={badgeTypes[status]} label={status} />,
    },
    {
      key: "action",
      label: "Action",
      sortable: false,
      render: () => (
        <span className="flex align-middle gap-1">
          <button>
            <PencilIcon />
          </button>
          <button
            onClick={(e: any) => {
              e?.stopPropagation();
              setShowDeleteDialog(true);
            }}
          >
            <DeleteIcon />
          </button>
        </span>
      ),
    },
  ];

  const handleNext = () => {
    setStart(start + 10);
    setEnd(end + 10);
  };

  const handlePrev = () => {
    setStart(start - 10);
    setEnd(end - 10);
  };

  const sampleCandidateData = generateSampleCandidateData(10);

  return (
    <div className="px-5 py-3">
      <div className="-mx-4 mt-8 sm:-mx-0">
        <Table<Candidate>
          data={sampleCandidateData}
          columns={columns}
          onRowClick={() => setShowPatientProfile(true)}
        />
      </div>

      <Pagination
        start={start}
        end={end}
        total={sampleCandidateData?.length}
        onPrevious={handlePrev}
        onNext={handleNext}
      />
      <ConfirmModal
        isOpen={showDeleteDialog}
        onCancel={() => setShowDeleteDialog(false)}
        onDelete={() => {}}
        itemName="Paula Agyepong"
        subject="Are you sure you want to delete a candidate"
      />
      <Drawer
        drawerHeader=""
        isOpen={showPatientProfile}
        onClose={togglePatientProfileModal}
      >
        <CandidateSummaryModal />
      </Drawer>
    </div>
  );
}

export default CandidateList;
