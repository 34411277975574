import CustomDatePicker from "@components/datepicker";
import Drawer from "@components/drawer/Drawer";
import Dropdown from "@components/dropdowns/dropdown";
import Input from "@components/input";

import React from "react";

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function UpdateEducation({open, setOpen}: Props) {
  const [position, setPosition] = React.useState("");
  const [company, setCompany] = React.useState("");
  const [selectedDate, setSelectedDate] = React.useState("");
  const [selectedStatus, setSelectedStatus] = React.useState("");

  return (
    <Drawer
      drawerHeader="Update education"
      isOpen={open}
      onClose={() => setOpen(false)}
    >
      <div className="mt-12 px-4 flex flex-col gap-y-4">
        <div>
          <label className="text-xs placeholder:text-custom_gray text-custom_gray">
            Institution:{" "}
          </label>
          <Input
            placeholder={"eg. University of Ibadon"}
            className={"mt-1"}
            value={position}
            onChange={(e: any) => setPosition(e.target.value)}
          />
        </div>

        <div>
          <label className="text-xs placeholder:text-custom_gray text-custom_gray">
            Programme:{" "}
          </label>
          <Input
            placeholder={"eg. Computer Science"}
            className={"mt-1"}
            value={company}
            onChange={(e: any) => setCompany(e.target.value)}
          />
        </div>
        <div>
          <label className="text-xs placeholder:text-custom_gray text-custom_gray">
            Degree:{" "}
          </label>
          <Dropdown
            list={[
              {
                id: 1,
                name: "Bachelor of Science",
                value: "Bachelor of Science",
              },
              {
                id: 2,
                name: "Bachelor of Arts",
                value: "Bachelor of Arts",
              },
              {
                id: 3,
                name: "Master of Science",
                value: "Master of Science",
              },
            ]}
            selected={selectedStatus}
            setSelected={setSelectedStatus}
          />
        </div>

        <div>
          <label className="text-xs placeholder:text-custom_gray text-custom_gray">
            Start date:{" "}
          </label>
          <CustomDatePicker
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
          />
        </div>

        <div>
          <label className="text-xs placeholder:text-custom_gray text-custom_gray">
            End date:{" "}
          </label>
          <CustomDatePicker
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
          />
          <div className="flex items-center mt-4 gap-x-2">
            <input
              type="checkbox"
              className="h-6 w-6 rounded-md checked:bg-custom_dark_blue active:outline-none ring-0 border-custom_gray"
            />
            <span className="text-custom_gray">Present</span>
          </div>
        </div>

        <div>
          <label className="text-xs placeholder:text-custom_gray text-custom_gray">
            Results:
          </label>
          <div className="">
            <Input
              placeholder={"eg. CGPA 4.0"}
              className={"mt-1"}
              value={company}
              onChange={(e: any) => setCompany(e.target.value)}
            />
          </div>
        </div>

        <div className="mt-8 flex justify-end">
          <div>
            <button
              className="inline-flex justify-center rounded-md border border-transparent bg-custom_button_dark px-8 py-3 text-sm font-medium text-white hover:bg-blue-900 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
              onClick={() => setOpen(false)}
            >
              Update Education
            </button>
          </div>
        </div>
      </div>
    </Drawer>
  );
}

export default UpdateEducation;
