const OpenJobButtonIcon = (props: any) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.5 13.5C11.7833 13.5 12.021 13.404 12.213 13.212C12.405 13.02 12.5007 12.7827 12.5 12.5C12.5 12.2167 12.404 11.9793 12.212 11.788C12.02 11.5967 11.7827 11.5007 11.5 11.5C11.2167 11.5 10.9793 11.596 10.788 11.788C10.5967 11.98 10.5007 12.2173 10.5 12.5C10.5 12.7833 10.596 13.021 10.788 13.213C10.98 13.405 11.2173 13.5007 11.5 13.5ZM7.5 21.5V19.5L13.5 18.5V7.375C13.5 7.125 13.425 6.9 13.275 6.7C13.125 6.5 12.9333 6.38333 12.7 6.35L7.5 5.5V3.5L13 4.4C13.7333 4.53333 14.3333 4.875 14.8 5.425C15.2667 5.975 15.5 6.61667 15.5 7.35V20.15L7.5 21.5ZM3.5 21.5V19.5H5.5V5.5C5.5 4.93333 5.696 4.45833 6.088 4.075C6.48 3.69167 6.95067 3.5 7.5 3.5H17.5C18.0667 3.5 18.5417 3.69167 18.925 4.075C19.3083 4.45833 19.5 4.93333 19.5 5.5V19.5H21.5V21.5H3.5ZM7.5 19.5H17.5V5.5H7.5V19.5Z"
        fill="white"
      />
    </svg>
  );
};

export default OpenJobButtonIcon;
