import {Menu, Transition} from "@headlessui/react";
import {Fragment, useState} from "react";
import users from "./users.json";
import {UserInterface} from "./types";
import defaulUser from "@assets/defaultuser.png";

interface DropdownProps {
  selecteduser: UserInterface | null;
  setSelectedUser: (user: UserInterface) => void;
}

export default function UserDropdown({
  selecteduser,
  setSelectedUser,
}: DropdownProps) {
  const [list, setList] = useState<UserInterface[]>(users);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const filteredList = users.filter((user) => {
      return (
        user.first_name.toLowerCase().includes(value.toLowerCase()) ||
        user.last_name.toLowerCase().includes(value.toLowerCase())
      );
    });
    setList(filteredList);
  };
  return (
    <div className="">
      <Menu as="div" className="relative w-full text-left">
        <div>
          <Menu.Button className="inline-flex h-12 mt-1 justify-between py-3 w-full rounded-lg bg-transparent border border-[#657A9345] px-4 text-xs  focus:outline-none  focus-visible:ring-white/75 items-center">
            <div className="flex gap-x-3 items-center">
              {selecteduser ? (
                <>
                  <img
                    src={selecteduser?.image || defaulUser}
                    alt="user"
                    className="w-8 h-8 object-cover rounded-full"
                  />
                  <span>
                    {selecteduser?.first_name + " " + selecteduser?.last_name}
                  </span>
                </>
              ) : (
                <span>Select user</span>
              )}
            </div>
            <svg
              width="18"
              height="10"
              viewBox="0 0 18 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.497 0.489027L8.99999 7.79703L1.50299 0.489027C1.36905 0.358193 1.18923 0.284947 1.00199 0.284947C0.814751 0.284947 0.634939 0.358193 0.500992 0.489027C0.436135 0.552573 0.384611 0.628421 0.349436 0.712131C0.314261 0.79584 0.296143 0.885727 0.296143 0.976526C0.296143 1.06733 0.314261 1.15721 0.349436 1.24092C0.384611 1.32463 0.436135 1.40048 0.500992 1.46403L8.47649 9.24003C8.61655 9.37655 8.8044 9.45295 8.99999 9.45295C9.19558 9.45295 9.38343 9.37655 9.52349 9.24003L17.499 1.46553C17.5643 1.40193 17.6162 1.3259 17.6517 1.24191C17.6871 1.15792 17.7054 1.06769 17.7054 0.976526C17.7054 0.885366 17.6871 0.795128 17.6517 0.711142C17.6162 0.627155 17.5643 0.55112 17.499 0.487526C17.365 0.356693 17.1852 0.283447 16.998 0.283447C16.8108 0.283447 16.6309 0.356693 16.497 0.487526V0.489027Z"
                fill="#969EAE"
              />
            </svg>
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute max-h-60 z-20 overflow-scroll right-0 mt-2 w-full origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
            <div className="sticky">
              <input
                type="text"
                placeholder="Search for user"
                className="w-full h-10 px-2 text-xs border-custom_blue bg-transparent border-none border-b"
                onChange={handleSearch}
              />
            </div>
            {list?.length === 0 && (
              <div className="p-2 text-sm text-center text-gray-500">
                No user found
              </div>
            )}
            {list?.map((user) => (
              <div className="px-1 py-1" key={user?.id}>
                <Menu.Item>
                  {() => (
                    <button
                      onClick={() => setSelectedUser(user)}
                      className={` group gap-2 flex w-full items-center rounded-md px-2 py-2 text-xs`}
                    >
                      <img
                        src={user?.image}
                        alt="user"
                        className="w-8 h-8 object-cover rounded-full"
                      />
                      {user?.first_name} {user?.last_name}
                    </button>
                  )}
                </Menu.Item>
              </div>
            ))}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
