import Input from "@components/input";
import MyEditor from "@components/editor";
import React from "react";
import Avartar from "@assets/candidate.jpg";
import StatusDropdown from "@components/dropdowns/status";
import Drawer from "@components/drawer/Drawer";
import ImagePicker from "@components/imagepicker";
import {Rating} from "react-simple-star-rating";
interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function ProfileUpdate({open, setOpen}: Props) {
  const [value, setValue] = React.useState("");
  const [position, setPosition] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [name, setName] = React.useState("");
  const [selectedImage, setSelectedImage] = React.useState(Avartar);

  return (
    <Drawer
      drawerHeader="User Profile"
      isOpen={open}
      onClose={() => setOpen(false)}
    >
      <div className="px-5">
        <div className="mt-6  flex flex-col gap-y-2 pe-6">
          <div>
            <ImagePicker
              selectedImage={selectedImage}
              setSelectedImage={setSelectedImage}
              defaultURL={Avartar}
            />
          </div>

          <div>
            <label className="text-xs placeholder:text-custom_gray text-custom_gray">
              Name:{" "}
            </label>
            <Input
              placeholder={"eg. Jane Doe"}
              className={"mt-1"}
              value={name}
              onChange={(e: any) => setName(e.target.value)}
            />
          </div>
          <div>
            <label className="text-xs placeholder:text-custom_gray text-custom_gray">
              Position:{" "}
            </label>
            <Input
              placeholder={"eg. Frontend developer"}
              className={"mt-1"}
              value={position}
              onChange={(e: any) => setPosition(e.target.value)}
            />
          </div>

          <div>
            <label className="text-xs placeholder:text-custom_gray text-custom_gray">
              About:{" "}
            </label>
            <div>
              <MyEditor
                value={value}
                setValue={setValue}
                placeholder="Enter Tasks Here..."
                height="60"
                className="h-60"
              />
            </div>
            <div className="flex mt-1 items-center gap-x-2">
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.66667 9.66667H7V7H6.33333M7 4.33333H7.00667M13 7C13 7.78793 12.8448 8.56815 12.5433 9.2961C12.2417 10.0241 11.7998 10.6855 11.2426 11.2426C10.6855 11.7998 10.0241 12.2417 9.2961 12.5433C8.56815 12.8448 7.78793 13 7 13C6.21207 13 5.43185 12.8448 4.7039 12.5433C3.97595 12.2417 3.31451 11.7998 2.75736 11.2426C2.20021 10.6855 1.75825 10.0241 1.45672 9.2961C1.15519 8.56815 1 7.78793 1 7C1 5.4087 1.63214 3.88258 2.75736 2.75736C3.88258 1.63214 5.4087 1 7 1C8.5913 1 10.1174 1.63214 11.2426 2.75736C12.3679 3.88258 13 5.4087 13 7Z"
                  stroke="#6F727A"
                  strokeWidth="1.4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span className="text-xs text-[#6F727A]">
                Be clear and concise when writing the content. Be as inclusive
                as possible!
              </span>
            </div>
          </div>

          <div>
            <label className="text-xs placeholder:text-custom_gray text-custom_gray">
              Status:{" "}
            </label>
            <StatusDropdown
              selectedStatus={status}
              setSelectedStatus={setStatus}
            />
          </div>
        </div>

        <div className="mt-6">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                Instagram:{" "}
              </label>
              <Input
                placeholder={""}
                className={"mt-1"}
                value={position}
                onChange={(e: any) => setPosition(e.target.value)}
              />
            </div>

            <div>
              <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                LinkedIn:{" "}
              </label>
              <Input
                placeholder={""}
                className={"mt-1"}
                value={position}
                onChange={(e: any) => setPosition(e.target.value)}
              />
            </div>

            <div>
              <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                Facebook:{" "}
              </label>
              <Input
                placeholder={""}
                className={"mt-1"}
                value={position}
                onChange={(e: any) => setPosition(e.target.value)}
              />
            </div>

            <div>
              <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                Twitter:{" "}
              </label>
              <Input
                placeholder={""}
                className={"mt-1"}
                value={position}
                onChange={(e: any) => setPosition(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="mt-5">
          <Rating />
        </div>
      </div>
      <div className="mt-8 flex justify-end">
        <div>
          <button
            className="inline-flex justify-center rounded-md border border-transparent bg-custom_button_dark px-8 py-3 text-sm font-medium text-white hover:bg-blue-900 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
            onClick={() => setOpen(false)}
          >
            Update
          </button>
        </div>
      </div>
    </Drawer>
  );
}

export default ProfileUpdate;
