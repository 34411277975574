import {useState} from "react";
import Drawer from "@components/drawer/Drawer";

import pdf from "@assets/svgs/pdf.svg";
import DeleteIcon from "@components/svgs/delete";
import Button from "@components/button";
import Delete from "@pages/dashboard/cms/blogs/delete";
import AddDocument from "@features/employees/AddDocument";

function Documents() {
  const [show, setShow] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [selected, setSelected] = useState<any>(null);

  const toggleDelete = (file: any) => {
    setSelected(file);
    setShowDelete(!showDelete);
  };

  const toggleAdd = () => {
    setShow(false);
  };

  const files = [
    {
      id: 1,
      name: "CV",
      size: 4233343,
    },
    {
      id: 2,
      name: "Cover Letter",
      size: 3234453,
    },
    {
      id: 3,
      name: "Resume",
      size: 43892893,
    },
  ];
  return (
    <div className="px-3 py-3">
      <div className="flex mt-5 pb-12 items-center gap-5">
        <div className="flex w-full flex-col gap-y-6">
          {files.map((file) => (
            <div className="">
              <div className="border px-5 py-5 flex items-center justify-between rounded-lg border-dashed">
                <div className="flex items-center rounded-xl">
                  <img src={pdf} className="" />
                  <div className="ms-4">
                    <p className="text-sm font-semibold text-gray-900">
                      {file.name}
                    </p>
                    {/* convert to MB */}
                    <p className="text-xs text-gray-600">
                      {(file.size / (1024 * 1024)).toFixed(2)} MB
                    </p>
                  </div>
                </div>
                <div className="flex gap-3 items-center">
                  <button>
                    <svg
                      className="text-gray-300 h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M2.4974 11.6641C2.95763 11.6641 3.33073 12.0372 3.33073 12.4974V15.8307C3.33073 16.0517 3.41853 16.2637 3.57481 16.42C3.73109 16.5763 3.94305 16.6641 4.16406 16.6641H15.8307C16.0517 16.6641 16.2637 16.5763 16.42 16.42C16.5763 16.2637 16.6641 16.0517 16.6641 15.8307V12.4974C16.6641 12.0372 17.0372 11.6641 17.4974 11.6641C17.9576 11.6641 18.3307 12.0372 18.3307 12.4974V15.8307C18.3307 16.4938 18.0673 17.1297 17.5985 17.5985C17.1297 18.0673 16.4938 18.3307 15.8307 18.3307H4.16406C3.50102 18.3307 2.86514 18.0673 2.3963 17.5985C1.92745 17.1297 1.66406 16.4938 1.66406 15.8307V12.4974C1.66406 12.0372 2.03716 11.6641 2.4974 11.6641Z"
                        fill="#71839B"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M5.24408 7.74408C5.56951 7.41864 6.09715 7.41864 6.42259 7.74408L10 11.3215L13.5774 7.74408C13.9028 7.41864 14.4305 7.41864 14.7559 7.74408C15.0814 8.06951 15.0814 8.59715 14.7559 8.92259L10.5893 13.0893C10.2638 13.4147 9.73618 13.4147 9.41074 13.0893L5.24408 8.92259C4.91864 8.59715 4.91864 8.06951 5.24408 7.74408Z"
                        fill="#71839B"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M9.9974 1.66406C10.4576 1.66406 10.8307 2.03716 10.8307 2.4974V12.4974C10.8307 12.9576 10.4576 13.3307 9.9974 13.3307C9.53716 13.3307 9.16406 12.9576 9.16406 12.4974V2.4974C9.16406 2.03716 9.53716 1.66406 9.9974 1.66406Z"
                        fill="#71839B"
                      />
                    </svg>
                  </button>
                  <button onClick={() => toggleDelete(file)}>
                    <DeleteIcon />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="flex items-end justify-end mt-8">
        <div>
          <Button
            title="Add document"
            className="w-1/6 px-5"
            onClick={() => setShow(true)}
          />
        </div>
      </div>

      <Drawer isOpen={show} onClose={toggleAdd} drawerHeader="Document">
        <AddDocument />
      </Drawer>

      <Delete
        open={showDelete}
        setOpen={() => setShowDelete(!open)}
        buttonTitle="Delete"
        selected={{title: selected?.name}}
      />
    </div>
  );
}

export default Documents;
