import React, { useState } from "react";
import Table from "@components/DataTable";
import { Pagination } from "@components/pagination/Pagination";
import ConfirmModal from "@components/confirmationDialog/ConfirmationDialog";
import Drawer from "@components/drawer/Drawer";
import DeleteIcon from "@components/svgs/delete";
import { TableColumn } from "@components/DataTable/types";
import LinkButton from "@components/ButtonV2/LinkButton";
import CandidateSummaryModal from "@features/candidates/CandidateSummaryModal";

type Item = {
  id: number;
  name: string;
};

export type TableRow = {
  id: number;
  name: string;
  jobRole: string;
  client: string;
  status: string;
  transfer: Item;
  action: string;
};

export const generateSampleData = (): TableRow[] => {
  const item: Item[] = [
    { id: 1, name: "Team Tokyo" },
    { id: 2, name: "Team New York" },
  ];

  return [
    {
      id: 5023,
      name: "Immaculate Akuvi (Team lead)",
      jobRole: "UI/UX Designer",
      client: "Diamond Logistics",
      status: "Full-time",
      transfer: item[0],
      action: "delete",
    },
    {
      id: 5023,
      name: "Grace Adoba (Senior role)",
      jobRole: "Business Assistant",
      client: "Diamond Logistics",
      status: "Full-time",
      transfer: item[0],
      action: "delete",
    },
    {
      id: 5023,
      name: "Salome Darko",
      jobRole: "Business Assistant",
      client: "Diamond Logistics",
      status: "Part-time",
      transfer: item[0],
      action: "delete",
    },
    {
      id: 5023,
      name: "Salome Darko",
      jobRole: "Business Assistant",
      client: "Diamond Logistics",
      status: "Part-time",
      transfer: item[0],
      action: "delete",
    },
  ];
};

function TeamList() {
  const [start, setStart] = React.useState(0);
  const [end, setEnd] = React.useState(10);
  const [showPatientProfile, setShowPatientProfile] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const handleNext = () => {
    setStart(start + 10);
    setEnd(end + 10);
  };

  const handlePrev = () => {
    setStart(start - 10);
    setEnd(end - 10);
  };

  function togglePatientProfileModal() {
    setShowPatientProfile(!showPatientProfile);
  }

  const columns: TableColumn<TableRow>[] = [
    { key: "id", label: "ID", sortable: true },
    {
      key: "name",
      label: "Name",
      sortable: true,
      render: (name) => <LinkButton label={name} />,
    },
    { key: "jobRole", label: "Job role", sortable: false },
    {
      key: "client",
      label: "Client",
      sortable: true,
      render: () => <span>Client</span>,
    },
    {
      key: "status",
      label: "Status",
      sortable: false,
      render: (status) => <span>{status}</span>,
    },
    {
      key: "action",
      label: "Action",
      sortable: false,
      render: () => (
        <span className="flex align-middle gap-1">
          <button onClick={() => setShowDeleteDialog(true)}>
            <DeleteIcon />
          </button>
        </span>
      ),
    },
  ];

  const sampleCandidateData = generateSampleData();

  return (
    <div className="px-5 py-3">
      <div className="-mx-4 mt-8 sm:-mx-0">
        <Table<TableRow>
          data={sampleCandidateData}
          columns={columns}
          onRowClick={() => setShowPatientProfile(true)}
        />
      </div>

      <Pagination
        start={start}
        end={end}
        total={sampleCandidateData?.length}
        onPrevious={handlePrev}
        onNext={handleNext}
      />
      <ConfirmModal
        isOpen={showDeleteDialog}
        onCancel={() => setShowDeleteDialog(false)}
        onDelete={() => {}}
        itemName="Paula Agyepong"
        subject="Are you sure you want to delete a candidate"
      />
      <Drawer
        drawerHeader=""
        isOpen={showPatientProfile}
        onClose={togglePatientProfileModal}
      >
        <CandidateSummaryModal />
      </Drawer>
    </div>
  );
}

export default TeamList;
