import {Outlet, useParams} from "react-router-dom";
import VerticalTab from "@components/tabs/VerticalTab";

function AddNewEmplyee() {
  const params = useParams();

  const tabs = [
    {
      name: "Personal Information",
      href: `/hr/employees/viewemployee/${params?.id}/additionalinformation`,
    },
    {
      name: "Documents",
      href: `/hr/employees/viewemployee/${params?.id}/additionalinformation/documents`,
    },
    {
      name: "End Employment",
      href: `/hr/employees/viewemployee/${params?.id}/additionalinformation/endemployment`,
    },
    {
      name: "Dependants",
      href: `/hr/employees/viewemployee/${params?.id}/additionalinformation/dependants`,
    },
    {
      name: "Emergency Contact Info",
      href: `/hr/employees/viewemployee/${params?.id}/additionalinformation/emergencycontact`,
    },
  ];

  return (
    <>
      <div className="gap-y-4 flex bg-custom_faded_blue flex-col px-2 py-2">
        <div className="gap-5 mt-4 flex lg:flex-row flex-col">
          <div className="lg:w-1/4 w-full py-4 rounded-lg flex flex-col gap-6 bg-white">
            <VerticalTab tabs={tabs} />
          </div>

          <div className="lg:w-3/4 w-full rounded-lg flex flex-col gap-6 ">
            <div className="bg-white rounded-xl py-4 px-3">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddNewEmplyee;
