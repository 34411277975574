"use client";
import { Fragment, useState } from "react";
import Card from "@components/cards/card";
import TeamStats from "@components/stats/teamsstats";
import { useNavigate } from "react-router-dom";
import ButtonV2 from "@components/ButtonV2/ButtonV2";
import Drawer from "@components/drawer/Drawer";
import AddNewTeam from "@features/teams/AddNewTeam";
function Candidates() {
  const navigate = useNavigate();
  const [showAddTeamModal, setShowAddTeamModal] = useState(false);
  const onClick = (dapartment: string) => {
    navigate(`/hr/teams/${dapartment?.toLowerCase()}`);
  };

  const toggleAddTeamModal = () => {
    setShowAddTeamModal(!showAddTeamModal);
  };

  const teams = [
    {
      id: 1,
      title: "Team Tokyo",
      image:
        "https://drive.google.com/thumbnail?id=1w4FHnNP4gqcPITmlF8Q8vjhulJcGQSsi",
      subtext: "Total members: 8",
      buttonText: "Explore Team",
      onClick: onClick,
    },
    {
      id: 2,
      title: "Team Montreal",
      image:
        "https://drive.google.com/thumbnail?id=15fMlKg6g7X2TouqJDI1bcLp5FQKOudxZ",
      subtext: "Total number in the pool: 208",
      buttonText: "Explore Team",
      onClick: onClick,
    },
    {
      id: 3,
      title: "Team Madrid",
      subtext: "Total number in the pool: 200",
      buttonText: "Explore Team",
      onClick: onClick,
      image:
        "https://cdn.getyourguide.com/img/tour/e7e2462b1899bd40.jpeg/98.jpg",
    },
    {
      id: 4,
      title: "Team California",
      subtext: "Total number in the pool: 108",
      buttonText: "Explore Team",
      onClick: onClick,

      image:
        "https://drive.google.com/thumbnail?id=1O-a3MOUX9lWLHMkkY9T22cmrm7cFRSFg",
    },
    {
      id: 5,
      title: "Team Kigali",
      subtext: "Total number in the pool: 108",
      buttonText: "Explore Team",
      onClick: onClick,
      image:
        "https://drive.google.com/thumbnail?id=15fMlKg6g7X2TouqJDI1bcLp5FQKOudxZ",
    },
    {
      id: 6,
      title: "Team Geneva",
      subtext: "Total number in the pool: 108",
      buttonText: "Explore Team",
      onClick: onClick,
      image:
        "https://drive.google.com/thumbnail?id=1xy_SxWGHeD-6qFNkwIMRYwYgCQzAEE22",
    },
    {
      id: 7,
      title: "People & Talent",
      subtext: "Total number in the pool: 108",
      buttonText: "Explore Team",
      onClick: onClick,
      image:
        "https://ikigaination.org/wp-content/uploads/brizy/63/assets/images/iW=829&iH=540&oX=149&oY=0&cW=515&cH=540/pexels-picha-stock-3894378.jpg",
    },
    {
      id: 8,
      title: "Operations",
      subtext: "Total number in the pool: 108",
      buttonText: "Explore Team",
      onClick: onClick,
      image:
        "https://d2wvwvig0d1mx7.cloudfront.net/data/org/26130/media/img/cache/370x0/3041301_370x0.webp",
    },
  ];

  return (
    <>
      <div className="bg-[#DDEFFF33] mx-4 rounded-lg font-inter">
        <div className="flex justify-between items-center">
          <div className="w-1/2">
            <TeamStats />
          </div>
        </div>
      </div>
      <div className="bg-[#DDEFFF33] rounded-xl mx-4 mt-8 px-3 py-3">
        <div className="font-outfit">
          <div className=" bg-[#DDEFFF33]">
            <div className="flex items-center justify-between mb-5">
              <span className="text-3xl font-semibold">Teams</span>
              <ButtonV2
                text="Add New Team"
                variant="tertiary"
                onClick={toggleAddTeamModal}
              />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5 gap-6">
              {teams?.map((team) => (
                <Fragment key={team?.id}>
                  <Card data={team} />
                </Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Drawer
        drawerHeader="Add a new team"
        isOpen={showAddTeamModal}
        onClose={toggleAddTeamModal}
      >
        <AddNewTeam />
      </Drawer>
    </>
  );
}

export default Candidates;
