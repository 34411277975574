import EditIcon from "@components/svgs/edit";
import Button from "@components/button";
import {useState} from "react";
import Drawer from "@components/drawer/Drawer";
import DeleteIcon from "@components/svgs/delete";
import UpdateDependant from "@features/employees/updatedependant";
import AddDependant from "@features/employees/adddependant";
import Delete from "@pages/dashboard/cms/blogs/delete";

function Dependants() {
  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState(false);

  const [showDelete, setShowDelete] = useState(false);
  const toggleDelete = () => {
    setShowDelete(!showDelete);
  };

  const toggleEdit = () => {
    setEdit(!edit);
  };
  const toggleShow = () => {
    setShow(false);
  };

  const educations = [
    {
      id: 3,
      title: "Columbia University (2014-2021) ",
      company: "BCS Business Analyst (In progress)",
      tasks: ["First class honours"],
    },

    {
      // use the template above but make the values unique
      id: 4,
      title: "not specified - Not given (2022)",
      company: "BCS Business Analyst (In progress)",
      tasks: ["Not given"],
    },
    {
      // use the template above but make the values unique
      id: 4,
      title: "not specified - Not given (2022)",
      company: "BCS Business Analyst (In progress)",
      tasks: ["Not given"],
    },
  ];
  return (
    <div className="px-3 py-3">
      <div className="flex w-full mt-5 pb-12 items-center gap-5">
        <div className="border-l w-full  gap-y-12 flex flex-col">
          {educations.map((work) => (
            <div key={work?.id} className="flex w-full relative">
              <svg
                width="12"
                height="12"
                className="absolute top-0 -left-1.5"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="6" cy="6" r="6" fill="#E96D17" />
              </svg>

              <div className="flex w-full relative">
                <div className="flex ms-5 flex-col gap-y-6">
                  <div className="">
                    <label className="text-sm text-custom_gray">
                      Name of Dependant:{" "}
                    </label>
                  </div>
                  <div className="">
                    <label className="text-sm text-custom_gray">
                      Relationship:{" "}
                    </label>
                  </div>
                  <div className="">
                    <label className="text-sm text-custom_gray">Age: </label>
                  </div>
                  <div className="">
                    <label className="text-sm text-custom_gray">Gender: </label>
                  </div>
                  <div className="">
                    <label className="text-sm text-custom_gray">
                      Contact:{" "}
                    </label>
                  </div>
                </div>

                <div className="flex ms-6 flex-col gap-y-6">
                  <div className="">
                    <span className="text-sm text-black">FLorence Akuvi</span>
                  </div>
                  <div className="">
                    <span className="text-sm text-black">Sister</span>
                  </div>
                  <div className="">
                    <span className="text-sm text-black">29 years</span>
                  </div>
                  <div className="">
                    <span className="text-sm text-black">Female</span>
                  </div>
                  <div className="">
                    <span className="text-sm text-black">
                      +233 8473 843 3843
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex items-start justify-end">
                <div className="flex gap-x-4 ms-4">
                  <EditIcon onClick={toggleEdit} className="" />
                </div>
                <div onClick={toggleDelete} className="flex gap-x-4 ms-4">
                  <DeleteIcon />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="flex items-end justify-end mt-8">
        <div>
          <Button
            title="Add new dependant"
            className="w-1/6 px-5"
            onClick={() => setShow(true)}
          />
        </div>
      </div>

      <Drawer isOpen={show} onClose={toggleShow} drawerHeader="Dependant">
        <AddDependant />
      </Drawer>
      <Drawer
        isOpen={edit}
        onClose={toggleEdit}
        drawerHeader="Update dependant"
      >
        <UpdateDependant />
      </Drawer>

      <Delete
        open={showDelete}
        setOpen={() => setShowDelete(!open)}
        buttonTitle="Delete"
        selected={{title: "dependant", subtext: "Florence Akuvi"}}
      />
    </div>
  );
}

export default Dependants;
