import EditIcon from "@components/svgs/edit";
import moment from "moment";
// import Button from "@components/button";
import {useState} from "react";
import Drawer from "@components/drawer/Drawer";
import UpdateAdditionalInformation from "@features/employees/UpdateAdditionalInformation";

function PersonalInformation() {
  const handleEdit = () => {
    setShow(true);
  };
  const [show, setShow] = useState(false);

  const toggleEdit = () => {
    setShow(false);
  };
  return (
    <div className="px-3 py-3">
      <div className="flex items-center justify-end">
        <div className="flex gap-x-4 ms-4">
          <EditIcon onClick={handleEdit} className="" />
          {/* <button onClick={handleDelete}>
            <DeleteIcon />
          </button> */}
        </div>
      </div>

      <div className="flex mt-5 pb-12 items-center gap-5">
        <div className="flex flex-col gap-y-6">
          <div className="">
            <label className="text-sm text-custom_gray">Personal Email: </label>
          </div>
          <div className="">
            <label className="text-sm text-custom_gray">Date of Birth: </label>
          </div>
          <div className="">
            <label className="text-sm text-custom_gray">Age: </label>
          </div>
          <div className="">
            <label className="text-sm text-custom_gray">Gender: </label>
          </div>
          <div className="">
            <label className="text-sm text-custom_gray">
              Emergency Contact:{" "}
            </label>
          </div>
        </div>

        <div className="flex flex-col gap-y-6">
          <div className="">
            <span className="text-sm text-black">liamsummer@provider.com </span>
          </div>
          <div className="">
            <span className="text-sm text-black">
              {moment()?.format("DD/MM/YYYY")}{" "}
            </span>
          </div>
          <div className="">
            <span className="text-sm text-black">27 years </span>
          </div>
          <div className="">
            <span className="text-sm text-black">Female </span>
          </div>
          <div className="">
            <span className="text-sm text-black">+233 2344 234 564</span>
          </div>
        </div>
      </div>

      {/* <div className="flex items-end justify-end mt-8">
        <div>
          <Button
            title="Add new personal information"
            className="w-1/6 px-8"
            onClick={() => setShow(true)}
          />
        </div>
      </div> */}

      <Drawer
        isOpen={show}
        onClose={toggleEdit}
        drawerHeader="Update Additional Information"
      >
        <UpdateAdditionalInformation />
      </Drawer>
    </div>
  );
}

export default PersonalInformation;
