import Drawer from "@components/drawer/Drawer";
import SkillSelector from "./skillselector";

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function Add({open, setOpen}: Props) {
  return (
    <Drawer
      drawerHeader="Add skill"
      isOpen={open}
      onClose={() => setOpen(false)}
    >
      <div className="mt-8 px-5 flex flex-col gap-y-2">
        <div>
          <label className="text-xs placeholder:text-custom_gray text-custom_gray">
            Skills:{" "}
          </label>
          <div className={"mt-1 py"}>
            <SkillSelector />
          </div>
        </div>

        <div className="mt-8 flex justify-end">
          <div>
            <button
              className="inline-flex justify-center rounded-md border border-transparent bg-custom_button_dark px-8 py-3 text-sm font-medium text-white hover:bg-blue-900 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
              onClick={() => setOpen(false)}
            >
              Add Skills
            </button>
          </div>
        </div>
      </div>
    </Drawer>
  );
}

export default Add;
