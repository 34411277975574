import {SVGProps} from "react";
const Timer = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <g fill="#7C8DB5" clipPath="url(#a)">
      <path d="M14.453 5.358 15.53 4.28l-1.06-1.06-1.153 1.152A7.452 7.452 0 0 0 9.75 3.038V1.5h1.5V0h-4.5v1.5h1.5v1.538a7.453 7.453 0 0 0-3.567 1.334L3.53 3.22 2.47 4.28l1.077 1.078a7.5 7.5 0 1 0 10.905 0ZM9 16.5a6 6 0 1 1 6-6 6.007 6.007 0 0 1-6 6Z" />
      <path d="M9 6v4.5H4.5A4.5 4.5 0 1 0 9 6Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h18v18H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default Timer;
