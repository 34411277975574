import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Holidayicon from "@components/svgs/holidayicon";
import Step1 from "./steps/step1";
import Step2 from "./steps/step2";

function RequestTimeOff() {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);

  const handleNext = () => {
    if (step === 2) return null;
    setStep(step + 1);
  };

  const handlePrev = () => {
    if (step === 1) return null;
    setStep(step - 1);
  };

  return (
    <>
      <div className="gap-y-4 flex pl-4 pt-4 flex-col pb-12">
        <div className="w-full px-6 items-center rounded-lg justify-between flex bg-custom_faded_blue">
          <h1 className="text-2xl font-outfit font-medium">Request Time off</h1>

          <div className="">
            <Holidayicon />
          </div>
        </div>

        <div className="flex justify-between">
          <button
            onClick={() => navigate(-1)}
            className="text-custom_secondary hover:underline flex gap-x-3 items-center font-outfit font-light"
          >
            <ChevronLeftIcon className="h-6 w-6 text-custom_secondary" />
            Back
          </button>
        </div>
        <div className="w-full flex-1 flex flex-col py-4 px-6 rounded-lg  bg-custom_faded_blue">
          <div className=" my-6 grid gap-x-4 gap-y-5 grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 lg:px-2 xl:px-0">
            {step === 1 && <Step1 />}
            {step === 2 && <Step2 />}
          </div>
          <div className="lg:col-span-4 mt-4 gap-x-4 col-span-10 flex justify-end items-end">
            {step === 2 && (
              <button
                onClick={handlePrev}
                className="border-[#1F3C49] border min-w-40 rounded-lg font-light py-3 px-4 items-center justify-center flex"
              >
                <span className="text-[#1F3C49]">Back</span>
              </button>
            )}
            <button
              onClick={handleNext}
              className="bg-[#1F3C49] rounded-lg min-w-40 font-light items-center justify-center py-3 px-4 flex"
            >
              <span className="text-white">
                {step === 1 ? "Continue" : "Request"}
              </span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default RequestTimeOff;
