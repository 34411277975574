import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import CopyIcon from "@components/svgs/copy";
import ShareIcon from "@components/svgs/share";
import NewPrint from "@components/svgs/newprint";
import PositionsList from "./positionsList";
import Drawer from "@components/drawer/Drawer";
import { useState } from "react";

const CurrentPositions = () => {
  const [showDrawer, setShowDrawer] = useState(false);

  const toggleDrawer = () => {
    setShowDrawer(!showDrawer);
  };

  return (
    <>
      <div className=" py-2 px-4 bg-custom_faded_blue rounded-xl">
        <div className="px-5 py-4 flex justify-between items-center">
          <div className="w-1/3">
            <form className="relative h-full flex w-full items-center flex-1">
              <label htmlFor="search-field" className="sr-only">
                Search
              </label>
              <MagnifyingGlassIcon
                className="pointer-events-none absolute inset-y-0 left-5 h-full w-5 text-[#657A93]"
                aria-hidden="true"
              />
              <input
                id="search-field"
                className="block bg-transparent py-3 rounded-lg w-full border border-[#657A9345] h-full pl-12 pr-0  placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                placeholder="Search here..."
                type="search"
                name="search"
              />
            </form>
          </div>
          <div className="flex gap-x-3 items-center">
            <CopyIcon />
            <ShareIcon />
            <NewPrint />

            <button
              onClick={toggleDrawer}
              className="bg-custom_deep_blue text-white font-light text-sm px-10 py-3 rounded-lg flex items-center gap-3"
            >
              <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.5 8.5H8.5V14.5H6.5V8.5H0.5V6.5H6.5V0.5H8.5V6.5H14.5V8.5Z"
                  fill="white"
                />
              </svg>
              <span>Add a new position</span>
            </button>
          </div>
        </div>
      </div>
      <PositionsList />
      <Drawer
        drawerHeader="Add a new position"
        isOpen={showDrawer}
        onClose={toggleDrawer}
      >
        <div>Add a new position</div>
      </Drawer>
    </>
  );
};

export default CurrentPositions;
