import React from "react";
import Input from "@components/input";

import MyEditor from "@components/editor";
import Drawer from "@components/drawer/Drawer";
import ButtonV2 from "@components/ButtonV2/ButtonV2";
import {PlusIcon} from "@heroicons/react/24/outline";

function AddProduct({isOpen, onClose}: {isOpen: boolean; onClose: () => void}) {
  const [tab, setTab] = React.useState("product");

  const [summary, setSummary] = React.useState("");

  const [levels, setLevels] = React.useState([
    {level: "Beginner", partTime: 100, fullTime: 200},
    {level: "Intermediate", partTime: 200, fullTime: 400},
  ]);

  return (
    <Drawer isOpen={isOpen} onClose={onClose} drawerHeader="Add a new Product">
      <div
        className={`grid grid-cols-1 gap-4 mt-4 mb-24 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4`}
      >
        {tab === "product" ? (
          <>
            <div className="md:col-span-4">
              <label className="  text-xs placeholder:text-custom_gray text-custom_gray">
                Product name:{" "}
              </label>
              <Input placeholder={"eg. Video Editor"} className={"mt-1 py-3"} />
            </div>

            <div className="col-span-4">
              <label className="  text-xs placeholder:text-custom_gray text-custom_gray">
                Description:{" "}
              </label>
              <div className="">
                <MyEditor
                  value={summary}
                  setValue={setSummary}
                  placeholder="Enter description here..."
                />
              </div>
            </div>
            <div className="col-span-4">
              <label className="  text-xs placeholder:text-custom_gray text-custom_gray">
                Summary:{" "}
              </label>
              <div className="">
                <MyEditor
                  value={summary}
                  setValue={setSummary}
                  placeholder="Enter description here..."
                />
              </div>
            </div>
          </>
        ) : (
          <div className="col-span-4 grid grid-cols-4">
            {levels.map(() => (
              <div className="col-span-4 grid grid-cols-4 gap-x-4 border-b pb-5 gap-y-3">
                <div className="md:col-span-4">
                  <label className="  text-xs placeholder:text-custom_gray text-custom_gray">
                    Level:{" "}
                  </label>
                  <Input
                    placeholder={"eg. Video Editor"}
                    className={"mt-1 py-3"}
                  />
                </div>
                <div className="md:col-span-2">
                  <label className="  text-xs placeholder:text-custom_gray text-custom_gray">
                    Part time cost:{" "}
                  </label>
                  <Input
                    placeholder={"eg. Video Editor"}
                    className={"mt-1 py-3"}
                  />
                </div>
                <div className="md:col-span-2">
                  <label className="  text-xs placeholder:text-custom_gray text-custom_gray">
                    Full time cost:{" "}
                  </label>
                  <Input
                    placeholder={"eg. Video Editor"}
                    className={"mt-1 py-3"}
                  />
                </div>
              </div>
            ))}
            <div className="col-span-2">
              <button
                className="items-center gap-2 flex py-3 mt-4 rounded-lg  text-xs transition-all duration-200 font-outfit focus:outline-none text-custom_blue"
                onClick={() =>
                  setLevels([...levels, {level: "", partTime: 0, fullTime: 0}])
                }
              >
                <PlusIcon className="text-custom_blue h-5 w-5" />
                <span>Add Another Level</span>
              </button>
            </div>
          </div>
        )}

        <div className="col-span-2  mt-12">
          <button
            onClick={() => setTab("product")}
            className={`px-6 py-3 rounded-lg  text-xs transition-all duration-200 font-light font-outfit focus:outline-none bg-custom_blue text-white ${tab === "product" && "hidden"}`}
          >
            Previous
          </button>
        </div>
        <div className="col-span-2 justify-end flex mt-12">
          <ButtonV2
            onClick={() => setTab("levels")}
            text={tab === "product" ? "Next" : "Add Product"}
          />
        </div>
      </div>
    </Drawer>
  );
}

export default AddProduct;
