import { useState, useMemo } from "react";
import { TableColumn } from "./types";

interface TableProps<T> {
  data: T[];
  columns: TableColumn<T>[];
  onRowClick?: (row: any) => void;
}

const Table = <T extends object>({
  data,
  columns,
  onRowClick,
}: TableProps<T>) => {
  const [sortConfig, setSortConfig] = useState<{
    key: keyof T | null;
    direction: "asc" | "desc";
  }>({key: null, direction: "asc"});

  const sortedData = useMemo(() => {
    if (!sortConfig.key) return data;

    return [...data].sort((a, b) => {
      const aValue = a[sortConfig.key as keyof T];
      const bValue = b[sortConfig.key as keyof T];

      if (aValue < bValue) return sortConfig.direction === "asc" ? -1 : 1;
      if (aValue > bValue) return sortConfig.direction === "asc" ? 1 : -1;
      return 0;
    });
  }, [data, sortConfig]);

  const handleSort = (key: keyof T) => {
    setSortConfig((prevConfig) => ({
      key,
      direction:
        prevConfig.key === key && prevConfig.direction === "asc"
          ? "desc"
          : "asc",
    }));
  };

  return (
    <table className="w-full divide-y divide-[#E6EDFF]">
      <thead>
        <tr>
          {columns.map((column) => (
            <th
              key={String(column.key)}
              className="p-5 text-left cursor-pointer text-[12px] leading-4"
              onClick={() => column.sortable && handleSort(column.key)}
            >
              <div className="flex">
                <span className=" text-nowrap ">{column.label}</span>
                {column.sortable && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className={` ml-2 h-4 w-4 size-6 ${
                      sortConfig.key === column.key
                        ? sortConfig.direction === "asc"
                          ? "text-blue-500"
                          : "text-red-500"
                        : "text-gray-400"
                    }`}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 7.5 7.5 3m0 0L12 7.5M7.5 3v13.5m13.5 0L16.5 21m0 0L12 16.5m4.5 4.5V7.5"
                    />
                  </svg>
                )}
              </div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="divide-y divide-[#E6EDFF] text-[12px] leading-4">
        {sortedData.map((row, index) => (
          <tr
            key={index}
            onClick={(e) => onRowClick?.(e)}
            className={`${onRowClick ? "cursor-pointer hover:bg-gray-50" : ""}`}
          >
            {columns.map((column) => (
              <td
                key={`${index}-${String(column.key)}`}
                className={`p-5 ${column?.nowrap && "text-nowrap"}`}
              >
                {column.render
                  ? column.render(row[column.key], row)
                  : String(row[column.key])}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
