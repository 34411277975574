import LinkButton from "@components/ButtonV2/LinkButton";
import ConfirmModal from "@components/confirmationDialog/ConfirmationDialog";
import Table from "@components/DataTable";
import { TableColumn } from "@components/DataTable/types";
import { Pagination } from "@components/pagination/Pagination";
import DeleteIcon from "@components/svgs/delete";
import PencilIcon from "@components/svgs/pencil";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

interface CompanyData {
  id: number;
  company: string;
  industry: string;
  location: string;
  assigned: string;
  lastContactDate: string;
  status: string;
  nextSteps: string;
  action: string;
}

const sampleData: CompanyData[] = [
  {
    id: 1,
    assigned: "Doreen Addo",
    company: "10pm Curfew",
    industry: "Tech",
    location: "USA, New York",
    lastContactDate: "12/10/2024",
    status: "In progress",
    nextSteps: "Follow up in 2 weeks",
    action: "Action",
  },
  {
    id: 2,
    assigned: "Doreen Addo",
    company: "10pm Curfew",
    industry: "Tech",
    location: "USA, New York",
    lastContactDate: "12/10/2024",
    status: "Idle",
    nextSteps: "Follow up in 2 weeks",
    action: "Action",
  },
  {
    id: 3,
    company: "10pm Curfew",
    industry: "Tech",
    location: "USA, New York",
    assigned: "Doreen Addo",
    lastContactDate: "12/10/2024",
    status: "Prospect",
    nextSteps: "Follow up in 2 weeks",
    action: "Action",
  },
  {
    id: 4,
    company: "10pm Curfew",
    industry: "Tech",
    location: "USA, New York",
    assigned: "Doreen Addo",
    lastContactDate: "12/10/2024",
    status: "New lead",
    nextSteps: "Follow up in 2 weeks",
    action: "Action",
  },
  {
    id: 5,
    company: "10pm Curfew",
    industry: "Tech",
    location: "USA, New York",
    assigned: "Doreen Addo",
    lastContactDate: "12/10/2024",
    status: "Idle",
    nextSteps: "Follow up in 2 weeks",
    action: "Action",
  },
  {
    id: 6,
    company: "10pm Curfew",
    industry: "Tech",
    location: "USA, New York",
    assigned: "Doreen Addo",
    lastContactDate: "12/10/2024",
    status: "New lead",
    nextSteps: "Follow up in 2 weeks",
    action: "Action",
  },
];

const CompaniesList = () => {
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(10);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const navigate = useNavigate();

  const handleNext = () => {
    setStart(start + 10);
    setEnd(end + 10);
  };

  const handlePrev = () => {
    setStart(start - 10);
    setEnd(end - 10);
  };

  const columns: TableColumn<CompanyData>[] = [
    {
      key: "id",
      label: "ID",
      sortable: true,
    },
    {
      key: "company",
      label: "Company",
      sortable: true,
      render: (value) => (
        <LinkButton
          label={value}
          onClick={() => navigate("/crm/companies/view/20")}
        />
      ),
    },
    {
      key: "industry",
      label: "Industry",
      sortable: true,
    },
    {
      key: "location",
      label: "Location",
      sortable: true,
    },
    {
      key: "assigned",
      label: "Assigned",
      sortable: true,
      render: (value) => <LinkButton label={value} />,
    },
    {
      key: "lastContactDate",
      label: "Last Contact Date",
      sortable: true,
    },
    {
      key: "status",
      label: "Status",
    },
    {
      key: "nextSteps",
      label: "Next Steps",
      render: (value) => <LinkButton label={value} />,
    },
    {
      key: "action",
      label: "Action",
      render: () => (
        <span className="flex align-middle gap-2">
          <button>
            <PencilIcon />
          </button>
          <button onClick={() => setShowDeleteDialog(!showDeleteDialog)}>
            <DeleteIcon />
          </button>
        </span>
      ),
    },
  ];

  return (
    <>
      <Table<CompanyData> data={sampleData} columns={columns} />
      <Pagination
        start={start}
        end={end}
        total={10}
        onPrevious={handlePrev}
        onNext={handleNext}
      />
      <ConfirmModal
        isOpen={showDeleteDialog}
        onCancel={() => setShowDeleteDialog(false)}
        onDelete={() => {}}
        itemName="Paula Agyepong"
        subject="Are you sure you want to delete a candidate"
      />
    </>
  );
};

export default CompaniesList;
