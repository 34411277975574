import ConfirmModal from "@components/confirmationDialog/ConfirmationDialog";
import Table from "@components/DataTable";
import { TableColumn } from "@components/DataTable/types";
import { Pagination } from "@components/pagination/Pagination";
import DeleteIcon from "@components/svgs/delete";
import PencilIcon from "@components/svgs/pencil";
import { useState } from "react";

interface Position {
  id: number;
  name: string;
  role: string;
  action: string;
}

const sampleEmployeeData: Position[] = [
  {
    id: 5023,
    name: "Obed Frimpong",
    role: "Frontend developer",
    action: "Action",
  },
  {
    id: 5023,
    name: "Grace Adoba",
    role: "Business Assistant",
    action: "Action",
  },
  {
    id: 5023,
    name: "Grace Adoba",
    role: "Business Assistant",
    action: "Action",
  },
];

const PositionsList = () => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(10);

  const handleNext = () => {
    setStart(start + 10);
    setEnd(end + 10);
  };

  const handlePrev = () => {
    setStart(start - 10);
    setEnd(end - 10);
  };

  const toggleShowDeleteDialog = () => {
    setShowDeleteDialog(!showDeleteDialog);
  };

  const columns: TableColumn<Position>[] = [
    {
      key: "id",
      label: "ID",
      sortable: true,
    },
    {
      key: "name",
      label: "Name",
      sortable: true,
    },
    {
      key: "role",
      label: "Role",
      sortable: true,
    },
    {
      key: "action",
      label: "Action",
      render: () => (
        <span className="flex align-middle gap-1">
          <button>
            <PencilIcon />
          </button>
          <button onClick={toggleShowDeleteDialog}>
            <DeleteIcon />
          </button>
        </span>
      ),
    },
  ];
  return (
    <>
      <Table data={sampleEmployeeData} columns={columns} />
      <ConfirmModal
        itemName="name"
        subject="Are you sure you want to delete task"
        isOpen={showDeleteDialog}
        onCancel={toggleShowDeleteDialog}
        onDelete={() => {}}
      />
      <Pagination
        start={start}
        end={end}
        total={sampleEmployeeData.length}
        onPrevious={handlePrev}
        onNext={handleNext}
      />
    </>
  );
};

export default PositionsList;
