import TasksLists from "@features/candidates/TasksLists";

function Tasks() {
  return (
    <div className=" py-2 px-4 rounded-xl">
      <TasksLists />
      {/* <div className="mt-4 rounded-xl">
        <EmptyInterface
          description="No tasks to show..."
          buttonText="Add a task"
          showButton={true}
          buttonAction={() => null}
        />
      </div> */}
    </div>
  );
}

export default Tasks;
