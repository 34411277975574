import PopUp from "@components/PopOver";
import AddIcon from "@components/svgs/AddIcon";
import {MagnifyingGlassIcon} from "@heroicons/react/24/outline";

interface PositionPopUpProps {
  handleCLick: (selected: any) => void;
  selectedPositions: any[];
}

function PositionPopUp({handleCLick, selectedPositions}: PositionPopUpProps) {
  const list = [
    {id: 1, name: "UI/UX Designer", value: "ui/ux designer"},
    {id: 2, name: "Software Engineer", value: "software engineer"},
    {id: 3, name: "Business Assistant", value: "business assistant"},
  ];

  return (
    <div>
      <PopUp label={<AddIcon />}>
        <div className="bg-white shadow-2xl py-4 z-50">
          <div className="relative  px-5">
            <MagnifyingGlassIcon className="absolute top-3 left-8 h-5 w-5 text-gray-400" />
            <input
              type="text"
              placeholder="Search"
              className="border-b bg-custom_faded_blue ps-12 rounded-full border-gray-300 focus:outline-none"
            />
          </div>

          <h3 className="px-5 my-5 font-semibold text-lg">Positions</h3>
          <div className="flex flex-col  gap-y-4 ">
            {list.map((pool) => (
              <div
                onClick={() => handleCLick(pool)}
                key={pool.id}
                className={`flex cursor-pointer select-none gap-x-3  ps-6 border-l-2  py-4  ${
                  selectedPositions?.some((pos) => pos.id === pool.id)
                    ? "bg-custom_faded_blue border-custom_blue"
                    : "border-transparent"
                } items-center`}
              >
                <span className="text-lg">{pool.name}</span>
              </div>
            ))}
          </div>
        </div>
      </PopUp>
    </div>
  );
}

export default PositionPopUp;
