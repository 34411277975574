 
import {SVGProps} from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={25}
    fill="none"
    {...props}
  >
    <path
      fill="#0D9967"
      d="M12 13a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7Zm-1.5 3.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Z"
    />
    <path
      fill="#0D9967"
      d="M17.526 5.616 14.347 1.16 2.658 10.497l-.648-.007v.01H1.5v12h21v-12h-.962l-1.914-5.599-2.098.715Zm1.899 4.884H9.397l7.469-2.546 1.522-.487 1.037 3.033ZM15.55 6.29 7.84 8.918l6.106-4.878 1.604 2.25ZM3.5 18.67v-4.34a3.008 3.008 0 0 0 1.83-1.83h13.34a3.01 3.01 0 0 0 1.83 1.83v4.34a3.008 3.008 0 0 0-1.83 1.83H5.332A3.01 3.01 0 0 0 3.5 18.67Z"
    />
  </svg>
);
export default SvgComponent;
