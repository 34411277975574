import Avatar from "@assets/notificationProfile.png";
import LinkButton from "@components/ButtonV2/LinkButton";
import Attachment1 from "@assets/notificationsAttachment1.png";
import Attachment2 from "@assets/notificationsAttachment2.png";

const NotificationsList = () => {
  const notifications = [
    {
      date: "24th March,2024",
      name: "Fidelia Neequaye",
      image: Avatar,
      action:
        "changed the status of the tasked you assigned her from Pending to Complete",
    },
    {
      date: "23rd March,2024",
      name: "Emmanuel Sunnu",
      image: Avatar,
      action: "Its Emmanuel Sunnu's birthday today !!",
      birthday: true,
    },
    {
      date: "2nd March,2024",
      name: "Matthias Boampong",
      image: Avatar,
      action: "has attached a document to leave request",
      attachments: [{ label: "Doctors report", file: "", size: "1MB" }],
    },
    {
      date: "18th February,2024",
      name: "Mi-lady Banfo",
      image: Avatar,
      action:
        "is handing over her tasks and responsibilities you as she goes on a holiday.",
    },
    {
      date: "3rd February,2024",
      name: "Liam Summers",
      image: Avatar,
      action: "assigned a task to you and added the following attachments",
      images: [
        { label: "Santorini, Greece", file: Attachment1 },
        { label: "Cinque Terre, Italy", file: Attachment2 },
      ],
    },
    {
      date: "31st January,2024",
      name: "Paula Agyepoong",
      image: Avatar,
      action: "Its Paula Agyepoong's birthday today !!",
      birthday: true,
    },
  ];

  const getCurrentTime = () => {
    const now = new Date();
    return now.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  };

  return (
    <div className="p-4">
      <div className="relative mt-8">
        <div className="absolute left-[197px] top-0 bottom-0 w-[1px] bg-gray-300"></div>
        <ul className="space-y-6 ml-12">
          {notifications.map((notification, index) => {
            const borderColors = [
              "border-blue-500",
              "border-green-500",
              "border-red-500",
            ];
            const borderColor = borderColors[index % borderColors.length];
            return (
              <li key={index} className="relative flex items-center space-x-4">
                <div className="flex-none w-32 text-black text-sm text-right">
                  {notification.date}
                </div>
                <div
                  className={`relative w-3 h-3 bg-white ${borderColor} border-2 rounded-full`}
                ></div>
                <div className="bg-white p-4 rounded-lg shadow-sm flex-grow">
                  <div className="flex items-center">
                    <img
                      src={notification.image}
                      alt={notification.name}
                      className="w-8 h-8 mr-4 rounded-full"
                    />
                    <LinkButton label={notification.name} underlineOnHover />
                    <p className="text-lg font-semibold flex items-center">
                      {notification.birthday && (
                        <span className="ml-2">🎂</span>
                      )}
                    </p>
                    <p className="text-[#292D32] text-sm">
                      &nbsp; {notification.action}
                    </p>
                  </div>

                  {notification.attachments && (
                    <ul className="mt-2">
                      {notification.attachments.map((attachment, idx) => (
                        <li
                          key={idx}
                          className="text-gray-600 border rounded-md w-fit"
                        >
                          {attachment.label} - {attachment.size}
                        </li>
                      ))}
                    </ul>
                  )}
                  {notification.images && (
                    <ul className="mt-2 flex">
                      {notification.images.map((image, idx) => (
                        <li key={idx} className="text-gray-600">
                          <img
                            src={image.file}
                            alt={image.label}
                            className="mr-2 inline"
                          />
                        </li>
                      ))}
                    </ul>
                  )}
                  <small className="text-gray-500 absolute right-0 top-0">
                    {getCurrentTime()}
                  </small>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default NotificationsList;
