export const pools = [
  {id: 1, name: "Candidate", color: "bg-custom_deep_purple"},
  {id: 2, name: "Interviewed", color: "bg-card_green"},
  {id: 3, name: "Employee", color: "bg-custom_orange"},
  {id: 3, name: "Rejected", color: "bg-custom_red"},
];



export const BASEURL = import.meta.env.VITE_REACT_APP_BASEURL || "";


export const Months = [
  {name: "January", value: "January"},
  {name: "February", value: "February"},
  {name: "March", value: "March"},
  {name: "April", value: "April"},
  {name: "May", value: "May"},
  {name: "June", value: "June"},
  {name: "July", value: "July"},
  {name: "August", value: "August"},
  {name: "September", value: "September"},
  {name: "October", value: "October"},
  {name: "November", value: "November"},
  {name: "December", value: "December"},
];