import SmallerAvatar from "@assets/avatar6.png";
import ButtonV2 from "@components/ButtonV2/ButtonV2";
import LinkButton from "@components/ButtonV2/LinkButton";
import FileCard from "@components/FileCard";
import {PlusIcon} from "@heroicons/react/24/solid";
import moment from "moment";

const files = [
  {
    id: 1,
    name: "Product Design Documentation",
    size: "1.2mb",
    type: "pdf",
  },
  {
    id: 2,
    name: "Product Design Documentation",
    size: "1.2mb",
    type: "pdf",
  },
];

function TaskDetails() {
  return (
    <>
      <div className="pt-12 divide-y flex flex-col px-5 scroll-pb-12">
        <div className="flex gap-4 pb-8">
          <div className="flex flex-col gap-5">
            <span className="text-custom_gray">Title:</span>
            <span className="text-custom_gray">Status:</span>
            <span className="text-custom_gray">Due Date:</span>
            <span className="text-custom_gray">Assigned to:</span>
          </div>
          <div className="flex flex-col ms-5 gap-5">
            <p className="">Product Design Domuentation</p>
            <p className="">In Progress</p>
            <p className="">{moment()?.format("DD/MM/YYYY hh:mm a")}</p>
            <div className="flex gap-2 items-center">
              <img
                src={SmallerAvatar}
                className="h-8 w-8 object-cover rounded-full "
              />

              <p>Florence Delali</p>
            </div>
          </div>
        </div>

        {/* convert this to component so i can loop on this for the subtask */}
        <div className="flex flex-col gap-y-5 py-8">
          <div className="">
            <label>
              <span className="text-custom_gray">Task:</span>
            </label>
            <div className="border border-gray-300 rounded-lg px-3 mt-3 py-4">
              <span className="text-lg">
                Are you a communication virtuoso with exceptional
                problem-solving skills and a passion for customer satisfaction?
                Do you speak fluent Arabic? Remoteli, a leading on-demand
                staffing solution, is seeking talented Customer Service
                Assistants to join our team. This role is available in both our
                Accra, Ghana office and as a remote position in Kigali, Rwanda.
              </span>
            </div>
          </div>

          <div>
            <div className="flex justify-between items-center">
              <label>
                <span className="text-custom_gray">Attachments:</span>
              </label>
              <LinkButton label="Download All" className="no-underline" />
            </div>

            <div className="grid mt-5 grid-cols-3 gap-x-6">
              {files.map((file) => (
                <div key={file?.id}>
                  <FileCard file={file} />
                </div>
              ))}
              <div className="flex">
                <div className="w-24 h-full flex items-center justify-center border rounded-md ">
                  <PlusIcon className="h-10 w-10text-black font-semmibold" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-y-5 py-8">
          <div className="">
            <label>
              <span className="text-custom_gray">Subtask:</span>
            </label>
            <div className="border border-gray-300 rounded-lg px-3 mt-3 py-4">
              <span className="text-lg">
                Are you a communication virtuoso with exceptional
                problem-solving skills and a passion for customer satisfaction?
                Do you speak fluent Arabic? Remoteli, a leading on-demand
                staffing solution, is seeking talented Customer Service
                Assistants to join our team. This role is available in both our
                Accra, Ghana office and as a remote position in Kigali, Rwanda.
              </span>
            </div>
          </div>

          <div>
            <div className="flex justify-between items-center">
              <label>
                <span className="text-custom_gray">Attachments:</span>
              </label>
              <LinkButton label="Download All" className="no-underline" />
            </div>

            <div className="grid mt-5 grid-cols-3 gap-x-6">
              {files.map((file) => (
                <div key={file?.id}>
                  <FileCard file={file} />
                </div>
              ))}
              <div className="flex">
                <div className="w-24 h-full flex items-center justify-center border rounded-md ">
                  <PlusIcon className="h-10 w-10text-black font-semmibold" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex mt-8 justify-end">
        <div>
          <ButtonV2 variant="tertiary" text="Add New Subtask" />
        </div>
      </div>
    </>
  );
}

export default TaskDetails;
