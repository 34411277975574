import {useEffect} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {oauthConfig} from "../config/oathConfig";
import axios from "axios";
import Spinner from "@components/loaders/Spinner";
import {toast} from "sonner";

const TokenPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchToken = async () => {
      const code = searchParams.get("code");
      const verifier = sessionStorage.getItem("code_verifier");

      if (code && verifier) {
        try {
          const response = await axios.post(oauthConfig.tokenEndpoint, {
            client_id: oauthConfig.clientId,
            grant_type: "authorization_code",
            code,
            redirect_uri: oauthConfig.redirectUri,
            code_verifier: verifier,
            scope: "profile email address",
          });

          const {access_token} = response.data;
          sessionStorage.setItem("access_token", access_token);
          sessionStorage.removeItem("code_verifier");
          toast?.success("Welcome back!");
          navigate("/");
        } catch (error) {
          console.error("Error exchanging token:", error);
        }
      }
    };

    fetchToken();
  }, [searchParams, navigate]);

  return (
    <div
      className="bg-custom_dark_blue flex-col gap-4"
      style={styles.container}
    >
      <Spinner size="w-12 h-12" color="text-white-500" />
      <h1 className="text-white">Processing ...</h1>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    textAlign: "center" as const,
  },
};

export default TokenPage;
