import ButtonV2 from "@components/ButtonV2/ButtonV2";
import CustomDatePicker from "@components/datepicker";
import Dropdown from "@components/dropdowns/dropdown";
import RichTextEditor from "@components/editor";
import moment from "moment";
import {useState} from "react";

function EditEndEmployment() {
  const [type, setType] = useState("1");
  const [dob, setDob] = useState(moment(new Date())?.format("YYYY-MM-DD"));
  const [emergencyContact, setEmergencyContact] = useState(
    "Eligible for rehire"
  );
  return (
    <div>
      <>
        <div className="gap-y-4 flex flex-col pb-10 overflow-auto">
          <div className="w-full flex-1 flex flex-col py-4 px-6 rounded-lg">
            <div className=" my-6 grid gap-x-4 gap-y-5 grid-cols-1">
              <div>
                <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                  Effective Date:{" "}
                </label>
                <CustomDatePicker
                  selectedDate={dob}
                  setSelectedDate={setDob}
                  type="date"
                />
              </div>

              <div>
                <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                  Type:{" "}
                </label>
                <Dropdown
                  list={[
                    {
                      id: 1,
                      name: "Resignation",
                      value: "resignation",
                    },
                    {
                      id: 2,
                      name: "Termination",
                      value: "termination",
                    },
                  ]}
                  selected={type}
                  setSelected={setType}
                />
              </div>

              <div>
                <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                  Comment:{" "}
                </label>
                <RichTextEditor
                  placeholder={"eg. +23349873787"}
                  value={emergencyContact}
                  setValue={setEmergencyContact}
                />
              </div>
            </div>
            <div className="w-full pb-8 mt-4 col-span-10 flex justify-end items-end">
              <ButtonV2 text="Update employment" onClick={() => {}} />
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default EditEndEmployment;
