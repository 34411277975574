import Modal from "@components/modal/modal";

 
interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selected: any;
  buttonTitle?: string;
}

function Delete({open, setOpen, selected, buttonTitle}: Props) {
  return (
    <Modal title="Delete" width="w-1/4" open={open} setOpen={setOpen}>
      <div className="mt-6 flex flex-col gap-y-2">
        <div className="bg-custom_faded_red pb-20 p-2 rounded-lg">
          <div className="flex items-center mb-1">
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 1.5C4.8675 1.5 1.5 4.8675 1.5 9C1.5 13.1325 4.8675 16.5 9 16.5C13.1325 16.5 16.5 13.1325 16.5 9C16.5 4.8675 13.1325 1.5 9 1.5ZM8.4375 6C8.4375 5.6925 8.6925 5.4375 9 5.4375C9.3075 5.4375 9.5625 5.6925 9.5625 6V9.75C9.5625 10.0575 9.3075 10.3125 9 10.3125C8.6925 10.3125 8.4375 10.0575 8.4375 9.75V6ZM9.69 12.285C9.6525 12.3825 9.6 12.4575 9.5325 12.5325C9.4575 12.6 9.375 12.6525 9.285 12.69C9.195 12.7275 9.0975 12.75 9 12.75C8.9025 12.75 8.805 12.7275 8.715 12.69C8.625 12.6525 8.5425 12.6 8.4675 12.5325C8.4 12.4575 8.3475 12.3825 8.31 12.285C8.2725 12.195 8.25 12.0975 8.25 12C8.25 11.9025 8.2725 11.805 8.31 11.715C8.3475 11.625 8.4 11.5425 8.4675 11.4675C8.5425 11.4 8.625 11.3475 8.715 11.31C8.895 11.235 9.105 11.235 9.285 11.31C9.375 11.3475 9.4575 11.4 9.5325 11.4675C9.6 11.5425 9.6525 11.625 9.69 11.715C9.7275 11.805 9.75 11.9025 9.75 12C9.75 12.0975 9.7275 12.195 9.69 12.285Z"
                fill="#FB1073"
              />
            </svg>
            <span className="ms-2">
              Are you sure you want to delete {selected?.class}
            </span>
          </div>
          <div className="ps-6">
            <span className="text-[#82868C] text-sm">
              <strong>"{selected?.title}"</strong>
              {selected?.subtext}
            </span>
          </div>
        </div>
        <div className="mt-8 flex gap-4">
          <button
            className="inline-flex justify-center w-1/2 rounded-md border border-[#EDF2F7] bg-none px-8 py-3 text-sm font-light text-[#7085A2] hover:bg-gray-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
            onClick={() => setOpen(false)}
          >
            Cancel
          </button>
          <button
            className="inline-flex justify-center w-1/2 rounded-md border border-transparent bg-custom_red px-8 py-3 text-sm font-light text-white hover:bg-pink-900 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
            onClick={() => setOpen(false)}
          >
            {buttonTitle || "Edit User!"}
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default Delete;
