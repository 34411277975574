const Gmail = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.1406 0H5.85938C2.62333 0 0 2.62333 0 5.85938V19.1406C0 22.3767 2.62333 25 5.85938 25H19.1406C22.3767 25 25 22.3767 25 19.1406V5.85938C25 2.62333 22.3767 0 19.1406 0Z"
        fill="#F4F2ED"
      />
      <path
        d="M4.06602 19.8284H7.17324V12.2822L2.73438 8.95312V18.4968C2.73438 19.2336 3.33145 19.8284 4.06602 19.8284Z"
        fill="#4285F4"
      />
      <path
        d="M17.8262 19.8285H20.9335C21.6703 19.8285 22.2651 19.2314 22.2651 18.4969V8.95312L17.8262 12.2823"
        fill="#34A853"
      />
      <path
        d="M17.8262 6.5074V12.278L22.2651 8.9489V7.17312C22.2651 5.52634 20.3853 4.58748 19.0691 5.57517"
        fill="#FBBC04"
      />
      <path
        d="M7.17383 12.2785V6.50801L12.5005 10.5028L17.8271 6.50781V12.2784L12.5005 16.2735"
        fill="#EA4335"
      />
      <path
        d="M2.73438 7.18093V8.95652L7.17324 12.2857V6.51521L5.93037 5.58299C4.61201 4.59529 2.73438 5.53416 2.73438 7.18093Z"
        fill="#C5221F"
      />
    </svg>
  );
};

export default Gmail;
