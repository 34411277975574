import React from "react";
import "react-quill/dist/quill.snow.css"; // Import the styles
import ReactQuill from "react-quill"; // Import the component
import "react-quill/dist/quill.bubble.css"; // Optional: Use 'quill.bubble.css' for a more compact toolbar style
// interface Attachment {
//   url: string;
//   alt: string;
// }

const modules = {
  // style the toolbar

  toolbar: {
    // make the toolbar have a padding
    container: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }], // Headers
      ["bold", "italic", "underline", "strike"], // Toggle buttons for basic formatting
      [{ list: "ordered" }, { list: "bullet" }], // Ordered and Bullet lists
      ["link", "image", "video", "attachment"], // Link, Image, Video, and Attachment
    ],
    handlers: {
      attachment: () => {
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.click();
        input.onchange = async () => {
          const file = input.files?.[0];
          if (file) {
            const formData = new FormData();
            formData.append("attachment", file);
          }
        };
      },
    },
  },
};

const formats = [
  "bold",
  "italic",
  "underline",
  "strike",
  "code-block",
  "list",
  "bullet",
  "indent",
  "header",
  "link",
  "image",
  "video",
  "attachment",
];

interface RichTextEditorProps {
  value: string;
  setValue: (value: string) => void;
  placeholder: string;
  height?: string;
  className?: string;
  label?: string;
  showHelpText?: boolean;
}

const RichTextEditor: React.FC<RichTextEditorProps> = ({
  value,
  setValue,
  placeholder,
  className,
  label,
  showHelpText = true,
}) => {
  // const ReactQuill = useMemo(
  //   () => lazy(() => import("react-quill")),
  //   []
  // );
  // const uploadAttachment = async (
  //   formData: FormData
  // ): Promise<string | null> => {
  //   // Implement the logic to upload the attachment to your server
  //   // You can use fetch or any other method to handle the upload

  //   try {
  //     const response = await fetch("YOUR_ATTACHMENT_UPLOAD_URL", {
  //       method: "POST",
  //       body: formData,
  //     });

  //     if (response.ok) {
  //       const data = await response.json();
  //       return data.url; // Assuming the server responds with the attachment URL
  //     } else {
  //       console.error("Error uploading attachment");
  //       return null;
  //     }
  //   } catch (error) {
  //     console.error("Error uploading attachment", error);
  //     return null;
  //   }
  // };

  return (
    <>
      {label && (
        <label className="block text-[#969EAE] text-xs mb-1">{label}</label>
      )}
      <div className={`overflow-scroll ${className}`}>
        <ReactQuill
          value={value}
          onChange={setValue}
          modules={modules}
          formats={formats}
          className="h-full w-full"
          bounds=".app"
          style={{
            height: "100%",
            background: "none",
            border: "1px solid #657A9345",
          }}
          placeholder={placeholder || "Write something..."}
        />
      </div>
      {showHelpText && (
        <div className="flex mt-1 items-center gap-x-2">
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.66667 9.66667H7V7H6.33333M7 4.33333H7.00667M13 7C13 7.78793 12.8448 8.56815 12.5433 9.2961C12.2417 10.0241 11.7998 10.6855 11.2426 11.2426C10.6855 11.7998 10.0241 12.2417 9.2961 12.5433C8.56815 12.8448 7.78793 13 7 13C6.21207 13 5.43185 12.8448 4.7039 12.5433C3.97595 12.2417 3.31451 11.7998 2.75736 11.2426C2.20021 10.6855 1.75825 10.0241 1.45672 9.2961C1.15519 8.56815 1 7.78793 1 7C1 5.4087 1.63214 3.88258 2.75736 2.75736C3.88258 1.63214 5.4087 1 7 1C8.5913 1 10.1174 1.63214 11.2426 2.75736C12.3679 3.88258 13 5.4087 13 7Z"
              stroke="#6F727A"
              strokeWidth="1.4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span className="text-xs text-[#6F727A]">
            Be clear and concise when writing the content. Be as inclusive as
            possible!
          </span>
        </div>
      )}
    </>
  );
};

export default RichTextEditor;
