import { useState } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/16/solid";

import moment from "moment";
import { TableColumn } from "@components/DataTable/types";
import Table from "@components/DataTable";
import DeleteIcon from "@components/svgs/delete";
import ConfirmModal from "@components/confirmationDialog/ConfirmationDialog";
import LinkButton from "@components/ButtonV2/LinkButton";
import { useNavigate } from "react-router-dom";

interface LeadData {
  id: number;
  name: string;
  company: string;
  value: number;
  expectedDate: string;
  status: string;
  action: string;
}

const sampleData: LeadData[] = [
  {
    id: 5023,
    name: "Web developer",
    company: "Catenge Innovations",
    value: 160000.0,
    expectedDate: "12/02/2024",
    status: "First meeting",
    action: "trash",
  },
  {
    id: 5023,
    name: "Web developer",
    company: "Catenge Innovations",
    value: 160000.0,
    expectedDate: "12/02/2024",
    status: "Proposal sent",
    action: "trash",
  },
  {
    id: 5023,
    name: "Web developer",
    company: "Catenge Innovations",
    value: 160000.0,
    expectedDate: "12/02/2024",
    status: "Proposal sent",
    action: "trash",
  },
  {
    id: 5023,
    name: "Sports App",
    company: "Catenge Innovations",
    value: 160000.0,
    expectedDate: "12/02/2024",
    status: "Waiting on payment",
    action: "trash",
  },
  {
    id: 5023,
    name: "Sports App",
    company: "Catenge Innovations",
    value: 160000.0,
    expectedDate: "12/02/2024",
    status: "Waiting on payment",
    action: "trash",
  },
  {
    id: 5023,
    name: "Sports App",
    company: "Catenge Innovations",
    value: 160000.0,
    expectedDate: "12/02/2024",
    status: "First meeting",
    action: "trash",
  },
];

function LeadsTable() {
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(10);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const navigate = useNavigate();

  const handleRowClick = (id: number) => {
    navigate(`/crm/leads/view/${id}`);
  };

  const handleNext = () => {
    setStart(start + 10);
    setEnd(end + 10);
  };

  const handlePrev = () => {
    setStart(start - 10);
    setEnd(end - 10);
  };

  const columns: TableColumn<LeadData>[] = [
    {
      key: "id",
      label: "ID",
      sortable: true,
    },
    {
      key: "name",
      label: "Name",
      sortable: true,
    },
    {
      key: "company",
      label: "Company",
      sortable: true,
      render: (item, row) => (
        <LinkButton label={item} onClick={() => handleRowClick(row.id)} />
      ),
    },
    {
      key: "value",
      label: "Value",
      sortable: true,
    },
    {
      key: "expectedDate",
      label: "Expected Date",
      sortable: true,
      render: (value) => moment(value).format("DD/MM/YYYY"),
    },
    {
      key: "status",
      label: "Status",
      sortable: true,
    },
    {
      key: "action",
      label: "Action",
      render: () => (
        <button onClick={() => setShowDeleteDialog(true)}>
          <DeleteIcon />
        </button>
      ),
    },
  ];

  return (
    <>
      <div className="px-5 py-2">
        <div className="-mx-4 mt-3 sm:-mx-0">
          <Table columns={columns} data={sampleData.slice(start, end)} />
        </div>

        <div className="hidden sm:flex sm:flex-1 sm:items-center mt-5 sm:justify-between">
          <div />
          <div>
            <nav
              className="isolate inline-flex gap-x-3 -space-x-px rounded-md"
              aria-label="Pagination"
            >
              <button
                onClick={handlePrev}
                className="relative inline-flex items-center rounded-full px-2 py-2 h-8 w-8 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
              >
                <span className="sr-only">Previous</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </button>
              {/* Current: "z-10 bg-indigo-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600", Default: "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0" */}

              <a
                href="#"
                className="relative inline-flex items-center px-4 py-2 h-8 w-8 text-sm font-medium text-[#94A4C4] ring-1 rounded-full ring-inset justify-center ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
              >
                1
              </a>

              <button
                onClick={handleNext}
                className="relative inline-flex items-center rounded-full px-2 py-2 h-8 w-8 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
              >
                <span className="sr-only">Next</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </nav>
          </div>
        </div>
      </div>
      <ConfirmModal
        isOpen={showDeleteDialog}
        onCancel={() => setShowDeleteDialog(false)}
        onDelete={() => {}}
        itemName="Paula Agyepong"
        subject="Are you sure you want to delete lead"
      />
    </>
  );
}

export default LeadsTable;
