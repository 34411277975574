import Input from "@components/input";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/solid";
import { useState } from "react";
import MyEditor from "@components/editor";
import ButtonV2 from "@components/ButtonV2/ButtonV2";

function AddCandidate() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [position, setPosition] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [value, setValue] = useState("");

  return (
    <>
      <div className="gap-y-4 flex flex-col pb-10 overflow-auto">
        <div className="w-full flex-1 flex flex-col py-4 px-6 rounded-lg">
          <div className=" my-6 grid gap-x-4 gap-y-5 grid-cols-1">
            <div>
              <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                First name:{" "}
              </label>
              <Input
                placeholder={"eg. John"}
                className={"mt-1 h-10"}
                value={firstName}
                onChange={(e: any) => setFirstName(e.target.value)}
              />
            </div>
            <div>
              <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                Last name:{" "}
              </label>
              <Input
                placeholder={"eg. Doe"}
                className={"mt-1 h-10"}
                value={lastName}
                onChange={(e: any) => setLastName(e.target.value)}
              />
            </div>
            <div>
              <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                Position:{" "}
              </label>
              <Input
                type="text"
                placeholder={"eg. software engineer"}
                className={"mt-1 h-10"}
                value={position}
                onChange={(e: any) => setPosition(e.target.value)}
              />
            </div>
            <div>
              <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                Email:{" "}
              </label>
              <Input
                type="email"
                placeholder={"eg. software engineer"}
                className={"mt-1 h-10"}
                value={email}
                onChange={(e: any) => setEmail(e.target.value)}
              />
            </div>

            <div>
              <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                Password:{" "}
              </label>
              <div className="mt-1 h-10 relative">
                <Input
                  id="password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e: any) => setPassword(e.target.value)}
                  autoComplete="current-password"
                  required
                  placeholder="eg. *********"
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute outline-none right-0 top-0 mt-3 mr-3"
                >
                  {showPassword ? (
                    <EyeSlashIcon className="h-6 w-6 text-custom_gray" />
                  ) : (
                    <EyeIcon className="h-6 w-6 text-custom_gray" />
                  )}
                </button>
              </div>
            </div>
            <div>
              <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                Confirm Password:{" "}
              </label>
              <div className="mt-1 h-10 relative">
                <Input
                  id="password"
                  name="password"
                  type={showConfirmPassword ? "text" : "password"}
                  value={confirmPassword}
                  onChange={(e: any) => setConfirmPassword(e.target.value)}
                  autoComplete="current-password"
                  required
                  placeholder="eg. *********"
                  //   className="h-10"
                />
                <button
                  type="button"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  className="absolute outline-none right-0 top-0 mt-1 mr-3"
                >
                  {showConfirmPassword ? (
                    <EyeSlashIcon className="h-6 w-6 text-custom_gray" />
                  ) : (
                    <EyeIcon className="h-6 w-6 text-custom_gray" />
                  )}
                </button>
              </div>
            </div>
            <div>
              <div className="">
                <MyEditor
                  value={value}
                  setValue={setValue}
                  placeholder="Enter Tasks Here..."
                  className="h-60"
                  height={"60"}
                />
              </div>
              <div className="flex mt-1 items-center gap-x-2">
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.66667 9.66667H7V7H6.33333M7 4.33333H7.00667M13 7C13 7.78793 12.8448 8.56815 12.5433 9.2961C12.2417 10.0241 11.7998 10.6855 11.2426 11.2426C10.6855 11.7998 10.0241 12.2417 9.2961 12.5433C8.56815 12.8448 7.78793 13 7 13C6.21207 13 5.43185 12.8448 4.7039 12.5433C3.97595 12.2417 3.31451 11.7998 2.75736 11.2426C2.20021 10.6855 1.75825 10.0241 1.45672 9.2961C1.15519 8.56815 1 7.78793 1 7C1 5.4087 1.63214 3.88258 2.75736 2.75736C3.88258 1.63214 5.4087 1 7 1C8.5913 1 10.1174 1.63214 11.2426 2.75736C12.3679 3.88258 13 5.4087 13 7Z"
                    stroke="#6F727A"
                    strokeWidth="1.4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span className="text-xs text-[#6F727A]">
                  Be clear and concise when writing the content. Be as inclusive
                  as possible!
                </span>
              </div>
            </div>
          </div>
          <div className="lg:col-span-4 mt-4 col-span-10 flex justify-end items-end">
            <ButtonV2 text="Add candidate" onClick={() => {}} />
          </div>
        </div>
      </div>
    </>
  );
}

export default AddCandidate;
