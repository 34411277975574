import ButtonV2 from "@components/ButtonV2/ButtonV2";
import Dropdown from "@components/dropdowns/dropdown";
import Input from "@components/input";
import {useState} from "react";

function AddEmergency() {
  const [type, setType] = useState("");
  const [name, setName] = useState("");
  const [relationship, setRelationship] = useState("");
  const [age, setAge] = useState("");
  const [emergencyContact, setEmergencyContact] = useState("");
  return (
    <div>
      <>
        <div className="gap-y-4 flex flex-col pb-10 overflow-auto">
          <div className="w-full flex-1 flex flex-col py-4 px-6 rounded-lg">
            <div className=" my-6 grid gap-x-4 gap-y-5 grid-cols-1">
              <div>
                <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                  Name:{" "}
                </label>
                <Input
                  placeholder={"eg. John Doe"}
                  className={"mt-1 h-10"}
                  value={name}
                  onChange={(e: any) => setName(e.target.value)}
                />
              </div>
              <div>
                <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                  Contact:{" "}
                </label>
                <Input
                  placeholder={"eg. 0541266598"}
                  className={"mt-1 h-10"}
                  value={relationship}
                  onChange={(e: any) => setRelationship(e.target.value)}
                />
              </div>
              <div>
                <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                  Alternate Contact:{" "}
                </label>
                <Input
                  placeholder={"eg. 0541266598"}
                  className={"mt-1 h-10"}
                  value={relationship}
                  onChange={(e: any) => setRelationship(e.target.value)}
                />
              </div>
              <div>
                <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                  Relationship:{" "}
                </label>
                <Dropdown
                  list={[
                    {
                      id: 1,
                      name: "Relative",
                      value: "relative",
                    },
                    {
                      id: 2,
                      name: "Sibling",
                      value: "sibling",
                    },
                    {
                      id: 3,
                      name: "Partner",
                      value: "partner",
                    },
                    {
                      id: 4,
                      name: "Parent",
                      value: "parent",
                    },
                  ]}
                  selected={type}
                  setSelected={setType}
                />
              </div>
              <div>
                <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                  Email:{" "}
                </label>
                <Input
                  placeholder={"eg. john@gmail.com"}
                  className={"mt-1 h-10"}
                  type="email"
                  value={age}
                  onChange={(e: any) => setAge(e.target.value)}
                />
              </div>

              <div>
                <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                  Address:{" "}
                </label>
                <Input
                  placeholder={"eg. Adenta Accra, Ghana"}
                  className={"mt-1 h-10"}
                  value={emergencyContact}
                  onChange={(e: any) => setEmergencyContact(e.target.value)}
                />
              </div>
            </div>
            <div className="w-full pb-8 mt-4 col-span-10 flex justify-end items-end">
              <ButtonV2 text="Add emergency contact" onClick={() => {}} />
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default AddEmergency;
