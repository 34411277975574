import { useState, ChangeEvent } from "react";
import PhoneInputComponent from "@components/phoneInput";
import TextInput from "@components/textInput";
import Dropdown from "@components/dropdowns/dropdown";

interface ContactForm {
  name: string;
  company: string;
  role: string;
  email: string;
  phone: string;
  assignedSalesPerson: string;
  lastContactDate: string;
  status: string;
  instagram: string;
  facebook: string;
  linkedin: string;
}

const AddNewContacts = () => {
  const [form, setForm] = useState<ContactForm>({
    name: "",
    company: "",
    role: "",
    email: "",
    phone: "",
    assignedSalesPerson: "",
    lastContactDate: "",
    status: "",
    instagram: "",
    facebook: "",
    linkedin: "",
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const handlePhoneChange = (value: string) => {
    setForm((prevForm) => ({ ...prevForm, phone: value }));
  };

  const handleDropdownChange = (name: string, value: string) => {
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  return (
    <div className="gap-y-4 flex flex-col pb-12">
      <div className="w-full flex-1 flex flex-col py-4 px-6 rounded-lg">
        <div className="my-6 grid gap-x-4 gap-y-5 grid-cols-1 lg:px-2 xl:px-0">
          {Object.keys(form).map((key) =>
            key !== "phone" &&
            key !== "status" &&
            key !== "assignedSalesPerson" ? (
              <TextInput
                key={key}
                name={key}
                value={form[key as keyof ContactForm]}
                label={`${key
                  .replace(/([A-Z])/g, " $1")
                  .replace(/^./, (str) => str.toUpperCase())}:`}
                onChange={handleChange}
              />
            ) : key === "phone" ? (
              <PhoneInputComponent
                key={key}
                value={form.phone}
                onChange={handlePhoneChange}
                label="Phone:"
              />
            ) : (
              <Dropdown
                key={key}
                label={`${key
                  .replace(/([A-Z])/g, " $1")
                  .replace(/^./, (str) => str.toUpperCase())}:`}
                selected={form[key as keyof ContactForm]}
                setSelected={(value) => handleDropdownChange(key, value)}
                placeholder=""
                list={
                  key === "status"
                    ? ["Active", "Inactive"]
                    : ["Salesperson 1", "Salesperson 2"]
                }
              />
            )
          )}
        </div>
        <div className="lg:col-span-4 mt-4 col-span-10 flex justify-end items-end">
          <button className="bg-[#1F3C49] rounded-lg font-light py-3 px-4 flex">
            <span className="text-white">Add contact</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddNewContacts;
