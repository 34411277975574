"use client";
import {useState} from "react";

// import {generateCodeChallenge, generateCodeVerifier} from "../../../utils/pkce";
// import {oauthConfig} from "../../../config/oathConfig";
import Spinner from "@components/loaders/Spinner";
import {useNavigate} from "react-router-dom";

function Login() {
  // const [
  //   codeVerifier, setCodeVerifier] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // useEffect(() => {
  //   const verifier = generateCodeVerifier();
  //   setCodeVerifier(verifier);
  //   sessionStorage.setItem("code_verifier", verifier);
  // }, []);

  const loginWithOAuth = async () => {
    setLoading(true);
    navigate("/");

    // const codeChallenge = await generateCodeChallenge(codeVerifier);
    // const authUrl = `${oauthConfig.authorizationEndpoint}?response_type=code&client_id=${oauthConfig.clientId}&redirect_uri=${oauthConfig.redirectUri}&code_challenge=${codeChallenge}&code_challenge_method=S256`;
    // window.location.href = authUrl;
  };

  return (
    <div className="mx-auto w-full max-w-lg lg:w-full">
      <div className="flex items-center justify-center flex-col">
        <h2 className="mt-8 text-3xl font-bold font-outfit leading-9 tracking-tight text-custom_blue">
          Sign in to account
        </h2>
        <p className="mt-2 text-sm leading-6 text-custom_gray">
          Clink the button below to sign in with your remoteli account
        </p>
      </div>

      <div className="mt-10">
        <div>
          <div className="space-y-6">
            <div>
              <button
                type="submit"
                onClick={loginWithOAuth}
                className="flex w-full justify-center items-center rounded-md bg-custom_deep_blue px-3 py-4 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                {loading && <Spinner color="text-white" size="h-6 w-6" />}
                <span className="ms-3">Login</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
