import { SVGProps } from "react";

const Clock = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="128"
      height="130"
      viewBox="0 0 128 130"
      fill="none"
      {...props}
    >
      <path
        d="M62.3 107.6C90.3 107.6 113 84.9 113 56.8C113 28.7 90.3 6 62.3 6C34.3 6 11.6 28.7 11.6 56.8C11.6 84.9 34.3 107.6 62.3 107.6Z"
        fill="#EAEEF9"
      />
      <path
        d="M118.8 35.9031C121.064 35.9031 122.9 34.0675 122.9 31.8031C122.9 29.5388 121.064 27.7031 118.8 27.7031C116.536 27.7031 114.7 29.5388 114.7 31.8031C114.7 34.0675 116.536 35.9031 118.8 35.9031Z"
        fill="#EAEEF9"
      />
      <path
        d="M124.8 19.8031C126.346 19.8031 127.6 18.5495 127.6 17.0031C127.6 15.4567 126.346 14.2031 124.8 14.2031C123.254 14.2031 122 15.4567 122 17.0031C122 18.5495 123.254 19.8031 124.8 19.8031Z"
        fill="#EAEEF9"
      />
      <path
        d="M8.20001 38.8031C9.74641 38.8031 11 37.5495 11 36.0031C11 34.4567 9.74641 33.2031 8.20001 33.2031C6.65361 33.2031 5.40001 34.4567 5.40001 36.0031C5.40001 37.5495 6.65361 38.8031 8.20001 38.8031Z"
        fill="#EAEEF9"
      />
      <path
        d="M13.4 99.6969C16.2719 99.6969 18.6 97.3688 18.6 94.4969C18.6 91.625 16.2719 89.2969 13.4 89.2969C10.5281 89.2969 8.2 91.625 8.2 94.4969C8.2 97.3688 10.5281 99.6969 13.4 99.6969Z"
        fill="#EAEEF9"
      />
      <path
        d="M66.3025 11.3125H57.9122V16.7732H66.3025V11.3125Z"
        fill="#D1D9E7"
      />
      <path
        d="M34.4995 22.1915L29.9464 26.7422L33.2577 30.0518L37.8108 25.5011L34.4995 22.1915Z"
        fill="#D5DDEA"
      />
      <path
        d="M87.7423 25.1602L92.2953 29.7109L95.2617 26.7461L90.7087 22.1953L87.7423 25.1602Z"
        fill="#D5DDEA"
      />
      <path
        d="M65.2292 97.9981C87.5361 97.9981 105.619 79.9238 105.619 57.628C105.619 35.3321 87.5361 17.2578 65.2292 17.2578C42.9223 17.2578 24.839 35.3321 24.839 57.628C24.839 79.9238 42.9223 97.9981 65.2292 97.9981Z"
        fill="#D5DDEA"
      />
      <g filter="url(#filter0_d_538_36160)">
        <path
          d="M62.7903 96.5372C85.0972 96.5372 103.18 78.4628 103.18 56.167C103.18 33.8712 85.0972 15.7969 62.7903 15.7969C40.4833 15.7969 22.4 33.8712 22.4 56.167C22.4 78.4628 40.4833 96.5372 62.7903 96.5372Z"
          fill="url(#paint0_linear_538_36160)"
        />
      </g>
      <path
        d="M27.1805 58.4083C26.2049 58.4083 25.4244 57.6282 25.4244 56.6531C25.4244 56.4581 25.4244 56.2631 25.4244 56.1656C25.4244 37.3457 39.4732 21.4511 58.1073 19.1108C59.083 19.0133 59.961 19.6959 60.0586 20.671C60.1561 21.6462 59.4732 22.5238 58.4976 22.6213C41.6195 24.7665 28.9366 39.1984 28.9366 56.1656C28.9366 56.3606 28.9366 56.4581 28.9366 56.6531C28.9366 57.6282 28.1561 58.4083 27.1805 58.4083Z"
        fill="white"
      />
      <path
        opacity="0.3"
        d="M94.3026 56.1684C94.3026 56.5584 94.3026 56.851 94.3026 57.241C93.7172 74.1107 79.8635 87.6649 62.7904 87.6649C45.7172 87.6649 31.8635 74.1107 31.2782 57.241C31.2782 56.851 31.2782 56.5584 31.2782 56.1684C31.2782 39.2012 44.7416 25.2569 61.6196 24.6719C62.0099 24.6719 62.4001 24.6719 62.7904 24.6719C80.0587 24.6719 94.0099 38.5186 94.3026 55.5833C94.3026 55.7783 94.3026 55.9734 94.3026 56.1684Z"
        fill="#CCD5E3"
      />
      <path
        d="M94.3026 57.2369C93.7172 74.1066 79.8635 87.6608 62.7904 87.6608C45.7172 87.6608 31.8635 74.1066 31.2782 57.2369C31.8635 40.3673 45.7172 26.813 62.7904 26.813C79.8635 26.7155 93.7172 40.2697 94.3026 57.2369Z"
        fill="white"
      />
      <path
        d="M90.1073 57.7298H84.9366C84.0585 57.7298 83.3756 57.0472 83.3756 56.1696C83.3756 55.292 84.0585 54.6094 84.9366 54.6094H90.1073C90.9854 54.6094 91.6683 55.292 91.6683 56.1696C91.6683 57.0472 90.8878 57.7298 90.1073 57.7298Z"
        fill="#CBD4E2"
      />
      <path
        d="M39.0829 57.7298H33.9122C33.0341 57.7298 32.3512 57.0472 32.3512 56.1696C32.3512 55.292 33.0341 54.6094 33.9122 54.6094H39.0829C39.9609 54.6094 40.6439 55.292 40.6439 56.1696C40.6439 57.0472 39.8634 57.7298 39.0829 57.7298Z"
        fill="#CBD4E2"
      />
      <path
        d="M62.0098 85.9057C61.1318 85.9057 60.4488 85.2232 60.4488 84.3455V79.1774C60.4488 78.2998 61.1318 77.6172 62.0098 77.6172C62.8879 77.6172 63.5708 78.2998 63.5708 79.1774V84.3455C63.4732 85.2232 62.7903 85.9057 62.0098 85.9057Z"
        fill="#CBD4E2"
      />
      <path
        d="M62.0098 34.9098C61.1318 34.9098 60.4488 34.2272 60.4488 33.3496V28.2789C60.4488 27.4013 61.1318 26.7188 62.0098 26.7188C62.8879 26.7188 63.5708 27.4013 63.5708 28.2789V33.4471C63.4732 34.2272 62.7903 34.9098 62.0098 34.9098Z"
        fill="#CBD4E2"
      />
      <path
        d="M62.4002 60.4547C65.0404 60.4547 67.1807 58.3154 67.1807 55.6765C67.1807 53.0377 65.0404 50.8984 62.4002 50.8984C59.76 50.8984 57.6197 53.0377 57.6197 55.6765C57.6197 58.3154 59.76 60.4547 62.4002 60.4547Z"
        fill="#F2F5FC"
      />
      <path
        opacity="0.3"
        d="M88.364 57.9487C88.3324 57.2361 87.7789 56.6283 87.1339 56.598L64.2084 56.103C64.1469 56.0355 64.1469 56.0355 64.0854 55.9679C63.2858 55.09 61.9958 55.0294 61.1183 55.8286C61.0508 55.8901 60.9833 55.9515 60.9158 56.013L57.2361 55.9048C56.5236 55.936 55.9161 56.4893 55.8861 57.1343C55.8681 57.5213 55.9852 57.7854 56.2312 58.0555C56.4772 58.3257 56.7907 58.5343 57.1162 58.485L60.7959 58.5932C60.8574 58.6607 60.9189 58.7282 60.9189 58.7282C61.7185 59.6062 63.0085 59.6668 63.886 58.8676C63.9535 58.8061 64.021 58.7447 64.0885 58.6832L86.9465 59.2397C87.788 59.2145 88.3955 58.6613 88.364 57.9487Z"
        fill="#DAE1ED"
      />
      <path
        d="M88.3069 56.394C88.2754 55.6814 87.7218 55.0736 87.0768 55.0433L64.1513 54.5483C64.0898 54.4808 64.0898 54.4808 64.0283 54.4133C63.2288 53.5353 61.9387 53.4747 61.0612 54.2739C60.9937 54.3354 60.9262 54.3968 60.8587 54.4583L57.179 54.3501C56.4665 54.3813 55.859 54.9346 55.829 55.5796C55.8111 55.9666 55.9281 56.2307 56.1741 56.5009C56.4201 56.771 56.7336 56.9796 57.0591 56.9303L60.7388 57.0385C60.8003 57.106 60.8618 57.1735 60.8618 57.1735C61.6614 58.0515 62.9514 58.1121 63.8289 57.3129C63.8964 57.2514 63.9639 57.19 64.0314 57.1285L86.8894 57.685C87.7309 57.6599 88.2769 57.0391 88.3069 56.394Z"
        fill="url(#paint1_linear_538_36160)"
      />
      <path
        d="M70.3025 3.60796V8.87363C70.3025 10.9214 68.644 12.4816 66.6928 12.4816H57.7172C55.6684 12.4816 54.1074 10.8239 54.1074 8.87363V3.60796C54.1074 1.5602 55.7659 0 57.7172 0H66.6928C68.7416 0 70.3025 1.65771 70.3025 3.60796Z"
        fill="url(#paint2_linear_538_36160)"
      />
      <path
        opacity="0.1"
        d="M70.3025 3.60796V8.87363C70.3025 10.9214 68.644 12.4816 66.6928 12.4816H57.7172C55.6684 12.4816 54.1074 10.8239 54.1074 8.87363V7.89851C54.6928 10.4338 56.9367 10.4338 56.9367 10.4338H66.205C67.4733 10.4338 68.5464 9.36119 68.5464 8.09353V4.29055C68.5464 1.65771 68.0586 0.682587 66.6928 0C68.7416 0 70.3025 1.65771 70.3025 3.60796Z"
        fill="#495A7C"
      />
      <path
        d="M55.473 9.4584C55.1803 9.4584 54.9852 9.26337 54.9852 8.97083V2.92506C54.9852 2.63252 55.1803 2.4375 55.473 2.4375C55.7657 2.4375 55.9608 2.63252 55.9608 2.92506V8.97083C55.8633 9.26337 55.6681 9.4584 55.473 9.4584Z"
        fill="#AEB8C9"
      />
      <path
        d="M57.7172 9.4584C57.4245 9.4584 57.2294 9.26337 57.2294 8.97083V2.92506C57.2294 2.63252 57.4245 2.4375 57.7172 2.4375C58.0098 2.4375 58.205 2.63252 58.205 2.92506V8.97083C58.205 9.26337 58.0098 9.4584 57.7172 9.4584Z"
        fill="#AEB8C9"
      />
      <path
        d="M60.0587 9.4584C59.766 9.4584 59.5709 9.26337 59.5709 8.97083V2.92506C59.5709 2.63252 59.766 2.4375 60.0587 2.4375C60.3514 2.4375 60.5465 2.63252 60.5465 2.92506V8.97083C60.449 9.26337 60.2538 9.4584 60.0587 9.4584Z"
        fill="#AEB8C9"
      />
      <path
        d="M62.3024 9.4584C62.0097 9.4584 61.8146 9.26337 61.8146 8.97083V2.92506C61.8146 2.63252 62.0097 2.4375 62.3024 2.4375C62.595 2.4375 62.7902 2.63252 62.7902 2.92506V8.97083C62.7902 9.26337 62.595 9.4584 62.3024 9.4584Z"
        fill="#AEB8C9"
      />
      <path
        d="M64.6439 9.4584C64.3512 9.4584 64.1561 9.26337 64.1561 8.97083V2.92506C64.1561 2.63252 64.3512 2.4375 64.6439 2.4375C64.9366 2.4375 65.1317 2.63252 65.1317 2.92506V8.97083C65.0342 9.26337 64.839 9.4584 64.6439 9.4584Z"
        fill="#AEB8C9"
      />
      <path
        d="M66.8878 9.4584C66.5951 9.4584 66.4 9.26337 66.4 8.97083V2.92506C66.4 2.63252 66.5951 2.4375 66.8878 2.4375C67.1805 2.4375 67.3756 2.63252 67.3756 2.92506V8.97083C67.3756 9.26337 67.0829 9.4584 66.8878 9.4584Z"
        fill="#AEB8C9"
      />
      <path
        opacity="0.7"
        d="M69.1317 9.4584C68.839 9.4584 68.6439 9.26337 68.6439 8.97083V2.92506C68.6439 2.63252 68.839 2.4375 69.1317 2.4375C69.4244 2.4375 69.6195 2.63252 69.6195 2.92506V8.97083C69.6195 9.26337 69.4244 9.4584 69.1317 9.4584Z"
        fill="#AEB8C9"
      />
      <path
        d="M35.3758 22.6225L30.4978 27.4982C29.4246 28.5708 27.6685 28.5708 26.4978 27.4982L23.6685 24.6703C22.5953 23.5977 22.5953 21.8424 23.6685 20.6723L28.5466 15.7967C29.6197 14.724 31.3758 14.724 32.5466 15.7967L35.3758 18.6245C36.449 19.7947 36.449 21.5499 35.3758 22.6225Z"
        fill="url(#paint3_linear_538_36160)"
      />
      <path
        d="M89.8145 22.6225L94.6926 27.4982C95.7657 28.5708 97.5218 28.5708 98.6926 27.4982L101.522 24.6703C102.595 23.5977 102.595 21.8424 101.522 20.6723L96.6438 15.7967C95.5706 14.724 93.8145 14.724 92.6438 15.7967L89.8145 18.6245C88.7413 19.7947 88.7413 21.5499 89.8145 22.6225Z"
        fill="url(#paint4_linear_538_36160)"
      />
      <path
        d="M62.2057 56.0747C62.3415 56.2239 62.5726 56.2347 62.7218 56.0988C62.871 55.9629 62.8818 55.7318 62.7459 55.5826C62.6101 55.4334 62.379 55.4226 62.2298 55.5585C62.0806 55.6944 62.0698 55.9255 62.2057 56.0747Z"
        fill="white"
      />
      <path
        d="M51.2227 51.7504C52.8499 51.7504 54.2268 50.3735 54.2268 48.7463C54.2268 47.1191 52.8499 45.7422 51.2227 45.7422C49.5955 45.7422 48.2186 47.1191 48.2186 48.7463C48.2186 50.3735 49.5955 51.7504 51.2227 51.7504Z"
        fill="#989FB0"
      />
      <path
        d="M74.2541 51.7504C75.8814 51.7504 77.2583 50.3735 77.2583 48.7463C77.2583 47.1191 75.8814 45.7422 74.2541 45.7422C72.6269 45.7422 71.25 47.1191 71.25 48.7463C71.25 50.4987 72.6269 51.7504 74.2541 51.7504Z"
        fill="#989FB0"
      />
      <path
        d="M51.1052 38.8069L44.4969 42.375L45.389 44.0271L51.9973 40.459L51.1052 38.8069Z"
        fill="#989FB0"
      />
      <path
        d="M73.8456 38.754L72.9538 40.4062L79.5628 43.9736L80.4546 42.3214L73.8456 38.754Z"
        fill="#989FB0"
      />
      <rect x="57.9758" y="65.7578" width="9" height="2" fill="#989FB0" />
      <defs>
        <filter
          id="filter0_d_538_36160"
          x="0.400009"
          y="4.79688"
          width="124.78"
          height="124.742"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="11" />
          <feGaussianBlur stdDeviation="11" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_538_36160"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_538_36160"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_538_36160"
          x1="62.7639"
          y1="13.9293"
          x2="62.7639"
          y2="97.4076"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FDFEFF" />
          <stop offset="0.9964" stop-color="#ECF0F5" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_538_36160"
          x1="80.3871"
          y1="48.4365"
          x2="70.3565"
          y2="57.5717"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#B0BACC" />
          <stop offset="1" stop-color="#969EAE" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_538_36160"
          x1="62.2062"
          y1="0.433596"
          x2="62.2062"
          y2="7.43939"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#B0BACC" />
          <stop offset="1" stop-color="#969EAE" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_538_36160"
          x1="29.5232"
          y1="15.4546"
          x2="29.5232"
          y2="22.9256"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#B0BACC" />
          <stop offset="1" stop-color="#969EAE" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_538_36160"
          x1="95.6692"
          y1="15.4546"
          x2="95.6692"
          y2="22.9256"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#B0BACC" />
          <stop offset="1" stop-color="#969EAE" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Clock;
