import {useState} from "react";
import Quiz from "./quiz";
import ButtonV2 from "@components/ButtonV2/ButtonV2";
import {useNavigate} from "react-router-dom";

function AddQuizes({toggleTab}: {toggleTab: () => void}) {
  const [quizes, setQuizes] = useState([
    {title: "", description: "", link: ""},
  ]);

  const navigate = useNavigate();

  const addQuiz = () => {
    setQuizes([...quizes, {title: "", description: "", link: ""}]);
  };

  const createCourse = () => {
    navigate("/lms");
  };

  const removeQuiz = (index: number) => {
    const newQuizes = quizes.filter((_, i) => i !== index);
    setQuizes(newQuizes);
  };

  return (
    <div className="gap-y-4 flex flex-col pb-10 overflow-auto">
      <div className="w-full  flex-1 flex flex-col py-4 px-6 rounded-lg">
        <div className=" my-6 bg-custom_faded_blue px-4 rounded-xl py-4 grid gap-x-4 gap-y-5 grid-cols-2">
          {quizes.map((_, index) => (
            <Quiz key={index} removeQuiz={() => removeQuiz(index)} />
          ))}

          <div className="col-span-2 flex">
            <div>
              <button
                onClick={addQuiz}
                className="no-underline w-auto flex px-8 py-2 text-xs rounded-lg bg-transparent border text-black border-custom_blue"
              >
                Add question
              </button>
            </div>
          </div>

          <div className={`mt-5 flex col-span-2 items-end justify-between`}>
            <button
              onClick={toggleTab}
              className="bg-custom_blue text-xs rounded-lg font-light py-3 px-4 flex"
            >
              <span className="text-white">Back to section</span>
            </button>

            <ButtonV2 text="Create course" onClick={createCourse} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddQuizes;
