import React from "react";

interface LinkButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: "default" | "danger" | "success";
  label: string;
  className?: string;
  icon?: React.ReactNode;
  underlineOnHover?: boolean;
}

const LinkButton = ({
  variant = "default",
  label,
  className = "",
  icon,
  underlineOnHover = false,
  ...props
}: LinkButtonProps) => {
  const baseStyles =
    "inline-flex items-center transition-all duration-200 focus:outline-none";

  const variantStyles = {
    default: "text-blue-400",
    danger: "text-red-400 hover:text-red-600",
    success: "text-green-400 hover:text-green-600",
  };

  const underlineStyles = underlineOnHover
    ? "no-underline hover:underline"
    : "underline hover:no-underline";

  return (
    <button
      className={`${baseStyles} ${variantStyles[variant]} ${underlineStyles} ${className}`}
      {...props}
    >
      {icon && <span className="mr-2">{icon}</span>}
      {label}
    </button>
  );
};
export default LinkButton;
