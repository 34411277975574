 

interface ButtonProps {
  onClick: (e?: any) => void;
  className?: string;
  title: string;
  icon?: React.ReactNode;
}

function Button({onClick, title, className, icon}: ButtonProps) {
  return (
    <button
      onClick={onClick}
      className={`bg-custom_deep_blue text-sm w-full justify-center text-white px-4 py-3 rounded-lg flex items-center gap-3 ${className}`}
    >
      {icon}
      <span>{title}</span>
    </button>
  );
}

export default Button;
