import React from "react";
import SmallAvatar from "@assets/avatar6.png";
import LinkButton from "@components/ButtonV2/LinkButton";
import Gmail from "@components/svgs/gmail";
import { useNavigate } from "react-router-dom";

interface Note {
  id: string;
  title: string;
  content: string;
  timestamp: string;
  author: {
    name: string;
    avatarSrc: string;
  };
  accentColor: string;
}

interface NoteCardProps {
  note: Note;
}

const notes: Note[] = [
  {
    id: "1",
    title: "Meeting with customer",
    content:
      "Reduce technical debt by refactoring legacy code and improving architecture design. Reduce technical debt by refactoring legacy code and improving architecture design. Reduce technical debt by refactoring legacy code and improving architecture design. Reduce technical debt by refactoring legacy code and improving architecture design.",
    timestamp: "12/01/2024 12:05pm",
    author: {
      name: "Fidelia Quaye",
      avatarSrc: SmallAvatar,
    },
    accentColor: "bg-indigo-500",
  },
];

const NoteCard: React.FC<NoteCardProps> = ({ note }) => {
  return (
    <div className="relative flex gap-4 bg-white rounded-lg p-6 mb-4 shadow-md">
      <div
        className={`absolute left-0 top-0 w-1 h-full ${note.accentColor} rounded-s-sm`}
      />

      <div className="flex-grow">
        <div className="flex justify-between items-start mb-4">
          <div className="space-y-4 w-full">
            <div className="flex justify-between items-center">
              <h2 className="text-sm font-semibold text-custom_black">
                {note.title}
              </h2>
              <Gmail />
            </div>
            <p className="text-[#82868C] text-xs font-light pr-4">
              {note.content}
            </p>
          </div>
        </div>

        <div className="flex items-center justify-between mt-6">
          <div className="flex items-center space-x-3">
            <img
              src={note.author.avatarSrc}
              alt={`${note.author.name}'s avatar`}
              className="w-8 h-8 rounded-full object-cover"
            />
            <span className="text-sm font-light">{note.author.name}</span>
            <span className="text-sm text-gray-500">{note.timestamp}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const NotesComponent: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-lg font-light text-[#82868C]">Notes:</h1>
        <LinkButton
          underlineOnHover
          label="View all notes"
          onClick={() => navigate("/hr/employees/viewemployee/1/notes")}
        />
      </div>

      <div className="space-y-4">
        {notes.map((note) => (
          <NoteCard key={note.id} note={note} />
        ))}
      </div>
    </div>
  );
};

export default NotesComponent;
