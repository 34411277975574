import TimeReportStats from "@components/stats/timeReports";
import EmptyReportStats from "@components/stats/emptyReportStats";
import TimeReports from "./timeReports";
import CompanyTimeReports from "./companyTimeReports";
import UserTimeReports from "./userTimeReports";
import TabsV2 from "@components/tabs/TabV2";

const tabsData = [
  {
    id: "time-reports",
    label: "Time Reports",
    component: <TimeReports />,
  },
  {
    id: "company-time-reports",
    label: "Company Time Reports",
    component: <CompanyTimeReports />,
  },
  {
    id: "user-time-reports",
    label: "User Time Reports",
    component: <UserTimeReports />,
  },
];

function TimeReportsLayout() {
  const reportsData = [];
  const hasReports = reportsData.length > 0;
  return (
    <>
      <div className="bg-[#DDEFFF33] mx-4 rounded-lg font-inter">
        <div className="flex justify-between items-center">
          {!hasReports ? (
            <div>
              <div className="w-1/2">
                <TimeReportStats />
              </div>
            </div>
          ) : (
            <div className="w-1/3">
              <EmptyReportStats />
            </div>
          )}
        </div>
      </div>
      {/* <div className="px-4 my-2"> */}
      {/* <TimeReportsCharts /> */}
      {/* </div> */}
      <div className="bg-[#DDEFFF33] flex flex-col gap-4 m-4 p-2 rounded-lg font-inter">
        <TabsV2 tabs={tabsData} />
      </div>
    </>
  );
}

export default TimeReportsLayout;
