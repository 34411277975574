import ButtonV2 from "@components/ButtonV2/ButtonV2";
import LinkButton from "@components/ButtonV2/LinkButton";
import ConfirmModal from "@components/confirmationDialog/ConfirmationDialog";
import Table from "@components/DataTable";
import { TableColumn } from "@components/DataTable/types";
import Drawer from "@components/drawer/Drawer";
import { Pagination } from "@components/pagination/Pagination";
import DeleteIcon from "@components/svgs/delete";
import PencilIcon from "@components/svgs/pencil";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

interface Job {
  name: string;
  salary: string;
  contract_perm: string;
  part_time_full_time: string;
  job_description: string;
  actions: string;
}

const sampleData: Job[] = [
  {
    name: "Developer",
    salary: "TBC",
    contract_perm: "Permanent",
    part_time_full_time: "Full time",
    job_description: "View JD!",
    actions: "Actions",
  },
  {
    name: "Developer",
    salary: "TBC",
    contract_perm: "Permanent",
    part_time_full_time: "Full time",
    job_description: "",
    actions: "Actions",
  },
  {
    name: "Developer",
    salary: "TBC",
    contract_perm: "Permanent",
    part_time_full_time: "Full time",
    job_description: "",
    actions: "Actions",
  },
];

const JobItemsList = () => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(10);
  const [showAddJobItem, setShowAddJobItem] = useState(false);

  const navigate = useNavigate();

  const toggleShowAddJobItem = () => {
    setShowAddJobItem(!showAddJobItem);
  };

  const handleNext = () => {
    setStart(start + 10);
    setEnd(end + 10);
  };

  const handlePrev = () => {
    setStart(start - 10);
    setEnd(end - 10);
  };

  const toggleShowDeleteDialog = () => {
    setShowDeleteDialog(!showDeleteDialog);
  };

  const columns: TableColumn<Job>[] = [
    {
      key: "name",
      label: "Name",
      sortable: false,
      render: (item) => (
        <LinkButton
          label={item}
          onClick={() => navigate(`/workspace/jobs/job_applications/${item}`)}
        />
      ),
    },
    {
      key: "salary",
      label: "Salary",
      sortable: false,
    },
    {
      key: "contract_perm",
      label: "Contract/Perm",
      sortable: false,
    },
    {
      key: "part_time_full_time",
      label: "Part/Full Time",
      sortable: false,
    },
    {
      key: "job_description",
      label: "Job Description",
      sortable: false,
      render: () => (
        <ButtonV2 className="text-sm" text="View JD!" onClick={() => {}} />
      ),
    },
    {
      key: "actions",
      label: "Actions",
      sortable: false,
      render: () => (
        <span className="flex align-middle gap-1">
          <button>
            <PencilIcon />
          </button>
          <button onClick={() => setShowDeleteDialog(true)}>
            <DeleteIcon />
          </button>
        </span>
      ),
    },
  ];

  return (
    <>
      <Table data={sampleData} columns={columns} />
      <ConfirmModal
        itemName="name"
        subject="Are you sure you want to delete task"
        isOpen={showDeleteDialog}
        onCancel={toggleShowDeleteDialog}
        onDelete={() => {}}
      />
      <Pagination
        start={start}
        end={end}
        total={sampleData.length}
        onPrevious={handlePrev}
        onNext={handleNext}
      />
      <ConfirmModal
        isOpen={showDeleteDialog}
        onCancel={() => setShowDeleteDialog(false)}
        onDelete={() => {}}
        itemName="Paula Agyepong"
        subject="Are you sure you want to delete task"
      />
      <Drawer
        drawerHeader="Job Item"
        isOpen={showAddJobItem}
        onClose={toggleShowAddJobItem}
      >
        Job item form
      </Drawer>
    </>
  );
};

export default JobItemsList;
