import ProductsStats from "@components/stats/productsstats";
import ProductList from "./productlist/productlist";

function Products() {
  return (
    <>
      <div className="bg-[#DDEFFF33] mx-4 rounded-lg font-inter">
        <div className="flex justify-between items-center pe-6">
          <div className="">
            <ProductsStats />
          </div>
        </div>
      </div>
      <div className="my-4 px-3">
        <ProductList />
      </div>
    </>
  );
}

export default Products;
