import ButtonV2 from "@components/ButtonV2/ButtonV2";
import CustomDatePicker from "@components/datepicker";
import Dropdown from "@components/dropdowns/dropdown";
import Input from "@components/input";
import {useState} from "react";

function AddAdditionalInformation() {
  const [firstName, setFirstName] = useState("");
  const [country, setCountry] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("");

  const [dob, setDob] = useState("");
  return (
    <div>
      <>
        <div className="gap-y-4 flex flex-col pb-10 overflow-auto">
          <div className="w-full flex-1 flex flex-col py-4 px-6 rounded-lg">
            <div className=" my-6 grid gap-x-4 gap-y-5 grid-cols-1">
              <div>
                <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                  First Name:{" "}
                </label>
                <Input
                  placeholder={"eg.John"}
                  className={"mt-1 h-10"}
                  value={firstName}
                  onChange={(e: any) => setFirstName(e.target.value)}
                />
              </div>
              <div>
                <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                  Last Name:{" "}
                </label>
                <Input
                  placeholder={"eg. Doe"}
                  className={"mt-1 h-10"}
                  value={firstName}
                  onChange={(e: any) => setFirstName(e.target.value)}
                />
              </div>
              <div>
                <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                  Date of Birth:{" "}
                </label>
                <CustomDatePicker
                  selectedDate={dob}
                  setSelectedDate={setDob}
                  type="date"
                />
              </div>
              <div>
                <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                  Age:{" "}
                </label>
                <Input
                  placeholder={"eg. 27 years"}
                  className={"mt-1 h-10"}
                  value={firstName}
                  onChange={(e: any) => setFirstName(e.target.value)}
                />
              </div>
              <div>
                <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                  Gender:{" "}
                </label>
                <Dropdown
                  list={[
                    {
                      id: 1,
                      name: "Male",
                      value: "male",
                    },
                    {
                      id: 2,
                      name: "Female",
                      value: "female",
                    },
                  ]}
                  selected={selectedStatus}
                  setSelected={setSelectedStatus}
                />
              </div>

              <div>
                <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                  Contact Number:{" "}
                </label>
                <Input
                  placeholder={"eg. +23349873787"}
                  className={"mt-1 h-10"}
                  value={firstName}
                  onChange={(e: any) => setFirstName(e.target.value)}
                />
              </div>
              <div>
                <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                  Personal Email Address:{" "}
                </label>
                <Input
                  placeholder={"eg. name@provider.com"}
                  className={"mt-1 h-10"}
                  value={firstName}
                  onChange={(e: any) => setFirstName(e.target.value)}
                />
              </div>
              <div>
                <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                  Highest level of education to date:{" "}
                </label>
                <Input
                  placeholder={"eg.Bachelor's Degree"}
                  className={"mt-1 h-10"}
                  value={firstName}
                  onChange={(e: any) => setFirstName(e.target.value)}
                />
              </div>
              <div>
                <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                  Nationality:{" "}
                </label>
                <Dropdown
                  list={[
                    {
                      id: 1,
                      name: "Ghana",
                      value: "Ghana",
                    },
                    {
                      id: 2,
                      name: "Kenya",
                      value: "Kenya",
                    },
                    {
                      id: 3,
                      name: "Uganda",
                      value: "Uganda",
                    },
                    {
                      id: 3,
                      name: "Nigeria",
                      value: "Nigeria",
                    },
                  ]}
                  selected={country}
                  setSelected={setCountry}
                />
              </div>
            </div>
            <div className="w-full pb-8 mt-4 col-span-10 flex justify-end items-end">
              <ButtonV2 text="Add New Detail" onClick={() => {}} />
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default AddAdditionalInformation;
