import ConfirmModal from "@components/confirmationDialog/ConfirmationDialog";
import Table from "@components/DataTable";
import { TableColumn } from "@components/DataTable/types";
import Drawer from "@components/drawer/Drawer";
import { Pagination } from "@components/pagination/Pagination";
import DeleteIcon from "@components/svgs/delete";
import PencilIcon from "@components/svgs/pencil";
import { useState } from "react";
import JobApplicationDetail from "./JobApplicationDetail";
import LinkButton from "@components/ButtonV2/LinkButton";
import ButtonV2 from "@components/ButtonV2/ButtonV2";

export type ApplicationStatus = "Rejected" | "Talent pool" | "Selected";

export interface JobApplication {
  id: string;
  jobTitle: string;
  dateApplied: string;
  status: ApplicationStatus;
  jobDescription?: string;
  cv?: string;
  actions?: string[];
}

const generateSampleApplications = (count: number = 6): JobApplication[] => {
  const statuses: ApplicationStatus[] = ["Rejected", "Talent pool", "Selected"];

  return Array.from({length: count}, (_, index) => ({
    id: "5023",
    jobTitle: "Learning and Development Coordinator",
    dateApplied: "12/02/2024",
    status: statuses[index % statuses.length],
    jobDescription: "Job description content here",
    cv: "CV content here",
  }));
};

const JobApplicationsList = () => {
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(10);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showApplicationsDetail, setShowApplicationsDetail] = useState(false);
  const applications = generateSampleApplications();

  function toggleShowApplication() {
    setShowApplicationsDetail(!showApplicationsDetail);
  }

  const handleNext = () => {
    setStart(start + 10);
    setEnd(end + 10);
  };

  const handlePrev = () => {
    setStart(start - 10);
    setEnd(end - 10);
  };

  const applicationColumns: TableColumn<JobApplication>[] = [
    {
      key: "id",
      label: "ID",
      sortable: true,
    },
    {
      key: "jobTitle",
      label: "Job title",
      render: (value: string) => (
        <LinkButton label={value} onClick={toggleShowApplication} />
      ),
      sortable: true,
    },
    {
      key: "dateApplied",
      label: "Date applied",
      sortable: true,
    },
    {
      key: "status",
      label: "Status",
      render: (value: ApplicationStatus) => {
        const statusStyles = {
          Rejected: "bg-red-100 text-red-800",
          "Talent pool": "bg-purple-100 text-purple-800",
          Selected: "bg-green-100 text-green-800",
        };

        return (
          <span
            className={`px-3 py-1 rounded-full text-sm ${statusStyles[value]}`}
          >
            {value}
          </span>
        );
      },
      sortable: true,
    },
    {
      key: "jobDescription",
      label: "Job description",
      render: () => (
        <ButtonV2 className="text-sm" text="View JD!" onClick={() => {}} />
      ),
    },
    {
      key: "cv",
      label: "CV",
      render: () => (
        <ButtonV2
          className="text-sm py-1"
          variant="tertiary"
          text="View CV"
          onClick={() => {}}
        />
      ),
    },
    {
      key: "actions",
      label: "Action",
      render: () => (
        <span className="flex align-middle gap-1">
          <button>
            <PencilIcon />
          </button>
          <button onClick={() => setShowDeleteDialog(true)}>
            <DeleteIcon />
          </button>
        </span>
      ),
    },
  ];

  return (
    <div className="px-5 py-3">
      <div className="-mx-4 mt-8 sm:-mx-0">
        <Table<JobApplication>
          data={applications}
          columns={applicationColumns}
        />
      </div>

      <Pagination
        start={start}
        end={end}
        total={applications?.length}
        onPrevious={handlePrev}
        onNext={handleNext}
      />
      <ConfirmModal
        isOpen={showDeleteDialog}
        onCancel={() => setShowDeleteDialog(false)}
        onDelete={() => {}}
        itemName="Paula Agyepong"
        subject="Are you sure you want to delete task"
      />
      <Drawer
        drawerHeader="Job description"
        isOpen={showApplicationsDetail}
        onClose={toggleShowApplication}
      >
        <JobApplicationDetail />
      </Drawer>
    </div>
  );
};

export default JobApplicationsList;
