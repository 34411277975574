import Clock from "@components/svgs/clock";

type EmptyStateProps = {
  message?: string;
};
function ReportsEmptyState({
  message = "No time recorded yet...",
}: EmptyStateProps) {
  return (
    <div className="flex flex-col justify-center items-center">
      <Clock />
      <p className="text-[#7C8DB5] text-sm">{message}</p>
    </div>
  );
}

export default ReportsEmptyState;
