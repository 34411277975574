import NoticationsStats from "@components/stats/notificationStats";
import TabsV2 from "@components/tabs/TabV2";
import NotificationsList from "./notificationsList";

const tabs = [
  {
    id: "new",
    label: "New",
    component: <NotificationsList />,
  },
  {
    id: "read",
    label: "Read",
    component: <NotificationsList />,
  },
  {
    id: "allNotifications",
    label: "All Notifications",
    component: <NotificationsList />,
  },
];

const NotificationsPage = () => {
  return (
    <div>
      <div className="bg-[#DDEFFF33] mx-4 rounded-lg font-outfit">
        <div className="flex justify-between items-center">
          <div className="w-1/3">
            <NoticationsStats />
          </div>
        </div>
      </div>
      <div className={`mt-8 bg-[#DDEFFF33] p-5`}>
        <TabsV2 tabs={tabs} />
      </div>
    </div>
  );
};

export default NotificationsPage;
