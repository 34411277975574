 
import {SVGProps} from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={25}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillOpacity={0.7}
      d="M18 17h2.25v1.5H16.5V14H18v3ZM15 6.5H3V5h12v1.5Zm2.25 4.5a6.676 6.676 0 0 1 4.77 1.98 6.95 6.95 0 0 1 1.44 2.145A6.68 6.68 0 0 1 24 17.75a6.676 6.676 0 0 1-1.98 4.77 6.95 6.95 0 0 1-2.145 1.44 6.68 6.68 0 0 1-2.625.54 6.676 6.676 0 0 1-4.77-1.98 6.95 6.95 0 0 1-1.44-2.145 6.68 6.68 0 0 1-.54-2.625 6.676 6.676 0 0 1 1.98-4.77 6.95 6.95 0 0 1 2.145-1.44A6.68 6.68 0 0 1 17.25 11Zm0 12c.727 0 1.406-.137 2.04-.41a5.304 5.304 0 0 0 2.788-2.79 5.257 5.257 0 0 0 .422-2.05c0-.727-.137-1.406-.41-2.04a5.304 5.304 0 0 0-2.79-2.788 5.257 5.257 0 0 0-2.05-.422c-.727 0-1.406.137-2.04.41a5.304 5.304 0 0 0-2.788 2.79A5.257 5.257 0 0 0 12 17.75c0 .727.137 1.406.41 2.04a5.304 5.304 0 0 0 2.79 2.788 5.257 5.257 0 0 0 2.05.422ZM13.664 9.5A9.065 9.065 0 0 0 11.32 11H3V9.5h10.664ZM9.082 14c-.11.242-.207.488-.293.738s-.168.504-.246.762H3V14h6.082ZM3 18.5h5.285c.024.25.055.5.094.75s.094.5.164.75H3v-1.5ZM9.96 23c.392.555.845 1.055 1.36 1.5H0V.5h18v8.285l-.375-.023a6.012 6.012 0 0 0-.75 0l-.375.023V2h-15v21h8.46Z"
    />
  </svg>
);
export default SvgComponent;
