import {SVGProps} from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.7}
      d="M6.513 1.079H6.44c-.043.001-.07.003-.096.005a1.272 1.272 0 0 0-.3.005h-.001c-1.589.206-2.764 1.732-2.764 3.664 0 .838.212 1.676.591 2.35a3.237 3.237 0 0 0-1.289.943c-.468.562-.777 1.266-.99 2.039-.426 1.545-.475 3.385-.475 5.044v.438h1.971l.493 7.665h17.07l.493-7.665h1.97v-.438c0-1.66-.048-3.499-.474-5.044-.213-.773-.522-1.477-.99-2.039a3.236 3.236 0 0 0-1.29-.942c.38-.675.592-1.513.592-2.35 0-1.933-1.175-3.459-2.763-3.665h-.001l-.043-.005h-.003a1.293 1.293 0 0 0-.16-.005c-.041.001-.068.003-.094.005a1.293 1.293 0 0 0-.254 0H6.599a1.294 1.294 0 0 0-.086-.005Zm-.169.881h.028c.054 0 .107.004.16.01h11.166c.053-.006.106-.01.16-.01h.059c.25 0 .505.066.743.178l.004-.007c.813.385 1.411 1.331 1.411 2.622 0 .875-.322 1.808-.748 2.321l-.475.573.732.137c.6.113 1.034.393 1.392.823.357.429.627 1.017.818 1.71.349 1.265.427 2.859.438 4.374h-1.91l-.494 7.665H4.402l-.493-7.665H1.998c.011-1.515.09-3.109.438-4.373.19-.694.46-1.282.818-1.711.358-.43.792-.71 1.393-.822l.731-.138-.475-.573c-.426-.513-.748-1.446-.748-2.32 0-1.293.6-2.24 1.413-2.624l.003.008c.239-.112.494-.177.745-.177h.028Zm1.624.886c.184.254.332.555.43.898h7.436c.098-.343.246-.644.43-.898H7.968ZM8.53 4.62l.002.133c0 .254-.028.512-.077.764h7.32a4.05 4.05 0 0 1-.075-.897h-7.17Zm-.343 1.773c-.115.266-.252.5-.403.681l-.18.217h9.021l-.18-.217a2.853 2.853 0 0 1-.402-.68H8.188Zm.777 1.774c.172.122.326.269.469.44.114.137.219.29.315.457h4.733c.097-.167.202-.32.316-.457.142-.17.297-.318.469-.44H8.965Zm1.172 1.774a8.41 8.41 0 0 1 .24.897h3.478a7.066 7.066 0 0 1 .24-.897h-3.958Zm.386 1.773c.037.293.067.593.09.897h3.005c.023-.304.053-.604.091-.897h-3.186Zm.14 1.774c.012.299.019.598.023.897h2.86c.004-.299.01-.598.022-.898h-2.905Zm-1.92 1.773-.058.898h6.862l-.058-.898H8.743Zm-.114 1.774-.058.897h7.09l-.058-.897H8.63Zm-.114 1.773-.058.898h7.318l-.058-.898H8.515ZM8.4 20.582l-.058.897h7.546l-.058-.897h-7.43Z"
    />
  </svg>
);
export default SvgComponent;
