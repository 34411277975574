import ActivitiesList from "./activities";

const timelineItems = [
  {
    title: "File Uploaded",
    description: "Added by Paula Agyepong",
    timestamp: "2 hours ago",
    author: "Paula Agyepong",
  },
  {
    title: "Started a call",
    description: "Gift Avi started a call on What'sapp",
    timestamp: "November 14, 2024, 8:00 AM",
  },
  {
    title: "Noted Created",
    description: "Created by Paula Agyepong",
    timestamp: "November 14, 2024, 8:00 AM",
  },
  {
    title: "New Task Added",
    description: "New task created by Paula Agyepong",
    timestamp: "November 14, 2024, 8:00 AM",
  },
];

const Activities = () => {
  return (
    <div className="py-2 px-4 bg-custom_faded_blue rounded-xl">
      <div className="bg-white p-6 mx-auto my-8 rounded-lg">
        <ActivitiesList items={timelineItems} />
      </div>
    </div>
  );
};

export default Activities;
