import EditIcon from "@components/svgs/edit";
import moment from "moment";
import Button from "@components/button";
import {useState} from "react";
import Drawer from "@components/drawer/Drawer";
import EndEmployment from "@features/employees/EndEmployment";
import EditEndEmployment from "@features/employees/EditEndEmployment";

function EndEmploymentPage() {
  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState(false);
  const toggleEdit = () => {
    setEdit(!edit);
  };
  const toggleShow = () => {
    setShow(false);
  };
  return (
    <div className="px-3 py-3">
      <div className="flex items-center justify-end">
        <div className="flex gap-x-4 ms-4">
          <EditIcon onClick={toggleEdit} className="" />
        </div>
      </div>

      <div className="flex mt-5 pb-12 items-center gap-5">
        <div className="flex flex-col gap-y-6">
          <div className="">
            <label className="text-sm text-custom_gray">Effective Date: </label>
          </div>
          <div className="">
            <label className="text-sm text-custom_gray">Type: </label>
          </div>
          <div className="">
            <label className="text-sm text-custom_gray">Comment: </label>
          </div>
        </div>

        <div className="flex flex-col gap-y-6">
          <div className="">
            <span className="text-sm text-black">
              {moment()?.format("DD/MM/YYYY")}{" "}
            </span>
          </div>
          <div className="">
            <span className="text-sm text-black">Resignation</span>
          </div>
          <div className="">
            <span className="text-sm text-black">Eligible for rehire </span>
          </div>
        </div>
      </div>

      <div className="flex items-end justify-end mt-8">
        <div>
          <Button
            title="End Employemnt"
            className="w-1/6 px-5"
            onClick={() => setShow(true)}
          />
        </div>
      </div>

      <Drawer isOpen={show} onClose={toggleShow} drawerHeader="End Employment">
        <EndEmployment />
      </Drawer>
      <Drawer
        isOpen={edit}
        onClose={toggleEdit}
        drawerHeader="Update Employment"
      >
        <EditEndEmployment />
      </Drawer>
    </div>
  );
}

export default EndEmploymentPage;
