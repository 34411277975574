import AddCourse from "@features/lms/addcourse";

function AddCoursePage() {
  return (
    <div>
      <div className="py-14 rounded-xl px-5 mx-6 bg-custom_faded_blue">
        <h1 className="text-2xl">Add a course</h1>
      </div>
      <AddCourse />
    </div>
  );
}

export default AddCoursePage;
