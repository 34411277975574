import CandidatesIcon from "@components/svgs/companiesbig";
import Avartar from "@assets/candidate.jpg";
import FacebookIcon from "@components/svgs/facebook";
import LinkedIn from "@components/svgs/linkedin";
import Instagram from "@components/svgs/instagram";
import EditIcon from "@components/svgs/edit";
import Leads from "./components/leads/leads";
import Contact from "./components/contacts/contacts";
import Notes from "./components/notes";
import Tasks from "./components/tasks";
import { Suspense, lazy, useState } from "react";
import { TopLoader } from "@components/loaders/toploader";
import TabsV2 from "@components/tabs/TabV2";
import CurrentPositions from "./components/currentPositions/currentPositions";
import Email from "./components/email/page";
import Locations from "./components/location/page";
import Activities from "./components/acticities/page";
import LinkButton from "@components/ButtonV2/LinkButton";
import Badge from "@components/badges/Badge";

const EditProfile = lazy(() => import("./components/edit/editprofile"));

const tabs = [
  {
    id: "currentPosition",
    label: "Current Position",
    component: <CurrentPositions />,
  },
  {
    id: "leads",
    label: "Leads",
    component: <Leads />,
  },
  {
    id: "contacts",
    label: "Contacts",
    component: <Contact />,
  },
  {
    id: "notes",
    label: "Notes",
    component: <Notes />,
  },
  {
    id: "tasks",
    label: "Tasks",
    component: <Tasks />,
  },
  {
    id: "email",
    label: "Email",
    component: <Email />,
  },
  {
    id: "location",
    label: "Location",
    component: <Locations />,
  },
  {
    id: "activities",
    label: "Activities",
    component: <Activities />,
  },
];

function ViewCompany() {
  const [show, setShow] = useState(false);
  return (
    <div>
      <div className="bg-[#DDEFFF33]  rounded-2xl font-outfit">
        <div className="flex justify-between items-center">
          <div className="w-2/3 ps-4">
            <h2 className="font-normal text-2xl">ALWAYS 49 LIMITED</h2>
          </div>
          <CandidatesIcon />
        </div>
      </div>

      <div className="py-4 bg-custom_faded_blue rounded-2xl my-2 mb-5 flex flex-col lg:flex-row px-4">
        <div className="flex flex-col items-center justify-center">
          <img
            src={Avartar}
            className=" h-auto w-24 rounded-full"
            alt="Picture of the Candidate"
          />

          <div className="flex gap-x-3 items-center mt-2">
            <LinkedIn />
            <Instagram />
            <FacebookIcon />
          </div>
        </div>

        <div className="lg:ms-12  w-full items-start mt-8 lg:mt-0">
          <div className="flex justify-between items-start">
            <div className="flex">
              <div>
                <div className="flex items-center gap-2">
                  <h2 className="text-xl">ALWAYS 49 LIMITED</h2>
                  <Badge type="success" label="Team lead" />
                </div>
                <LinkButton label="TECH/SOFTWARE DEVELOPMENT" />
                <p className="font-light text-[#969EAE] text-sm my-1">
                  New York, USA
                </p>
              </div>
            </div>
            <button
              onClick={() => setShow(true)}
              className="bg-transparent focus:outline-none"
            >
              <EditIcon />
            </button>
          </div>
          <div className="grid text-sm lg:grid-cols-4 grid-cols-1 mt-6 gap-y-4 lg:gap-x-6 lg:gap-y-0">
            <div className="flex flex-col gapy-1 lg:border-r">
              <p className="text-[#7C8DB5]">Email address</p>

              <span className="text-[#82868C] text-sm">paula@gmail.com</span>
            </div>

            <div className="flex flex-col gapy-1 lg:border-r">
              <p className="text-[#7C8DB5]">Phone number</p>

              <span className="text-[#82868C] text-sm">+233 024 5813 456</span>
            </div>

            <div className="flex flex-col gapy-1 lg:border-r">
              <span className="text-[#7C8DB5]">Last contact date</span>

              <span className="text-[#82868C] text-sm">12/02/2024</span>
            </div>
            <div className=" flex flex-col gapy-1">
              <p className="text-[#7C8DB5]">Assigned Sales Person</p>

              <span className="text-[#82868C] text-sm">Gift Avi</span>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-3">
        <TabsV2 tabs={tabs} />
      </div>

      <Suspense fallback={<TopLoader />}>
        <EditProfile open={show} setOpen={setShow} />
      </Suspense>
    </div>
  );
}

export default ViewCompany;
