import DeleteIcon from "@components/svgs/delete";
import Image2 from "@assets/lms2.png";
import Image3 from "@assets/lms3.png";
import PencilIcon from "@components/svgs/pencil";
import {Rating} from "react-simple-star-rating";

function LMSCard({data}: any) {
  const images = [Image2, Image3];
  const getRandomNumberBetween1and3 = () => {
    return Math.floor(Math.random() * 2) + 1;
  };

  return (
    <div className="relative shadow-sm bg-white rounded-2xl py-3">
      <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-ss-lg rounded-se-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
        <img
          src={images[getRandomNumberBetween1and3() - 1]}
          alt="department-image"
          className="pointer-events-none bg-white w-full h-40 object-cover rounded-ss-lg rounded-se-lg group-hover:opacity-75"
        />
      </div>
      <div className="px-2.5">
        <div className="flex flex-col gap-y-4">
          <span className="flex justify-between mt-8 align-middle text-center">
            <p className="pointer-events-none block truncate font-medium text-gray-900">
              {data.jobTitle}
            </p>
            <div className="flex justify-center gap-2 items-center">
              <PencilIcon />
              <DeleteIcon />
            </div>
          </span>
          <p className="pointer-events-none font-outfit block text-xs font-normal leading-5 ">
            {data.skills} Lorem ipsum dolor, sit amet consectetur adipisicing
            elit. Iusto debitis vitae est rem inventore dicta deserunt
            voluptatum odit sint in, assumenda asperiores? Omnis non odit
            aliquid nobis dolorem itaque cupiditate.
          </p>

          <Rating readonly size={20} initialValue={4} />

          <span className="font-light text-xs">
            Date created: 12th December,2023
          </span>
        </div>
        <hr className="my-3" />
        <div className="flex items-center">
          <div className="flex relative">
            <img className="rounded-full z-10 h-10 w-10" src={Image2} />
            <img
              className="rounded-full z-20 -left-6 relative h-10 w-10"
              src={Image3}
            />
            <img
              className="rounded-full z-20 -left-12 relative h-10 w-10"
              src={Image2}
            />
          </div>
          <span className="text-xs font-light mr-12">
            + 25 have taken this course
          </span>
        </div>
      </div>
    </div>
  );
}

export default LMSCard;
