import React from "react";

interface TextInputProps {
  label?: string;
  placeholder?: string;
  name?: string;
  type?: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
}

const TextInput = ({
  label,
  placeholder,
  type = "text",
  name,
  value,
  onChange,
  className,
}: TextInputProps) => {
  return (
    <div className="">
      <label className="block text-[#969EAE] text-xs mb-1">{label}</label>
      <input
        type={type}
        placeholder={placeholder}
        value={value}
        name={name}
        onChange={onChange}
        className={`appearance-none h-11 rounded-lg border border-[#657A9345] w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder:text-custom_gray text-sm ${className}`}
      />
    </div>
  );
};

export default TextInput;
