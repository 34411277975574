import Stats from "@components/stats/stats";
import Graphs from "@components/stats/graphs";
import EmptyInterface from "@components/empty";
import TaskList from "@features/candidates/TasksLists";
import data from "./dataview/data.json";
import CopyIcon from "@components/svgs/copy";
import ShareIcon from "@components/svgs/share";
import PrintIcon from "@components/svgs/print";
import {useNavigate} from "react-router-dom";

function HRDashboard() {
  const navigate = useNavigate();

  const handleAddTask = () => {
    navigate("/addtask");
  };

  return (
    <>
      <div className="bg-[#DDEFFF33] px-4 rounded-lg py-3 font-inter">
        <h1 className="text-2xl font-outfit font-medium">
          Welcome Immaculate!
        </h1>
        <Stats />
        <Graphs />

        <div className={`mt-8  ${data && "bg-white"}`}>
          <div className="px-5 py-4 flex justify-between items-center">
            <h1 className="text-2xl font-outfit font-semibold">Tasks</h1>
            <div className="flex gap-x-3 items-center">
              <CopyIcon />
              <ShareIcon />
              <PrintIcon />
              <button
                onClick={handleAddTask}
                className="bg-custom_deep_blue text-white px-4 py-2 rounded-lg flex items-center gap-3"
              >
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.5 8.5H8.5V14.5H6.5V8.5H0.5V6.5H6.5V0.5H8.5V6.5H14.5V8.5Z"
                    fill="white"
                  />
                </svg>
                <span>Add a new task</span>
              </button>
            </div>
          </div>
          <div className="">
            {data.length === 0 ? (
              <div className="mt-4 border border-custom_lighter_gray rounded-xl">
                <EmptyInterface
                  description="No tasks assigned yet..."
                  buttonText="Add a new task"
                  buttonAction={handleAddTask}
                />
              </div>
            ) : (
              <div>
                <div className="mt-4">
                  <TaskList />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default HRDashboard;
