import Input from "@components/input";
import {Switch} from "@headlessui/react";
import {XMarkIcon} from "@heroicons/react/24/outline";
import {useState} from "react";

function SingleChoice() {
  const [enabled, setEnabled] = useState(false);
  const [enabled2, setEnabled2] = useState(false);
  const [answers, setAnswers] = useState([{answer: "", correct: false}]);

  const addAnswer = () => {
    setAnswers([...answers, {answer: "", correct: false}]);
  };

  const removeAnswer = (index: number) => {
    const newAnswers = answers.filter((_, i) => i !== index);
    setAnswers(newAnswers);
  };
  return (
    <>
      <div className="col-span-2 flex gap-x-4">
        <div className=" flex items-center">
          <label className="text-xs placeholder:text-black text-black">
            Multiple Answer:
          </label>
          <Switch
            checked={enabled}
            onChange={setEnabled}
            className="group relative ms-3 inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-0 focus:ring-indigo-600 focus:ring-offset-2 data-[checked]:bg-custom_deep_blue"
          >
            <span className="sr-only">Use setting</span>
            <span
              aria-hidden="true"
              className="pointer-events-none inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
            />
          </Switch>
        </div>
        <div className=" flex items-center">
          <label className="text-xs placeholder:text-black text-black">
            Answer with image:
          </label>
          <Switch
            checked={enabled2}
            onChange={setEnabled2}
            className="group relative ms-3 inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-0 focus:ring-indigo-600 focus:ring-offset-2 data-[checked]:bg-custom_deep_blue"
          >
            <span className="sr-only">Use setting</span>
            <span
              aria-hidden="true"
              className="pointer-events-none inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
            />
          </Switch>
        </div>
      </div>

      <div className="col-span-2 flex flex-col gap-1">
        {answers.map((_, index) => (
          <div key={index} className="border-b border-gray-150">
            <div className="flex justify-between">
              <div className="flex items-center w-3/4">
                <input
                  type="radio"
                  className="checked:bg-custom_deep_blue focus:ring-0 rounded-full shadow-sm border-custom_faded_gray"
                />
                <Input
                  className="border-0 ms-4 focus:ring-0 outline-none bg-red-500"
                  placeholder={"Answer " + (index + 1)}
                />
              </div>
              {answers?.length > 1 && (
                <div className="flex gap-2">
                  <button onClick={() => removeAnswer(index)}>
                    <XMarkIcon className="h-6 w-6 rounded-full bg-gray-100 p-1 font-bold text-custom_text_secondary" />
                  </button>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>

      <div className="col-span-2 py-4">
        <div>
          <button
            onClick={addAnswer}
            className="no-underline w-auto flex px-8 py-2 text-xs rounded-lg bg-transparent border text-black border-custom_blue"
          >
            Add answer
          </button>
        </div>
      </div>
    </>
  );
}

export default SingleChoice;
