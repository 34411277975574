// import { Note } from "@types/notes"
import NotesComponent from "./NotesComponent";
import LinkButton from "@components/ButtonV2/LinkButton";
import Gmail from "@components/svgs/gmail";
import PencilIcon from "@components/svgs/pencil";
import DeleteIcon from "@components/svgs/delete";

interface Note {
  id: string;
  title: string;
  content: string;
  timestamp: string;
  author: {
    name: string;
    avatarSrc: string;
  };
  accentColor: string;
}

type NoteDetailProps = {
  selectedNote: Note;
  notes: Note[];
  onClose: () => void;
};

const NotesDetail = ({selectedNote, notes, onClose}: NoteDetailProps) => {
  return (
    <div className="mt-8 pr-6">
      <div className="flex-grow mb-10 py-6 border-b border-custom_input_border">
        <div className="flex justify-between items-start mb-4">
          <div className="space-y-4 w-full">
            <div className="flex justify-between items-center">
              <h2 className="text-lg flex gap-2 font-semibold text-custom_black">
                {selectedNote.title}
                <Gmail />
              </h2>
              <span className="flex align-middle gap-1">
                <button>
                  <PencilIcon />
                </button>
                <button onClick={() => {}}>
                  <DeleteIcon />
                </button>
              </span>
            </div>
            <p className="text-[#82868C] text-xs font-light pr-4">
              {selectedNote.content}
            </p>
          </div>
        </div>

        <div className="flex items-center justify-between mt-6">
          <div className="flex items-center space-x-3">
            <img
              src={selectedNote.author.avatarSrc}
              alt={`${selectedNote.author.name}'s avatar`}
              className="w-8 h-8 rounded-full object-cover"
            />
            <span className="text-sm font-light">
              {selectedNote.author.name}
            </span>
            <span className="text-sm text-gray-500">
              {selectedNote.timestamp}
            </span>
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-base font-light text-[#232325]">Other notes:</h1>
        <LinkButton underlineOnHover label="View all notes" onClick={onClose} />
      </div>
      <NotesComponent notes={notes} />
    </div>
  );
};

export default NotesDetail;
