import CandidatesIcon from "@components/svgs/companiesbig";
import CompaniesStats from "@components/stats/companiesstats";
import CopyIcon from "@components/svgs/copy";
import ShareIcon from "@components/svgs/share";
import PrintIcon from "@components/svgs/print";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
// import Board from "./board";
import CompaniesList from "./components/companiesList";
import { useState } from "react";
import Drawer from "@components/drawer/Drawer";
import AddCompany from "./add/page";

function HRDashboard() {
  const [showAddCompany, setShowAddCompany] = useState(false);

  function toggleAddCompany() {
    setShowAddCompany(!showAddCompany);
  }
  return (
    <>
      <div className="bg-[#DDEFFF33] mx-4 rounded-lg font-outfit">
        <div className="flex justify-between items-center">
          <div className="w-2/3">
            <CompaniesStats />
          </div>
          <CandidatesIcon />
        </div>
      </div>
      <div className={`mt-8 bg-[#DDEFFF33]`}>
        <div className="px-5 py-4 flex justify-between items-center">
          <form
            className="relative flex items-center flex-1"
            action="#"
            method="GET"
          >
            <label htmlFor="search-field" className="sr-only">
              Search
            </label>
            <MagnifyingGlassIcon
              className="pointer-events-none absolute inset-y-0 left-5 h-full w-5 text-[#B0BACC]"
              aria-hidden="true"
            />
            <input
              id="search-field"
              className="block border bg-transparent  rounded-lg w-full lg:w-2/5 h-3/5 border-[#657A9345] py-3 pl-12 pr-0  placeholder:text-[#B0BACC] focus:ring-0 sm:text-sm"
              placeholder="Find team member..."
              type="search"
              name="search"
            />
          </form>
          <div className="flex gap-x-3 items-center">
            <CopyIcon />
            <ShareIcon />
            <PrintIcon />
            <button
              onClick={toggleAddCompany}
              className="bg-custom_deep_blue text-sm text-white px-4 py-2 rounded-lg flex items-center gap-3"
            >
              <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.5 8.5H8.5V14.5H6.5V8.5H0.5V6.5H6.5V0.5H8.5V6.5H14.5V8.5Z"
                  fill="white"
                />
              </svg>
              <span>Add a new company</span>
            </button>
          </div>
        </div>

        <div className=" ">
          <CompaniesList />
          <Drawer
            drawerHeader="Add a new company"
            isOpen={showAddCompany}
            onClose={toggleAddCompany}
          >
            <AddCompany />
          </Drawer>
          {/* <Board /> */}
        </div>
      </div>
    </>
  );
}

export default HRDashboard;
