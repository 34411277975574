import { cn } from "@lib/utils";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

interface Tab {
  id: string;
  label: string;
  component: React.ReactNode;
}

interface TabsProps {
  tabs: Tab[];
  defaultTab?: string;
  className?: string;
}

const TabsV2: React.FC<TabsProps> = ({ tabs, defaultTab, className }) => {
  const [activeTab, setActiveTab] = useState(defaultTab || tabs[0].id);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const hash = location.hash.replace("#", "");
    if (hash && tabs.some((tab) => tab.id === hash)) {
      setActiveTab(hash);
    } else if (tabs.length > 0) {
      setActiveTab(tabs[0].id);
      navigate(`${location.pathname}#${tabs[0].id}`, { replace: true });
    }
  }, []);

  const getTabStyles = (isActive: boolean) => {
    const baseStyles =
      "flex items-center gap-2 px-4 py-2 text-sm font-medium transition-all duration-200";
    const hoverStyles = "hover:text-gray-700 hover:bg-white";
    const activeStyles = "text-[#61A9FF] border-b-2 border-[#61A9FF]";
    const inactiveStyles = "text-gray-500";

    return cn(
      baseStyles,
      isActive ? activeStyles : `${inactiveStyles} ${hoverStyles}`,
      className
    );
  };

  return (
    <div className="w-full">
      <div className="flex gap-1 bg-custom_faded_blue rounded-lg">
        {tabs.map((tab) => (
          <button
            key={tab.id}
            onClick={() => {
              setActiveTab(tab.id);
              navigate(`${location.pathname}#${tab.id}`);
            }}
            className={getTabStyles(activeTab === tab.id)}
            aria-selected={activeTab === tab.id}
            role="tab"
          >
            {tab.label}
          </button>
        ))}
      </div>

      <div className="">
        {tabs.map((tab) => (
          <div
            key={tab.id}
            className={cn(
              "transition-opacity",
              activeTab === tab.id ? "visible opacity-100" : "hidden opacity-0"
            )}
            role="tabpanel"
          >
            {tab.component}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TabsV2;
