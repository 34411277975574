import LinkButton from "@components/ButtonV2/LinkButton";
import Table from "@components/DataTable";
import {TableColumn} from "@components/DataTable/types";

import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/20/solid";

import data from "@pages/dashboard/hr/employees/dataview/data.json";
import {useState} from "react";
import {Rating} from "react-simple-star-rating";

function LMSLIST() {
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(10);

  const handleNext = () => {
    setStart(start + 10);
    setEnd(end + 10);
  };

  const handlePrev = () => {
    setStart(start - 10);
    setEnd(end - 10);
  };

  const handleView = () => {};

  const columns: TableColumn<any>[] = [
    {
      key: "jobTitle",
      label: "Title",
      sortable: false,
      nowrap: true,
      render: (name) => <LinkButton label={name} onClick={handleView} />,
    },

    {
      key: "skills",
      label: "Description",
      sortable: true,
    },
    {
      key: "dateCreated",
      label: "Date Created",
      nowrap: true,
      sortable: true,
      render: () => new Date().toDateString(),
    },

    {
      key: "Rating",
      label: "Rating",
      sortable: true,
      render: () => <Rating initialValue={3} size={15} readonly />,
    },

    // {
    //   key: "action",
    //   label: "Action",
    //   sortable: false,
    //   render: () => (
    //     <span className="flex align-middle gap-x-2">
    //       <button onClick={handleEdit}>
    //         <PencilIcon />
    //       </button>
    //       <button onClick={(e) => handleDelete(e, data[0])}>
    //         <DeleteIcon />
    //       </button>
    //     </span>
    //   ),
    // },
  ];

  return (
    <div className="">
      <div className="px-5 py-3">
        <div className="-mx-4 mt-5 sm:-mx-0">
          <Table
            data={data?.slice(start, end)}
            columns={columns}
            onRowClick={handleView}
          />
        </div>

        <div className="hidden sm:flex sm:flex-1 sm:items-center mt-4 sm:justify-between">
          <div>
            <p className="text-sm text-gray-700">
              Showing <span className="font-medium">{start + 1}</span> to{" "}
              <span className="font-medium">{end}</span> of{" "}
              <span className="font-medium">{data?.length}</span> results
            </p>
          </div>
          <div>
            <nav
              className="isolate inline-flex gap-x-3 -space-x-px rounded-md shadow-sm"
              aria-label="Pagination"
            >
              <button
                onClick={handlePrev}
                className="relative inline-flex items-center rounded-full px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
              >
                <span className="sr-only">Previous</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </button>
              {/* Current: "z-10 bg-indigo-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600", Default: "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0" */}

              <a
                href="#"
                className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-[#94A4C4] ring-1 rounded-full ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
              >
                1
              </a>

              <button
                onClick={handleNext}
                className="relative inline-flex items-center rounded-full px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
              >
                <span className="sr-only">Next</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LMSLIST;
