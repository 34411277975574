import { useState } from "react";
import TextInput from "@components/textInput";
import MyEditor from "@components/editor";
import FilePicker from "@components/filepicker";
import AddNewContacts from "../../contacts/components/addNewContacts";
import DeleteIcon from "@components/svgs/delete";

interface CompanyDetails {
  name: string;
  email: string;
  phone: string;
  postCode: string;
  address: string;
  country: string;
  addressLine1: string;
  webAddress: string;
  addressLine2: string;
  facebook: string;
  addressLine3: string;
  twitter: string;
  type: string;
  linkedIn: string;
  status: string;
  instagram: string;
  description: string;
}

function AddCompany() {
  const [showAddContact, setShowAddContact] = useState(false);
  const [companyDetails, setCompanyDetails] = useState<CompanyDetails>({
    name: "",
    email: "",
    phone: "",
    postCode: "",
    address: "",
    country: "",
    addressLine1: "",
    webAddress: "",
    addressLine2: "",
    facebook: "",
    addressLine3: "",
    twitter: "",
    type: "",
    linkedIn: "",
    status: "In Progress",
    instagram: "",
    description: "",
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setCompanyDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleEditorChange = (value: string) => {
    setCompanyDetails((prevDetails) => ({
      ...prevDetails,
      description: value,
    }));
  };

  const handleShowAddContact = () => {
    setShowAddContact(!showAddContact);
  };

  return (
    <>
      <div className="gap-y-4 flex flex-col pb-16 mb-3 px-5">
        <div className="">
          {Object.keys(companyDetails).map((key) => (
            <div
              key={key}
              className="my-6 grid gap-x-4 gap-y-5 grid-cols-1 lg:px-2 xl:px-0"
            >
              {key === "description" ? (
                <MyEditor
                  value={companyDetails[key as keyof CompanyDetails]}
                  setValue={handleEditorChange}
                  placeholder={`Eg. ${
                    key.charAt(0).toUpperCase() + key.slice(1)
                  }`}
                  height={"60"}
                />
              ) : (
                <TextInput
                  name={key}
                  label={`${key
                    .replace(/([A-Z])/g, " $1")
                    .replace(/^./, (str) => str.toUpperCase())}:`}
                  value={companyDetails[key as keyof CompanyDetails]}
                  onChange={handleChange}
                />
              )}
            </div>
          ))}
          <FilePicker />
          <div className="col-span-2 flex justify-between mt-3">
            <button
              className="inline-flex justify-center rounded-md border border-transparent bg-[#407BFF] px-8 py-3 text-sm font-light text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
              onClick={handleShowAddContact}
            >
              Add contact
            </button>
            <button
              className="inline-flex justify-center h-11 rounded-md border border-transparent bg-custom_button_dark px-8 py-3 text-sm font-light text-white hover:bg-blue-900 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
              onClick={() => null}
            >
              Add a new company
            </button>
          </div>
        </div>
      </div>
      {showAddContact && (
        <div>
          <div className="flex justify-between items-center">
            <span className="text-2xl px-6 font-medium">Add Contact</span>
            <button onClick={handleShowAddContact}>
              <DeleteIcon />
            </button>
          </div>
          <AddNewContacts />
        </div>
      )}
    </>
  );
}

export default AddCompany;
