import Input from "@components/input";
import { useState } from "react";
import MyEditor from "@components/editor";
import FilePicker from "@components/filepicker";
import UserDropdown from "@components/dropdowns/userdropdown";
import { Rating } from "react-simple-star-rating";
import ButtonV2 from "@components/ButtonV2/ButtonV2";
import { LinkIcon } from "@heroicons/react/24/outline";
import Section from "./section";
import AddQuizes from "./addquizes";

function AddCourse() {
  const [addcourse, setAddCourse] = useState("");
  const [description, setDescription] = useState("");
  const [selecteduser, setSelectedUser] = useState<any>(null);
  const [showAddSection, setShowAddSection] = useState(false);
  const [link, setLink] = useState("");

  const [sections, setSections] = useState<any>([]);
  const toggleAddSection = () => {
    setShowAddSection(!showAddSection);
  };

  const addSection = () => {
    setSections([...sections, { title: addcourse, description, link }]);
  };

  const [tab, setTab] = useState<"course" | "quiz">("course");

  const toggleTab = () => {
    setTab(tab === "course" ? "quiz" : "course");
  };
  return (
    <>
      {tab === "course" ? (
        <div className="gap-y-4 flex flex-col pb-10 overflow-auto">
          <div className="w-full  flex-1 flex flex-col py-4 px-6 rounded-lg">
            <div className=" my-6 bg-custom_faded_blue px-4 rounded-xl py-1 grid gap-x-4 gap-y-5 grid-cols-2">
              <div className="col-span-2">
                <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                  Course title:{" "}
                </label>
                <Input
                  placeholder={"eg.John"}
                  className={"mt-1 h-10"}
                  value={addcourse}
                  onChange={(e: any) => setAddCourse(e.target.value)}
                />
              </div>

              <div className="col-span-2">
                <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                  Description:{" "}
                </label>
                <MyEditor
                  value={description}
                  setValue={setDescription}
                  placeholder="Enter Tasks Here..."
                />
              </div>
              <div className="col-span-2">
                <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                  Attach video or image:
                </label>
                <FilePicker />
              </div>

              <div className="col-span-2">
                <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                  Insert link:{" "}
                </label>
                <Input
                  icon={
                    <LinkIcon className="text-custom_gray h-5 w- font-semibold" />
                  }
                  placeholder={"eg.https://www.google.com"}
                  className={"mt-1 h-10"}
                  value={link}
                  onChange={(e: any) => setLink(e.target.value)}
                />
              </div>

              {!showAddSection && (
                <>
                  <div className="col-span-1">
                    <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                      Assign to:{" "}
                    </label>
                    <UserDropdown
                      selecteduser={selecteduser}
                      setSelectedUser={setSelectedUser}
                    />
                  </div>

                  <div className="col-span-1">
                    <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                      Rating:{" "}
                    </label>
                    <div>
                      <Rating />
                    </div>
                  </div>
                </>
              )}

              {showAddSection && (
                <div className="col-span-1 flex">
                  <div>
                    <button
                      onClick={toggleTab}
                      className="no-underline w-auto flex px-8 py-2 text-xs rounded-lg bg-transparent border text-black border-custom_blue"
                    >
                      Add quiz
                    </button>
                  </div>
                </div>
              )}

              {sections.map((_: any, index: number) => (
                <Section key={index} toggleTab={toggleTab} />
              ))}

              <div
                className={`mt-5 flex col-span-2 items-end ${showAddSection ? "justify-between" : "justify-end"}`}
              >
                {showAddSection && (
                  <button
                    onClick={addSection}
                    className="bg-custom_blue text-xs rounded-lg font-light py-3 px-4 flex"
                  >
                    <span className="text-white">Add another section</span>
                  </button>
                )}
                <ButtonV2
                  text={showAddSection ? "Create course" : "Next"}
                  onClick={toggleAddSection}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <AddQuizes toggleTab={toggleTab} />
      )}
    </>
  );
}

export default AddCourse;
