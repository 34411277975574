import NotesTab from "@features/candidates/NotesTab";

function Notes() {
  return (
    <div className=" py-2 px-4 bg-custom_faded_blue rounded-xl">
      <NotesTab />
      {/* <div className="mt-4 rounded-xl">
        <EmptyInterface
          description="No notes to show..."
          buttonText="Add a note"
          showButton={true}
          buttonAction={() => null}
        />
      </div> */}
    </div>
  );
}

export default Notes;
