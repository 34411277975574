import ButtonV2 from "@components/ButtonV2/ButtonV2";
import StatusDropdown from "@components/dropdowns/status";
import { useState } from "react";

const AddTeamMember = () => {
  const [selectedStatus, setSelectedStatus] = useState("");

  return (
    <>
      {" "}
      <div className="gap-y-4 flex flex-col pb-28">
        <div className="w-full flex-1 flex flex-col py-4 px-6 rounded-lg">
          <div className="lg:col-span-2 col-span-10">
            <label className="text-xs">First name:</label>
            <StatusDropdown
              selectedStatus={selectedStatus}
              setSelectedStatus={setSelectedStatus}
            />
          </div>
        </div>
        <div className="lg:col-span-4 mt-4 col-span-10 flex justify-end items-end">
          <ButtonV2 text="Add member" onClick={() => {}} />
        </div>
      </div>
    </>
  );
};

export default AddTeamMember;
