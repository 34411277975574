import React, {Fragment, Suspense, lazy} from "react";
import BlogStats from "@components/stats/blogstats";
import Blog from "@components/cards/blog";
import CopyIcon from "@components/svgs/copy";
import ShareIcon from "@components/svgs/share";
import NewPrint from "@components/svgs/newprint";
import {MagnifyingGlassIcon} from "@heroicons/react/24/outline";
import {TopLoader} from "@components/loaders/toploader";
import AddTestimonial from "./addtestimonial/page";

const blogs = [
  {
    id: 1,
    title: "Vagabond digital",
    image:
      "https://media.licdn.com/dms/image/C560BAQEf-7ObEUkNRg/company-logo_200_200/0/1630585478791?e=2147483647&v=beta&t=_TKraA7RpwEVm02S61nhYDS48TOldmX3VJ6DKDnvy3U",
  },
  {
    id: 2,
    title: "Time to Reply",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTimXhh9cXPNXQy3vrdAppz0hsyDpp_trSol-MdcrnRBQ&s",
  },
  {
    id: 3,
    title: "The Turmeric Co",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQT78URqW0c-Vk7Aeigyv0ouj--b8MwLP4z3v7i_POziQ&s",
  },
  {
    id: 4,
    title: "Bamboo Orchard",
    image:
      "https://bamboo-orchard.com/wp-content/uploads/2021/11/BO_Icon_Light_RGB.png",
  },
];

const Delete = lazy(() => import("./delete"));

function Testimonials() {
  const [show, setShow] = React.useState(false);
  const [showDelete, setShowDelete] = React.useState(false);
  const [selected, setSelected] = React.useState(null);

  const handleDelete = (data: any) => {
    setSelected(data);
    setShowDelete(true);
  };

  const toggleShow = () => {
    setShow(!show);
  };

  return (
    <div>
      <>
        <div className="bg-[#DDEFFF33] mx-4 rounded-lg font-inter">
          <div className="flex justify-between items-center pe-6">
            <div className="">
              <BlogStats />
            </div>
          </div>
        </div>
        <div className="my-4">
          <div className="bg-[#DDEFFF33] rounded-xl mx-4 mt-8 px-3 py-3">
            <div className="mb-5 flex justify-between items-center">
              <div className="w-1/3">
                <form className="relative h-full flex w-full items-center flex-1">
                  <label htmlFor="search-field" className="sr-only">
                    Search
                  </label>
                  <MagnifyingGlassIcon
                    className="pointer-events-none absolute inset-y-0 left-5 h-full w-5 text-[#657A93]"
                    aria-hidden="true"
                  />
                  <input
                    id="search-field"
                    className="block bg-transparent py-3 rounded-lg w-full border border-[#657A9345] h-full pl-12 pr-0  placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                    placeholder="Search here..."
                    type="search"
                    name="search"
                  />
                </form>
              </div>
              <div className="flex gap-x-3 items-center">
                <CopyIcon />
                <ShareIcon />
                <NewPrint />

                <button
                  onClick={toggleShow}
                  className="bg-custom_deep_blue text-white font-light text-sm px-10 py-3 rounded-lg flex items-center gap-3"
                >
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.5 8.5H8.5V14.5H6.5V8.5H0.5V6.5H6.5V0.5H8.5V6.5H14.5V8.5Z"
                      fill="white"
                    />
                  </svg>
                  <span>Add testimonial</span>
                </button>
              </div>
            </div>
            <div className="font-outfit">
              <div className="bg-[#DDEFFF33]">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-6">
                  {blogs?.map((blog) => (
                    <Fragment key={blog?.id}>
                      <Blog data={blog} onDelete={handleDelete} />
                    </Fragment>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
      <Suspense fallback={<TopLoader />}>
        <AddTestimonial isOpen={show} onClose={toggleShow} />

        <Delete open={showDelete} setOpen={setShowDelete} selected={selected} />
      </Suspense>
    </div>
  );
}

export default Testimonials;
