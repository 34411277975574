import {ChevronLeftIcon} from "@heroicons/react/24/solid";
import Tabs from "@components/tabs";
import {Outlet, useNavigate, useParams} from "react-router-dom";
import ViewEmployeeeStats from "@components/stats/viewemployeestats";
function AddNewEmplyee({}: {}) {
  const navigate = useNavigate();

  const params = useParams();
  const tabs = [
    {
      name: "Employee profile",
      href: `/hr/employees/viewemployee/${params.id}`,
    },
    {
      name: "Tasks",
      href: `/hr/employees/viewemployee/${params.id}/tasks`,
    },
    {
      name: "Time reports",
      href: `/hr/employees/viewemployee/${params.id}/timereports`,
    },
    {
      name: "Notes",
      href: `/hr/employees/viewemployee/${params.id}/notes`,
    },
    {
      name: "LMS",
      href: `/hr/employees/viewemployee/${params.id}/lms`,
    },
    {
      name: "Additional Information",
      href: `/hr/employees/viewemployee/${params.id}/additionalinformation`,
    },
  ];

  return (
    <>
      <div className="gap-y-4 font-outfit flex flex-col pb-12">
        <div className="bg-[#DDEFFF33] mx-4 rounded-lg font-inter">
          <div className="flex justify-between items-center">
            <div className="w-2/4">
              <ViewEmployeeeStats />
            </div>
          </div>
        </div>

        <div className="flex justify-between">
          <button
            onClick={() => navigate("/hr/employees")}
            className="text-custom_secondary font-poppins hover:underline flex gap-x-3 items-center font-outfit font-light"
          >
            <ChevronLeftIcon className="h-6 w-6 text-custom_secondary" />
            Back
          </button>
        </div>
        <div className="w-full flex-1 flex flex-col py-4 px-6 ">
          <div className="bg-custom_faded_blue rounded-ss-xl rounded-se-xl  px-4">
            <Tabs tabs={tabs} />
          </div>
          <div className="">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
}

export default AddNewEmplyee;
