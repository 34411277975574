import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";

interface PaginationProps {
  start: number;
  end: number;
  total: number;
  onPrevious: () => void;
  onNext: () => void;
}

export const Pagination = ({
  start,
  end,
  total,
  onPrevious,
  onNext,
}: PaginationProps) => {
  return (
    <div className="hidden sm:flex sm:flex-1 sm:items-center mt-4 sm:justify-between">
      <div>
        <p className="text-sm text-gray-700">
          Showing <span className="font-medium">{start + 1}</span> to{" "}
          <span className="font-medium">{end}</span> of{" "}
          <span className="font-medium">{total}</span> results
        </p>
      </div>
      <div>
        <nav
          className="isolate inline-flex gap-x-3 -space-x-px rounded-md shadow-sm"
          aria-label="Pagination"
        >
          <button
            onClick={onPrevious}
            className="relative inline-flex items-center rounded-full px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
          >
            <span className="sr-only">Previous</span>
            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
          </button>
          <a
            href="#"
            className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-[#94A4C4] ring-1 rounded-full ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
          >
            1
          </a>
          <button
            onClick={onNext}
            className="relative inline-flex items-center rounded-full px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
          >
            <span className="sr-only">Next</span>
            <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </nav>
      </div>
    </div>
  );
};
