import {Fragment} from "react";
import data from "../../hr/employees/dataview/data.json";
import LMSCard from "@components/cards/lmscard";

function LMSGridList() {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 mt-4 lg:grid-cols-3 2xl:grid-cols-4 gap-3">
      {data?.slice(0, 12)?.map((team) => (
        <Fragment key={team?.id}>
          <LMSCard data={team} />
        </Fragment>
      ))}
    </div>
  );
}

export default LMSGridList;
