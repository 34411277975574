 
import CopyIcon from "@components/svgs/copy";
import ShareIcon from "@components/svgs/share";
import data from "@components/constants/data.json";
import EmptyInterface from "@components/empty";
import {MagnifyingGlassIcon, PlusCircleIcon} from "@heroicons/react/24/outline";
import PencilIcon from "@components/svgs/pencil";
import NewPrint from "@components/svgs/newprint";
import React, {Suspense, lazy} from "react";
import {TopLoader} from "@components/loaders/toploader";
import {useNavigate} from "react-router-dom";
import {TableColumn} from "@components/DataTable/types";
import LinkButton from "@components/ButtonV2/LinkButton";
import DeleteIcon from "@components/svgs/delete";
import Table from "@components/DataTable";
import {Pagination} from "@components/pagination/Pagination";
import AddProduct from "../addproduct/page";
import AddTools from "../addproduct/addtools";
import AddSkills from "../addproduct/addskill";

const Delete = lazy(() => import("./delete"));

function ProductList() {
  const [showDelete, setShowDelete] = React.useState(false);
  const [selected, setSelected] = React.useState({});
  const navigate = useNavigate();
  const [showAdd, setShowAdd] = React.useState(false);
  const [showAddTools, setShowAdTools] = React.useState(false);
  const [showAddSkills, setShowAddSkills] = React.useState(false);
  const [start, setStart] = React.useState(0);
  const [end, setEnd] = React.useState(10);

  const toggleAddTools = () => {
    setShowAdTools(!showAddTools);
  };

  const toggleShowAddSkills = () => {
    setShowAddSkills(!showAddSkills);
  };

  const handleNext = () => {
    setStart(start + 10);
    setEnd(end + 10);
  };

  const handlePrev = () => {
    setStart(start - 10);
    setEnd(end - 10);
  };

  const handleDelete = (e: any, data: any) => {
    e?.stopPropagation();
    setSelected(data);
    setShowDelete(true);
  };

  const handleView = (e: any) => {
    e?.stopPropagation();
    navigate("viewproduct");
  };

  const handleEdit = (e: any) => {
    e?.stopPropagation();
    navigate("editproduct");
  };

  const columns: TableColumn<any>[] = [
    {key: "id", label: "Position", sortable: true},
    {
      key: "name",
      label: "Name",
      sortable: true,
      nowrap: true,
      render: (name) => <LinkButton label={name} onClick={handleView} />,
    },
    {
      key: "type",
      label: "Type",
      sortable: false,
      render: () => "Marketing & Creative",
    },
    {
      key: "tools",
      label: "Tools",
      sortable: true,
      nowrap: true,

      render: () => (
        <div className="flex gap-x-1">
          <LinkButton label="Editing software" onClick={toggleAddTools} />{" "}
          <PlusCircleIcon className="text-custom_blue h-5 w-5" />
        </div>
      ),
    },
    {
      key: "skills",
      label: "Skills",
      sortable: true,
      nowrap: true,

      render: () => (
        <div className="flex gap-x-1">
          <LinkButton
            label="Technical Proficiency"
            onClick={toggleShowAddSkills}
          />{" "}
          <PlusCircleIcon className="text-custom_blue h-5 w-5" />
        </div>
      ),
    },
    {
      key: "levels",
      label: "Levels",
      sortable: true,
      nowrap: true,
      render: () => (
        <div className="flex gap-x-1">
          <LinkButton label="Mid Senior" onClick={toggleAddTools} />{" "}
          <PlusCircleIcon className="text-custom_blue h-5 w-5" />
        </div>
      ),
    },

    {
      key: "action",
      label: "Action",
      sortable: false,
      render: () => (
        <span className="flex align-middle gap-x-2">
          <button onClick={handleEdit}>
            <PencilIcon />
          </button>
          <button onClick={(e) => handleDelete(e, data[0])}>
            <DeleteIcon />
          </button>
        </span>
      ),
    },
  ];

  return (
    <div className="py-2 px-4 bg-custom_faded_blue rounded-xl">
      <div className={` ${data && "bg-white"} rounded-xl mt-4`}>
        <div className="px-5 py-4 flex justify-between items-center">
          <div className="w-1/3">
            <form className="relative h-full flex w-full items-center flex-1">
              <label htmlFor="search-field" className="sr-only">
                Search
              </label>
              <MagnifyingGlassIcon
                className="pointer-events-none absolute inset-y-0 left-5 h-full w-5 text-[#657A93]"
                aria-hidden="true"
              />
              <input
                id="search-field"
                className="block bg-transparent py-3 rounded-lg w-full border border-[#657A9345] h-full pl-12 pr-0  placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                placeholder="Search here..."
                type="search"
                name="search"
              />
            </form>
          </div>
          <div className="flex gap-x-3 items-start">
            <CopyIcon />
            <ShareIcon />
            <NewPrint />

            <button
              onClick={() => setShowAdd(true)}
              className="bg-custom_deep_blue text-white font-light text-sm px-10 py-3 rounded-lg flex items-center gap-3"
            >
              <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.5 8.5H8.5V14.5H6.5V8.5H0.5V6.5H6.5V0.5H8.5V6.5H14.5V8.5Z"
                  fill="white"
                />
              </svg>
              <span>Add a Product</span>
            </button>
          </div>
        </div>
        <div className="">
          {data.length === 0 ? (
            <div className="mt-4 border border-custom_lighter_gray rounded-xl">
              <EmptyInterface
                description="No tasks assigned yet..."
                buttonText="Add a new task"
                showButton={false}
                buttonAction={() => null}
              />
            </div>
          ) : (
            <div>
              <div className="">
                <Table<any> data={data?.slice(start, end)} columns={columns} />

                <Pagination
                  start={start}
                  end={end}
                  total={data.length}
                  onNext={handleNext}
                  onPrevious={handlePrev}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <Suspense fallback={<TopLoader />}>
        <Delete open={showDelete} setOpen={setShowDelete} selected={selected} />
        <AddProduct isOpen={showAdd} onClose={() => setShowAdd(false)} />
        <AddTools isOpen={showAddTools} onClose={toggleAddTools} />
        <AddSkills isOpen={showAddSkills} onClose={toggleShowAddSkills} />
      </Suspense>
    </div>
  );
}

export default ProductList;
