interface EmailCardProps {
  title: string;
  sender: string;
  email: string;
  timestamp: string;
  avartar: string;
  message: string;
}

const EmailCard = ({
  title,
  sender,
  email,
  timestamp,
  avartar,
  message,
}: EmailCardProps) => {
  return (
    <div className="bg-custom_faded_blue mb-6 p-6 rounded-lg border">
      <div className="flex items-start justify-between">
        <div className="flex items-start my-2">
          <img
            src={avartar}
            alt={sender}
            className="w-8 h-8 rounded-full mr-2"
          />
          <div className="text-sm">
            <h3 className="text-sm text-[#232325] font-medium">{title}</h3>
            <span className="text-sm text-[#7C8DB5] ">{sender}</span>,&nbsp;
            <a
              href={`mailto:${email}`}
              className="text-[#7C8DB5] hover:underline"
            >
              {email}
            </a>
            <p className="text-[#737375] mt-4">{message}</p>
          </div>
        </div>
        <p className="text-[#7C8DB5] text-sm">{timestamp}</p>
      </div>
    </div>
  );
};

export default EmailCard;
