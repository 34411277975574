import Input from "@components/input";
import MyEditor from "@components/editor";
import FilePicker from "@components/filepicker";

import {LinkIcon} from "@heroicons/react/24/outline";
import {useState} from "react";

function Section({toggleTab}: {toggleTab: () => void}) {
  const [addcourse, setAddCourse] = useState("");
  const [description, setDescription] = useState("");

  const [link, setLink] = useState("");

  return (
    <>
      <div className="col-span-2">
        <label className="text-xs placeholder:text-custom_gray text-custom_gray">
          Course title:{" "}
        </label>
        <Input
          placeholder={"eg.John"}
          className={"mt-1 h-10"}
          value={addcourse}
          onChange={(e: any) => setAddCourse(e.target.value)}
        />
      </div>

      <div className="col-span-2">
        <label className="text-xs placeholder:text-custom_gray text-custom_gray">
          Description:{" "}
        </label>
        <MyEditor
          value={description}
          setValue={setDescription}
          placeholder="Enter Tasks Here..."
        />
      </div>
      <div className="col-span-2">
        <FilePicker />
      </div>

      <div className="col-span-2">
        <label className="text-xs placeholder:text-custom_gray text-custom_gray">
          Insert link:{" "}
        </label>
        <Input
          icon={<LinkIcon className="text-custom_gray h-5 w- font-semibold" />}
          placeholder={"eg.https://www.google.com"}
          className={"mt-1 h-10"}
          value={link}
          onChange={(e: any) => setLink(e.target.value)}
        />
      </div>

      <div className="col-span-1 flex">
        <div>
          <button
            onClick={toggleTab}
            className="no-underline w-auto flex px-8 py-2 text-xs rounded-lg bg-transparent border text-black border-custom_blue"
          >
            Add quiz
          </button>
        </div>
      </div>
    </>
  );
}

export default Section;
