import React from "react";

interface SpinnerProps {
  size?: string; // Example: "w-6 h-6" for Tailwind or custom size classes
  color?: string; // Example: "text-blue-500" for Tailwind color classes
}

const Spinner: React.FC<SpinnerProps> = ({
  size = "w-8 h-8",
  color = "text-blue-500",
}) => {
  return (
    <div className={`flex justify-center items-center`}>
      <div
        className={`animate-spin rounded-full border-4 border-t-transparent ${color} ${size}`}
        role="status"
      ></div>
    </div>
  );
};

export default Spinner;
