import React from "react";
import Dropdown from "@components/dropdowns/dropdown";
import Input from "@components/input";
 
import MyEditor from "@components/editor";
const list = [
  {id: 1, name: "Admin", value: "admin"},
  {id: 2, name: "Client", value: "client"},
  {id: 3, name: "Employee", value: "employee"},
  {id: 4, name: "Manager", value: "manager"},
];

function EditProduct() {
  const [selected, setSelected] = React.useState("");
  const [jobDescription, setJobDescription] = React.useState(
    "Are you a communication virtuoso with exceptional problem-solving skills and a passion for customer satisfaction? Do you speak fluent Arabic? Remoteli, a leading on-demand staffing solution, is seeking talented Customer Service Assistants to join our team. This role is available in both our Accra, Ghana office and as a remote position in Kigali, Rwanda."
  );
  const [summary, setSummary] = React.useState(
    "Tech Alchemy: Our Full-Stack Developers, are tech visionaries crafting end-to-end solutions. Proficient in front-end (HTML, CSS, JavaScript) and back-end (Node.js, Python, Ruby on Rails) development, orchestrating digital symphonies using SQL and NoSQL databases, RESTful APIs, and microservices. They blend creativity with functionality, to engineer dynamic and efficient applications. Remoteli's developers redefine your digital landscape, leveraging cutting-edge tech stacks."
  );
  const [skills, setSkills] = React.useState(
    "End-to-End Development: Capable of working on both front-end and back-end components of web applications, handling the entire development process.Front-End Skills: Proficiency in HTML, CSS, JavaScript, and front-end frameworks (e.g., React, Angular, Vue.js) to create user-friendly interfaces. Back-End Skills: Mastery of server-side programming languages (e.g., Python, Java, Node.js), databases, and server management for robust back-end development. Database Management: Expertise in working with databases, including designing schemas, querying data, and optimising performance."
  );
  const [systems, setSystems] = React.useState(
    "Programming Languages: Mastery of programming languages like JavaScript, Python, Java, Ruby, PHP, or others, depending on the technology stack. Front-End Development: Proficiency in HTML5, CSS3, and JavaScript, along with front-end frameworks like React, Angular, or Vue.js. Back-End Development: Skill in server-side programming languages like Node.js, Java, Python, PHP, or others, along with expertise in database management."
  );

  return (
    <div className="mt-4">
      <div className="flex justify-between items-center">
        <h2 className="font-bold text-xl">Edit Product</h2>
      </div>

      <div className="grid grid-cols-1 gap-4 mt-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4">
        <div className="md:col-span-4">
          <label className="  text-xs placeholder:text-custom_gray text-custom_gray">
            Role:{" "}
          </label>
          <Input placeholder={"eg. Video Editor"} className={"mt-1"} />
        </div>

        <div className="col-span-2">
          <label className="  text-xs placeholder:text-custom_gray text-custom_gray">
            Position:{" "}
          </label>
          <Input placeholder={"eg. 0"} className={"mt-1"} />
        </div>
        <div className="col-span-2">
          <label className="  text-xs placeholder:text-custom_gray text-custom_gray">
            Type:{" "}
          </label>
          <Dropdown list={list} selected={selected} setSelected={setSelected} />
        </div>

        <div className="col-span-2">
          <label className="  text-xs placeholder:text-custom_gray text-custom_gray">
            Part time:{" "}
          </label>
          <Input placeholder={"eg. $ 1,000.00"} className={"mt-1"} />
        </div>
        <div className="col-span-2">
          <label className="  text-xs placeholder:text-custom_gray text-custom_gray">
            Full time:{" "}
          </label>
          <Input placeholder={"eg. $ 1,000.00"} className={"mt-1"} />
        </div>

        <div className="col-span-2">
          <label className="  text-xs placeholder:text-custom_gray text-custom_gray">
            Job description:{" "}
          </label>
          <div className="h-54">
            <MyEditor
              value={jobDescription}
              setValue={setJobDescription}
              placeholder="Enter Tasks Here..."
            />
          </div>
        </div>

        <div className="col-span-2">
          <label className="  text-xs placeholder:text-custom_gray text-custom_gray">
            Summary:{" "}
          </label>
          <div className="h-54">
            <MyEditor
              value={summary}
              setValue={setSummary}
              placeholder="Enter Tasks Here..."
            />
          </div>
        </div>
        <div className="col-span-2">
          <label className="  text-xs placeholder:text-custom_gray text-custom_gray">
            Skills:{" "}
          </label>
          <div className="h-54">
            <MyEditor
              value={skills}
              setValue={setSkills}
              placeholder="Enter Tasks Here..."
            />
          </div>
        </div>
        <div className="col-span-2">
          <label className="  text-xs placeholder:text-custom_gray text-custom_gray">
            Systems:{" "}
          </label>
          <div className="h-54">
            <MyEditor
              value={systems}
              setValue={setSystems}
              placeholder="Enter Tasks Here..."
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditProduct;
