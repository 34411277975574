import TabsV2 from "@components/tabs/TabV2";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { Outlet, useNavigate } from "react-router-dom";
import Holidays from "./holidays/page";
import Sickness from "./sickness/page";
import RequestTimeOff from "./requests/page";
import Stats from "@components/stats/leavestats";
import OffToday from "./today/page";

const tabs = [
  {
    id: "hoidays",
    label: `Holidays`,
    component: <Holidays />,
  },
  {
    id: "sickness",
    label: `Sickness`,
    component: <Sickness />,
  },
  {
    id: "requests",
    label: `Requests`,
    component: <RequestTimeOff />,
  },
  {
    id: "today",
    label: `Today`,
    component: <OffToday />,
  },
];

function LeaveTypeLayout() {
  const navigate = useNavigate();
  return (
    <div>
      <Stats />
      <div className="bg-white py-4 my-3">
        <button
          onClick={() => navigate("/hr/leave")}
          className="flex text-gray-400 items-center"
        >
          <ChevronLeftIcon className="h-6 w-6  inline-block" />
          <span className="ms-2">Back</span>
        </button>
      </div>

      <div>
        <TabsV2 tabs={tabs} />
        <Outlet />
      </div>
    </div>
  );
}

export default LeaveTypeLayout;
