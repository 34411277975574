export function extractTextFromPathname(pathname: string): string | null {
  const pathParts = pathname.split("/");

  // Check if there are at least two parts in the path
  if (pathParts.length >= 2) {
    return pathParts[1];
  } else {
    return null;
  }
}




export const getRandomColor = () => {
  const colors = [
    {
      border: "border-card_yellow",
      bg: "bg-card_yellow",
      text: "text-card_yellow",
    },
    {
      border: "border-card_blue",
      bg: "bg-card_blue",
      text: "text-card_blue",
    },
    {
      border: "border-card_green",
      bg: "bg-card_green",
      text: "text-card_green",
    },
    {
      border: "border-card_purple",
      bg: "bg-card_purple",
      text: "text-card_purple",
    },
    {
      border: "border-card_red",
      bg: "bg-card_pink",
      text: "text-card_pink",
    },
  ];

  return colors[Math.floor(Math.random() * colors.length)];
}



export const STATUSLIST = [
  {
    name: "Pending",
    colors: {
      bg: "bg-orange-100",
      text: "text-[#FBB03B]",
    },
  },
  {
    name: "Complete",
    colors: {bg: "bg-green-100", text: "text-green-800"},
  },
  {
    name: "Incomplete",
    colors: {bg: "bg-red-100", text: "text-red-800"},
  },
];