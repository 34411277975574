const JobApplicationsStatIcon = (props: any) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.0525 16.25L16.5 11.75L21.0525 7.25L22.125 8.3075L18.645 11.75L22.125 15.1925L21.0525 16.25ZM16.5 23H15V19.25C15 18.2554 14.6049 17.3016 13.9017 16.5983C13.1984 15.8951 12.2446 15.5 11.25 15.5H6.75C5.75544 15.5 4.80161 15.8951 4.09835 16.5983C3.39509 17.3016 3 18.2554 3 19.25V23H1.5V19.25C1.5 17.8576 2.05312 16.5223 3.03769 15.5377C4.02226 14.5531 5.35761 14 6.75 14H11.25C12.6424 14 13.9777 14.5531 14.9623 15.5377C15.9469 16.5223 16.5 17.8576 16.5 19.25V23ZM9 3.5C9.74168 3.5 10.4667 3.71993 11.0834 4.13199C11.7001 4.54404 12.1807 5.12971 12.4645 5.81494C12.7484 6.50016 12.8226 7.25416 12.6779 7.98159C12.5333 8.70902 12.1761 9.3772 11.6517 9.90165C11.1272 10.4261 10.459 10.7833 9.73159 10.9279C9.00416 11.0726 8.25016 10.9984 7.56494 10.7145C6.87971 10.4307 6.29404 9.95007 5.88199 9.33339C5.46993 8.7167 5.25 7.99168 5.25 7.25C5.25 6.25544 5.64509 5.30161 6.34835 4.59835C7.05161 3.89509 8.00544 3.5 9 3.5ZM9 2C7.96165 2 6.94661 2.30791 6.08326 2.88478C5.2199 3.46166 4.54699 4.2816 4.14963 5.24091C3.75227 6.20022 3.6483 7.25582 3.85088 8.27422C4.05345 9.29262 4.55346 10.2281 5.28769 10.9623C6.02191 11.6965 6.95738 12.1966 7.97578 12.3991C8.99418 12.6017 10.0498 12.4977 11.0091 12.1004C11.9684 11.703 12.7883 11.0301 13.3652 10.1667C13.9421 9.30339 14.25 8.28835 14.25 7.25C14.25 5.85761 13.6969 4.52226 12.7123 3.53769C11.7277 2.55312 10.3924 2 9 2Z"
        fill="#6C63FF"
      />
    </svg>
  );
};

export default JobApplicationsStatIcon;
