import Location from "./locations";

const locations = [
  {
    location: "Ghana",
    address: "GNAT Heights,Independence Avenue, Accra",
    contact: "+233 8053 657 8975",
  },
  {
    location: "United Kingdom",
    address: "86-90 Paul Street, London, England, EC2A 4NE",
    contact: "+44 20 4537 5915",
  },
  {
    location: "United Kingdom",
    address: "86-90 Paul Street, London, England, EC2A 4NE",
    contact: "+44 20 4537 5915",
  },
];

const Locations = () => {
  return (
    <div className="py-2 px-4 bg-custom_faded_blue rounded-xl">
      <div className="bg-white p-6 mx-auto my-8 rounded-lg">
        <Location locations={locations} />
      </div>
    </div>
  );
};

export default Locations;
