import {SVGProps} from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M9.003 6a2.997 2.997 0 1 1 5.994 0 2.997 2.997 0 0 1-5.994 0ZM12 4.505a1.497 1.497 0 1 0 0 2.994 1.497 1.497 0 0 0 0-2.994Zm4.5 2.247a2.25 2.25 0 1 1 4.499 0 2.25 2.25 0 0 1-4.499 0ZM18.75 6a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5ZM5.25 4.5a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5ZM4.5 6.75a.75.75 0 1 1 1.5 0 .75.75 0 0 1-1.5 0Zm1.902 3.75A2.985 2.985 0 0 0 6 12H3v3.75a2.25 2.25 0 0 0 3.144 2.065c.111.497.285.97.51 1.413A3.75 3.75 0 0 1 1.5 15.751V12A1.5 1.5 0 0 1 3 10.5h3.402Zm10.944 8.728a3.75 3.75 0 0 0 5.154-3.478V12a1.5 1.5 0 0 0-1.5-1.5h-3.402c.255.44.402.952.402 1.5h3v3.75a2.25 2.25 0 0 1-3.144 2.065c-.113.497-.285.97-.51 1.413ZM9 10.5A1.5 1.5 0 0 0 7.5 12v4.502a4.5 4.5 0 1 0 9 0V12a1.5 1.5 0 0 0-1.5-1.5l-6-.002ZM9 12h6v4.502a3 3 0 0 1-6 0v-4.502Z"
    />
  </svg>
);
export default SvgComponent;
