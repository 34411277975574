import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillOpacity={0.7}
      d="M8 .5c2.2 0 4 1.8 4 4s-1.8 4-4 4-4-1.8-4-4 1.8-4 4-4Zm7 17 1.8 1.77c.5.5 1.2.1 1.2-.49V14.5l2.8-3.4a1 1 0 0 0-.8-1.6h-7c-.8 0-1.3 1-.8 1.6l2.8 3.4v3Zm-2-2.3-2.3-2.8c-.4-.5-.6-1.1-.6-1.7-.7-.2-1.4-.2-2.1-.2-4.4 0-8 1.8-8 4v2h13v-1.3Z"
    />
  </svg>
);
export default SvgComponent;
