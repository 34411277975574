import Dropdown from "@components/dropdowns/dropdown";
import NotesComponent from "@features/candidates/NotesComponent";
import SmallAvatar from "@assets/avatar6.png";
import { useState } from "react";

const notes = [
  {
    id: "1",
    title: "Meeting with customer",
    content:
      "Reduce technical debt by refactoring legacy code and improving architecture design. Reduce technical debt by refactoring legacy code and improving architecture design. Reduce technical debt by refactoring legacy code and improving architecture design. Reduce technical debt by refactoring legacy code and improving architecture design.",
    timestamp: "12/01/2024 12:05pm",
    author: {
      name: "Fidelia Quaye",
      avatarSrc: SmallAvatar,
    },
    accentColor: "bg-indigo-500",
  },
  {
    id: "2",
    title: "Meeting with customer",
    content:
      "Reduce technical debt by refactoring legacy code and improving architecture design. Reduce technical debt by refactoring legacy code and improving architecture design. Reduce technical debt by refactoring legacy code and improving architecture design. Reduce technical debt by refactoring legacy code and improving architecture design.",
    timestamp: "12/01/2024 12:05pm",
    author: {
      name: "Fidelia Quaye",
      avatarSrc: SmallAvatar,
    },
    accentColor: "bg-indigo-500",
  },
  {
    id: "3",
    title: "Meeting with customer",
    content:
      "Reduce technical debt by refactoring legacy code and improving architecture design. Reduce technical debt by refactoring legacy code and improving architecture design. Reduce technical debt by refactoring legacy code and improving architecture design. Reduce technical debt by refactoring legacy code and improving architecture design.",
    timestamp: "12/01/2024 12:05pm",
    author: {
      name: "Fidelia Quaye",
      avatarSrc: SmallAvatar,
    },
    accentColor: "bg-indigo-500",
  },
  {
    id: "4",
    title: "Meeting with customer",
    content:
      "Reduce technical debt by refactoring legacy code and improving architecture design. Reduce technical debt by refactoring legacy code and improving architecture design. Reduce technical debt by refactoring legacy code and improving architecture design. Reduce technical debt by refactoring legacy code and improving architecture design.",
    timestamp: "12/01/2024 12:05pm",
    author: {
      name: "Fidelia Quaye",
      avatarSrc: SmallAvatar,
    },
    accentColor: "bg-indigo-500",
  },
];

function ReportNotes() {
  const [company, setCompany] = useState("");
  return (
    <>
      <h1 className="py-8 px-4 font-outfit text-3xl mb-7 bg-custom_faded_blue">
        Notes
      </h1>
      <div className="flex rounded-lg flex-col bg-custom_faded_blue px-4 gap-4">
        <div className="flex  w-auto gap-3">
          <div>
            <label className="text-xs placeholder:text-custom_gray text-custom_gray">
              Company:
            </label>
            <Dropdown
              list={[
                {
                  id: "Doctors",
                  name: "Doctors",
                  value: "Doctors",
                },
                {
                  id: "Emergency",
                  name: "Emergency",
                  value: "Emergency",
                },
                {
                  id: "Funeral",
                  name: "Funeral",
                  value: "Funeral",
                },
              ]}
              selected={company}
              setSelected={setCompany}
            />
          </div>
          <div>
            <label className="text-xs placeholder:text-custom_gray text-custom_gray">
              Employee:
            </label>
            <Dropdown
              list={[
                {
                  id: "Doctors",
                  name: "Doctors",
                  value: "Doctors",
                },
                {
                  id: "Emergency",
                  name: "Emergency",
                  value: "Emergency",
                },
                {
                  id: "Funeral",
                  name: "Funeral",
                  value: "Funeral",
                },
              ]}
              selected={company}
              setSelected={setCompany}
            />
          </div>
          <div>
            <label className="text-xs placeholder:text-custom_gray text-custom_gray">
              Channel:
            </label>
            <Dropdown
              list={[
                {
                  id: "Doctors",
                  name: "Doctors",
                  value: "Doctors",
                },
                {
                  id: "Emergency",
                  name: "Emergency",
                  value: "Emergency",
                },
                {
                  id: "Funeral",
                  name: "Funeral",
                  value: "Funeral",
                },
              ]}
              selected={company}
              setSelected={setCompany}
            />
          </div>
        </div>
        <NotesComponent notes={notes} onNoteClick={() => {}} />
      </div>
    </>
  );
}

export default ReportNotes;
