import React from "react";
import tasks from "./data.json";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/16/solid";

// import {useNavigate} from "react-router-dom";
import Badge from "@components/badges/Badge";
import moment from "moment";
import LinkButton from "@components/ButtonV2/LinkButton";
import { TableColumn } from "@components/DataTable/types";
import Table from "@components/DataTable";

interface LeaveRequest {
  id: string;
  name: string;
  notes: string;
  startDate: string;
  endDate: string;
  status: "Accepted" | "Awaiting response";
}

const sampleData: LeaveRequest[] = [
  {
    id: "5023",
    name: "Obed Frimpong",
    notes: "Going to spain",
    startDate: "12/01/2024",
    endDate: "12/02/2024",
    status: "Awaiting response",
  },
  {
    id: "5023",
    name: "Grace Adoba",
    notes: "Annual leave",
    startDate: "12/01/2024",
    endDate: "12/02/2024",
    status: "Accepted",
  },
  {
    id: "5023",
    name: "Salome Darko",
    notes: "Annual leave",
    startDate: "12/01/2024",
    endDate: "12/02/2024",
    status: "Accepted",
  },
  {
    id: "5023",
    name: "Maureen Agbagba",
    notes: "Annual leave",
    startDate: "12/01/2024",
    endDate: "12/02/2024",
    status: "Awaiting response",
  },
  {
    id: "5023",
    name: "Teddlyn Tackie",
    notes: "Going to spain",
    startDate: "12/01/2024",
    endDate: "12/02/2024",
    status: "Accepted",
  },
];

const badgeColors: any = {
  Accepted: "success",
  "Awaiting response": "warning",
};
const holidaysColumns: TableColumn<LeaveRequest>[] = [
  {
    key: "id",
    label: "ID",
    sortable: true,
  },
  {
    key: "name",
    label: "Name",
    sortable: true,
    render: (value) => <LinkButton label={value} />,
  },
  {
    key: "notes",
    label: "Notes",
    sortable: true,
  },
  {
    key: "startDate",
    label: "Start date",
    render: (value) => moment(value).format("DD/MM/YYYY"),
  },
  {
    key: "endDate",
    label: "End date",
    render: (value) => moment(value).format("DD/MM/YYYY"),
  },
  {
    key: "status",
    label: "Status",
    render: (value) => <Badge type={badgeColors[value]} label={value} />,
  },
];

function EmployeeLeaveList() {
  const [start, setStart] = React.useState(0);
  const [end, setEnd] = React.useState(10);

  const handleNext = () => {
    setStart(start + 6);
    setEnd(end + 6);
  };

  const handlePrev = () => {
    setStart(start - 6);
    setEnd(end - 6);
  };

  // const navigate = useNavigate();

  return (
    <div className="px-5 py-3">
      <div className="-mx-4 sm:-mx-0">
        <Table<LeaveRequest> data={sampleData} columns={holidaysColumns} />
      </div>

      <div className="hidden sm:flex sm:flex-1 sm:items-center mt-4 sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{start + 1}</span> to{" "}
            <span className="font-medium">{end}</span> of{" "}
            <span className="font-medium">{tasks?.length}</span> results
          </p>
        </div>
        <div>
          <nav
            className="isolate inline-flex gap-x-3 -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            <button
              onClick={handlePrev}
              className="relative inline-flex items-center rounded-full px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            {/* Current: "z-10 bg-indigo-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600", Default: "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0" */}

            <a
              href="#"
              className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-[#94A4C4] ring-1 rounded-full ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              1
            </a>

            <button
              onClick={handleNext}
              className="relative inline-flex items-center rounded-full px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default EmployeeLeaveList;
