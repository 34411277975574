import Input from "@components/input";
import {useState} from "react";
import Dropdown from "@components/dropdowns/dropdown";
import OpenEnded from "./openEnded";
import TimeFields from "./TimeFields";
import MultipleChoice from "./multichoice";
import SingleChoice from "./singlechoice";

function Quiz({removeQuiz}: {removeQuiz: () => void}) {
  const [questionType, setQuestionType] = useState("");

  const [question, setQuestion] = useState("");

  const questionTypes = [
    {id: 1, name: "Multiple Choice", value: "Multiple Choice"},
    {id: 2, name: "Open ended", value: "Open ended"},
    {id: 3, name: "Single-choice", value: "Single-choice"},
  ];

  return (
    <div className="col-span-2 grid-cols-2 shadow-sm space-y-2 bg-white rounded-lg p-4">
      <div className="col-span-2">
        <label className="text-xs placeholder:text-custom_gray text-custom_gray">
          Question type:
        </label>

        <Dropdown
          list={questionTypes}
          selected={questionType || "Select Question Type"}
          setSelected={setQuestionType}
        />
      </div>
      <div className="col-span-2">
        <label className="text-xs placeholder:text-custom_gray text-custom_gray">
          Type question here:
        </label>

        <Input
          placeholder={"eg. How does excel work?"}
          className={"mt-1 h-10"}
          value={question}
          onChange={(e: any) => setQuestion(e.target.value)}
        />
      </div>

      {questionType === "Open ended" ? (
        <OpenEnded />
      ) : questionType === "Multiple Choice" ? (
        <MultipleChoice />
      ) : questionType === "Single-choice" ? (
        <>
          <SingleChoice />
        </>
      ) : (
        <></>
      )}
      <TimeFields removeQuiz={removeQuiz} />
    </div>
  );
}

export default Quiz;
