import CustomDatePicker from "@components/datepicker";
import StatusDropdown from "@components/dropdowns/status";
import {useState} from "react";
import Input from "@components/input";
import ButtonV2 from "@components/ButtonV2/ButtonV2";

function AddCourse() {
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");

  return (
    <>
      <div className="gap-y-4 mt-10 flex flex-col pb-28">
        <div className="w-full flex-1 flex flex-col py-4 px-6 rounded-lg">
          <div className="lg:col-span-2 col-span-10">
            <label className="text-xs">Title</label>
            <div className="mt-1">
              <Input placeholder={"Anti money laudering policy"} />
            </div>
          </div>

          <div className="grid gap-4 my-4">
            <div className="lg:col-span-2 col-span-10">
              <label className="text-xs">Start Date</label>

              <div className="mt-1">
                <CustomDatePicker
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                />
              </div>
            </div>
            <div className="lg:col-span-2 col-span-10">
              <label className="text-xs">End Date</label>

              <div className="mt-1">
                <CustomDatePicker
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                />
              </div>
            </div>

            <div className="lg:col-span-2 col-span-10">
              <label className="text-xs">Status:</label>
              <StatusDropdown
                selectedStatus={selectedStatus}
                setSelectedStatus={setSelectedStatus}
              />
            </div>
          </div>
        </div>

        <div className="lg:col-span-4 mt-4 col-span-10 flex justify-end items-end">
          <ButtonV2 text="Add Course" />
        </div>
      </div>
    </>
  );
}

export default AddCourse;
