import {ChevronLeftIcon} from "@heroicons/react/24/solid";
import CandidateStats from "@components/stats/candidatestats";
import CandidateProfile from "./page";
import NotesTab from "@features/candidates/NotesTab";
import TasksTab from "@features/candidates/TasksTab";
import TabsV2 from "@components/tabs/TabV2";
import JobApplicationsTab from "@features/candidates/JobApplicationsTab";
import LMS from "@pages/dashboard/hr/employees/viewemployee/[id]/lms/page";
import {useNavigate} from "react-router-dom";
function AddNewEmplyee() {
  const navigate = useNavigate();

  const PreviewCVTab = () => (
    <div className="p-4 bg-white rounded-lg">Preview CV Tab Content</div>
  );

  const tabsData = [
    {
      id: "candidate-profile",
      label: "Candidate profile",
      component: <CandidateProfile />,
    },
    {
      id: "tasks",
      label: "Tasks",
      component: <TasksTab />,
    },
    {
      id: "notes",
      label: "Notes",
      component: <NotesTab />,
    },
    {
      id: "job-applications",
      label: "Job applications",
      component: <JobApplicationsTab />,
    },
    {
      id: "lms",
      label: "LMS",
      component: <LMS />,
    },
    {
      id: "preview-cv",
      label: "Preview CV",
      component: <PreviewCVTab />,
    },
  ];

  return (
    <>
      <div className="gap-y-4 font-outfit flex flex-col pb-12">
        <div className="bg-[#DDEFFF33] mx-4 rounded-lg font-inter">
          <div className="flex justify-between items-center">
            <div className="w-3/4">
              <CandidateStats />
            </div>
          </div>
        </div>

        <div className="flex justify-between">
          <button
            onClick={() => navigate("/hr/candidates")}
            className="text-custom_secondary font-poppins hover:underline flex gap-x-3 items-center font-outfit font-light"
          >
            <ChevronLeftIcon className="h-6 w-6 text-custom_secondary" />
            Back
          </button>
        </div>
        <div className="w-full flex-1 flex flex-col px-6">
          <TabsV2 tabs={tabsData} />
        </div>
      </div>
    </>
  );
}

export default AddNewEmplyee;
