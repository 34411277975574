import {SVGProps} from "react";
const Star = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <path
      fill="#7C8DB5"
      d="M9 1.5a7.5 7.5 0 1 0 0 15 7.5 7.5 0 0 0 0-15Zm4.26 7.155-1.598 1.635.375 2.325a.75.75 0 0 1-.7.883.75.75 0 0 1-.402-.095L9 12.314l-1.95 1.072a.75.75 0 0 1-1.103-.787l.383-2.325-1.605-1.62a.75.75 0 0 1 .428-1.267l2.182-.338.983-2.085A.75.75 0 0 1 9 4.5a.75.75 0 0 1 .682.428l.976 2.122 2.182.338a.75.75 0 0 1 .42 1.267Z"
    />
  </svg>
);
export default Star;
