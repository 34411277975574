import ButtonV2 from "@components/ButtonV2/ButtonV2";
import DeleteIcon from "@components/svgs/delete";

function Card({ data }: any) {
  return (
    <div className="relative bg-white rounded-2xl py-3 px-2">
      <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
        <img
          src={data.image}
          alt="department-image"
          className="pointer-events-none bg-white w-full h-80 object-cover rounded-3xl group-hover:opacity-75"
        />
      </div>
      <span className="flex justify-between mt-8 align-middle text-center">
        <p className="pointer-events-none block truncate text-xl font-medium text-gray-900">
          {data.title}
        </p>
        <DeleteIcon className="mt-1" />
      </span>
      <p className="pointer-events-none block text-sm font-extralight text-[##7C8DB5]">
        {data.subtext}
      </p>
      <ButtonV2
        text={data.buttonText}
        onClick={() => data.onClick(data.title?.replace(/\s/g, "-"))}
        className="py-2 text-xs font-light mt-1 w-full"
      />
    </div>
  );
}

export default Card;
