import Dropdown from "@components/dropdowns/dropdown";
import {useState} from "react";
import data from "../dataview/data.json";
import Drawer from "@components/drawer/Drawer";
import Input from "@components/input";
import CustomDatePicker from "@components/datepicker";
import ButtonV2 from "@components/ButtonV2/ButtonV2";
function AddPosition({isOpen, onClose}: {isOpen: boolean; onClose: any}) {
  const [contact, setContact] = useState("");
  const [type, setType] = useState("");
  const [product, setProduct] = useState("");
  const [selectedDate, setSelectedDate] = useState("");

  return (
    <Drawer isOpen={isOpen} drawerHeader="Add new position" onClose={onClose}>
      <div className="pb-24 gap-x-8 gap-y-3 flex flex-col py-12 px-6 rounded-lg">
        <div className="">
          <label className="text-xs text-[#82868C]">Role</label>
          <Input className="py-3" placeholder="" />
        </div>

        <div className="">
          <label className="text-xs text-[#82868C]">Contact</label>
          <Dropdown
            list={data?.map((item) => ({name: item?.user, value: item?.user}))}
            selected={contact}
            setSelected={setContact}
          />
        </div>

        <div className="col-span-2  w-full">
          <label className="text-xs text-[#82868C]">Role description:</label>

          <textarea
            rows={10}
            placeholder="Eg. Job description...."
            className="mt-1 w-full rounded-xl bg-transparent border placeholder:text-[#9CA1AD] border-[#657A9345]"
          />
        </div>

        <div className="">
          <label className="text-xs text-[#82868C]">Type:</label>
          <Dropdown
            list={[
              {name: "Intern", value: "Intern"},
              {name: "Permanent", value: "Permanent"},
              {name: "Contract", value: "Contract"},
            ]}
            selected={type}
            setSelected={setType}
          />
        </div>

        <div className="">
          <label className="text-xs text-[#82868C]">Product:</label>
          <Dropdown
            list={[
              {name: "Full-time", value: "full-time"},
              {name: "Part-time", value: "part-time"},
              {name: "Contract", value: "contract"},
            ]}
            selected={product}
            setSelected={setProduct}
          />
        </div>

        <div className="">
          <label className="text-xs text-[#82868C]">Start date:</label>
          <CustomDatePicker
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
          />
        </div>

        <div className="">
          <label className="text-xs text-[#82868C]">Budget:</label>
          <Input className="py-3" placeholder="" />
        </div>

        <div className="flex justify-end mt-3">
          <ButtonV2
            text="Add Position"
            // className="inline-flex justify-center rounded-md border border-transparent bg-custom_button_dark px-8 py-3 text-sm font-light text-white hover:bg-blue-900 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
            onClick={() => null}
          />
        </div>
      </div>
    </Drawer>
  );
}

export default AddPosition;
