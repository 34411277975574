import {XMarkIcon} from "@heroicons/react/24/solid";
import FacebookIcon from "@components/svgs/facebook";
import LinkedIn from "@components/svgs/linkedin";
import Instagram from "@components/svgs/instagram";
import EditIcon from "@components/svgs/edit";
import Button from "@components/button";
import Avartar from "@assets/candidate.jpg";
import Accordion from "@components/accordionemployees/accordion";
import {useState} from "react";
import Badge from "@components/badges/Badge";
import PoolPopup from "../PoolPopup";
import {Pool} from "../../../../../../types/pools";
import PositionPopUp from "../PositionPopup";
import ProfileUpdate from "@components/accordion/add/profile";

import FilePickerImage from "@components/filepickerImage";

function AddNewEmplyee() {
  const [selectedPositions, setSelectedPositions] = useState([
    {
      id: 1,
      name: "UI/UX Designer",
    },
  ]);
  const [show, setShow] = useState(false);
  const [selectedPools, setSelectedPools] = useState([
    {id: 1, name: "Talent pool", color: "bg-custom_deep_purple"},
  ]);

  const addToPool = (selected: Pool) => {
    setSelectedPools([...selectedPools, selected]);
  };

  const addToPositions = (selected: any) => {
    setSelectedPositions([...selectedPositions, selected]);
  };

  const handleRemovePool = (id: number) => {
    const newPools = selectedPools.filter((pool) => pool.id !== id);
    setSelectedPools(newPools);
  };

  const handleRemovePosition = (id: number) => {
    const newPositions = selectedPositions.filter(
      (position) => position.id !== id
    );
    setSelectedPositions(newPositions);
  };

  return (
    <>
      <div className="gap-y-4 flex flex-col  ">
        <div className="py-8 bg-custom_faded_blue flex flex-col lg:flex-row px-4">
          <div className="flex flex-col items-center justify-center">
            <img
              src={Avartar}
              className=" h-auto w-80 rounded-full"
              alt="Picture of the Candidate"
            />

            <div className="flex gap-x-3 items-center mt-2">
              <LinkedIn />
              <Instagram />
              <FacebookIcon />
            </div>
          </div>
          <div className="lg:ms-12 mt-8 lg:mt-0">
            <div className="flex justify-between">
              <div className="flex">
                <div>
                  <h2 className="text-xl">Immaculate Akuvi</h2>
                  <p className="font-light">UI/UX Design</p>
                  <div className="mt-2">
                    <svg
                      width="147"
                      height="24"
                      viewBox="0 0 147 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.9651 0L14.6515 8.2918H23.3446L16.3117 13.4164L18.998 21.7082L11.9651 16.5836L4.9322 21.7082L7.61853 13.4164L0.585614 8.2918H9.27878L11.9651 0Z"
                        fill="#FFC527"
                      />
                      <path
                        d="M42.7327 0L45.419 8.2918H54.1122L47.0793 13.4164L49.7656 21.7082L42.7327 16.5836L35.6998 21.7082L38.3861 13.4164L31.3532 8.2918H40.0464L42.7327 0Z"
                        fill="#FFC527"
                      />
                      <path
                        d="M73.5003 0L76.1866 8.2918H84.8798L77.8469 13.4164L80.5332 21.7082L73.5003 16.5836L66.4674 21.7082L69.1537 13.4164L62.1208 8.2918H70.8139L73.5003 0Z"
                        fill="#FFC527"
                      />
                      <path
                        d="M104.268 0L106.954 8.2918H115.647L108.614 13.4164L111.301 21.7082L104.268 16.5836L97.2349 21.7082L99.9213 13.4164L92.8883 8.2918H101.582L104.268 0Z"
                        fill="#FFC527"
                      />
                      <path
                        d="M135.035 0L137.722 8.2918H146.415L139.382 13.4164L142.068 21.7082L135.035 16.5836L128.003 21.7082L130.689 13.4164L123.656 8.2918H132.349L135.035 0Z"
                        fill="#2B2B2B"
                        fill-opacity="0.1"
                      />
                    </svg>
                  </div>
                </div>
                <div className="ms-6">
                  {/* badge */}
                  <Badge type="warning" label="Employee" />
                </div>
              </div>
              <button
                onClick={() => setShow(true)}
                className="bg-transparent focus:outline-none"
              >
                <EditIcon />
              </button>
            </div>
            <div className="mt-3">
              <p className="text-gray-400 font-outfit">
                Highly skilled software quality assurance engineer with over 4
                years experience in testing and ensuring the quality of software
                applications. consistently met project deadlines and exceeded
                client expectations by implementing effective testing
                strategies. seeking a challenging role where i can leverage my
                expertise to contribute to the success of a dynamic
                organization.
              </p>
            </div>
          </div>
        </div>

        <div className="gap-5 flex lg:flex-row flex-col">
          <div className="lg:w-1/4 w-full py-4 px-3 rounded-lg flex flex-col gap-6 bg-custom_faded_blue">
            <div className="flex mt-5 items-center gap-5">
              <div className="flex flex-col gap-y-6">
                <div className="">
                  <label className="text-xs text-custom_gray">
                    Team Lead:{" "}
                  </label>
                </div>
                <div className="">
                  <label className="text-xs text-custom_gray">
                    Department:{" "}
                  </label>
                </div>
              </div>

              <div className="flex flex-col gap-y-6">
                <div className="">
                  <span className="text-xs text-black">Liam Summers</span>
                </div>
                <div className="">
                  <span className="text-xs text-black">
                    Software and Graphic Design
                  </span>
                </div>
              </div>
            </div>

            <div className="">
              <div className="flex mb-3 justify-between items-center">
                <label className="text-xs">Talent pool:</label>
                <PoolPopup handleCLick={addToPool} />
              </div>
              <div className="border rounded-xl gap-2 py-3 px-2 flex items-center flex-wrap ">
                {selectedPools.map((pool) => (
                  <div className="flex rounded-md border bg-white py-2 px-3 items-center gap-x-2">
                    <div className={`h-4 w-4 rounded-full ${pool?.color}`} />{" "}
                    <span className="text-black text-xs">{pool?.name}</span>
                    <XMarkIcon
                      onClick={() => handleRemovePool(pool.id)}
                      className="h-3 w-3 cursor-pointer"
                    />
                  </div>
                ))}
              </div>
            </div>

            <div className="">
              <div className="flex mb-3 justify-between items-center">
                <label className="text-xs">Current Position:</label>
                <PositionPopUp
                  selectedPositions={selectedPositions}
                  handleCLick={addToPositions}
                />
              </div>
              <div className="border rounded-xl gap-2 py-3 px-2 flex items-center flex-wrap ">
                {selectedPositions.map((pool) => (
                  <div className="flex rounded-md border bg-white py-2 px-3 items-center gap-x-2">
                    <span className="text-black text-xs">{pool?.name}</span>
                    <XMarkIcon
                      onClick={() => handleRemovePosition(pool.id)}
                      className="h-3 w-3 cursor-pointer"
                    />
                  </div>
                ))}
              </div>
            </div>

            <div className="">
              <button className="inline-flex h-12 justify-center py-3 w-full rounded-lg bg-transparent border border-[#27CE88] px-4 text-xs  focus:outline-none  focus-visible:ring-white/75 items-center">
                Portfolio
              </button>
            </div>
            <div className="">
              <button className="inline-flex text-white h-12 justify-center py-3 w-full rounded-lg bg-custom_dark_blue border border-custom_dark_blue px-4 text-xs  focus:outline-none  focus-visible:ring-white/75 items-center">
                CV
              </button>
            </div>
            <div className="">
              <button className="inline-flex text-white h-12 justify-center py-3 w-full rounded-lg bg-custom_dark_blue border border-custom_dark_blue px-4 text-xs  focus:outline-none  focus-visible:ring-white/75 items-center">
                Remoteli CV
              </button>
            </div>

            <div className="">
              <FilePickerImage />
              {/* <div className="flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-4">
                <div className="text-center">
                  <PhotoIcon
                    className="mx-auto h-12 w-12 text-gray-300"
                    aria-hidden="true"
                  />
                  <div className="mt-4 flex text-sm leading-6 text-gray-600">
                    <label
                      htmlFor="file-upload"
                      className="relative cursor-pointer rounded-md bg-white font-semibold text-custom_blue focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                    >
                      <span>Upload cv</span>
                      <input
                        id="file-upload"
                        name="file-upload"
                        type="file"
                        className="sr-only"
                      />
                    </label>
                    <p className="pl-1">or drag and drop</p>
                  </div>
                  <p className="text-xs leading-5 text-gray-600">
                    PDF, DOCX, DOC up to 10MB
                  </p>
                </div>
              </div> */}
            </div>

            <div>
              <Button title="Update" onClick={() => null} />
            </div>
          </div>

          <div className="lg:w-3/4 w-full  rounded-lg flex flex-col gap-6 ">
            <div className="bg-custom_faded_blue py-4 px-3">
              <Accordion />
            </div>
          </div>
        </div>
      </div>

      <ProfileUpdate open={show} setOpen={setShow} />
    </>
  );
}

export default AddNewEmplyee;
