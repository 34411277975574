const Twitter = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 4H20L14 11L21 20H15L11 14L6 20H3L10 13L3 4H9L13 9L17 4ZM16 18H18L8 6H6L16 18Z"
        fill="#232325"
      />
    </svg>
  );
};

export default Twitter;
