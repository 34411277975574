import CopyIcon from "@components/svgs/copy";
import ShareIcon from "@components/svgs/share";
import NewPrint from "@components/svgs/newprint";
import Drawer from "@components/drawer/Drawer";
import { useState } from "react";
import JobItemsList from "./jobItemsList";
import SearchComponent from "@components/baseSearch";

const JobItems = () => {
  const [showDrawer, setShowDrawer] = useState(false);

  const toggleDrawer = () => {
    setShowDrawer(!showDrawer);
  };

  return (
    <>
      <div className="py-2 px-4 bg-custom_faded_blue rounded-xl">
        <div className="py-4 flex justify-between items-center">
          <div className="w-1/3">
            <SearchComponent
              placeholder="Search here..."
              value=""
              onChange={() => {}}
            />
          </div>
          <div className="flex gap-x-3 items-center">
            <CopyIcon />
            <ShareIcon />
            <NewPrint />

            <button
              onClick={toggleDrawer}
              className="bg-custom_deep_blue text-white font-light text-sm px-10 py-3 rounded-lg flex items-center gap-3"
            >
              <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.5 8.5H8.5V14.5H6.5V8.5H0.5V6.5H6.5V0.5H8.5V6.5H14.5V8.5Z"
                  fill="white"
                />
              </svg>
              <span>Add job item</span>
            </button>
          </div>
        </div>
        <div className="bg-white rounded-lg">
          <JobItemsList />
        </div>
      </div>
      <Drawer
        drawerHeader="Add a job item"
        isOpen={showDrawer}
        onClose={toggleDrawer}
      >
        <div>Add a job item</div>
      </Drawer>
    </>
  );
};

export default JobItems;
