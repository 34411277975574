interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  icon?: React.ReactNode;
  className?: string;
}

function Input({ icon, className, ...rest }: InputProps) {
  return (
    <div className="relative w-full">
      {icon && <span className="absolute left-4 z-10 top-3">{icon}</span>}
      <input
        className={`border border-[#657A9345] w-full py-3 px-4 rounded-lg bg-transparent placeholder:text-custom_gray text-sm ${
          icon ? "pl-12" : "pl-4"
        } ${className}`}
        {...rest}
      />
    </div>
  );
}

export default Input;
