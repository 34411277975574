import {SVGProps} from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <rect
      width={19.5}
      height={19.5}
      x={0.25}
      y={0.25}
      stroke="#61A9FF"
      strokeWidth={0.5}
      rx={9.75}
    />
    <path
      fill="#407BFF"
      stroke="#407BFF"
      strokeWidth={0.047}
      d="M10.563 10.54h-.024v3.585a.54.54 0 1 1-1.078 0V10.539H5.875a.539.539 0 0 1 0-1.078H9.461V5.875a.539.539 0 1 1 1.078 0V9.461h3.586a.54.54 0 1 1 0 1.078h-3.563Z"
    />
  </svg>
);
export default SvgComponent;
