import { useState } from "react";
import {Link} from "react-router-dom";
import {toast} from "sonner";

function ForgotPassword() {
  const [sent, setSent] = useState(false);
  const handleSend = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    toast.success("Password reset link sent to your email");
    setSent(true);
  };
  return (
    <div className="mx-auto w-full max-w-lg lg:w-full">
      <div className="flex items-center justify-center flex-col">
        <h2 className="mt-8 text-3xl font-bold font-outfit leading-9 tracking-tight text-custom_blue">
          Reset password
        </h2>
        <p
          className={` ${
            sent ? "mt-5" : "mt-2"
          }  text-sm leading-6 text-center text-custom_gray`}
        >
          {sent
            ? "If the email submitted has an account we will send you an email. Please check your junk mail"
            : "Enter your email to reset your password"}
        </p>
      </div>

      {!sent && (
        <div className="mt-10">
          <div>
            <form
              action="#"
              onSubmit={handleSend}
              method="POST"
              className="space-y-6"
            >
              <div>
                <label
                  htmlFor="email"
                  className="block text-xs font-medium leading-6 text-custom_gray"
                >
                  Email:
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    placeholder="eg. john.doe@remoteli.co.uk"
                    required
                    className="block bg-transparent text-white w-full rounded-lg border-gray border py-3 px-4 shadow-sm focus:ring-0 placeholder:text-gray-400 sm:text-base sm:leading-6 "
                  />
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="flex w-full mt-12 justify-center rounded-md bg-custom_deep_blue px-3 py-4 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Send
                </button>

                <div className="flex mt-6 justify-center gap-x-4 hover:border-custom_blue hover:border py-5 rounded-lg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6 text-custom_blue"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6.75 15.75 3 12m0 0 3.75-3.75M3 12h18"
                    />
                  </svg>

                  <Link
                    to="/login"
                    className="text-sm font-medium leading-6 text-custom_blue"
                  >
                    Back to login
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default ForgotPassword;
``