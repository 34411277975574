import Drawer from "@components/drawer/Drawer";
import {XCircleIcon} from "@heroicons/react/24/outline";

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const skills = ["UI/UX Design", "Software Engineering"];

function Add({open, setOpen}: Props) {
  return (
    <Drawer
      drawerHeader="Add skill"
      isOpen={open}
      onClose={() => setOpen(false)}
    >
      <div className="mt-8 px-5 flex flex-col gap-y-2">
        <div>
          <label className="text-xs placeholder:text-custom_gray text-custom_gray">
            Skills:{" "}
          </label>
          <div className={"mt-1 py"}>
            <div className="flex gap-3 border py-2 px-3 rounded-lg flex-wrap">
              {skills.map((skill) => (
                <div
                  key={skill}
                  className="flex border px-3 items-center rounded-full py-1 gap-x-3 relative"
                >
                  <h1 className="text-sm text-black">{skill}</h1>
                  <XCircleIcon className="h-4 w-4" />
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="mt-8 flex justify-end">
          <div>
            <button
              className="inline-flex justify-center rounded-md border border-transparent bg-custom_button_dark px-8 py-3 text-sm font-medium text-white hover:bg-blue-900 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
              onClick={() => setOpen(false)}
            >
              Add Skills
            </button>
          </div>
        </div>
      </div>
    </Drawer>
  );
}

export default Add;
