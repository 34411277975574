import LinkButton from "@components/ButtonV2/LinkButton";
import Table from "@components/DataTable";
import { TableColumn } from "@components/DataTable/types";
import { useNavigate } from "react-router-dom";

interface Applicant {
  id: number;
  candidate: string;
  expectedSalary: number;
  dateApplied: string;
  status: string;
}

const sampleApplicationsData: Applicant[] = [
  {
    id: 213456,
    candidate: "Matthias Boampong",
    expectedSalary: 2000,
    dateApplied: "24/11/2024",
    status: "Interview Stage 1",
  },
  {
    id: 213456,
    candidate: "Paula Agyepong",
    expectedSalary: 3000,
    dateApplied: "24/11/2024",
    status: "Rejected",
  },
  {
    id: 213456,
    candidate: "Andy Brookes",
    expectedSalary: 2500,
    dateApplied: "24/11/2024",
    status: "Interview Stage 1",
  },
  {
    id: 213456,
    candidate: "Florence Deli",
    expectedSalary: 3000,
    dateApplied: "24/11/2024",
    status: "Rejected",
  },
  {
    id: 213456,
    candidate: "Matthias Boampong",
    expectedSalary: 2000,
    dateApplied: "24/11/2024",
    status: "Rejected",
  },
  {
    id: 213456,
    candidate: "Milson Grace",
    expectedSalary: 6000,
    dateApplied: "24/11/2024",
    status: "Interview Stage 1",
  },
  {
    id: 213456,
    candidate: "Peter Obi",
    expectedSalary: 8000,
    dateApplied: "24/11/2024",
    status: "Interview Stage 1",
  },
  {
    id: 213456,
    candidate: "Michael Onne",
    expectedSalary: 10000,
    dateApplied: "24/11/2024",
    status: "Rejected",
  },
  {
    id: 213456,
    candidate: "Joao Coimbra",
    expectedSalary: 8000,
    dateApplied: "24/11/2024",
    status: "Accepted",
  },
];

const ApplicationsList = () => {
  const navigate = useNavigate();
  const columns: TableColumn<Applicant>[] = [
    {
      key: "candidate",
      label: "Candidate",
      sortable: false,
      render: (item) => (
        <LinkButton
          label={item}
          onClick={() => navigate(`/workspace/jobs/job_applications/${item}`)}
        />
      ),
    },
    {
      key: "expectedSalary",
      label: "Expected Salary",
      sortable: false,
    },
    {
      key: "dateApplied",
      label: "Date Applied",
      sortable: false,
    },
    {
      key: "status",
      label: "Status",
      sortable: false,
    },
  ];
  return (
    <>
      <Table data={sampleApplicationsData} columns={columns} />
    </>
  );
};

export default ApplicationsList;
