import {TrashIcon} from "@heroicons/react/24/outline";
import EditIcon from "@components/svgs/edit";

function Blog({data, onDelete, onEdit, onView}: any) {
  return (
    <div className="relative bg-white border border-card_yellow rounded-2xl pt-2 pb-3 px-2">
      <div
        onClick={onView}
        className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100"
      >
        <img
          src={data.image}
          alt="blog-image"
          className="pointer-events-none w-full h-48 object-cover group-hover:opacity-75"
        />
      </div>
      <p className="pointer-events-none mt-2 block truncate text-xl font-medium text-gray-900">
        {data.title}
      </p>
      <p className="pointer-events-none my-4 block font-light text-[#7C8DB5]">
        {data.subtext}
      </p>
      <div className="mt-12 gap-x-4 flex items-center justify-end">
        {onEdit && (
          <button
            type="button"
            onClick={() => onEdit(data)}
            className="focus:outline-none z-10 text-white text-sm font-light"
          >
            <EditIcon />
          </button>
        )}
        <button
          type="button"
          onClick={() => onDelete(data)}
          className="focus:outline-none z-10 text-white text-sm font-light"
        >
          <TrashIcon className="h-5 w-5 text-red-500" />
        </button>
      </div>
    </div>
  );
}

export default Blog;
