import {useState} from "react";
import PencilIcon from "@components/svgs/pencil";
import DeleteIcon from "@components/svgs/delete";
import ConfirmModal from "@components/confirmationDialog/ConfirmationDialog";
import {TableColumn} from "@components/DataTable/types";
import Table from "@components/DataTable";
import LinkButton from "@components/ButtonV2/LinkButton";
import Badge from "@components/badges/Badge";
import {Pagination} from "@components/pagination/Pagination";
import Drawer from "@components/drawer/Drawer";
import AddCourse from "./addcourse";

type Props = {
  toggleDrawer?: () => void;
};

export type TaskStatus = "Complete" | "Incomplete" | "Pending";

export interface Task {
  id: string;
  user: {
    name: string;
    avatar?: string;
  };
  company: {
    name: string;
    logo?: string;
  };
  description: string;
  status: TaskStatus;
  dueDate: string;
  actions?: string[];
}

export const generateSampleTasks = (count: number = 6): Task[] => {
  const users = [{name: "Paula Agyepong"}, {name: "Immaculate Akuvi"}];

  const companies = [{name: "Remoteli"}, {name: "Ascender Sugar Free"}];

  const statuses: TaskStatus[] = ["Complete", "Incomplete", "Pending"];

  return Array.from({length: count}, (_, index) => ({
    id: `task-${index + 1}`,
    user: {
      name: users[index % users.length].name,
    },
    company: {
      name: companies[index % companies.length].name,
    },
    description:
      "Reduce technical debt by refactoring legacy code and improving architecture design.",
    status: statuses[index % statuses.length],
    dueDate: "12/01/2024",
    actions: ["Edit"],
  }));
};

const badgeTypes: any = {
  Complete: "success",
  Pending: "warning",
  Incomplete: "error",
};

const LMSList = (props: Props) => {
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(10);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showTaskDetail, setShowTaskDetail] = useState(false);

  function toggleShowTask() {
    setShowTaskDetail(!showTaskDetail);
  }

  const handleNext = () => {
    setStart(start + 10);
    setEnd(end + 10);
  };

  const handlePrev = () => {
    setStart(start - 10);
    setEnd(end - 10);
  };

  const tasks = generateSampleTasks();

  const taskColumns: TableColumn<Task>[] = [
    {
      key: "id",
      label: "ID",
      render: () => <span className="font-medium">684654</span>,
      sortable: true,
    },
    {
      key: "description",
      label: "Course taken",
      render: (value: string) => <span>{value}</span>,
      sortable: true,
    },
    {
      key: "status",
      label: "Status",
      render: (value: TaskStatus) => {
        return <Badge type={badgeTypes[value]} label={value} />;
      },
      sortable: true,
    },
    {
      key: "company",
      label: "Activity",
      render: () => {
        return <LinkButton label={"View Cerificate"} />;
      },
      sortable: true,
    },
    {
      key: "dueDate",
      label: "Due date",
      sortable: true,
    },
    {
      key: "actions",
      label: "Actions",
      render: () => (
        <span className="flex align-middle gap-1">
          <button onClick={props?.toggleDrawer}>
            <PencilIcon />
          </button>
          <button onClick={() => setShowDeleteDialog(true)}>
            <DeleteIcon />
          </button>
        </span>
      ),
    },
  ];

  return (
    <div className="px-5 py-3">
      <div className="-mx-4 mt-8 sm:-mx-0">
        <Table<Task> data={tasks} columns={taskColumns} />
      </div>

      <Pagination
        start={start}
        end={end}
        total={tasks?.length}
        onPrevious={handlePrev}
        onNext={handleNext}
      />
      <ConfirmModal
        isOpen={showDeleteDialog}
        onCancel={() => setShowDeleteDialog(false)}
        onDelete={() => {}}
        itemName="User experience design"
        subject="Are you sure you want to delete this LMS?"
      />
      <Drawer
        drawerHeader="Task"
        isOpen={showTaskDetail}
        hasActions={true}
        onClose={toggleShowTask}
        onDelete={() => setShowDeleteDialog(true)}
        onEdit={props.toggleDrawer}
      >
        <AddCourse />
      </Drawer>
    </div>
  );
};

export default LMSList;
