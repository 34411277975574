import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import React from "react";

interface SearchComponentProps {
  /**
   * Placeholder text for the search input
   * @default "Search..."
   */
  placeholder?: string;

  /**
   * Current value of the search input
   */
  value: string;

  /**
   * Callback fired when the input value changes
   */
  onChange: (value: string) => void;

  /**
   * Additional CSS classes for the container
   */
  className?: string;

  /**
   * Additional CSS classes for the input element
   */
  inputClassName?: string;

  /**
   * Additional CSS classes for the search icon
   */
  iconClassName?: string;

  /**
   * Whether the input is disabled
   * @default false
   */
  disabled?: boolean;

  /**
   * Callback fired when the form is submitted
   */
  onSubmit?: (value: string) => void;
}

const SearchComponent = ({
  placeholder = "Search...",
  value,
  onChange,
  className = "",
  inputClassName = "",
  iconClassName = "",
  disabled = false,
  onSubmit = () => {},
}: SearchComponentProps) => {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit(value);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <form
      onSubmit={handleSubmit}
      className={`relative ${className}`}
      role="search"
    >
      <div
        className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
        aria-hidden="true"
      >
        <MagnifyingGlassIcon
          className={`h-5 w-5 text-gray-400 ${iconClassName}`}
          aria-hidden="true"
        />
      </div>
      <input
        type="text"
        value={value}
        onChange={handleChange}
        disabled={disabled}
        placeholder={placeholder}
        className={`
          w-full
          pl-10
          pr-4
          py-1
          rounded-xl
          border
          border-[#657A9345]
          bg-white
          text-[#657A93]
          placeholder:text-[#657A93]
          placeholder:text-xs
          focus:outline-none
          focus:ring-2
          focus:ring-blue-500
          focus:border-transparent
          disabled:opacity-50
          disabled:cursor-not-allowed
          ${inputClassName}
        `}
        aria-label={placeholder}
      />
    </form>
  );
};

export default SearchComponent;
