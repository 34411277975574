import LinkButton from "@components/ButtonV2/LinkButton";
import CandidatesIcon from "@components/svgs/companiesbig";
import OpenJobButtonIcon from "@components/svgs/openJobButtonIcon";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import SmallAvatar from "@assets/avatar6.png";
import TabsV2 from "@components/tabs/TabV2";
import JobItems from "@pages/dashboard/workspace/jobs/components/jobItems";
import NotesTab from "@features/candidates/NotesTab";
import Activities from "@pages/dashboard/crm/companies/view/[id]/components/acticities/page";
import TasksTab from "@features/candidates/TasksTab";

const KeyResponsibilities = () => {
  const responsibilities = [
    "Administrative Support: Provide administrative assistance to the team, including scheduling appointments, managing calendars, organizing meetings, and handling correspondence.",
    "Canva Design: Utilize Canva to create visually appealing graphics, presentations, and marketing materials for internal and external use, ensuring brand consistency and professionalism.",
    "Social Media Management: Assist in managing Remoteli's social media accounts, including content creation, scheduling posts, engaging with followers, and monitoring analytics to track performance.",
    "Document Creation: Prepare documents, reports, and presentations using Microsoft Office suite (Word, Excel, PowerPoint) and Canva, ensuring accuracy and professionalism in all deliverables.",
    "Data Entry and Management: Perform data entry tasks, update records, and maintain databases accurately and efficiently, ensuring data integrity and confidentiality.",
    "Communication Support: Assist in drafting emails, newsletters, and announcements, and coordinate communication efforts to keep team members informed and engaged.",
    "Event Coordination: Support the planning and execution of company events, workshops, and webinars, including logistics coordination, attendee management, and post-event follow-up.",
    "Research Assistance: Conduct research on various topics as needed, compile findings into comprehensive reports, and present insights to support decision-making processes.",
    "Social Media Analytics: Monitor social media metrics and analytics, analyze trends, and generate reports to assess the effectiveness of social media campaigns and inform future strategies.",
    "Collaborative Projects: Collaborate with cross-functional teams on special projects, initiatives, and campaigns, providing administrative and creative support as required.",
  ];

  return (
    <div>
      <h2 className="mb-4 text-[12px]">Key Responsibilities:</h2>
      <ol className="space-y-1 list-decimal pl-4 text-[12px]">
        {responsibilities.map((responsibility, index) => (
          <li key={index}>
            <p>{responsibility}</p>
          </li>
        ))}
      </ol>
    </div>
  );
};

const tabs = [
  {
    id: "jobItems",
    label: "Job Items",
    component: <JobItems />,
  },
  {
    id: "notes",
    label: "Notes",
    component: <NotesTab />,
  },
  {
    id: "tasks",
    label: "Tasks",
    component: <TasksTab />,
  },
  {
    id: "activities",
    label: "Activities",
    component: <Activities />,
  },
];

const JobDetailPage = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="bg-[#DDEFFF33] rounded-2xl font-outfit">
        <div className="flex justify-between items-center">
          <div className="w-2/3 ps-8">
            <h2 className="font-normal text-2xl">Job Info</h2>
          </div>
          <CandidatesIcon />
        </div>
      </div>
      <div className="flex mt-2 justify-between">
        <button
          onClick={() => navigate(-1)}
          className="text-custom_secondary hover:underline flex gap-x-3 items-center font-outfit font-light"
        >
          <ChevronLeftIcon className="h-6 w-6 text-custom_secondary" />
          Back
        </button>
      </div>
      <div className="py-4 bg-custom_faded_blue rounded-2xl my-2 mb-5 flex flex-col px-8">
        <div className="flex w-full items-center justify-between">
          <div className="gap-x-3 items-center mt-2">
            <h1 className="text-2xl font-outfit font-semibold">
              SOLUTIONS FOR PROS
            </h1>
            <LinkButton label="Learning and management coordinator" />
          </div>
          <button
            onClick={() => {}}
            className="bg-custom_deep_blue text-sm text-white px-4 py-2 font-outfit rounded-lg flex items-center gap-1"
          >
            <OpenJobButtonIcon />
            <span>Open</span>
          </button>
        </div>
        <div className="gap-x-3 mt-2">
          <KeyResponsibilities />
          <div className="flex mt-8 items-center gap-2">
            <img
              src={SmallAvatar}
              alt="avatar"
              className="w-8 h-8 rounded-full"
            />
            <div className="flex flex-col">
              <span className="text-[#7C8DB5] text-sm">
                Assigned Sales Person
              </span>
              <span className="text-[#82868C] text-sm">Gift Avi</span>
            </div>
          </div>
        </div>
      </div>
      <div className={`mt-8 bg-[#DDEFFF33] p-5`}>
        <TabsV2 tabs={tabs} />
      </div>
    </div>
  );
};

export default JobDetailPage;
