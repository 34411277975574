import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import "./App.css";
import Navigator from "@navigation/index";
function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <Navigator />
    </QueryClientProvider>
  );
}

export default App;
