import React from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/16/solid";
import Table from "@components/DataTable";
import { TableColumn } from "@components/DataTable/types";
import LinkButton from "@components/ButtonV2/LinkButton";

interface Props {
  handleClick: (item: any) => void;
}

interface ContactInfo {
  id: number;
  name: string;
  company: string;
  role: string;
  email: string;
  phone: string;
  assignedSalesPerson: string;
}

const sampleData: ContactInfo[] = [
  {
    id: 5023,
    name: "Paula Agyepong",
    company: "Catenge Innovations",
    role: "CTO",
    email: "paula@gmail.com",
    phone: "02458123456",
    assignedSalesPerson: "Gift Avi",
  },
  {
    id: 5024,
    name: "Igwe Emmanuel",
    company: "Catenge Innovations",
    role: "Partner",
    email: "igwe@gmail.com",
    phone: "02458123456",
    assignedSalesPerson: "Gift Avi",
  },
  {
    id: 5025,
    name: "Delia Kooma",
    company: "Aviaple Company",
    role: "Head of Sales",
    email: "delia@gmail.com",
    phone: "02458123456",
    assignedSalesPerson: "Gift Avi",
  },
  {
    id: 5023,
    name: "Paula Agyepong",
    company: "Catenge Innovations",
    role: "CTO",
    email: "paula@gmail.com",
    phone: "02458123456",
    assignedSalesPerson: "Gift Avi",
  },
  {
    id: 5023,
    name: "Paula Agyepong",
    company: "Catenge Innovations",
    role: "CTO",
    email: "paula@gmail.com",
    phone: "02458123456",
    assignedSalesPerson: "Gift Avi",
  },
  {
    id: 5023,
    name: "Paula Agyepong",
    company: "Catenge Innovations",
    role: "CTO",
    email: "paula@gmail.com",
    phone: "02458123456",
    assignedSalesPerson: "Gift Avi",
  },
];

function ContacTable({ handleClick }: Props) {
  const [start, setStart] = React.useState(0);
  const [end, setEnd] = React.useState(10);

  const handleNext = () => {
    setStart(start + 10);
    setEnd(end + 10);
  };

  const handlePrev = () => {
    setStart(start - 10);
    setEnd(end - 10);
  };

  const columns: TableColumn<ContactInfo>[] = [
    {
      key: "id",
      label: "ID",
      sortable: true,
    },
    {
      key: "name",
      label: "Name",
      sortable: true,
    },
    {
      key: "company",
      label: "Company",
      sortable: true,
      render: (item) => (
        <LinkButton label={item} onClick={() => handleClick(item)} />
      ),
    },
    {
      key: "role",
      label: "Role",
      sortable: true,
    },
    {
      key: "email",
      label: "Email",
      sortable: true,
      render: (item) => (
        <LinkButton label={item} onClick={() => handleClick(item)} />
      ),
    },
    {
      key: "phone",
      label: "Phone",
      sortable: true,
      render: (item) => (
        <LinkButton label={item} onClick={() => handleClick(item)} />
      ),
    },
    {
      key: "assignedSalesPerson",
      label: "Assigned Sales Person",
      sortable: true,
    },
  ];

  return (
    <div className="px-5 py-2">
      <div className="-mx-4 mt-3 sm:-mx-0">
        <Table data={sampleData.slice(start, end)} columns={columns} />
      </div>

      <div className="hidden sm:flex sm:flex-1 sm:items-center mt-5 sm:justify-between">
        <div />
        <div>
          <nav
            className="isolate inline-flex gap-x-3 -space-x-px rounded-md"
            aria-label="Pagination"
          >
            <button
              onClick={handlePrev}
              className="relative inline-flex items-center rounded-full px-2 py-2 h-8 w-8 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            {/* Current: "z-10 bg-indigo-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600", Default: "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0" */}

            <a
              href="#"
              className="relative inline-flex items-center px-4 py-2 h-8 w-8 text-sm font-medium text-[#94A4C4] ring-1 rounded-full ring-inset justify-center ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              1
            </a>

            <button
              onClick={handleNext}
              className="relative inline-flex items-center rounded-full px-2 py-2 h-8 w-8 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default ContacTable;
