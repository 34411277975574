import {SVGProps} from "react";
const DragIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={19}
    fill="none"
    {...props}
  >
    <path
      stroke="#7085A2"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.125}
      d="M13.155 6.733a1.845 1.845 0 1 0 0-3.69 1.845 1.845 0 0 0 0 3.69ZM4.845 6.733a1.845 1.845 0 1 0 0-3.69 1.845 1.845 0 0 0 0 3.69ZM13.155 15.96a1.845 1.845 0 1 0 0-3.69 1.845 1.845 0 0 0 0 3.69ZM4.845 15.96a1.845 1.845 0 1 0 0-3.69 1.845 1.845 0 0 0 0 3.69Z"
    />
  </svg>
);
export default DragIcon;
