import {SVGProps} from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M18.333 12.499v-5c0-4.167-1.666-5.834-5.833-5.834h-5c-4.167 0-5.833 1.667-5.833 5.834v5c0 4.166 1.666 5.833 5.833 5.833h5c4.167 0 5.833-1.667 5.833-5.833ZM1.667 10h16.666"
    />
  </svg>
);
export default SvgComponent;
