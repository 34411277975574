import {SVGProps} from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M6.429 2.411a.75.75 0 0 0-1.13-.986l-1.292 1.48a4.75 4.75 0 0 0-1.17 3.024L2.78 8.65a.75.75 0 1 0 1.5.03l.056-2.718a3.25 3.25 0 0 1 .8-2.069l1.293-1.48Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.237 7.7a4.214 4.214 0 0 1 4.206-3.95H11V3a1 1 0 0 1 2 0v.75h.557a4.214 4.214 0 0 1 4.206 3.95l.22 3.534a7.377 7.377 0 0 0 1.309 3.754 1.617 1.617 0 0 1-1.135 2.529l-3.407.408V19a2.75 2.75 0 1 1-5.5 0v-1.075l-3.407-.409a1.617 1.617 0 0 1-1.135-2.528 7.377 7.377 0 0 0 1.308-3.754L6.237 7.7Zm4.206-2.45a2.714 2.714 0 0 0-2.709 2.544l-.22 3.534a8.877 8.877 0 0 1-1.574 4.516.117.117 0 0 0 .082.183l3.737.449c1.489.178 2.993.178 4.482 0l3.737-.449a.116.116 0 0 0 .093-.068.118.118 0 0 0-.011-.115 8.877 8.877 0 0 1-1.573-4.516l-.221-3.534a2.714 2.714 0 0 0-2.71-2.544h-3.113Zm1.557 15c-.69 0-1.25-.56-1.25-1.25v-.75h2.5V19c0 .69-.56 1.25-1.25 1.25Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="M17.643 1.357a.75.75 0 0 0-.072 1.058l1.292 1.48a3.25 3.25 0 0 1 .8 2.07l.057 2.717a.75.75 0 0 0 1.5-.031l-.057-2.718a4.75 4.75 0 0 0-1.17-3.024l-1.292-1.48a.75.75 0 0 0-1.058-.072Z"
    />
  </svg>
);
export default SvgComponent;
