import {SVGProps} from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M4.5 18H6v1.5H3v-15H1.5V21H6v1.5H0V3h3V1.5h4.5c.688 0 1.348.105 1.98.316.633.211 1.223.528 1.77.95a5.824 5.824 0 0 1 1.758-.95A6.258 6.258 0 0 1 15 1.5h4.5V3h3v9.598l-1.5-.68V4.5h-1.5v6.738l-1.5-.691V3h-3c-.555 0-1.086.094-1.594.281A4.613 4.613 0 0 0 12 4.113v7.125l-1.5.68V4.113a4.587 4.587 0 0 0-1.395-.82A4.793 4.793 0 0 0 7.5 3h-3v15ZM21 20.988l-5.25 2.637-5.25-2.637c0-.281.004-.578.012-.89.008-.313.039-.63.093-.95.055-.32.133-.62.235-.902a3.05 3.05 0 0 1 .422-.785L9 16.43v6.82H7.5v-7.5L15.75 12 24 15.75l-3.762 1.71.094.13c.164.242.29.504.375.785a6.442 6.442 0 0 1 .281 1.746c.008.305.012.594.012.867Zm-5.25-7.347-4.629 2.109 4.629 2.11 4.629-2.11-4.629-2.11Zm0 8.308 3.75-1.887a5.364 5.364 0 0 0-.047-.55 2.082 2.082 0 0 0-.129-.48 27.826 27.826 0 0 0-.187-.458 3.176 3.176 0 0 0-.27-.492L15.75 19.5l-3.117-1.418c-.117.188-.211.355-.281.504-.07.148-.133.297-.188.445a1.86 1.86 0 0 0-.105.469c-.016.164-.032.355-.047.574l3.738 1.875Z"
    />
  </svg>
);
export default SvgComponent;
