import Button from "@components/button";
import {Disclosure, Transition} from "@headlessui/react";
import {ChevronUpIcon} from "@heroicons/react/16/solid";
import EditIcon from "@components/svgs/edit";
import Add from "./add/education";
import {useState} from "react";
import UpdateEducation from "./add/updateEducation";
import Delete from "@pages/dashboard/cms/blogs/delete";
import DeleteIcon from "@components/svgs/delete";

const educations = [
  {
    id: 3,
    title: "Columbia University (2014-2021) ",
    company: "BCS Business Analyst (In progress)",
    tasks: ["First class honours"],
  },

  {
    // use the template above but make the values unique
    id: 4,
    title: "not specified - Not given (2022)",
    company: "BCS Business Analyst (In progress)",
    tasks: ["Not given"],
  },
  {
    // use the template above but make the values unique
    id: 4,
    title: "not specified - Not given (2022)",
    company: "BCS Business Analyst (In progress)",
    tasks: ["Not given"],
  },
];



function Education() {
  const [show, setShow] = useState(false);
  const [update, setUpdate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  return (
    <Disclosure>
      {({open}) => (
        <>
          <Disclosure.Button className="flex w-full justify-between rounded-lg bg-white px-4 py-6 text-left text-sm font-medium text-black  focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75">
            <span>Education</span>
            <ChevronUpIcon
              className={`${
                open ? "rotate-180 transform" : ""
              } h-6 w-6 text-black-500`}
            />
          </Disclosure.Button>
          <Transition
            enter="transition ease-out duration-300 transform"
            enterFrom="opacity-0 translate-y-[-10%]"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-300 transform"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-[-10%]"
          >
            <Disclosure.Panel className="px-4 pb-2 bg-white pt-4 rounded-es-lg rounded-ee-lg  text-sm text-gray-500">
              <div className="border-l gap-y-8 flex flex-col">
                {educations.map((work) => (
                  <div key={work?.id} className="flex relative">
                    <svg
                      width="12"
                      height="12"
                      className="absolute top-0 -left-1.5"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="6" cy="6" r="6" fill="#E96D17" />
                    </svg>

                    <div className="px-4 ms-4">
                      <div className="flex">
                        <h1 className="text-sm text-black">{work.title}</h1>
                        <div className="flex gap-x-4 ms-4">
                          <EditIcon
                            onClick={() => setUpdate(true)}
                            className=""
                          />
                          <button onClick={() => setOpenDelete(true)}>
                            <DeleteIcon />
                          </button>
                        </div>
                      </div>
                      <h2 className="text-sm my-0.5 font-normal text-black">
                        {work.company}
                      </h2>
                      <ul className="list-inside">
                        {work.tasks.map((task) => (
                          <li key={task} className="text-sm text-black">
                            {task}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                ))}
              </div>
              <div className="flex items-end justify-end mt-8">
                <div>
                  <Button
                    title="Add education"
                    className="w-1/6 px-8"
                    onClick={() => setShow(true)}
                  />
                </div>
              </div>
            </Disclosure.Panel>
          </Transition>

          <Add open={show} setOpen={setShow} />
          <UpdateEducation open={update} setOpen={setUpdate} />

          <Delete
            open={openDelete}
            setOpen={setOpenDelete}
            selected={{
              title: "Columbia University (2014-2021) ",
              class: "education",
            }}
            buttonTitle="Delete"
          />
        </>
      )}
    </Disclosure>
  );
}

export default Education;
