import ButtonV2 from "@components/ButtonV2/ButtonV2";
import InfoCircle from "@components/svgs/infoCircleIcon";
import { XCircleIcon } from "@heroicons/react/24/outline";

interface ConfirmDeleteModalProps {
  isOpen: boolean;
  onCancel: () => void;
  onDelete: () => void;
  itemName: string;
  subject: string;
}

const ConfirmModal = ({
  isOpen,
  onCancel,
  onDelete,
  itemName = "Paula Agyepong",
  subject,
}: ConfirmDeleteModalProps) => {
  return (
    <div
      className={`fixed inset-0 z-50 flex items-center justify-center transition-opacity bg-gray-800 bg-opacity-30 ${
        isOpen ? "opacity-100" : "opacity-0 pointer-events-none"
      }`}
    >
      <div className="bg-white rounded-lg shadow-lg w-full max-w-md">
        <div className="flex flex-col items-center">
          <div className="flex align-middle w-full px-8 py-4 justify-between border-b-[0.5px] border-gray-200">
            <h2 className="text-2xl font-light">Delete ?</h2>
            <button onClick={onCancel}>
              <XCircleIcon className="w-6 h-6 text-[#FF6584]" />
            </button>
          </div>
          <div className="px-4 py-4 gap-2 mt-4 rounded-md mx-8 bg-custom_faded_red flex mb-6">
            <InfoCircle />
            <div>
              <h3 className="font-medium font-outfit">{subject}</h3>
              <p className="font-outfit font-light text-sm text-[#82868C]">
                You are deleting "{itemName}" from the system. This process is
                irreversible.
              </p>
            </div>
          </div>
          <div className="flex justify-between gap-3 pb-6 px-8 w-full">
            <ButtonV2
              variant="secondary"
              text="Cancel"
              className="w-full"
              onClick={onCancel}
            />
            <ButtonV2
              variant="warning"
              className="w-full"
              text="Delete"
              onClick={onDelete}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
