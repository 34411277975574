import {SVGProps} from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#969EAE"
      d="m9.25 22-.4-3.2a3.771 3.771 0 0 1-.612-.3 8.534 8.534 0 0 1-.563-.375L4.7 19.375l-2.75-4.75 2.575-1.95a2.384 2.384 0 0 1-.025-.337v-.675c0-.109.008-.221.025-.338L1.95 9.375l2.75-4.75 2.975 1.25c.183-.133.375-.258.575-.375.2-.117.4-.217.6-.3l.4-3.2h5.5l.4 3.2c.217.083.421.183.613.3.192.117.38.242.562.375l2.975-1.25 2.75 4.75-2.575 1.95a2.4 2.4 0 0 1 .025.338v.674c0 .109-.017.221-.05.338l2.575 1.95-2.75 4.75-2.95-1.25a6.842 6.842 0 0 1-.575.375c-.2.117-.4.217-.6.3l-.4 3.2h-5.5ZM11 20h1.975l.35-2.65a5.624 5.624 0 0 0 1.438-.587c.442-.258.846-.57 1.212-.938l2.475 1.025.975-1.7-2.15-1.625c.083-.233.142-.479.175-.737a6.15 6.15 0 0 0 0-1.575 3.576 3.576 0 0 0-.175-.738l2.15-1.625-.975-1.7-2.475 1.05a5.548 5.548 0 0 0-1.212-.962 5.633 5.633 0 0 0-1.438-.588L13 4h-1.975l-.35 2.65c-.517.133-.996.33-1.437.588a6.04 6.04 0 0 0-1.213.937L5.55 7.15l-.975 1.7 2.15 1.6c-.083.25-.142.5-.175.75s-.05.517-.05.8c0 .267.017.525.05.775s.092.5.175.75l-2.15 1.625.975 1.7 2.475-1.05c.367.383.77.704 1.213.963a5.58 5.58 0 0 0 1.437.587L11 20Zm1.05-4.5c.967 0 1.792-.342 2.475-1.025A3.372 3.372 0 0 0 15.55 12c0-.967-.342-1.792-1.025-2.475A3.373 3.373 0 0 0 12.05 8.5c-.983 0-1.813.342-2.488 1.025A3.389 3.389 0 0 0 8.55 12c0 .967.338 1.792 1.013 2.475.675.683 1.504 1.025 2.487 1.025Z"
    />
  </svg>
);
export default SvgComponent;
