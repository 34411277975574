import {Disclosure} from "@headlessui/react";
import {ChevronUpIcon} from "@heroicons/react/20/solid";
import {DesktopSidebarProps} from "./types";
import logo from "@assets/logo.png";
import {Link, useLocation} from "react-router-dom";
import {extractTextFromPathname} from "@components/utils";
function DesktopSidebar({navigation}: DesktopSidebarProps) {
  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
  }
  const {pathname} = useLocation();

  return (
    <>
      <div className="flex grow font-outfit flex-col gap-y-5 overflow-y-auto border-gray-200 bg-custom_dark_blue ">
        <div className="flex h-20 shrink-0 items-center border-b border-[#134270] px-5">
          <img className="h-5 w-auto" src={logo} alt="Your Company" />
        </div>
        <nav className="flex flex-1 flex-col">
          <ul role="list" className="flex flex-1 flex-col gap-y-7">
            <li>
              <ul role="list" className="-mx-2 space-y-1">
                {navigation.map((item) =>
                  !item?.children ? (
                    <li key={item.name}>
                      <Link
                        to={item?.href || ""}
                        className={classNames(
                          extractTextFromPathname(pathname) ===
                            item.href?.slice(1)?.toLowerCase()
                            ? "bg-custom_blue"
                            : "",
                          "flex items-center w-full text-left rounded-md p-2 px-6 gap-x-3 text-base leading-6  text-white"
                        )}
                      >
                        <item.icon
                          className={classNames(
                            pathname === item?.href
                              ? "text-white"
                              : "text-gray-400 group-hover:text-indigo-600",
                            " shrink-0 transition-all duration-500 ease-in-out"
                          )}
                          aria-hidden="true"
                        />
                        {item.name}
                      </Link>
                    </li>
                  ) : (
                    <li key={item.name}>
                      <Disclosure defaultOpen as="div">
                        {({open}) => (
                          <>
                            <Disclosure.Button
                              className={classNames(
                                extractTextFromPathname(pathname) ===
                                  item.name?.toLowerCase()
                                  ? "bg-custom_blue"
                                  : "",
                                "flex items-center w-full text-left rounded-md p-2 px-6 gap-x-3 text-base leading-6  text-white"
                              )}
                            >
                              <item.icon
                                className={classNames(
                                  extractTextFromPathname(pathname) ===
                                    item.name?.toLowerCase()
                                    ? "text-white "
                                    : "text-gray-400 group-hover:text-indigo-600",
                                  " shrink-0 transition-all duration-500 ease-in-out"
                                )}
                                aria-hidden="true"
                              />
                              {item.name}
                              <ChevronUpIcon
                                className={classNames(
                                  open ? "rotate-180 text-white" : "text-white",
                                  "ml-auto h-5 w-5 shrink-0"
                                )}
                                aria-hidden="true"
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel as="ul" className="mt-1 px-2">
                              {item?.children?.map((subItem) => (
                                <li key={subItem.name}>
                                  {/* 44px */}
                                  <Link
                                    to={subItem.href}
                                    className={classNames(
                                      pathname === subItem?.href
                                        ? " text-custom_blue scale-95 text-base"
                                        : "hover:bg-custom_light_gray  text-gray-400 text-sm",
                                      "rounded-md py-2 pr-2 pl-9 transition-all duration-500 ease-in-out flex gap-x-3 items-center leading-6 "
                                    )}
                                  >
                                    {subItem.name}
                                  </Link>
                                </li>
                              ))}
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </li>
                  )
                )}
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
}

export default DesktopSidebar;
