import Table from "@components/DataTable";
import { TableColumn } from "@components/DataTable/types";


interface Reports {
  id: string;
  task: string;
  company: string;
  user: string;
  info: string;
  startDate: string;
  endDate: string;
  days: number;
}

const sampleData: Reports[] = [
  {
    id: "0042",
    task: "Interview 30 candidates for the video editor role",
    company: "Solutions for Pros",
    user: "Liam Summers",
    info: "United Kingdom",
    startDate: "21/12/2023",
    endDate: "26/12/2023",
    days: 5,
  },
  {
    id: "0043",
    task: "Interview 30 candidates for the video editor role",
    company: "Solutions for Pros",
    user: "Liam Summers",
    info: "Afghanistan",
    startDate: "21/12/2023",
    endDate: "26/12/2023",
    days: 2,
  },
];

const columns: TableColumn<Reports>[] = [
  {
    key: "id",
    label: "ID",
    sortable: true,
  },
  {
    key: "task",
    label: "Task",
    sortable: true,
  },
  {
    key: "company",
    label: "Company",
    sortable: true,
  },
  {
    key: "user",
    label: "User",
    sortable: true,
  },
  {
    key: "info",
    label: "Info",
    sortable: true,
  },
  {
    key: "startDate",
    label: "Start Date",
    sortable: true,
  },
  {
    key: "endDate",
    label: "End Date",
    sortable: true,
  },
  {
    key: "days",
    label: "Days",
    sortable: true,
  },
];

const TimeReportsList = () => {
  return (
    <>
      <Table<Reports> data={sampleData} columns={columns} />
    </>
  );
};

export default TimeReportsList;
