import ButtonV2 from "@components/ButtonV2/ButtonV2";
import StatusDropdown from "@components/dropdowns/status";
import Input from "@components/input";
import { useState } from "react";
import { PhotoIcon } from "@heroicons/react/16/solid";

const AddNewTeam = () => {
  const [selectedStatus, setSelectedStatus] = useState("");

  return (
    <>
      {" "}
      <div className="gap-y-4 flex flex-col pb-28">
        <div className="w-full flex-1 flex flex-col py-4 px-6 rounded-lg">
          <div className="lg:col-span-2 col-span-10">
            <label className="text-xs">Team name:</label>

            <div className="mt-1">
              <Input />
            </div>
          </div>
          <div className="lg:col-span-2 col-span-10">
            <label className="text-xs">Team members:</label>
            <StatusDropdown
              selectedStatus={selectedStatus}
              setSelectedStatus={setSelectedStatus}
            />
          </div>
          <div className=" mt-1">
            <label className="text-xs">Upload candidate image:</label>
            <div className="flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-8">
              <div className="text-center">
                <PhotoIcon
                  className="mx-auto h-12 w-12 text-gray-300"
                  aria-hidden="true"
                />
                <div className="mt-4 flex text-sm leading-6 text-gray-600">
                  <label
                    htmlFor="file-upload"
                    className="relative cursor-pointer rounded-md bg-white font-semibold text-custom_blue focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                  >
                    <span>Upload a file</span>
                    <input
                      id="file-upload"
                      name="file-upload"
                      type="file"
                      className="sr-only"
                    />
                  </label>
                  <p className="pl-1">or drag and drop</p>
                </div>
                <p className="text-xs leading-5 text-gray-600">
                  PNG, JPG, GIF up to 10MB
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:col-span-4 mt-4 col-span-10 flex justify-end items-end">
          <ButtonV2 text="Add team" onClick={() => {}} />
        </div>
      </div>
    </>
  );
};

export default AddNewTeam;
