import Button from "@components/button";
import {Disclosure, Transition} from "@headlessui/react";
import {ChevronUpIcon} from "@heroicons/react/16/solid";
import EditIcon from "@components/svgs/edit";
import {useState} from "react";
import Add from "./add/work";
import UpdateWork from "./add/updatework";
import Delete from "@pages/dashboard/cms/blogs/delete";
import DeleteIcon from "@components/svgs/delete";

const works = [
  {
    id: 3,
    title:
      "Business Anayst (Business Anayst | Robson Moore | Hybrid Project In Progress) ",
    company: "Robson Moore",
    tasks: [
      "Coordinate business stakeholders and project delivery",
      "Created detailed project plan for implementation of the new CRM tool",
      "Mapped out existing sales and marketing processes (AS IS)",
      "Detailed target operating manual (TOM) for future sales process",
      "Created RAID and RACI document to ensure timely delivery",
      "Introduced change management procedures to ensure CRM was embedded into work culture",
    ],
  },

  {
    // use the template above but make the values unique
    id: 4,
    title:
      "Business Anayst (Business Anayst | Robson Moore | Hybrid Project In Progress) ",
    company: "Robson Moore",
    tasks: [
      "Coordinate business stakeholders and project delivery",
      "Created detailed project plan for implementation of the new CRM tool",
      "Mapped out existing sales and marketing processes (AS IS)",
      "Detailed target operating manual (TOM) for future sales process",
      "Created RAID and RACI document to ensure timely delivery",
      "Introduced change management procedures to ensure CRM was embedded into work culture",
    ],
  },
];

function Work() {
  const [show, setShow] = useState(false);
  const [update, setUpdate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  return (
    <>
      <Disclosure>
        {({open}) => (
          <>
            <Disclosure.Button className="flex w-full justify-between rounded-lg bg-white px-4 py-6 text-left text-sm font-medium text-black  focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75">
              <span>Work</span>
              <ChevronUpIcon
                className={`${
                  open ? "rotate-180 transform" : ""
                } h-6 w-6 text-black-500`}
              />
            </Disclosure.Button>
            <Transition
              enter="transition ease-out duration-300 transform"
              enterFrom="opacity-0 translate-y-[-10%]"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-300 transform"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-[-10%]"
            >
              <Disclosure.Panel className="px-4 pb-2 bg-white pt-4 rounded-es-lg rounded-ee-lg  text-sm text-gray-500">
                <div className="border-l gap-y-8 flex flex-col">
                  {works.map((work) => (
                    <div key={work?.id} className="flex relative">
                      <svg
                        width="12"
                        height="12"
                        className="absolute top-0 -left-1.5"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="6" cy="6" r="6" fill="#E96D17" />
                      </svg>

                      <div className="px-4 ms-4">
                        <div className="flex">
                          <h1 className="text-sm text-black">{work.title}</h1>
                          <div className="flex gap-x-4 ms-4">
                            <EditIcon
                              onClick={() => setUpdate(true)}
                              className=""
                            />
                            <button onClick={() => setOpenDelete(true)}>
                              <DeleteIcon />
                            </button>
                          </div>
                        </div>
                        <h2 className="text-lg my-1 font-medium text-black">
                          {work.company}
                        </h2>
                        <ul className="list-inside">
                          {work.tasks.map((task) => (
                            <li key={task} className="text-sm text-black">
                              {task}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="flex items-end justify-end mt-8">
                  <div>
                    <Button
                      title="Add work"
                      className="w-1/6 px-8"
                      onClick={() => setShow(true)}
                    />
                  </div>
                </div>
              </Disclosure.Panel>
            </Transition>

            <Add open={show} setOpen={setShow} />
            <UpdateWork open={update} setOpen={setUpdate} />

            <Delete
              open={openDelete}
              setOpen={setOpenDelete}
              selected={{
                title: "Robson Moore",
                class: "work",
              }}
              buttonTitle="Delete"
            />
          </>
        )}
      </Disclosure>
    </>
  );
}

export default Work;
