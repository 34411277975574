import { useState } from "react";
import Dropdown from "@components/dropdowns/dropdown";
import Holidayicon from "@components/svgs/holidayicon";
import CustomDatePicker from "@components/datepicker";
import { PhotoIcon } from "@heroicons/react/24/outline";

function AddHoliday() {
  const [user, setUser] = useState("Mathias Boampong");
  const [type, setType] = useState("Holiday");
  const [startDate, setStartDate] = useState("");
  const [handOverTo, setHandOverTo] = useState("Sena Can");

  return (
    <>
      <div className="gap-y-4 pl-4 flex flex-col pb-12">
        <div className="w-full px-6 items-center rounded-lg justify-between flex bg-custom_faded_blue">
          <h1 className="text-2xl font-outfit font-medium">Annual leave</h1>

          <div className="">
            <Holidayicon />
          </div>
        </div>
        <div className="w-full flex-1 flex flex-col py-4 px-6 rounded-lg  bg-custom_faded_blue">
          <div className=" my-6 grid gap-x-4 gap-y-5 grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 lg:px-2 xl:px-0">
            <div>
              <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                User:{" "}
              </label>
              <Dropdown
                list={[
                  {
                    id: "me",
                    name: "Mathias Boampong",
                    value: "Mathias Boampong",
                  },
                ]}
                selected={user}
                setSelected={setUser}
              />
            </div>
            <div>
              <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                Type:{" "}
              </label>
              <Dropdown
                list={[
                  {
                    id: "holiday",
                    name: "Holiday",
                    value: "Holiday",
                  },
                ]}
                selected={type}
                setSelected={setType}
              />
            </div>
            <div>
              <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                Start date:{" "}
              </label>
              <CustomDatePicker
                selectedDate={startDate}
                setSelectedDate={setStartDate}
              />
            </div>
            <div>
              <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                End date:{" "}
              </label>
              <CustomDatePicker
                selectedDate={startDate}
                setSelectedDate={setStartDate}
              />
            </div>

            <div className="col-span-2">
              <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                Hand over to:{" "}
              </label>
              <Dropdown
                list={[
                  {
                    id: "senacan",
                    name: "Sena Can",
                    value: "Sena Can",
                  },
                ]}
                selected={handOverTo}
                setSelected={setHandOverTo}
              />
            </div>

            <div className="col-span-2">
              <div className="mt-1 relative">
                <textarea
                  // value={notes}
                  rows={6}
                  // onChange={(e: any) => {
                  //   setNotes(e.target.value);
                  // }}
                  placeholder="Write Notes here...."
                  className="mt-1 w-full rounded-xl bg-transparent border py-4 placeholder:text-[#9CA1AD] border-[#657A9345]"
                />
              </div>
            </div>
            <div className="col-span-2">
              <div className="flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-8">
                <div className="text-center">
                  <PhotoIcon
                    className="mx-auto h-12 w-12 text-gray-300"
                    aria-hidden="true"
                  />
                  <div className="mt-4 flex text-sm leading-6 text-gray-600">
                    <label
                      htmlFor="file-upload"
                      className="relative cursor-pointer rounded-md bg-white font-semibold text-custom_blue focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                    >
                      <span>Upload a file</span>
                      <input
                        id="file-upload"
                        name="file-upload"
                        type="file"
                        className="sr-only"
                      />
                    </label>
                    <p className="pl-1">or drag and drop</p>
                  </div>
                  <p className="text-xs leading-5 text-gray-600">
                    PNG, JPG, GIF up to 10MB
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="lg:col-span-4 mt-4 col-span-10 flex justify-end items-end">
            <button className="bg-[#1F3C49] rounded-lg font-light py-3 px-4 flex">
              <span className="text-white">Add Holiday !</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddHoliday;
