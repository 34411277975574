import {useState} from "react";
import Input from "@components/input";
import Dropdown from "@components/dropdowns/dropdown";
import {EyeIcon, EyeSlashIcon} from "@heroicons/react/24/solid";
import CustomDatePicker from "@components/datepicker";
import ImagePicker from "@components/imagepicker";

function AddNewEmployee() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [selected, setSelected] = useState("");
  const [position, setPosition] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [dateJoined, setDateJoined] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [holidays, setHolidays] = useState("");

  const list = [
    {id: 1, name: "Admin", value: "admin"},
    {id: 2, name: "Client", value: "client"},
    {id: 3, name: "Employee", value: "employee"},
    {id: 4, name: "Manager", value: "manager"},
  ];
  return (
    <div className="px-4 pb-12">
      <div className=" my-6 flex flex-col gap-y-5">
        <div className="">
          <label className="text-xs placeholder:text-custom_gray text-custom_secondary">
            First name:{" "}
          </label>
          <Input
            placeholder={"eg. John"}
            className={"mt-1"}
            value={firstName}
            onChange={(e: any) => setFirstName(e.target.value)}
          />
        </div>
        <div>
          <label className="text-xs placeholder:text-custom_gray text-custom_secondary">
            Last name:{" "}
          </label>
          <Input
            placeholder={"eg. Doe"}
            className={"mt-1 placeholder:text-custom_gray"}
            value={lastName}
            onChange={(e: any) => setLastName(e.target.value)}
          />
        </div>
        <div>
          <label className="text-xs  text-custom_secondary">Position: </label>
          <Input
            type="text"
            placeholder={"eg. software engineer"}
            className={"mt-1 placeholder:text-custom_gray"}
            value={position}
            onChange={(e: any) => setPosition(e.target.value)}
          />
        </div>
        <div>
          <label className="text-xs placeholder:text-custom_gray text-custom_secondary">
            Email:{" "}
          </label>
          <Input
            type="email"
            placeholder={"eg. software engineer"}
            className={"mt-1 placeholder:text-custom_gray"}
            value={email}
            onChange={(e: any) => setEmail(e.target.value)}
          />
        </div>
        <div>
          <label className="text-xs placeholder:text-custom_gray text-custom_secondary">
            Date Joined:{" "}
          </label>
          <CustomDatePicker
            selectedDate={dateJoined}
            setSelectedDate={(e: any) => setDateJoined(e.target.value)}
            type="date"
          />
        </div>
        <div>
          <label className="text-xs  text-custom_secondary">Holidays: </label>
          <Input
            type="text"
            placeholder={"eg. 20 days"}
            min={0}
            className={"mt-1 placeholder:text-custom_gray"}
            value={holidays}
            onChange={(e: any) => setHolidays(e.target.value)}
          />
        </div>
        <div>
          <label className="text-xs placeholder:text-custom_gray text-custom_black">
            Type:{" "}
          </label>
          <Dropdown selected={selected} setSelected={setSelected} list={list} />
        </div>

        <div>
          <label className="text-xs placeholder:text-custom_gray text-custom_black">
            Password:{" "}
          </label>
          <div className="mt-1 relative">
            <Input
              id="password"
              name="password"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e: any) => setPassword(e.target.value)}
              autoComplete="current-password"
              required
              placeholder="eg. *********"
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="absolute outline-none right-0 top-0 mt-3 mr-3"
            >
              {showPassword ? (
                <EyeSlashIcon className="h-6 w-6 text-custom_gray" />
              ) : (
                <EyeIcon className="h-6 w-6 text-custom_gray" />
              )}
            </button>
          </div>
        </div>
        <div>
          <label className="text-xs placeholder:text-custom_gray text-custom_black">
            Confirm password:{" "}
          </label>
          <div className="mt-1 relative">
            <Input
              id="password"
              name="password"
              type={showPassword ? "text" : "password"}
              value={confirmPassword}
              onChange={(e: any) => setConfirmPassword(e.target.value)}
              autoComplete="current-password"
              required
              placeholder="eg. *********"
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="absolute outline-none right-0 top-0 mt-3 mr-3"
            >
              {showPassword ? (
                <EyeSlashIcon className="h-6 w-6 text-custom_gray" />
              ) : (
                <EyeIcon className="h-6 w-6 text-custom_gray" />
              )}
            </button>
          </div>
        </div>

        <div className="col-span-2">
          <ImagePicker
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
            label="Upload employee image:"
          />
        </div>
      </div>
      <div className="lg:col-span-4 pb-12 mt-4 col-span-10 flex justify-end items-end">
        <button className="bg-[#1F3C49] rounded-lg font-light py-3 px-4 flex">
          <span className="text-white">Add employee !</span>
        </button>
      </div>
    </div>
  );
}

export default AddNewEmployee;
