import data from "./dataview/data.json";
import CopyIcon from "@components/svgs/copy";
import ShareIcon from "@components/svgs/share";
import PrintIcon from "@components/svgs/print";
import EmptyInterface from "@components/empty";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";

import TeamList from "./dataview";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SearchComponent from "@components/baseSearch";
import Drawer from "@components/drawer/Drawer";
import AddTeamMember from "@features/teams/AddTeamMember";

function Page() {
  const navigate = useNavigate();

  const params = useParams();
  const [searchQuery, setSearchQuery] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleSearch = (e: any) => {
    setSearchQuery(e.target.value);
  };

  return (
    <>
      <div className="mx-4">
        <div className="bg-[#DDEFFF33]  rounded-lg font-outfit">
          <div className="flex justify-between items-center">
            <div className="w-1/2 ps-6 py-11">
              <h1 className="uppercase text-3xl text-transparent bg-gradient-to-r font-medium from-orange-500 via-[#88400D95] to-[#E5C4AC] bg-clip-text ">
                {params?.team?.replace(/-/g, " ")}
              </h1>
              <span className="text-[#7C8DB5] font-light">Members: 12</span>
            </div>
          </div>
        </div>
        <div className="flex mt-8 justify-between">
          <button
            onClick={() => navigate(-1)}
            className="text-custom_secondary hover:underline flex gap-x-3 items-center font-outfit font-light"
          >
            <ChevronLeftIcon className="h-6 w-6 text-custom_secondary" />
            Back
          </button>
        </div>
        <div className="bg-[#DDEFFF33] p-3 mt-4">
          <div className={` ${data && "bg-white"}`}>
            <div className="px-5 py-4 flex justify-between items-center">
              <SearchComponent
                placeholder="Find team member..."
                value={searchQuery}
                onChange={handleSearch}
                className="min-w-80"
              />
              <div className="flex gap-x-3 items-center">
                <CopyIcon />
                <ShareIcon />
                <PrintIcon />
                <button
                  onClick={toggleDrawer}
                  className="bg-custom_deep_blue text-sm text-white px-4 py-2 rounded-lg flex items-center gap-3"
                >
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.5 8.5H8.5V14.5H6.5V8.5H0.5V6.5H6.5V0.5H8.5V6.5H14.5V8.5Z"
                      fill="white"
                    />
                  </svg>
                  <span>Add a member</span>
                </button>
              </div>
            </div>
            <div className="">
              {data.length === 0 ? (
                <div className="mt-4 border border-custom_lighter_gray rounded-xl">
                  <EmptyInterface
                    description="No tasks assigned yet..."
                    buttonText="Add a new task"
                    showButton={false}
                    buttonAction={() => null}
                  />
                </div>
              ) : (
                <div>
                  <div className="mt-4">
                    <TeamList />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Drawer
        drawerHeader="Add a team member"
        isOpen={isDrawerOpen}
        onClose={toggleDrawer}
      >
        <AddTeamMember />
      </Drawer>
    </>
  );
}

export default Page;
