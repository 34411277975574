import Table from "@components/DataTable";
import { TableColumn } from "@components/DataTable/types";

interface TimeTrackingEntry {
  id: string;
  name: string;
  totalDuration: string;
}

const sampleData: TimeTrackingEntry[] = [
  {
    id: "502",
    name: "Paula Agyepong",
    totalDuration: "40 hours 30 minutes 20 seconds",
  },
  {
    id: "502",
    name: "Paula Agyepong",
    totalDuration: "40 hours 30 minutes 20 seconds",
  },
  {
    id: "502",
    name: "Fidelia Quaye",
    totalDuration: "40 hours 30 minutes 20 seconds",
  },
  {
    id: "502",
    name: "Fidelia Quaye",
    totalDuration: "40 hours 30 minutes 20 seconds",
  },
  {
    id: "502",
    name: "Fidelia Quaye",
    totalDuration: "40 hours 30 minutes 20 seconds",
  },
  {
    id: "502",
    name: "Fidelia Quaye",
    totalDuration: "40 hours 30 minutes 20 seconds",
  },
];

const columns: TableColumn<TimeTrackingEntry>[] = [
  {
    key: "id",
    label: "ID",
    sortable: true,
  },
  {
    key: "name",
    label: "Name",
    sortable: true,
  },
  {
    key: "totalDuration",
    label: "Total Duration",
    sortable: true,
  },
];
const UserTimeReportsLists = () => {
  return (
    <>
      <Table<TimeTrackingEntry> data={sampleData} columns={columns} />
    </>
  );
};

export default UserTimeReportsLists;
