import EmployeeStats from "@components/stats/employeestats";
import SearchIcon from "@components/svgs/search";
import Input from "@components/input";
import EmptyInterface from "@components/empty";
import data from "./dataview/data.json";
import CopyIcon from "@components/svgs/copy";
import ShareIcon from "@components/svgs/share";
import PrintIcon from "@components/svgs/print";
import EmployeeList from "./dataview";
import {useState} from "react";
import Drawer from "@components/drawer/Drawer";
import AddNewEmployee from "./addemployee/page";
import UpdateEmployee from "./updateemployee/[id]/page";
import EmployeeSummary from "@features/employees/EmployeeSummary";
// import {useQuery} from "@tanstack/react-query";
// import {getEmployees} from "@services/employees";

function Users() {
  const [viewDetails, setViewDetails] = useState(false);
  const handleAddEmployee = () => {
    toggleDrawer();
  };
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [edit, setEdit] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleEdit = (e: any) => {
    e?.stopPropagation();
    setEdit(!edit);
  };

  const toggleViewDetails = (e: any) => {
    e?.stopPropagation();
    setViewDetails(!viewDetails);
  };

  return (
    <>
      <div className="bg-[#DDEFFF33] mx-4 rounded-lg font-inter">
        <div className="flex justify-between items-center">
          <div className="w-1/2">
            <EmployeeStats />
          </div>
        </div>
      </div>

      <div className="bg-[#DDEFFF33] mt-4 py-2 px-2 mx-4 rounded-xl font-inter">
        {/* <div className="flex items-center justify-between">
          <div className="flex items-center gap-x-3 w-1/2">
            <div className="w-40">
              <Dropdown
                selected={selected}
                setSelected={setSelected}
                list={list}
              />
            </div>
          </div>
        </div> */}

        <div className={` ${data && "bg-white rounded-2xl"}`}>
          <div className="px-5 py-4 flex justify-between items-center">
            <h1 className="text-2xl font-outfit font-semibold">Employees</h1>
            <div className="flex gap-x-3 items-center">
              <div className="relative">
                <SearchIcon className="absolute top-3 left-3" />

                <Input placeholder="Find user..." className={`ps-10 text-sm`} />
              </div>
              <CopyIcon />
              <ShareIcon />
              <PrintIcon />
              <div className="flex">
                <button
                  onClick={handleAddEmployee}
                  className=" flex items-center gap-3 bg-custom_deep_blue rounded-xl text-sm px-4  font-outfit  py-3 text-white"
                >
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.5 8.5H8.5V14.5H6.5V8.5H0.5V6.5H6.5V0.5H8.5V6.5H14.5V8.5Z"
                      fill="white"
                    />
                  </svg>
                  <span>Add new employee</span>
                </button>
              </div>
            </div>
          </div>
          <div className="">
            {data.length === 0 ? (
              <div className="mt-4 border border-custom_lighter_gray rounded-2xl">
                <EmptyInterface
                  description="No tasks assigned yet..."
                  buttonText="Add a new task"
                  buttonAction={handleAddEmployee}
                />
              </div>
            ) : (
              <div>
                <div className="">
                  <EmployeeList
                    handleEdit={handleEdit}
                    handleView={toggleViewDetails}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <Drawer
        drawerHeader="Add Employee"
        isOpen={isDrawerOpen}
        onClose={toggleDrawer}
      >
        <AddNewEmployee />
      </Drawer>
      <Drawer
        drawerHeader="Edit employee details"
        isOpen={edit}
        onClose={handleEdit}
      >
        <UpdateEmployee />
      </Drawer>

      <Drawer drawerHeader="" isOpen={viewDetails} onClose={toggleViewDetails}>
        <EmployeeSummary />
      </Drawer>
    </>
  );
}

export default Users;
