import Table from "@components/DataTable";
import { TableColumn } from "@components/DataTable/types";

interface TimeTracking {
  user: string;
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

const sampleData: TimeTracking[] = [
  {
    user: "Liam Summers",
    days: 5,
    hours: 10,
    minutes: 30,
    seconds: 33,
  },
  {
    user: "Liam Summers",
    days: 5,
    hours: 10,
    minutes: 30,
    seconds: 33,
  },
];

const columns: TableColumn<TimeTracking>[] = [
  {
    key: "user",
    label: "User",
    sortable: true,
  },
  {
    key: "days",
    label: "Days",
    sortable: true,
  },
  {
    key: "hours",
    label: "Hours",
    sortable: true,
  },
  {
    key: "minutes",
    label: "Minutes",
    sortable: true,
  },
  {
    key: "seconds",
    label: "Seconds",
    sortable: true,
  },
];

const TotalTimes = () => {
  return (
    <>
      <Table<TimeTracking> data={sampleData} columns={columns} />
    </>
  );
};

export default TotalTimes;
