import CandidatesIcon from "@components/svgs/companiesbig";
import FacebookIcon from "@components/svgs/facebook";
import LinkedIn from "@components/svgs/linkedin";
import Instagram from "@components/svgs/instagram";
import EditIcon from "@components/svgs/edit";
import TabsV2 from "@components/tabs/TabV2";
import LinkButton from "@components/ButtonV2/LinkButton";
import Badge from "@components/badges/Badge";
import Notes from "../../companies/view/[id]/components/notes";
import TasksLists from "@features/candidates/TasksLists";
import Activities from "../../companies/view/[id]/components/acticities/page";
import LeadsProfileIcon from "@components/svgs/leadsProfileIcon";
import JobItems from "../../../workspace/jobs/components/jobItems";

const tabs = [
  {
    id: "jobItems",
    label: "Job Items",
    component: <JobItems />,
  },
  {
    id: "notes",
    label: "Notes",
    component: <Notes />,
  },
  {
    id: "tasks",
    label: "Tasks",
    component: <TasksLists />,
  },
  {
    id: "activities",
    label: "Activities",
    component: <Activities />,
  },
];

function ViewLead() {
  return (
    <div>
      <div className="bg-[#DDEFFF33]  rounded-2xl font-outfit">
        <div className="flex justify-between items-center">
          <div className="w-2/3 ps-4">
            <h2 className="font-normal text-2xl">Catenae Innovations</h2>
          </div>
          <CandidatesIcon />
        </div>
      </div>

      <div className="py-4 bg-custom_faded_blue rounded-2xl my-2 mb-5 flex flex-col lg: px-4">
        <div className="flex w-full items-start mt-8 lg:mt-0">
          <div className="flex flex-col items-center justify-center">
            <LeadsProfileIcon />

            <div className="flex gap-x-3 items-center mt-2">
              <LinkedIn />
              <Instagram />
              <FacebookIcon />
            </div>
          </div>
          {/* <div> */}
          <div className="lg:ms-12 flex justify-between w-full items-start">
            <div className="flex">
              <div>
                <div className="flex items-center gap-2">
                  <h2 className="text-xl">Catenae Innovations</h2>
                  <Badge type="success" label="Team lead" />
                </div>
                <LinkButton label="TECH/SOFTWARE DEVELOPMENT" />
                <p className="font-light text-[#969EAE] text-sm my-1">
                  New York, USA
                </p>
                <p className="font-light text-[#82868C] text-sm my-1">
                  About: Catenae Innovations is a software development company
                  that specializes in developing software for the health sector.
                </p>
              </div>
            </div>
            <button
              onClick={() => {}}
              className="bg-transparent focus:outline-none"
            >
              <EditIcon />
            </button>
          </div>
          {/* </div> */}
        </div>
        <div className="grid text-sm lg:grid-cols-6 grid-cols-1 mt-6 gap-y-4 lg:gap-x-6 lg:gap-y-0">
          <div className="flex flex-col gapy-1 lg:border-r">
            <p className="text-[#7C8DB5]">Email address</p>
            <span className="text-[#82868C] text-sm">paula@gmail.com</span>
          </div>

          <div className="flex flex-col gapy-1 lg:border-r">
            <p className="text-[#7C8DB5]">Phone number</p>
            <span className="text-[#82868C] text-sm">+233 024 5813 456</span>
          </div>

          <div className="flex flex-col gapy-1 lg:border-r">
            <span className="text-[#7C8DB5]">Last contact date</span>
            <span className="text-[#82868C] text-sm">12/02/2024</span>
          </div>
          <div className="flex flex-col gapy-1 lg:border-r">
            <span className="text-[#7C8DB5]">Target close date</span>
            <span className="text-[#82868C] text-sm">12/02/2024</span>
          </div>
          <div className=" flex flex-col gapy-1">
            <p className="text-[#7C8DB5]">Lead Sales Person</p>
            <span className="text-[#82868C] text-sm">Gift Avi</span>
          </div>
          <div className=" flex flex-col gapy-1">
            <p className="text-[#7C8DB5]">Contact Person</p>
            <span className="text-[#82868C] text-sm">Nuella Andoh</span>
          </div>
        </div>
      </div>
      <div className="mx-3">
        <TabsV2 tabs={tabs} />
      </div>
    </div>
  );
}

export default ViewLead;
