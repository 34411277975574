import ButtonV2 from "@components/ButtonV2/ButtonV2";
import CustomDatePicker from "@components/datepicker";
import Dropdown from "@components/dropdowns/dropdown";
import Input from "@components/input";
import moment from "moment";
import {useState} from "react";

function UpdateAdditionalInformation() {
  const [email, setEmail] = useState("mathiassample@gmail.com");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState(moment(new Date())?.format("YYYY-MM-DD"));
  const [age, setAge] = useState("27 years");
  const [emergencyContact, setEmergencyContact] = useState("+23349873787");
  return (
    <div>
      <>
        <div className="gap-y-4 flex flex-col pb-10 overflow-auto">
          <div className="w-full flex-1 flex flex-col py-4 px-6 rounded-lg">
            <div className=" my-6 grid gap-x-4 gap-y-5 grid-cols-1">
              <div>
                <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                  Personal Email:{" "}
                </label>
                <Input
                  placeholder={"eg.John"}
                  className={"mt-1 h-10"}
                  value={email}
                  onChange={(e: any) => setEmail(e.target.value)}
                />
              </div>

              <div>
                <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                  Date of Birth:{" "}
                </label>
                <CustomDatePicker
                  selectedDate={dob}
                  setSelectedDate={setDob}
                  type="date"
                />
              </div>
              <div>
                <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                  Age:{" "}
                </label>
                <Input
                  placeholder={"eg. 27 years"}
                  className={"mt-1 h-10"}
                  value={age}
                  onChange={(e: any) => setAge(e.target.value)}
                />
              </div>
              <div>
                <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                  Gender:{" "}
                </label>
                <Dropdown
                  list={[
                    {
                      id: 1,
                      name: "Male",
                      value: "male",
                    },
                    {
                      id: 2,
                      name: "Female",
                      value: "female",
                    },
                  ]}
                  selected={gender}
                  setSelected={setGender}
                />
              </div>

              <div>
                <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                  Emergenct Contact:{" "}
                </label>
                <Input
                  placeholder={"eg. +23349873787"}
                  className={"mt-1 h-10"}
                  value={emergencyContact}
                  onChange={(e: any) => setEmergencyContact(e.target.value)}
                />
              </div>
            </div>
            <div className="w-full pb-8 mt-4 col-span-10 flex justify-end items-end">
              <ButtonV2 text="Update personal information" onClick={() => {}} />
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default UpdateAdditionalInformation;
