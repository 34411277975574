import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from "recharts";

function LMSPerWeek() {
  const data = [
    {month: "Nov", value: 6},
    {month: "Oct", value: 8},
    {month: "Dec", value: 5},
    {month: "Jan", value: 10},
    {month: "Feb", value: 12},
    {month: "March", value: 7},
    {month: "April", value: 9},
    {month: "May", value: 10},
    {month: "June", value: 4},
  ];

  const CustomTooltip = ({active, payload}: any) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-white border border-gray-300 shadow-md p-2 rounded text-center">
          <p className="text-sm font-semibold">{payload[0].value}</p>
        </div>
      );
    }
    return null;
  };
  const highlightBar = "Feb";

  return (
    <div className="col-span-5 flex flex-col bg-white rounded-xl px-3 pt-3">
      <div className="flex justify-between gap-y-2">
        <h4 className="font-outfit font-semibold text-black">
          Number of people who have taken courses so far
        </h4>
      </div>

      <div className="w-full mt-6">
        <ResponsiveContainer width="100%" height={290}>
          <BarChart
            data={data}
            margin={{top: 20, right: 20, bottom: 30, left: 0}}
          >
            <XAxis
              dataKey="month"
              tick={{
                fontSize: 12,
                fontWeight: 400,
                fill: "#6B7280",
              }}
              tickMargin={10}
              textAnchor="end"
              axisLine={false}
              tickLine={false}
            />
            <YAxis hide />
            <Tooltip
              content={<CustomTooltip />}
              cursor={{fill: "rgba(0,0,0,0.1)"}}
            />
            <Bar dataKey="value" radius={[10, 10, 10, 10]}>
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  width={24}
                  style={{
                    cursor: "pointer",
                    width: "24px",
                    maxWidth: "24px",
                  }}
                  fill={entry.month === highlightBar ? "#FFA26B" : "#3A4DE9"}
                  opacity={entry.month === "June" ? 0.4 : 1}
                />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

export default LMSPerWeek;
