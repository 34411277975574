"use client";
import React, {Fragment} from "react";
import tasks from "./data.json";
import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/16/solid";
// import { useNavigate } from "react-router-dom";



function TimeReports() {
  const [start, setStart] = React.useState(0);
  const [end, setEnd] = React.useState(10);

  const handleNext = () => {
    setStart(start + 10);
    setEnd(end + 10);
  };

  const handlePrev = () => {
    setStart(start - 10);
    setEnd(end - 10);
  };

  // const navigate = useNavigate();

  return (
    <div className="px-5 py-3">
      <div className="-mx-4 mt-8 sm:-mx-0">
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
              >
                User
              </th>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
              >
                Days
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                Hours
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
              >
                Minutes
              </th>
              <th
                scope="col"
                className="py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Seconds
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {tasks?.slice(start, end).map((task) => (
              <Fragment key={task?.id}>
                <tr key={task.id} className="cursor-pointer hover:bg-gray-50">
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-normal text-gray-900 sm:pl-0">
                    {task.user}
                  </td>
                  <td
                    onClick={() => `candidates/viewcandidate/${task?.id}`}
                    className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-normal sm:pl-0"
                  >
                    {task.days}
                  </td>
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-normal text-gray-900 sm:pl-0">
                    10
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-900 sm:table-cell">
                    <span className="line-clamp-2">0:30</span>
                  </td>
                  <td className="whitespace-nowrap  py-4 pl-4 pr-3 text-sm font-medium  sm:pl-0">
                    0:0:30
                  </td>
                </tr>
              </Fragment>
            ))}
          </tbody>
        </table>
      </div>

      <div className="hidden sm:flex sm:flex-1 sm:items-center mt-4 sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{start + 1}</span> to{" "}
            <span className="font-medium">{end}</span> of{" "}
            <span className="font-medium">{tasks?.length}</span> results
          </p>
        </div>
        <div>
          <nav
            className="isolate inline-flex gap-x-3 -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            <button
              onClick={handlePrev}
              className="relative inline-flex items-center rounded-full px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            {/* Current: "z-10 bg-indigo-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600", Default: "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0" */}

            <a
              href="#"
              className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-[#94A4C4] ring-1 rounded-full ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              1
            </a>

            <button
              onClick={handleNext}
              className="relative inline-flex items-center rounded-full px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default TimeReports;
