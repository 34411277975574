import EditIcon from "@components/svgs/edit";
import Button from "@components/button";
import {useState} from "react";
import Drawer from "@components/drawer/Drawer";
import DeleteIcon from "@components/svgs/delete";
import Delete from "@pages/dashboard/cms/blogs/delete";
import AddEmergency from "@features/employees/addnewemergency";
import UpdateEmergency from "@features/employees/updateemergency";

function EmergencyContact() {
  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState(false);

  const [showDelete, setShowDelete] = useState(false);
  const toggleDelete = () => {
    setShowDelete(!showDelete);
  };

  const toggleEdit = () => {
    setEdit(!edit);
  };
  const toggleShow = () => {
    setShow(false);
  };

  const educations = [
    {
      id: 1,
      name: "Gifty Akuvi",
      contact: "+233 8473 843 3843",
      alternate: "+233 8473 313 3425",
      relationship: "Mother",
      email: "glas@gmail.com",
      address: "Accra, Ghana",
    },
    {
      id: 2,
      name: "Samuel Ransford",
      contact: "+233 4553 259 9503",
      alternate: "+233 8473 313 3425",
      relationship: "Brother",
      email: "sam@gmail.com",
      address: "Accra, Ghana",
    },
  ];
  return (
    <div className="px-3 py-3">
      <div className="flex w-full mt-5 pb-12 items-center gap-5">
        <div className="border-l w-full  gap-y-12 flex flex-col">
          {educations.map((work) => (
            <div key={work?.id} className="flex w-full relative">
              <svg
                width="12"
                height="12"
                className="absolute top-0 -left-1.5"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="6" cy="6" r="6" fill="#E96D17" />
              </svg>

              <div className="flex w-full relative">
                <div className="flex ms-5 flex-col gap-y-4">
                  <div className="">
                    <label className="text-sm text-custom_gray">Name: </label>
                  </div>
                  <div className="">
                    <label className="text-sm text-custom_gray">
                      Contact:{" "}
                    </label>
                  </div>
                  <div className="">
                    <label className="text-sm text-custom_gray">
                      Alternate Contact:{" "}
                    </label>
                  </div>
                  <div className="">
                    <label className="text-sm text-custom_gray">
                      Relationship:{" "}
                    </label>
                  </div>
                  <div className="">
                    <label className="text-sm text-custom_gray">
                      Email Address:{" "}
                    </label>
                  </div>
                  <div className="">
                    <label className="text-sm text-custom_gray">
                      Address:{" "}
                    </label>
                  </div>
                </div>

                <div className="flex ms-6 flex-col gap-y-4">
                  <div className="">
                    <span className="text-sm text-black">{work?.name}</span>
                  </div>
                  <div className="">
                    <span className="text-sm text-black">{work?.contact}</span>
                  </div>
                  <div className="">
                    <span className="text-sm text-black">
                      {work?.alternate}
                    </span>
                  </div>
                  <div className="">
                    <span className="text-sm text-black">
                      {work?.relationship}
                    </span>
                  </div>
                  <div className="">
                    <span className="text-sm text-black">{work?.email}</span>
                  </div>
                  <div className="">
                    <span className="text-sm text-black">{work?.address}</span>
                  </div>
                </div>
              </div>
              <div className="flex items-start justify-end">
                <div className="flex gap-x-4 ms-4">
                  <EditIcon onClick={toggleEdit} className="" />
                </div>
                <div onClick={toggleDelete} className="flex gap-x-4 ms-4">
                  <DeleteIcon />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="flex items-end justify-end mt-8">
        <div>
          <Button
            title="Add new emergency contact"
            className="w-1/6 px-5"
            onClick={() => setShow(true)}
          />
        </div>
      </div>

      <Drawer
        isOpen={show}
        onClose={toggleShow}
        drawerHeader="Emergency Contact"
      >
        <AddEmergency />
      </Drawer>
      <Drawer
        isOpen={edit}
        onClose={toggleEdit}
        drawerHeader="Update dependant"
      >
        <UpdateEmergency />
      </Drawer>

      <Delete
        open={showDelete}
        setOpen={() => setShowDelete(!open)}
        buttonTitle="Delete"
        selected={{title: "emergency contact", subtext: "Gifty Akuvi?"}}
      />
    </div>
  );
}

export default EmergencyContact;
