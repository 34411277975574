import {SVGProps} from "react";
const ActiveUsersIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={25}
    fill="none"
    {...props}
  >
    <path
      fill="#E96D17"
      d="M12.28 11.373a3.8 3.8 0 1 1 3.84-3.8 3.826 3.826 0 0 1-3.84 3.8Zm0-6.266a2.467 2.467 0 1 0 2.506 2.466 2.493 2.493 0 0 0-2.506-2.466Z"
    />
    <path
      fill="#E96D17"
      d="M12.28 11.373a3.8 3.8 0 1 1 3.84-3.8 3.826 3.826 0 0 1-3.84 3.8Zm0-6.266a2.466 2.466 0 1 0 2.506 2.466 2.494 2.494 0 0 0-2.507-2.466Zm2.326 7.16A13.733 13.733 0 0 0 5.939 13.6a1.18 1.18 0 0 0-.606 1.067v2.373a.667.667 0 1 0 1.333 0v-2.287a12.614 12.614 0 0 1 8-1.12l-.06-1.366Z"
    />
    <path
      fill="#E96D17"
      d="M22 15.169h-4.467v-.987a.667.667 0 0 0-1.334 0v.987h-4.866a.667.667 0 0 0-.667.667v6.666a.667.667 0 0 0 .667.667h10.666a.667.667 0 0 0 .667-.667v-6.666a.667.667 0 0 0-.667-.667Zm-.667 6.667h-9.334v-5.334h4.2v.274a.667.667 0 0 0 1.334 0v-.274h3.8v5.334Z"
    />
    <path
      fill="#E96D17"
      d="M14.54 18.779h3.974v.933h-3.973v-.933ZM7.228 8.659a12 12 0 0 0-5.3 1.333 1.114 1.114 0 0 0-.593.98v2.067a.667.667 0 1 0 1.333 0v-1.934a10.667 10.667 0 0 1 5.054-1.113 4.853 4.853 0 0 1-.494-1.333Zm14.847 1.326a11.867 11.867 0 0 0-4.747-1.333 4.973 4.973 0 0 1-.486 1.333c1.562.038 3.096.42 4.493 1.12v1.934a.667.667 0 1 0 1.333 0v-2.067a1.113 1.113 0 0 0-.593-.987ZM7.107 7.572v-.447a2.047 2.047 0 0 1 .36-4.073 2.1 2.1 0 0 1 1.467.593 5.44 5.44 0 0 1 1.133-.72 3.42 3.42 0 0 0-6 2.18A3.4 3.4 0 0 0 7.201 8.44a4.947 4.947 0 0 1-.094-.867Zm9.407-5.853a3.447 3.447 0 0 0-2.46 1.033c.45.163.877.388 1.267.667a2.093 2.093 0 0 1 3.286 1.68 2.06 2.06 0 0 1-1.193 1.846 4.76 4.76 0 0 1-.027 1.42A3.4 3.4 0 0 0 19.941 5.1a3.414 3.414 0 0 0-3.427-3.38Z"
    />
  </svg>
);
export default ActiveUsersIcon;
