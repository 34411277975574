 
import {SVGProps} from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={19}
    fill="currentColor"
    {...props}
  >
    <path
      fill="currentColor"
      fillOpacity={0.7}
      d="M8.003 3.5a2.997 2.997 0 1 1 5.994 0 2.997 2.997 0 0 1-5.994 0ZM11 2.003a1.497 1.497 0 1 0 0 2.994 1.497 1.497 0 0 0 0-2.994Zm4.5 2.247a2.25 2.25 0 1 1 4.5 0 2.25 2.25 0 0 1-4.5 0Zm2.25-.75a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5ZM4.25 2a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5ZM3.5 4.25a.75.75 0 1 1 1.5 0 .75.75 0 0 1-1.5 0ZM5.402 8A2.985 2.985 0 0 0 5 9.5H2v3.75a2.25 2.25 0 0 0 3.144 2.065c.111.497.285.97.51 1.413A3.749 3.749 0 0 1 .5 13.25V9.5A1.5 1.5 0 0 1 2 8h3.402Zm10.944 8.728A3.749 3.749 0 0 0 21.5 13.25V9.5A1.5 1.5 0 0 0 20 8h-3.402c.255.44.402.952.402 1.5h3v3.75a2.25 2.25 0 0 1-3.144 2.065c-.113.497-.285.97-.51 1.413ZM8 7.998a1.5 1.5 0 0 0-1.5 1.5V14a4.5 4.5 0 1 0 9 0V9.5A1.5 1.5 0 0 0 14 8l-6-.002Zm0 1.5h6V14a3 3 0 0 1-6 0V9.498Z"
    />
  </svg>
);
export default SvgComponent;
