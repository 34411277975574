const InstagramAlt = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 2H6C3.65279 2 2 3.65279 2 6V15C2 17.3472 3.65279 19 6 19H15C17.3472 19 19 17.3472 19 15V6C19 3.65279 17.3472 2 15 2Z"
        stroke="#232325"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.0001 6V6M14.0001 10C14.1022 10.6893 13.3207 11.3814 13.0001 12C12.6795 12.6186 12.6219 13.6867 12.0001 14C11.3783 14.3133 10.6874 14.1107 10.0001 14C9.31278 13.8893 8.49233 13.4928 8.00008 13C7.50783 12.5072 7.11068 11.688 7.00008 11C6.88948 10.312 6.6871 9.62246 7.00008 9C7.31306 8.37754 8.38217 8.32095 9.00008 8C9.61799 7.67905 10.3115 6.89778 11.0001 7C11.7025 7.10427 12.498 7.49737 13.0001 8C13.5022 8.50263 13.8959 9.29686 14.0001 10Z"
        stroke="#232325"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default InstagramAlt;
