import React from "react";
import Input from "@components/input";

import MyEditor from "@components/editor";
import Drawer from "@components/drawer/Drawer";
import ButtonV2 from "@components/ButtonV2/ButtonV2";
import {PlusIcon} from "@heroicons/react/24/outline";

function AddTools({isOpen, onClose}: {isOpen: boolean; onClose: () => void}) {
  const [summary, setSummary] = React.useState("");

  const [tools, setTools] = React.useState([
    {
      name: "",
      description: "",
    },
  ]);

  return (
    <Drawer isOpen={isOpen} onClose={onClose} drawerHeader="Add a tool">
      <div
        className={`grid grid-cols-1 gap-4 px-5 mt-4 mb-24 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4`}
      >
        {tools?.map(() => (
          <>
            <div className="md:col-span-4">
              <label className="  text-xs placeholder:text-custom_gray text-custom_gray">
                Tools:
              </label>
              <Input placeholder={"Name"} className={"mt-1 py-3"} />
            </div>

            <div className="col-span-4">
              <label className="  text-xs placeholder:text-custom_gray text-custom_gray">
                Description:{" "}
              </label>
              <div className="">
                <MyEditor
                  value={summary}
                  setValue={setSummary}
                  placeholder="Enter description here..."
                />
              </div>
            </div>
          </>
        ))}

        <div className="col-span-2 flex p-0 items-start">
          <button
            onClick={() => setTools([...tools, {name: "", description: ""}])}
            className={` text-xs transition-all duration-200 font-light font-outfit focus:outline-none  text-custom_blue flex items-center gap-2`}
          >
            <PlusIcon className="h-6 w-6" />

            <span>Add Another Tool</span>
          </button>
        </div>
        <div className="col-span-2 justify-end flex mt-12">
          <ButtonV2 onClick={() => console.log("updaete")} text={"Update"} />
        </div>
      </div>
    </Drawer>
  );
}

export default AddTools;
