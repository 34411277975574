import CustomDatePicker from "@components/datepicker";
import TableActions from "@components/tableActions/tableActions";
import { useState } from "react";
import ReportsEmptyState from "./reportsEmptyState";
import Dropdown from "@components/dropdowns/dropdown";
import TotalTimes from "./dataView/totalTimes";
import { Pagination } from "@components/pagination/Pagination";
import TimeReportsList from "./dataView/timeReportsList";

const CompanyTimeReports = () => {
  const [selectedDate, setSelectedDate] = useState("");
  const [company, setCompany] = useState("");
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(10);

  const handleNext = () => {
    setStart(start + 10);
    setEnd(end + 10);
  };

  const handlePrev = () => {
    setStart(start - 10);
    setEnd(end - 10);
  };

  const data = [];
  const hasReports = data.length > 0;
  return (
    <>
      <div className="flex gap-4 my-6">
        <div>
          <label className="text-xs placeholder:text-custom_gray text-custom_gray">
            Company:
          </label>
          <Dropdown
            list={[
              {
                id: "Doctors",
                name: "Doctors",
                value: "Doctors",
              },
              {
                id: "Emergency",
                name: "Emergency",
                value: "Emergency",
              },
              {
                id: "Funeral",
                name: "Funeral",
                value: "Funeral",
              },
            ]}
            selected={company}
            setSelected={setCompany}
          />
        </div>
        <div>
          <label className="text-xs placeholder:text-custom_gray text-custom_gray">
            Start date:{" "}
          </label>
          <CustomDatePicker
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
          />
        </div>
        <div>
          <label className="text-xs placeholder:text-custom_gray text-custom_gray">
            End date:{" "}
          </label>
          <CustomDatePicker
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
          />
        </div>
      </div>
      <div className="flex flex-col gap-5">
        {" "}
        <div className="bg-white p-4 rounded-lg">
          <div className="flex justify-between items-center">
            <h2 className="font-outfit font-semibold">All times</h2>
            <TableActions />
          </div>
          {!hasReports ? (
            <>
              <TimeReportsList />
              <Pagination
                start={start}
                end={end}
                total={data?.length}
                onPrevious={handlePrev}
                onNext={handleNext}
              />
            </>
          ) : (
            <ReportsEmptyState message="No time recorded yet..." />
          )}
        </div>
        <div className="bg-white p-4 rounded-lg">
          <div className="flex justify-between items-center">
            <h2 className="font-outfit font-semibold">Total time</h2>
            <TableActions />
          </div>
          {!hasReports ? (
            <>
              <TotalTimes />
              <Pagination
                start={start}
                end={end}
                total={data?.length}
                onPrevious={handlePrev}
                onNext={handleNext}
              />
            </>
          ) : (
            <ReportsEmptyState message="No time recorded yet..." />
          )}
        </div>
      </div>
    </>
  );
};

export default CompanyTimeReports;
