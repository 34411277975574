import PDFIcon from "./svgs/PDFIcon";

interface FileCardProps {
  file: {
    id: number;
    name: string;
    size: string;
    type: string;
  };
}
function FileCard({file}: FileCardProps) {
  return (
    <div className="border border-dashed rounded-md shadow-sm gap-x-4 px-3 py-5 flex items-center">
      <PDFIcon />

      <div className="flex flex-col">
        <span className="text-lg line-clamp-1">{file.name}</span>
        <span className="text-sm text-gray-400">{file.size}</span>
      </div>
    </div>
  );
}

export default FileCard;
