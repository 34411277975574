 
import {SVGProps} from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={25}
    fill="none"
    {...props}
  >
    <path
      stroke="#E94A74"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m5.5 8-3 .5c.815-3.757 3.182-4.997 5.5-5 1.498-.003 2.976.513 4 1 1.024-.487 2.5-1 4-1 2.305 0 4.685 1.243 5.5 5l-3-.5c.28 1.05.894 2.896 0 4.5-1.49-1.337-4.935-3.355-6.5-4.5-1.565 1.145-5.01 3.163-6.5 4.5-.894-1.604-.28-3.45 0-4.5Z"
    />
    <path
      stroke="#E94A74"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 8c-.377 1.944-.905 6.767 0 10.5"
    />
    <path
      stroke="#E94A74"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M6 21.5h12c-2.393-2.293-3.5-2.997-6-3-2.5-.003-5.054 1.691-6 3Z"
    />
  </svg>
);
export default SvgComponent;
