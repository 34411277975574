import {FolderArrowDownIcon, PhotoIcon} from "@heroicons/react/24/outline";
import React, {useCallback} from "react";
import {useDropzone} from "react-dropzone";

function FilePickerImage() {
  const [file, setFile] = React.useState<File | null>(null);
  const [tempUrl, setTempUrl] = React.useState<string>("");
  const onDrop = useCallback((acceptedFiles: any) => {
    setFile(acceptedFiles[0]);
    setTempUrl(URL.createObjectURL(acceptedFiles[0]));
  }, []);
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});
  return (
    <>
      {file ? (
        <div className="border px-5 py-4 flex items-center justify-between rounded-xl">
          <div className="flex items-center rounded-xl">
            <img src={tempUrl} className="rounded-full h-16 w-16" />
          </div>
          <button
            className="bg-custom_blue px-3 py-1 rounded-md text-white"
            onClick={() => setFile(null)}
          >
            Remove
          </button>
        </div>
      ) : (
        <div
          {...getRootProps()}
          className="flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-8"
        >
          <div className="text-center">
            {isDragActive ? (
              <div className="flex flex-col gap-4 items-center justify-center">
                <FolderArrowDownIcon className="h-14 text-custom_blue w-14" />
                <p>Drop the files here ...</p>
              </div>
            ) : (
              <>
                <PhotoIcon
                  className="mx-auto h-12 w-12 text-gray-300"
                  aria-hidden="true"
                />
                <div className="mt-4 flex text-sm leading-6 text-gray-600">
                  <label
                    htmlFor="file-upload"
                    className="relative cursor-pointer rounded-md bg-white font-semibold text-custom_blue focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                  >
                    <span>Upload a file</span>
                    <input
                      {...getInputProps()}
                      id="file-upload"
                      name="file-upload"
                      type="file"
                      onChange={(e) => {
                        setFile(e.target.files![0]);
                      }}
                      className="sr-only"
                    />
                  </label>
                  <p className="pl-1">or drag and drop</p>
                </div>
                <p className="text-xs leading-5 text-gray-600">
                  PNG, JPG, GIF up to 10MB
                </p>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default FilePickerImage;
