import {SVGProps} from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.7}
      d="m23.25 22.19-3.516-3.517a6.02 6.02 0 1 0-1.061 1.061l3.517 3.516 1.06-1.06ZM15 19.5a4.5 4.5 0 1 1 0-9 4.5 4.5 0 0 1 0 9Zm-9 0H3A1.502 1.502 0 0 1 1.5 18v-3H3v3h3v1.5ZM1.5 9H3v3H1.5V9Zm18-3H18V3h-3V1.5h3A1.501 1.501 0 0 1 19.5 3v3ZM9 1.5h3V3H9V1.5ZM3 6H1.5V3A1.502 1.502 0 0 1 3 1.5h3V3H3v3Z"
    />
  </svg>
);
export default SvgComponent;
