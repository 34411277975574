import DeleteIcon from "@components/svgs/delete";
import PencilIcon from "@components/svgs/pencil";

interface LocationData {
  location: string;
  address: string;
  contact: string;
}

interface LocationProps {
  locations: LocationData[];
}

const Location = ({ locations }: LocationProps) => {
  return (
    <>
      <div className="relative">
        <div className="absolute left-[5px] top-0 h-full border-l-2 border-gray-300"></div>
        <div className="space-y-8">
          {locations.map((locationData, index) => (
            <div key={index} className="relative flex items-start">
              <div className="relative z-10 w-3 h-3 rounded-full bg-orange-500"></div>
              <div className="ml-6 w-1/2 flex items-start justify-between">
                <div className="flex flex-col gap-4">
                  <h3 className="text-sm text-[#7C8DB5]">
                    Location:{" "}
                    <span className="text-[#232325] ml-9">
                      {locationData.location}
                    </span>
                  </h3>
                  <p className="text-sm text-[#7C8DB5]">
                    Address:{" "}
                    <span className="text-[#232325] ml-9">
                      {locationData.address}
                    </span>
                  </p>
                  <p className="text-sm text-[#7C8DB5]">
                    Contact:{" "}
                    <span className="text-[#232325] ml-9">
                      {locationData.contact}
                    </span>{" "}
                  </p>
                </div>
                <span className="flex align-middle gap-1">
                  <button onClick={() => {}}>
                    <PencilIcon />
                  </button>
                  <button onClick={() => {}}>
                    <DeleteIcon />
                  </button>
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="flex justify-end">
        <button
          onClick={() => {}}
          className="bg-custom_deep_blue text-white font-light font-outfit text-sm px-10 py-3 rounded-lg flex items-center gap-3"
        >
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.5 8.5H8.5V14.5H6.5V8.5H0.5V6.5H6.5V0.5H8.5V6.5H14.5V8.5Z"
              fill="white"
            />
          </svg>
          <span>Add a new location</span>
        </button>
      </div>
    </>
  );
};

export default Location;
