const NotificationsBell = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      {...props}
    >
      <path
        d="M12 22.5C11.45 22.5 10.9793 22.3043 10.588 21.913C10.1967 21.5217 10.0007 21.0507 10 20.5H14C14 21.05 13.8043 21.521 13.413 21.913C13.0217 22.305 12.5507 22.5007 12 22.5ZM18 13.5V10.5H15V8.5H18V5.5H20V8.5H23V10.5H20V13.5H18ZM4 19.5V17.5H6V10.5C6 9.11667 6.41667 7.88767 7.25 6.813C8.08333 5.73834 9.16667 5.034 10.5 4.7V4C10.5 3.58334 10.646 3.22934 10.938 2.938C11.23 2.64667 11.584 2.50067 12 2.5C12.416 2.49934 12.7703 2.64534 13.063 2.938C13.3557 3.23067 13.5013 3.58467 13.5 4V4.7C13.7333 4.76667 13.9627 4.83767 14.188 4.913C14.4133 4.98834 14.6257 5.084 14.825 5.2C14.2583 5.75 13.8127 6.39167 13.488 7.125C13.1633 7.85834 13.0007 8.65 13 9.5C13 11 13.475 12.3043 14.425 13.413C15.375 14.5217 16.5667 15.1923 18 15.425V17.5H20V19.5H4Z"
        fill="#FF9A40"
      />
    </svg>
  );
};

export default NotificationsBell;
