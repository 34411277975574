const PencilIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.547852 17.9368H17.4479C17.5805 17.9368 17.7076 17.8841 17.8014 17.7904C17.8952 17.6966 17.9479 17.5694 17.9479 17.4368C17.9479 17.3042 17.8952 17.177 17.8014 17.0832C17.7076 16.9895 17.5805 16.9368 17.4479 16.9368H0.547852C0.415243 16.9368 0.288066 16.9895 0.194298 17.0832C0.10053 17.177 0.0478516 17.3042 0.0478516 17.4368C0.0478516 17.5694 0.10053 17.6966 0.194298 17.7904C0.288066 17.8841 0.415243 17.9368 0.547852 17.9368ZM6.70985 14.1788C7.13225 14.0606 7.51766 13.8369 7.82985 13.5288L17.3699 3.9888C17.6966 3.66081 17.88 3.21674 17.88 2.7538C17.88 2.29086 17.6966 1.84679 17.3699 1.5188L16.4299 0.5888C16.0972 0.271055 15.6549 0.09375 15.1949 0.09375C14.7348 0.09375 14.2925 0.271055 13.9599 0.5888L4.41985 10.1188C4.11119 10.4289 3.89035 10.8154 3.77985 11.2388L3.03985 13.9988C3.00474 14.1249 3.00384 14.258 3.03723 14.3846C3.07062 14.5111 3.13711 14.6265 3.22985 14.7188C3.3714 14.8579 3.56137 14.9368 3.75985 14.9388L6.70985 14.1788ZM7.11985 12.8188C6.9355 13.0065 6.7041 13.1412 6.44985 13.2088L5.47985 13.4688L4.47985 12.4688L4.73985 11.4988C4.8098 11.2455 4.94416 11.0147 5.12985 10.8288L5.50985 10.4588L7.49985 12.4488L7.11985 12.8188ZM8.20985 11.7388L6.21985 9.7488L12.9499 3.0188L14.9399 5.0088L8.20985 11.7388ZM16.6599 3.2888L15.6499 4.2988L13.6599 2.3088L14.6699 1.2888C14.7393 1.21902 14.8219 1.16364 14.9129 1.12586C15.0038 1.08808 15.1014 1.06863 15.1999 1.06863C15.2983 1.06863 15.3959 1.08808 15.4868 1.12586C15.5778 1.16364 15.6604 1.21902 15.7299 1.2888L16.6599 2.2288C16.7993 2.36994 16.8776 2.56037 16.8776 2.7588C16.8776 2.95723 16.7993 3.14766 16.6599 3.2888Z"
        fill="#61A9FF"
      />
    </svg>
  );
};

export default PencilIcon;
