import Work from "./work";
import Education from "./education";
import Skills from "./skills";
import Contact from "./contact";
import AdditionalInformation from "./additionalinformation";

export default function Accordion() {
  return (
    <div className="w-full py-2">
      <div className="mx-auto w-ful rounded-2xl gap-y-2 flex flex-col">
        <div>
          <Work />
        </div>

        <div>
          <Education />
        </div>

        <div>
          <Skills />
        </div>

        <div>
          <Contact />
        </div>
        <div>
          <AdditionalInformation />
        </div>
      </div>
    </div>
  );
}
