type BadgeType =
  | "success"
  | "error"
  | "warning"
  | "info"
  | "neutral"
  | "default";

interface BadgeProps {
  type?: BadgeType;
  label: string;
  className?: string;
}

const Badge = ({ type = "default", label, className = "" }: BadgeProps) => {
  const baseStyles =
    "inline-flex items-center px-3 py-1 rounded-full text-xs font-light";

  const typeStyles = {
    success: "bg-[#DCFBEA] text-[#27CE88]",
    error: "bg-[#FFCFCA] text-[#DD0000]",
    warning: "bg-[#FDE4BC] text-[#FF9A40]",
    info: "bg-blue-100 text-blue-800",
    neutral: "bg-custom_faded_purple text-custom_deep_purple",
    default: "bg-gray-100 text-gray-800",
    leaveHoliday: "bg-[#FF9A40] text-white",
    leaveDoctor: "bg-[#7B61FF] text-white",
    leaveEducation: "bg-[#485575] text-white",
    leaveFuneral: "bg-[#232325] text-white",
  };

  return (
    <span className={`${baseStyles} ${typeStyles[type]} ${className}`}>
      {label}
    </span>
  );
};

export default Badge;
