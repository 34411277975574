import ButtonV2 from "@components/ButtonV2/ButtonV2";
import Dropdown from "@components/dropdowns/dropdown";
import Input from "@components/input";
import {useState} from "react";

function AddDependant() {
  const [type, setType] = useState("");
  const [name, setName] = useState("");
  const [relationship, setRelationship] = useState("");
  const [age, setAge] = useState("");
  const [emergencyContact, setEmergencyContact] = useState("");
  return (
    <div>
      <>
        <div className="gap-y-4 flex flex-col pb-10 overflow-auto">
          <div className="w-full flex-1 flex flex-col py-4 px-6 rounded-lg">
            <div className=" my-6 grid gap-x-4 gap-y-5 grid-cols-1">
              <div>
                <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                  Name:{" "}
                </label>
                <Input
                  placeholder={"eg. John Doe"}
                  className={"mt-1 h-10"}
                  value={name}
                  onChange={(e: any) => setName(e.target.value)}
                />
              </div>
              <div>
                <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                  Relationship:{" "}
                </label>
                <Input
                  placeholder={"eg. Brother"}
                  className={"mt-1 h-10"}
                  value={relationship}
                  onChange={(e: any) => setRelationship(e.target.value)}
                />
              </div>
              <div>
                <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                  Age:{" "}
                </label>
                <Input
                  placeholder={"eg. 23 years"}
                  className={"mt-1 h-10"}
                  value={age}
                  onChange={(e: any) => setAge(e.target.value)}
                />
              </div>

              <div>
                <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                  Gender:{" "}
                </label>
                <Dropdown
                  list={[
                    {
                      id: 1,
                      name: "Male",
                      value: "male",
                    },
                    {
                      id: 2,
                      name: "Female",
                      value: "female",
                    },
                  ]}
                  selected={type}
                  setSelected={setType}
                />
              </div>
              <div>
                <label className="text-xs placeholder:text-custom_gray text-custom_gray">
                  Contact:{" "}
                </label>
                <Input
                  placeholder={"eg. +233 234 234 234"}
                  className={"mt-1 h-10"}
                  value={emergencyContact}
                  onChange={(e: any) => setEmergencyContact(e.target.value)}
                />
              </div>
            </div>
            <div className="w-full pb-8 mt-4 col-span-10 flex justify-end items-end">
              <ButtonV2 text="Add dependency" onClick={() => {}} />
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default AddDependant;
