import LinkButton from "@components/ButtonV2/LinkButton";
import ConfirmModal from "@components/confirmationDialog/ConfirmationDialog";
import Table from "@components/DataTable";
import { TableColumn } from "@components/DataTable/types";
import { Pagination } from "@components/pagination/Pagination";
import DeleteIcon from "@components/svgs/delete";
import PencilIcon from "@components/svgs/pencil";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

interface JobData {
  id: number;
  jobTitle: string;
  company: string;
  numberOfOpenings: number;
  assignedRecruiter: string;
  jobType: string;
  actions?: string;
}

const sampleJobData: JobData[] = [
  {
    id: 213456,
    jobTitle: "Learning and development coordinator",
    company: "Google",
    numberOfOpenings: 1,
    assignedRecruiter: "Gift Avi",
    jobType: "Full-time",
  },
  {
    id: 213456,
    jobTitle: "Front-end Developer",
    company: "Transformation lead",
    numberOfOpenings: 5,
    assignedRecruiter: "Deli Florence",
    jobType: "Part-time",
  },
  {
    id: 213456,
    jobTitle: "Finance Analyst",
    company: "Catenge Innovations",
    numberOfOpenings: 3,
    assignedRecruiter: "Mathias Andy",
    jobType: "Contract",
  },
  {
    id: 213456,
    jobTitle: "Business Analyst",
    company: "Skype",
    numberOfOpenings: 4,
    assignedRecruiter: "Gift Avi",
    jobType: "Internship",
  },
  {
    id: 213456,
    jobTitle: "QA Engineer",
    company: "Transformation lead",
    numberOfOpenings: 2,
    assignedRecruiter: "Gift Avi",
    jobType: "Full-time",
  },
  {
    id: 213456,
    jobTitle: "Product Manager",
    company: "Catenge Innovations",
    numberOfOpenings: 1,
    assignedRecruiter: "Gift Avi",
    jobType: "Full-time",
  },
  {
    id: 213456,
    jobTitle: "Product Designer",
    company: "Transformation lead",
    numberOfOpenings: 7,
    assignedRecruiter: "Gift Avi",
    jobType: "Part-time",
  },
  {
    id: 213456,
    jobTitle: "Backend Engineer",
    company: "Transformation lead",
    numberOfOpenings: 5,
    assignedRecruiter: "Gift Avi",
    jobType: "Part-time",
  },
  {
    id: 213456,
    jobTitle: "UX Writer",
    company: "Transformation lead",
    numberOfOpenings: 6,
    assignedRecruiter: "Gift Avi",
    jobType: "Contract",
  },
];

const JobsList = () => {
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(10);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const navigate = useNavigate();

  const handleNext = () => {
    setStart(start + 10);
    setEnd(end + 10);
  };

  const handlePrev = () => {
    setStart(start - 10);
    setEnd(end - 10);
  };

  const colums: TableColumn<JobData>[] = [
    {
      key: "id",
      label: "ID",
      sortable: true,
    },
    {
      key: "jobTitle",
      label: "Job Title",
      sortable: true,
      render: (item, row) => (
        <LinkButton
          label={item}
          onClick={() => navigate(`/workspace/jobs/view/${row.id}`)}
        />
      ),
    },
    {
      key: "company",
      label: "Company",
      sortable: true,
    },
    {
      key: "numberOfOpenings",
      label: "Number of Openings",
      sortable: true,
    },
    {
      key: "assignedRecruiter",
      label: "Assigned Recruiter",
      sortable: true,
    },
    {
      key: "jobType",
      label: "Job Type",
      sortable: true,
    },
    {
      key: "actions",
      label: "Actions",
      render: () => (
        <span className="flex align-middle gap-1">
          <button>
            <PencilIcon />
          </button>
          <button onClick={() => setShowDeleteDialog(true)}>
            <DeleteIcon />
          </button>
        </span>
      ),
    },
  ];

  return (
    <div>
      <Table columns={colums} data={sampleJobData} />{" "}
      <Pagination
        start={start}
        end={end}
        total={sampleJobData?.length}
        onPrevious={handlePrev}
        onNext={handleNext}
      />
      <ConfirmModal
        isOpen={showDeleteDialog}
        onCancel={() => setShowDeleteDialog(false)}
        onDelete={() => {}}
        itemName="Paula Agyepong"
        subject="Are you sure you want to delete task"
      />
    </div>
  );
};

export default JobsList;
