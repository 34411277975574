import Button from "@components/ButtonV2/ButtonV2";
import NotesComponent from "./NotesComponent";
import SmallAvatar from "@assets/avatar6.png";
import Drawer from "@components/drawer/Drawer";
import { useState } from "react";
import AddNewNote from "./AddNewNote";
import { Pagination } from "@components/pagination/Pagination";
import NotesDetail from "./NotesDetail";

interface Note {
  id: string;
  title: string;
  content: string;
  timestamp: string;
  author: {
    name: string;
    avatarSrc: string;
  };
  accentColor: string;
}

const notes = [
  {
    id: "1",
    title: "Meeting with customer",
    content:
      "Reduce technical debt by refactoring legacy code and improving architecture design. Reduce technical debt by refactoring legacy code and improving architecture design. Reduce technical debt by refactoring legacy code and improving architecture design. Reduce technical debt by refactoring legacy code and improving architecture design.",
    timestamp: "12/01/2024 12:05pm",
    author: {
      name: "Fidelia Quaye",
      avatarSrc: SmallAvatar,
    },
    accentColor: "bg-indigo-500",
  },
  {
    id: "2",
    title: "Meeting with customer",
    content:
      "Reduce technical debt by refactoring legacy code and improving architecture design. Reduce technical debt by refactoring legacy code and improving architecture design. Reduce technical debt by refactoring legacy code and improving architecture design. Reduce technical debt by refactoring legacy code and improving architecture design.",
    timestamp: "12/01/2024 12:05pm",
    author: {
      name: "Fidelia Quaye",
      avatarSrc: SmallAvatar,
    },
    accentColor: "bg-indigo-500",
  },
  {
    id: "3",
    title: "Meeting with customer",
    content:
      "Reduce technical debt by refactoring legacy code and improving architecture design. Reduce technical debt by refactoring legacy code and improving architecture design. Reduce technical debt by refactoring legacy code and improving architecture design. Reduce technical debt by refactoring legacy code and improving architecture design.",
    timestamp: "12/01/2024 12:05pm",
    author: {
      name: "Fidelia Quaye",
      avatarSrc: SmallAvatar,
    },
    accentColor: "bg-indigo-500",
  },
  {
    id: "4",
    title: "Meeting with customer",
    content:
      "Reduce technical debt by refactoring legacy code and improving architecture design. Reduce technical debt by refactoring legacy code and improving architecture design. Reduce technical debt by refactoring legacy code and improving architecture design. Reduce technical debt by refactoring legacy code and improving architecture design.",
    timestamp: "12/01/2024 12:05pm",
    author: {
      name: "Fidelia Quaye",
      avatarSrc: SmallAvatar,
    },
    accentColor: "bg-indigo-500",
  },
];

const NotesTab = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(10);
  const [selectedNote, setSelectedNote] = useState<Note | null>(null);
  const [showNotesDetail, setShowNotesDetail] = useState(false);

  const handleNoteClick = (note: Note) => {
    setSelectedNote(note);
    setShowNotesDetail(true);
  };

  const handleNotesDrawerClose = () => {
    setSelectedNote(null);
    setShowNotesDetail(false);
  };

  const handleNext = () => {
    setStart(start + 10);
    setEnd(end + 10);
  };

  const handlePrev = () => {
    setStart(start - 10);
    setEnd(end - 10);
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <>
      <div className="py-8 bg-custom_faded_blue flex flex-col px-4">
        <Button
          variant="tertiary"
          text="+ Add a note"
          className="w-fit ml-auto mb-8"
          onClick={toggleDrawer}
        />
        <NotesComponent notes={notes} onNoteClick={handleNoteClick} />
        <Pagination
          start={start}
          end={end}
          total={notes?.length}
          onPrevious={handlePrev}
          onNext={handleNext}
        />
      </div>
      <Drawer
        drawerHeader="Add note"
        isOpen={isDrawerOpen}
        onClose={toggleDrawer}
      >
        <AddNewNote />
      </Drawer>
      <Drawer
        drawerHeader=""
        isOpen={showNotesDetail}
        onClose={handleNotesDrawerClose}
      >
        {selectedNote && (
          <NotesDetail
            onClose={handleNotesDrawerClose}
            notes={notes.slice(0, 2)}
            selectedNote={selectedNote}
          />
        )}
      </Drawer>
    </>
  );
};

export default NotesTab;
