import DeleteIcon from "@components/svgs/delete";
import PencilIcon from "@components/svgs/pencil";
import { ReactNode, useEffect } from "react";

interface DrawerProps {
  isOpen: boolean;
  onClose: (e?: any) => void;
  children: ReactNode;
  drawerHeader: string;
  hasActions?: boolean;
  onEdit?: () => void;
  onDelete?: () => void;
}

const Drawer = ({
  isOpen,
  onClose,
  children,
  drawerHeader,
  hasActions,
  onEdit,
  onDelete,
}: DrawerProps) => {
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isOpen]);

  return (
    <div
      className={`fixed inset-0 z-50 ${isOpen ? "" : "pointer-events-none"}`}
    >
      <div
        className={`absolute inset-0 bg-gray-800 transition-opacity duration-300 ${
          isOpen ? "opacity-50" : "opacity-0"
        }`}
        onClick={onClose}
      ></div>
      <div
        className={`fixed top-0 right-0 h-full mt-20 w-2/5 bg-white shadow-lg p-4 transform transition-transform duration-300 ${
          isOpen ? "translate-x-0" : "translate-x-full"
        } overflow-y-auto`}
      >
        <div className="flex text-center  justify-between px-6">
          <div className="flex gap-x-6 items-center">
            <span className="text-2xl font-medium">{drawerHeader}</span>

            {hasActions && (
              <div className="flex gap-x-3">
                <button onClick={onEdit}>
                  <PencilIcon />
                </button>
                <button onClick={onDelete}>
                  <DeleteIcon />
                </button>
              </div>
            )}
          </div>
          <button onClick={onClose} className="hover:text-gray-700">
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.9993 0.664062C6.62602 0.664062 0.666016 6.62406 0.666016 13.9974C0.666016 21.3707 6.62602 27.3307 13.9993 27.3307C21.3727 27.3307 27.3327 21.3707 27.3327 13.9974C27.3327 6.62406 21.3727 0.664062 13.9993 0.664062ZM13.9993 24.6641C8.11935 24.6641 3.33268 19.8774 3.33268 13.9974C3.33268 8.1174 8.11935 3.33073 13.9993 3.33073C19.8793 3.33073 24.666 8.1174 24.666 13.9974C24.666 19.8774 19.8793 24.6641 13.9993 24.6641ZM18.786 7.33073L13.9993 12.1174L9.21268 7.33073L7.33268 9.21073L12.1193 13.9974L7.33268 18.7841L9.21268 20.6641L13.9993 15.8774L18.786 20.6641L20.666 18.7841L15.8793 13.9974L20.666 9.21073L18.786 7.33073Z"
                fill="#DD0000"
              />
            </svg>
          </button>
        </div>
        {children}
      </div>
    </div>
  );
};

export default Drawer;
