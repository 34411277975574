import React, { ReactNode } from "react";

type ButtonVariant =
  | "primary"
  | "secondary"
  | "tertiary"
  | "warning"
  | "invincible";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariant;
  text: string;
  className?: string;
  icon?: ReactNode;
}

const ButtonV2 = ({
  variant = "primary",
  className = "",
  text,
  icon,
  ...props
}: ButtonProps) => {
  const baseStyles =
    "px-6 py-3 rounded-lg  text-xs transition-all duration-200 font-light font-outfit focus:outline-none";

  const variantStyles = {
    primary: "bg-custom_button_gray text-white text-sm hover:bg-slate-700",
    secondary:
      "bg-transparent text-[#7085A2] border-[0.5px] border-gray-200 hover:bg-slate-50",
    tertiary: "bg-custom_deep_blue text-sm justify-center text-white",
    warning: "bg-[#FB1073] text-white hover:bg-pink-600",
    invincible: "bg-transparent",
  };

  return (
    <button
      className={`${baseStyles} ${variantStyles[variant]} ${className}`}
      {...props}
    >
      {icon}
      {text}
    </button>
  );
};

export default ButtonV2;
