 
import {SVGProps} from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={25}
    fill="currentColor"
    {...props}
  >
    <path
      fill="currentColor"
      fillOpacity={0.7}
      d="M2.5 19.5v-12h19v12c0 1.1-.9 2-2 2h-15c-1.1 0-2-.9-2-2Z"
    />
    <path
      fill="#657A93"
      fillOpacity={0.27}
      d="M21.5 5.5v3h-19v-3c0-1.1.9-2 2-2h15c1.1 0 2 .9 2 2Z"
    />
    <path
      fill="#fff"
      fillOpacity={0.7}
      d="M16.5 7a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3ZM7.5 7a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"
    />
    <path
      fill="#BDBDBD"
      d="M16.5 2c-.55 0-1 .45-1 1v2.5c0 .55.45 1 1 1s1-.45 1-1V3c0-.55-.45-1-1-1Zm-9 0c-.55 0-1 .45-1 1v2.5c0 .55.45 1 1 1s1-.45 1-1V3c0-.55-.45-1-1-1Z"
    />
    <path
      fill="#657A93"
      fillOpacity={0.27}
      d="M11.1 18.15c0-.1 0-.25.05-.35.05-.1.1-.2.2-.25.1-.05.15-.15.25-.15.1-.05.25-.05.35-.05.1 0 .25 0 .35.05l.3.15c.1.05.15.15.2.25.05.1.05.2.05.35 0 .1 0 .25-.05.35-.05.1-.1.2-.2.25-.1.05-.15.15-.3.15s-.15.1-.3.1-.25 0-.35-.05c-.1-.05-.2-.1-.25-.15-.1-.05-.15-.15-.2-.25-.05-.15-.1-.25-.1-.4ZM12.65 16h-1.3l-.2-5.5h1.65l-.15 5.5Z"
    />
  </svg>
);
export default SvgComponent;
